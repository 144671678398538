import { FC, useState, useEffect, useMemo, useContext } from "react";
import { useTheme } from "@mui/material";
import { Button, Box } from "@ntpkunity/controls";
import { DrawerWrap, DataTableWrap } from "@sharedComponents";
import AddIndividualProduct from "./individual-products-drawer";
import FinancialInsuranceTable from "./individual-products-table";
import { IFinancialInsurance, IImage } from "Interface";
import { ActionOptions } from "Enums";
import { useForm } from "react-hook-form";
import { DealerContext } from "context";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

export declare type FinancialInsuranceProps = {
  isDisabled: boolean;
  s3Response: any;
  profileData: any;
  providerData: any;
};
const FinancialInsurance: FC<FinancialInsuranceProps> = ({
  isDisabled,
  s3Response,
  profileData,
  providerData,
}) => {
  const theme = useTheme();
  const { dealer_code } = useContext(DealerContext);
  const [popUpState, setPopUpState] = useState(false);
  const [enabledPin, setEnabledPin] = useState(false);
  const [images, setImages] = useState<Array<IImage>>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, dispatch] = useSetupsStore();

  const [actionTitle, setActionTitle] = useState<string>("Add New Product");

  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IFinancialInsurance>();
  const [ProductState, setProductState] = useState<boolean>(false);

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Product");
        setActionData(value);
        setProductState(true);
        setIsEditMode(false);
        dispatch(setOpenDrawer(true));
        setImages([]);
        form.reset({} as IFinancialInsurance);
      default:
        return;
    }
  };

  const defaultValues: IFinancialInsurance = useMemo(() => {
    const updatedFormDefaultValues = actionData;
    setActionData(updatedFormDefaultValues);
    return actionData;
  }, [actionData]) as unknown as IFinancialInsurance;

  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      form.reset({ ...defaultValues });
      setIsEditMode(true);
    } else {
      form.reset();
    }
  }, [defaultValues]);

  useEffect(() => {
    if (ProductState == false) {
      form.reset();
      dispatch(setOpenDrawer(false));
      setIsEditMode(false);
    }
  }, [ProductState]);

  const form: {
    handleSubmit: any;
    reset: any;
  } = useForm<IFinancialInsurance>();

  return (
    <DrawerWrap open={popUpState}>
      <form onSubmit={(e) => e.preventDefault()}>
        {dealer_code && ProductState && (
          <AddIndividualProduct
            popUpState={ProductState}
            setPopUpState={setProductState}
            title={actionTitle}
            actionType={actionType}
            financialInsuranceData={actionData}
            form={form}
            enabledPin={enabledPin}
            dealer_code={dealer_code}
            setImages={setImages}
            images={images}
            isDisabled={isDisabled}
            s3Response={s3Response}
            profileData={profileData}
            providerData={providerData}
            defaultValues={defaultValues}
            setIsEditMode={setIsEditMode}
            isEditMode={isEditMode}
          ></AddIndividualProduct>
        )}
      </form>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        {dealer_code && (
          <FinancialInsuranceTable
            setPopUpState={setProductState}
            setActionData={setActionData}
            setActionType={setActionType}
            setActionTitle={setActionTitle}
            actionData={actionData}
            dealer_code={dealer_code}
          ></FinancialInsuranceTable>
        )}
        <Button
          id="addNewOptionCategory"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Product"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
      </DataTableWrap>
    </DrawerWrap>
  );
};

export default FinancialInsurance;
