import { ActionOptions, ToastMessages } from "Enums";
import {
  useMutation_CheckDealerRegistrationWithPen,
  useMutation_CheckDealerRegistrationWithStripe,
  useUpdateLenderIntegration,
  useUpdateProviderStatus,
} from "services";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, Button, Icon, Menu, Tooltip } from "@ntpkunity/controls";
import { Switch } from "@sharedComponents";
import React, { FC, useEffect, useState } from "react";
import { useStoreContext } from "Stores/SetupStore/Store";
import integrationData from "utilities/IntegrationData";
import { getProviderName } from "utilities/common";
import { Providers, IntegrationType } from "Enums/IntegrationTypesAndProviders";

const DealerTableChildren: FC<{
  onEdit: (data: any, actionType: ActionOptions) => unknown;
  integrationsRow;
  onDelete: (data) => unknown;
  onProviderMapping: (id, integration_type) => unknown;
  penDealerId: any;
  dealer_code;
  detailedSubmited;
  marketScanConfig;
  shiftDigitalId;
  marketScanCredentials;
  setMarketScanCredentials;
  payoffDealerID;
  stripeDealerID;
  homenetDealerID;
}> = ({
  onEdit,
  integrationsRow,
  onDelete,
  dealer_code,
  penDealerId,
  detailedSubmited,
  marketScanConfig,
  shiftDigitalId,
  marketScanCredentials,
  setMarketScanCredentials,
  onProviderMapping,
  payoffDealerID,
  stripeDealerID,
  homenetDealerID,
}) => {
  const { mutate: updateIntegration } = useUpdateLenderIntegration();
  const { mutate: updateProviderStatus } = useUpdateProviderStatus();
  const [openDialog, setOpenDialog] = useState(false);
  const [providerId, setProviderId] = useState<number | undefined>(undefined);
  const { mutate: checkPenRegistration } =
    useMutation_CheckDealerRegistrationWithPen();
  const { mutate: checkStirpeRegistration } =
    useMutation_CheckDealerRegistrationWithStripe();
  const theme = useTheme();
  const { actions } = useStoreContext();

  useEffect(() => {
    if (marketScanConfig) {
      setMarketScanCredentials(true);
    }
  }, [marketScanCredentials, dealer_code]);

  const getTooltip = (provider_name: string) => {
    const tootltipIcon = (
      <Button
        defaultBtn
        className="pt-0 pb-0"
        iconText={<Icon name="InfoIcon" />}
      />
    );
    if (
      (provider_name == "PEN" && !penDealerId) ||
      (provider_name == "MarketScan" &&
        !marketScanCredentials &&
        !marketScanConfig) ||
      (provider_name == "ShiftDigital" && !shiftDigitalId) ||
      (provider_name == Providers.PAYOFF_ROUTEONE && !payoffDealerID) ||
      (provider_name == Providers.STRIPE && !stripeDealerID) ||
      (provider_name == Providers.HOMENET && !homenetDealerID)
    )
      return tootltipIcon;
    else if (
      (provider_name == "PEN" && penDealerId) ||
      (provider_name == "MarketScan" &&
        marketScanCredentials &&
        marketScanConfig) ||
      (provider_name == "ShiftDigital" && shiftDigitalId) ||
      (provider_name == Providers.PAYOFF_ROUTEONE && payoffDealerID) ||
      (provider_name == Providers.STRIPE && stripeDealerID) ||
      (provider_name == Providers.HOMENET && homenetDealerID)
    )
      return <></>;
  };

  const addOptions = (provider, integrationsRow) => {
    const options = [
      {
        optionText: (
          <>
            <Icon className="menu-icon" name="EditIcon" />
            {ActionOptions.EDIT}
          </>
        ),
        optionValue: {
          ...provider,
          integration_type: integrationsRow.integration_type,
        },
        optionkey: ActionOptions.EDIT,
      },
    ];

    if (
      ![
        Providers.PEN,
        Providers.MARKETSCAN,
        Providers.SHIFTDIGITAL,
        Providers.PAYOFF_ROUTEONE,
        Providers.STRIPE,
        Providers.HOMENET,
      ].includes(provider.provider_name)
    ) {
      options.push({
        optionText: (
          <>
            <Icon className="menu-icon" name="DeleteIcon" />
            {ActionOptions.DELETE}
          </>
        ),
        optionkey: ActionOptions.DELETE,
        optionValue: {
          ...provider,
          integration_type: integrationsRow.integration_type,
        },
      });
    }

    if (integrationsRow.integration_type === "Deal Push") {
      options.push({
        optionText: (
          <>
            <Icon className="menu-icon" name="EditIcon" />
            Provider Mapping
          </>
        ),
        optionValue: { provider },
        optionkey: ActionOptions.PROVIDER_MAPPING,
      });
    }
    return options;
  };

  const setSwitchValue = (provider, integrationsRow) => {
    if (provider?.provider_name == Providers.BMW_INVENTORY) {
      //For BMW it will be always be enabled and user cannot update it
      return true;
    }
    if (
      integrationsRow.is_company_level &&
      provider.dealer_providers.length == 0 &&
      !provider.dealer_code
    ) {
      return false;
    }
    if (provider?.dealer_providers.length == 1) {
      return provider?.dealer_providers[0]?.is_active;
    }
    if (provider?.dealer_code && provider?.dealer_providers.length == 0) {
      return provider?.is_active;
    }
  };

  const setDisablefield = (provider) => {
    if (provider?.provider_name == "MarketScan" && !marketScanCredentials) {
      return true;
    }
    if (provider?.provider_name == "PEN" && !penDealerId) {
      return true;
    }
    if (provider?.provider_name == "ShiftDigital" && !shiftDigitalId) {
      return true;
    }
    if (provider?.provider_name == "RouteOnePayOff" && !payoffDealerID) {
      return true;
    }
    if (provider?.provider_name == Providers.STRIPE && !stripeDealerID) {
      return true;
    }
    if (provider?.provider_name == "Homenet" && !homenetDealerID) {
      return true;
    }
    if (provider?.provider_name == Providers.BMW_INVENTORY) {
      return true;
    }
  };

  return (
    <>
      {integrationsRow?.providers?.map((provider, index) => (
        <TableRow key={`child${provider.id}`} className="child-tr">
          <TableCell>
            <Box
              theme={theme}
              display="flex"
              alignItems="center"
              sx={{ pl: 5 }}
            >
              {getProviderName(
                integrationsRow?.integration_type,
                provider?.provider_name
              )}
              <Tooltip theme={theme} title={"Configuration is missing"}>
                {getTooltip(provider?.provider_name)}
              </Tooltip>
            </Box>
          </TableCell>
          <TableCell>{provider?.dealer_code ? "Dealer" : "Company"}</TableCell>
          <TableCell>
            <Switch
              id={integrationsRow.id}
              theme={theme}
              varient={"basic"}
              switchEnabled={
                provider?.provider_name == Providers.BMW_INVENTORY
                  ? true
                  : setSwitchValue(provider, integrationsRow)
              }
              onChange={(event) => {
                integrationsRow?.is_company_level && !provider?.dealer_code
                  ? updateProviderStatus({
                      ...provider,
                      is_active: event.target.checked,
                      dealer_code: dealer_code,
                    })
                  : updateIntegration({
                      ...integrationsRow,
                      providers: integrationsRow.providers.map((providerObj) =>
                        providerObj.id === provider.id
                          ? { ...providerObj, is_active: event.target.checked }
                          : providerObj
                      ),
                    });
              }}
              label={
                setSwitchValue(provider, integrationsRow)
                  ? "Enabled"
                  : "Disabled"
              }
              disabled={setDisablefield(provider)}
            />
          </TableCell>
          <TableCell className="action-cell fixed-cell">
            {provider.dealer_code ||
            [
              Providers.PEN,
              Providers.MARKETSCAN,
              Providers.SHIFTDIGITAL,
              Providers.PAYOFF_ROUTEONE,
              Providers.STRIPE,
              Providers.HOMENET,
            ].includes(provider.provider_name) ? (
              <Menu
                theme={theme}
                options={addOptions(provider, integrationsRow)}
                handleOptionClick={(_event, _key, value) => {
                  switch (_key) {
                    case ActionOptions.EDIT:
                      onEdit(value, ActionOptions.EDIT);
                      break;
                    case ActionOptions.PROVIDER_MAPPING:
                      onProviderMapping(
                        provider.id,
                        integrationsRow?.integration_type
                      );
                      break;
                    case ActionOptions.DELETE:
                      if (value.provider_name === "PEN") {
                        checkPenRegistration(dealer_code, {
                          onSuccess: (response) => {
                            if (response.data == 0) {
                              onDelete(value);
                            } else {
                              actions.setToast({
                                toastMessage:
                                  ToastMessages.RECORD_ALREADY_IN_USE,
                                toastState: true,
                                variant: "error",
                              });
                            }
                          },
                        });
                      } else if (value.provider_name === "Stripe Connect") {
                        checkStirpeRegistration(
                          {},
                          {
                            onSuccess: (response) => {
                              if (response.data == 0) {
                                onDelete(value);
                              } else {
                                actions.setToast({
                                  toastMessage:
                                    ToastMessages.RECORD_ALREADY_IN_USE,
                                  toastState: true,
                                  variant: "error",
                                });
                              }
                            },
                          }
                        );
                      } else {
                        onDelete(value);
                      }
                      return;
                  }
                }}
                render={(onMenuSelection) => (
                  <Button
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={onMenuSelection}
                  ></Button>
                )}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
            ) : (
              ""
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
export default DealerTableChildren;
