import { FC, useEffect, useState, useContext, useMemo } from "react";
import { useTheme } from "@mui/material";
import { Box, Button, Icon } from "@ntpkunity/controls";
import { DataTableWrap, ConfirmationDialog } from "@sharedComponents";
import { IFinancialInsuranceBundle, IImage } from "Interface";
import ProductBundlesTable from "./product-bundles-table";
import AddNewProductBundles from "./product-bundles-drawer";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  useDeleteFinancialInsuranceBundleById,
  useDeleteBundleProductById,
} from "services/financial-insurance.service";
import { ActionOptions, DialogMessages } from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useForm } from "react-hook-form";
import { DealerContext } from "context";

export declare type FinancialInsuranceBundlePopup = {
  isDisabled: boolean;
  s3Response: any;
};
const ProductBundles: FC<FinancialInsuranceBundlePopup> = ({
  isDisabled,
  s3Response,
}) => {
  const form: {
    handleSubmit: any;
    reset: any;
    setValue: any;
  } = useForm<IFinancialInsuranceBundle>();
  const theme = useTheme();
  const [actionTitle, setActionTitle] = useState<string>("Add New Bundle");
  const { mutate: deleteFinancialInsuranceBundle } =
    useDeleteFinancialInsuranceBundleById();
  const { mutate: deleteBundleProduct } = useDeleteBundleProductById();
  const [actionData, setActionData] =
    useState<IFinancialInsuranceBundle>(undefined);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [state, dispatch] = useSetupsStore();
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const { dealer_code } = useContext(DealerContext);
  const [ProductBundlesState, setProductBundlesState] =
    useState<boolean>(false);
  const [productBunldeData, setProductbundleData] = useState(undefined);
  const [images, setImages] = useState<Array<IImage>>([]);
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Bundle");
        setActionData(value);
        setImages([]);
        setProductBundlesState(true);
        dispatch(setOpenDrawer(true));
        form.reset({});

      default:
        return;
    }
  };
  useEffect(() => {
    if (ProductBundlesState === false) {
      form.reset();
      dispatch(setOpenDrawer(false));
    }
  }, [ProductBundlesState]);

  const onEdit = (formData: IFinancialInsuranceBundle) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Bundle");
    setActionData(formData);
    setProductBundlesState(true);
    dispatch(setOpenDrawer(true));
  };

  const onDelete = (formData) => {
    if (formData?.product_id && formData?.bundle_id) {
      setProductbundleData(formData);
    } else {
      setActionData(formData);
    }
    setConfirmationPopupState(true);
  };

  const onDeleteConfirm = (e: any) => {
    if (productBunldeData) {
      deleteBundleProduct(productBunldeData);
    } else {
      deleteFinancialInsuranceBundle(actionData);
    }
  };

  const defaultValues: IFinancialInsuranceBundle = useMemo(() => {
    const updatedFormDefaultValues = actionData;
    setActionData(updatedFormDefaultValues);
    return actionData;
  }, [actionData]) as unknown as IFinancialInsuranceBundle;

  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      form.reset({ ...defaultValues });
    } else {
      form.reset({});
    }
  }, [defaultValues]);

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <ProductBundlesTable
          onEdit={onEdit}
          onDelete={onDelete}
          dealer_code={dealer_code}
        />
        <Button
          id="addNewOptionCategory"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Bundle"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
      </DataTableWrap>
      {ProductBundlesState && (
        <AddNewProductBundles
          openPopUp={ProductBundlesState}
          setPopUpState={setProductBundlesState}
          title={actionTitle}
          form={form}
          setImages={setImages}
          images={images}
          dealer_code={dealer_code}
          fpbundleData={actionData}
          actionType={actionType}
          isDisabled={isDisabled}
          s3Response={s3Response}
        />
      )}
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default ProductBundles;
