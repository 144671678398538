import { PaletteMode, createTheme } from "@mui/material";
import { unityTheme, unityDarkTheme } from "@ntpkunity/controls";
import "./font-family.css";

export const StyleVariables = {
  primary: "#3952F5", // Index Primary
  gradient: "linear-gradient(100deg, #FC4778 0%, #FC4778 0.01%, #3952F5 100%)", // Index Gradient
  // primary: "#0653b6", // BMW Primary
  // gradient: "#0653b6", // BMW Gradient
  textSecondary: "#4d4d4d",
  inputLableTextColor: "rgba(0,0,0,0.87)",
  inputBorderColor: "rgba(0,0,0,0.20)",
  inputHoverBorderColor: "rgba(0, 0, 0, 0.87)",
  inputPlaceholder: "rgba(0,0,0,0.87)",
  darkInputPlaceholder: "rgba(255,255,255,0.20)",
  darkInputBorder: "rgba(255,255,255,0.20)",
  darkInputDisableColor: "rgba(255, 255, 255, 0.40)",
  darkInputDisableBorder: "rgba(255,255,255,0.20)",
  darkPaperBg: "#252833",
  darkPaperBorder: "#515466",
  defaultFontFamily: "UnityFontFamily",
  lightGrey: "#F2F2F2",
  grey700: "#303340",
  grey800: "#1C1E26",
  grey900: "#12131A",
  black20: "rgba(0, 0, 0, 0.20)",
  darkWhite: "rgba(255, 255, 255, 0.87)",
  lightWhite: "rgba(255, 255, 255, 0.20)",
};

export const IndexTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: StyleVariables.primary },
  },
  typography: {
    ...unityTheme.typography,
    fontFamily: StyleVariables.defaultFontFamily,
  },
});

export const DarkTheme = createTheme({
  ...unityDarkTheme,
  palette: {
    ...unityDarkTheme.palette,
    mode: "dark",
    primary: { main: StyleVariables.primary },
  },
  typography: {
    ...unityTheme.typography,
    fontFamily: StyleVariables.defaultFontFamily,
  },
});
