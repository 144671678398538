import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  AddressType,
  QueryKeys,
  ValidationMessages,
  PermissionTypes,
} from "Enums";
import {
  Select,
  Input,
  Grid,
  MultiSelect,
  Icon,
  Typography,
  Box,
  Tooltip,
} from "@ntpkunity/controls";
import { CurrencyInputWrap, MultiSelectWrap } from "@sharedComponents";
import { Controller } from "react-hook-form";
import { validation } from "shared/helper/methods";
import { IGeneralProps } from "./general.type";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { ISelectItemList, ICompanyProfile, ICountry } from "Interface";
import { useQueryClient } from "react-query";
import { useHasPermissions, indexPermissions } from "@ntpkunity/controls-ums";
import { ACCESS_TOKEN_KEY } from "shared/config/constants";
import { LocalStorage } from "shared/helper/browserStorage";
import { EP_VERIFY_EMAIL } from "shared/config/endpoints";

const timeZone = [
  {
    text: "(GMT-04:00) Atlantic Time (Canada)",
    value: "Atlantic",
  },
  {
    text: "(GMT-05:00) Eastern Time (US and Canada)",
    value: "Eastern",
  },
  {
    text: "(GMT-06:00) Central Time (US and Canada)",
    value: "Central",
  },
  {
    text: "(GMT-07:00) Mountain Time (US and Canada)",
    value: "Mountain",
  },
  {
    text: "(GMT-08:00) Pacific Time (US and Canada)",
    value: "Pacific",
  },
  { text: "(GMT-09:00) Alaska", value: "Alaska" },
  { text: "(GMT-10:00) Hawaii", value: "Hawaii" },
  { text: "(GMT-11:00) Midway Island, Samoa", value: "Samoa" },
  {
    text: "(GMT+10:00) Chamorro Time",
    value: "Chamorro",
  },
];

const CompanyProfile: FC<IGeneralProps> = ({
  CheckDuplicateSlug,
  isUniqueSlug,
  trigger,
  setError,
  clearErrors,
  control,
  errors,
  setValue,
  getValues,
  setCompanyFieldState,
  setDefaultCurrency,
  defaultCurrency,
  selectedDefaultCurrencyId,
  setSelectedDefaultCurrencyId,
  setCompanyCountries,
  resetMailingAddress,
}) => {
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();

  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const allCountries: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_COUNTRIES
  );

  const alldefaultRadius: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_RADIUS
  );

  const { currencies } = state;
  const [Currencies, setCurrencies] = useState<Array<ISelectItemList>>();
  const [countries, setCountries] = useState<Array<ISelectItemList>>();
  const [defaultRadius, setDefaultRadius] = useState<ISelectItemList[]>([]);
  const [mileageUnit, setMileageUnit] = useState("");
  const [slugNameValidation, setSlugNameValidation] = useState<boolean>(false);
  const [isSlugDisabled, setIsSlugDisabled] = useState(false);
  const [selectedDefaultCurrency, setSelectedDefaultCurrency] =
    useState<string>("");
  const [hasTooltip, setHasTooltip] = useState(true);
  const [emailStatus, setEmailStatus] = useState<EmailStatus>("verify");
  const [email, setEmail] = useState("");
  const [isIconDisable, setIsIconDisable] = useState(false);

  const setDefaultDropdown = (values) => {
    let arr: Array<ISelectItemList> = [];
    if (values) {
      for (let i = 0; i < values?.length; i++) {
        let foundCurrency = currencies?.find((x) => x.id == values[i]);
        if (foundCurrency)
          arr.push({ text: foundCurrency.name, value: foundCurrency.id });
      }
      if (arr?.length > 0) {
        setValue("CompanyProfile.default_currency", arr?.[0]?.value, {
          shouldValidate: true,
        });
        setSelectedDefaultCurrencyId(Number(arr[0]?.value));
      } else if (arr?.length === 0 && values?.length === 0) {
        setValue("CompanyProfile.default_currency", "", {
          shouldValidate: true,
        });
      }
      setDefaultCurrency(arr);
    }
  };

  useEffect(() => {
    if (companyProfile) {
      localStorage.setItem("company_id", companyProfile?.id);
    }
  }, [companyProfile]);
  useEffect(() => {
    setSelectedDefaultCurrency(
      currencies?.filter(
        (currency) => currency.id === Number(selectedDefaultCurrencyId)
      )?.length > 0
        ? currencies?.filter(
            (currency) => currency.id === Number(selectedDefaultCurrencyId)
          )[0].symbol
        : ""
    );
  }, [selectedDefaultCurrencyId, companyProfile]);

  useEffect(() => {
    setCurrencies(
      currencies?.map((currency) => ({
        value: currency.id,
        text: currency.name,
      }))
    );
    let CompanyProfile: ICompanyProfile = companyProfile;
    setDefaultDropdown(
      CompanyProfile?.preferred_currency_ids?.split(",").map(Number)
    );
  }, [currencies, companyProfile]);
  useEffect(() => {
    setCountries(
      allCountries?.map((country) => ({
        value: country.id,
        text: country.name,
      }))
    );
  }, [allCountries]);

  const storedMileageUnit = getValues("CompanyProfile.mileage_unit");
  useEffect(() => {
    if (storedMileageUnit) {
      setMileageUnit(storedMileageUnit);
      setDefaultRadius(
        alldefaultRadius?.map((radius: any) => ({
          text: `${radius.radius_value} ${storedMileageUnit}`,
          value: radius.radius_value,
        }))
      );
    }
  }, [alldefaultRadius, storedMileageUnit]);

  const handleMileageUnitChange = (newValue) => {
    setValue("CompanyProfile.mileage_unit", newValue);
    setMileageUnit(newValue);
    setDefaultRadius(
      alldefaultRadius?.map((radius: any) => ({
        text: `${radius.radius_value} ${newValue || mileageUnit}`,
        value: radius.radius_value,
      }))
    );
  };

  useEffect(() => {
    if (companyProfile && companyProfile.slug) {
      setIsSlugDisabled(true);
    } else {
      setIsSlugDisabled(false);
    }
  }, [companyProfile]);

  const checkSlugName = (data: string) => {
    CheckDuplicateSlug(
      {
        data: `slug=${data}`,
      },
      {
        onSuccess: (response) => {
          setSlugNameValidation(false);
          trigger("CompanyProfile.slug");
          clearErrors("CompanyProfile.slug");
        },
        onError: (error) => {
          if (error.response && error.response.status === 400) {
            setSlugNameValidation(true);
            setError(
              "CompanyProfile.slug",
              {
                message: ValidationMessages.UNIQUE_SLUG,
              },
              { shouldFocus: true }
            );
          }
        },
      }
    );
  };

  type IconName = "InfoIcon" | "GreenCheckIcon";
  type EmailStatus = "verify" | "verified" | "cancelled" | "pending";

  const getTooltipContent = (status: EmailStatus) => {
    switch (status) {
      case "verify":
        return "Verify email address";
      case "verified":
        return "Email verified";
      case "cancelled":
        return "Email cancelled";
      case "pending":
      default:
        return "Verification pending";
    }
  };

  const getIconName = (status): IconName => {
    switch (status) {
      case "verify":
        return "InfoIcon";
      case "verified":
        return "GreenCheckIcon";
      case "cancelled":
        return "InfoIcon";
      case "pending":
      default:
        return "InfoIcon";
    }
  };

  const verifyEmails: () => void = () => {
    const _localStorage = new LocalStorage();
    const token = _localStorage.getItemByKey(ACCESS_TOKEN_KEY);

    const request = new XMLHttpRequest();
    const emailList = [email];

    const requestBody = JSON.stringify({ source_emails: emailList });

    request.open(
      "POST",
      `${process.env.INTEGRATION_API_GATEWAY}/${EP_VERIFY_EMAIL}`
    ); // Replace with your API endpoint
    request.setRequestHeader("Content-Type", "application/json");
    request.setRequestHeader("x-service-provider", "SES");
    request.setRequestHeader("Authorization", `Bearer ${token}`);

    request.send(requestBody);
    setEmailStatus("pending");
    setIsIconDisable(true);

    request.onload = () => {
      if (request.status === 200) {
        setEmailStatus("verified");
        const responseData = JSON.parse(request.response);
        console.log("Verification response:", responseData);
        setIsIconDisable(false);
      } else {
        console.error("Error during email verification:", request.statusText);
        setEmailStatus("cancelled");
        setIsIconDisable(false);
      }
    };

    request.onerror = () => {
      console.error("Request failed");
      setEmailStatus("cancelled");
      setIsIconDisable(false);
    };
  };

  return (
    <Grid theme={theme} container spacing={3}>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_Name">
        <Controller
          name="CompanyProfile.name"
          control={control}
          rules={{
            required: ValidationMessages.NAME_REQUIRED,
            pattern: {
              value: /^[A-Za-z0-9 ]+$/,
              message: ValidationMessages.COMPANY_NAME_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              theme={theme}
              id="company_name"
              inputClass="string"
              label="Company Name"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              placeholder="Type here..."
              type="string"
              value={value}
              wrapperClass="string"
              fullWidth={true}
              error={errors?.CompanyProfile?.name?.message}
            />
          )}
        />
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_TradingName"
      >
        <Controller
          name="CompanyProfile.trading_name"
          control={control}
          rules={{
            required: ValidationMessages.TRADING_NAME_REQUIRED,
            pattern: {
              value: /^[A-Za-z0-9 ]+$/,
              message: ValidationMessages.TRADING_NAME_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="company_trading_name"
              inputClass="string"
              label="Company Trading Name"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              placeholder="Type here..."
              theme={theme}
              type="string"
              wrapperClass="string"
              value={value}
              fullWidth={true}
              error={errors?.CompanyProfile?.trading_name?.message}
            />
          )}
        />
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CompanyWebsite"
      >
        <Controller
          name="CompanyProfile.url"
          control={control}
          rules={{
            required: ValidationMessages.URL_REQUIRED,
            pattern: {
              value:
                /(http:\/\/|https:\/\/|www\.|[a-zA-Z])+([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,6}/,
              message: ValidationMessages.INVALID_URL,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="company_url"
              inputClass="string"
              label="Company's Website"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              placeholder="www.sample.com"
              theme={theme}
              type="string"
              wrapperClass="string"
              value={value}
              error={errors?.CompanyProfile?.url?.message}
              fullWidth={true}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_email">
        <Controller
          name="CompanyProfile.email"
          control={control}
          rules={{
            required: ValidationMessages.EMAIL_REQUIRED,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: ValidationMessages.INVALID_EMAIL,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="company_email"
              inputClass="string"
              label="Email"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              theme={theme}
              placeholder={"sample@sample.com"}
              type="string"
              wrapperClass="string"
              value={value}
              error={errors?.CompanyProfile?.email?.message}
              fullWidth={true}
            />
          )}
        />
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CorrespondingEmail"
      >
        <Controller
          name="CompanyProfile.correspondence_email"
          control={control}
          rules={validation("Email Address", false)}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="correspondence_email"
              inputClass="string"
              label="Correspondence Email"
              onChange={(e) => {
                onChange(e);
                setEmail(e);
                setEmailStatus("verify");
                setCompanyFieldState(true);
              }}
              placeholder="sample@gmail.com"
              theme={theme}
              type="text"
              wrapperClass="string"
              endAdornment={
                <>
                  {hasTooltip ? (
                    <>
                      <Tooltip
                        placement={"left"}
                        title={
                          <Box theme={theme} className="tooltip-content">
                            <Typography
                              theme={theme}
                              variant="body2"
                              component="span"
                              display={"block"}
                            >
                              <span className="fw-bold">
                                {getTooltipContent(emailStatus)}
                              </span>{" "}
                            </Typography>
                          </Box>
                        }
                        theme={theme}
                      >
                        <Icon
                          className={
                            isIconDisable ? "icon-disabled" : "icon-clickable"
                          }
                          name={getIconName(emailStatus)}
                          onClick={() => verifyEmails()}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                </>
              }
              value={value?.trimStart() || ""}
              error={errors?.CompanyProfile?.url?.message}
              fullWidth={true}
            />
          )}
        />
      </Grid>

      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_taxId">
        <Controller
          name="CompanyProfile.tax_id"
          control={control}
          rules={{
            required: ValidationMessages.TAX_ID_REQUIRED,
            minLength: {
              value: 9,
              message: ValidationMessages.INVALID_TAX_ID_FORMAT,
            },
            maxLength: {
              value: 9,
              message: ValidationMessages.INVALID_TAX_ID_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Input
                id="company_tax_id"
                label="Tax ID"
                onChange={(e) => {
                  onChange(e);
                  if (e !== companyProfile?.tax_id) setCompanyFieldState(true);
                }}
                placeholder="00-0000000"
                theme={theme}
                type="text"
                value={value}
                error={errors?.CompanyProfile?.tax_id?.message}
                format="##-#######"
                masking={true}
                fullWidth={true}
                maskNumeric={true}
              />
            </>
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_mileageUnits"
      >
        <Controller
          name="CompanyProfile.mileage_unit"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              disablePortal
              id="company_mileage_unit"
              label={"Mileage Unit"}
              placeholder={"Select"}
              items={[
                { text: "Miles", value: "Miles" },
                { text: "Kilometers", value: "Kilometers" },
              ]}
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
                handleMileageUnitChange(e.target.value as string);
                setCompanyFieldState(true);
              }}
            />
          )}
        />
      </Grid>
      {useHasPermissions(
        indexPermissions.FIELDS.DEFAULT_SEARCH_RADIUS,
        PermissionTypes.VIEW
      ) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_default_search_radius"
        >
          <Controller
            name="CompanyProfile.default_radius"
            control={control}
            rules={{
              required: ValidationMessages.DEFAULT_RADIUS,
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                id={"company_default_search_radius"}
                theme={theme}
                disablePortal
                label={"Default Search Radius"}
                placeholder={"Select"}
                items={defaultRadius}
                value={value ?? ""}
                onChange={(e: any) => {
                  onChange(e.target.value);
                  setCompanyFieldState(true);
                }}
                selectError={errors?.CompanyProfile?.default_radius?.message}
                sxProps={{ m: 0, width: "100%" }}
              />
            )}
          />
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.INITIALS,
        PermissionTypes.VIEW
      ) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_initials"
        >
          <Controller
            name="CompanyProfile.initials"
            control={control}
            rules={{
              required: ValidationMessages.INITIALS_REQUIRED,
              maxLength: {
                value: 2,
                message: ValidationMessages.INVALID_INITIAL,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                formControlClass="string"
                id="company_initials"
                inputClass="string"
                label="Initials"
                onChange={(e) => {
                  onChange(
                    e
                      .replace(/[^a-z]/gi, "")
                      .toUpperCase()
                      .slice(0, 2)
                  );
                  setCompanyFieldState(true);
                }}
                placeholder="Type here..."
                theme={theme}
                type="text"
                wrapperClass="string"
                fullWidth={true}
                format="##"
                masking={false}
                maskNumeric={false}
                value={value}
                error={errors?.CompanyProfile?.initials?.message}
              />
            )}
          />
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.COUNTRY,
        PermissionTypes.VIEW
      ) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_preferred_currrency"
        >
          <MultiSelectWrap>
            <Controller
              name="CompanyProfile.countries"
              control={control}
              rules={{
                required: ValidationMessages.COUNTRY_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  // multi-select-all={true}
                  id={"company_countries"}
                  theme={theme}
                  label={"Country"}
                  placeholder={""}
                  items={countries}
                  value={value || []}
                  onChange={(e: any) => {
                    let arr: Array<ICountry> = [];
                    if (e.target.value) {
                      for (let i = 0; i < e.target.value?.length; i++) {
                        arr.push(
                          allCountries?.find((x) => x.id == e.target.value[i])
                        );
                      }
                    }
                    setCompanyCountries(arr);
                    setCompanyFieldState(true);
                    onChange(e);
                    const country_id = companyProfile.addresses?.find(
                      (x) => x.address_type == AddressType.MAILING
                    ).country_id;
                    if (!arr.find((x) => x.id == country_id)) {
                      resetMailingAddress();
                    }
                  }}
                  selectError={errors?.CompanyProfile?.countries?.message}
                  sxProps={{ m: 0, width: "100%" }}
                />
              )}
            />
          </MultiSelectWrap>
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.PREFERRED_CURRENCY,
        PermissionTypes.VIEW
      ) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_preferred_currrency"
        >
          <MultiSelectWrap>
            <Controller
              name="CompanyProfile.preferred_currency_ids"
              control={control}
              rules={{
                required: ValidationMessages.PREFERRED_CURRENCY_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  // multi-select-all={true}
                  id={"company_preferred_currrency"}
                  theme={theme}
                  label={"Preferred Currency"}
                  placeholder={""}
                  items={Currencies}
                  value={value || []}
                  onChange={(e: any) => {
                    setDefaultDropdown(e.target.value);
                    setCompanyFieldState(true);
                    onChange(e);
                  }}
                  selectError={
                    errors?.CompanyProfile?.preferred_currency_ids?.message
                  }
                  sxProps={{ m: 0, width: "100%" }}
                />
              )}
            />
          </MultiSelectWrap>
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.DEFAULT_CURRENCY,
        PermissionTypes.VIEW
      ) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_default_currency"
        >
          <Controller
            name="CompanyProfile.default_currency"
            control={control}
            rules={{
              required: ValidationMessages.DEFAULT_CURRENCY_REQUIRED,
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                id={"company_default_currency"}
                theme={theme}
                disablePortal
                label={"Default Currency"}
                placeholder={"Select"}
                value={defaultCurrency?.length == 0 ? "" : value ?? ""}
                selectError={errors?.CompanyProfile?.default_currency?.message}
                items={defaultCurrency}
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                  setSelectedDefaultCurrencyId(Number(e.target.value));
                }}
              />
            )}
          />
        </Grid>
      ) : null}
      {useHasPermissions(indexPermissions.FIELDS.SLUG, PermissionTypes.VIEW) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_CompanySlug"
        >
          <Controller
            name="CompanyProfile.slug"
            control={control}
            rules={{
              validate: {
                checkUniqueSlug: () => {
                  if (slugNameValidation) {
                    return ValidationMessages.UNIQUE_SLUG;
                  }
                  return true;
                },
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                formControlClass="string"
                id="company_slug"
                inputClass="string"
                label="Slug (Optional)"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                onBlur={(e) => {
                  if (e.target.value.trim() !== "") {
                    checkSlugName(e.target.value);
                  } else {
                    clearErrors("CompanyProfile.slug");
                  }
                }}
                placeholder="sample-slug"
                theme={theme}
                type="string"
                wrapperClass="string"
                value={value}
                fullWidth={true}
                error={errors?.CompanyProfile?.slug?.message}
                disabled={isSlugDisabled}
              />
            )}
          />
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.MIN_ODOMETER_PRIOR_USE,
        PermissionTypes.VIEW
      ) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_CompanyMinOdometer"
        >
          <Controller
            name="CompanyProfile.min_odometer_prior_use"
            control={control}
            render={({ field: { onChange, value } }) => {
              value = parseInt(value?.toString().split(".")[0]);
              return (
                <Input
                  formControlClass="string"
                  id="min_odometer_prior_use"
                  inputClass="string"
                  label="Min. Odometer for Prior Use"
                  onChange={(e) => {
                    e = parseInt(e.toString().split(".")[0]);
                    onChange(e);
                    setCompanyFieldState(true);
                  }}
                  placeholder="0"
                  theme={theme}
                  type="number"
                  wrapperClass="string"
                  value={value}
                  fullWidth={true}
                  maskNumeric={true}
                  maskDecimalScale={0}
                  endAdornment="Miles"
                />
              );
            }}
          />
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.CREDIT_DECISOIN_EXPIRY,
        PermissionTypes.VIEW
      ) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_CompanyCreditDecDays"
        >
          <Controller
            name="CompanyProfile.credit_decision_expiry_in_days"
            control={control}
            rules={{
              required: ValidationMessages.CREDIT_DECISION_REQUIRED,
              pattern: {
                value: /^[0-9]+$/,
                message: ValidationMessages.CREDIT_DECISION_REQUIRED,
              },
            }}
            render={({ field: { onChange, value } }) => {
              value =
                value?.toString()?.trim().length > 0
                  ? parseInt(value?.toString().split(".")[0])
                  : value;
              return (
                <Input
                  formControlClass="string"
                  id="credit_decision_expiry_in_days"
                  inputClass="string"
                  label="Credit Decision Expiry"
                  onChange={(e) => {
                    e =
                      e?.toString()?.trim().length > 0
                        ? parseInt(e?.toString().split(".")[0])
                        : e;
                    onChange(e);
                    setCompanyFieldState(true);
                  }}
                  placeholder="0"
                  theme={theme}
                  type="number"
                  wrapperClass="string"
                  value={value}
                  fullWidth={true}
                  error={
                    errors?.CompanyProfile?.credit_decision_expiry_in_days
                      ?.message
                  }
                  endAdornment="days"
                />
              );
            }}
          />
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.PRE_QUALIFICATION_EXPIRY,
        PermissionTypes.VIEW
      ) ? (
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_CompanyPreQualificationExpiryDays"
        >
          <Controller
            name="CompanyProfile.pre_qualification_expiry_in_days"
            control={control}
            render={({ field: { onChange, value } }) => {
              value =
                value?.toString()?.trim().length > 0
                  ? parseInt(value?.toString().split(".")[0])
                  : value;
              return (
                <Input
                  formControlClass="string"
                  id="pre_qualification_expiry_in_days"
                  inputClass="string"
                  label="Pre-Qualification Expiry (Optional)"
                  onChange={(e) => {
                    e =
                      e?.toString()?.trim().length > 0
                        ? parseInt(e?.toString().split(".")[0])
                        : null;
                    onChange(e);
                    setCompanyFieldState(true);
                  }}
                  placeholder="0"
                  theme={theme}
                  type="number"
                  wrapperClass="string"
                  value={value}
                  fullWidth={true}
                  error={
                    errors?.CompanyProfile?.pre_qualification_expiry_in_days
                      ?.message
                  }
                  endAdornment="days"
                />
              );
            }}
          />
        </Grid>
      ) : null}
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CompanyTimeZone"
      >
        <Controller
          name="CompanyProfile.time_zone"
          control={control}
          defaultValue={""}
          rules={validation("Time Zone", true)}
          render={({ field }) => (
            <Select
              disablePortal
              theme={theme}
              label={"Time Zone"}
              items={timeZone}
              {...field}
              value={field.value ?? ""}
              onChange={field.onChange}
              sxProps={""}
              selectError={errors?.CompanyProfile?.time_zone?.message}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_DayLightSaving"
      >
        <Controller
          name="CompanyProfile.day_light_saving"
          control={control}
          defaultValue={null}
          rules={validation("Daylight Saving Applicable", true)}
          render={({ field }) => (
            <Select
              disablePortal
              theme={theme}
              label={"Daylight Saving Applicable"}
              items={[
                { text: "Yes", value: true },
                { text: "No", value: false },
              ]}
              {...field}
              value={field.value ?? ""}
              sxProps={""}
              onChange={(e) => {
                field.onChange(e);
              }}
              selectError={errors?.CompanyProfile?.day_light_saving?.message}
            />
          )}
        />
      </Grid>
      {useHasPermissions(
        indexPermissions.FIELDS.COMPANY_CODE,
        PermissionTypes.VIEW
      ) ? (
        <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_email">
          <Controller
            name="CompanyProfile.company_code"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                name="CompanyProfile.company_code"
                formControlClass="string"
                id="company_code"
                inputClass="string"
                label="Company Code"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                theme={theme}
                placeholder={"Type here..."}
                type="string"
                wrapperClass="string"
                value={value?.trimStart() || ""}
                error={errors?.CompanyProfile?.company_code?.message}
                fullWidth={true}
              />
            )}
          />
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.CONTACT_PERSON_EMAIL,
        PermissionTypes.VIEW
      ) ? (
        <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_email">
          <Controller
            name="CompanyProfile.contact_person_email"
            control={control}
            rules={{
              required: false,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: ValidationMessages.INVALID_EMAIL,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                name="CompanyProfile.contact_person_email"
                formControlClass="string"
                id="contact_person_email"
                inputClass="string"
                label="Contact Person Email"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                theme={theme}
                placeholder={"sample@sample.com"}
                value={value?.trimStart() || ""}
                type="string"
                wrapperClass="string"
                error={errors?.CompanyProfile?.contact_person_email?.message}
                fullWidth={true}
              />
            )}
          />
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.CONTACT_PERSON_NAME,
        PermissionTypes.VIEW
      ) ? (
        <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_email">
          <Controller
            name="CompanyProfile.contact_person_name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                name="CompanyProfile.contact_person_name"
                formControlClass="string"
                id="contact_person_name"
                inputClass="string"
                label="Contact Person Name"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                theme={theme}
                placeholder={"Type here..."}
                type="string"
                wrapperClass="string"
                value={value?.trimStart() || ""}
                error={errors?.CompanyProfile?.contact_person_name?.message}
                fullWidth={true}
              />
            )}
          />
        </Grid>
      ) : null}
      {useHasPermissions(
        indexPermissions.FIELDS.CONTACT_PERSON_PHONE_NUMBER,
        PermissionTypes.VIEW
      ) ? (
        <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_email">
          <Controller
            name="CompanyProfile.contact_person_phone_number"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                name="CompanyProfile.contact_person_phone_number"
                formControlClass="string"
                id="contact_person_phone_number"
                inputClass="string"
                label="Contact Person Phone"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                theme={theme}
                placeholder={"Type here..."}
                type="string"
                wrapperClass="string"
                value={value?.trimStart() || ""}
                error={
                  errors?.CompanyProfile?.contact_person_phone_number?.message
                }
                fullWidth={true}
              />
            )}
          />
        </Grid>
      ) : null}
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_email">
        <Controller
          name="CompanyProfile.domain"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="domain"
              inputClass="string"
              label="Domain"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              theme={theme}
              placeholder={"Type here..."}
              type="string"
              wrapperClass="string"
              value={value}
              error={errors?.CompanyProfile?.domain?.message}
              fullWidth={true}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyProfile;
