export enum TimeZone {
  "(GMT-12:00) Baker Island Time" = "-12:00",
  "(GMT-11:00) Niue Time" = "-11:00",
  "(GMT-11:00) Samoa Standard Time (SST)" = "-11:00",
  "(GMT-10:00) Hawaii-Aleutian Standard Time (HAST)" = "-10:00",
  "(GMT-09:00) Alaska Standard Time (AKST)" = "-09:00",
  "(GMT-08:00) Pacific Standard Time (PST)" = "-08:00",
  "(GMT-07:00) Mountain Standard Time (MST)" = "-07:00",
  "(GMT-06:00) Central Standard Time (CST)" = "-06:00",
  "(GMT-05:00) Eastern Standard Time (EST)" = "-05:00",
  "(GMT-04:00) Atlantic Standard Time (AST)" = "-04:00",
  "(GMT-03:00) Brasília Time (BRT)" = "-03:00",
  "(GMT-02:00) South Georgia Time" = "-02:00",
  "(GMT-01:00) Azores Standard Time" = "-01:00",
  "(GMT+00:00) Coordinated Universal Time (UTC)" = "+00:00",
  "(GMT+01:00) Central European Time (CET)" = "+01:00",
  "(GMT+02:00) Eastern European Time (EET)" = "+02:00",
  "(GMT+03:00) Moscow Standard Time (MSK)" = "+03:00",
  "(GMT+04:00) Gulf Standard Time" = "+04:00",
  "(GMT+05:00) Pakistan Standard Time" = "+05:00",
  "(GMT+06:00) Bangladesh Standard Time" = "+06:00",
  "(GMT+07:00) Indochina Time" = "+07:00",
  "(GMT+08:00) China Standard Time (CST)" = "+08:00",
  "(GMT+09:00) Japan Standard Time (JST)" = "+09:00",
  "(GMT+10:00) Australian Eastern Standard Time (AEST)" = "+10:00",
  "(GMT+10:00) Chamorro Standard Time (ChST)" = "+10:00",
  "(GMT+11:00) Magadan Time" = "+11:00",
  "(GMT+12:00) Fiji Time" = "+12:00",
}
