import { FC, useEffect, useState, useMemo } from "react";
import {
  useHasPermissions,
  indexPermissions,
  Validate,
} from "@ntpkunity/controls-ums";
import { PermissionTypes } from "Enums";
import { useNavigate } from "react-router-dom";
import { Company } from "pages";
import { APP_ROUTES } from "shared/config/links";

const DefaultRoute: FC = () => {
  const viewLenderProfile = useHasPermissions(
    indexPermissions?.ROUTING?.LENDER_PROFILE,
    PermissionTypes.VIEW
  );
  const viewDealerProfile = useHasPermissions(
    indexPermissions?.ROUTING?.DEALER_CONFIGURATIONS,
    PermissionTypes.VIEW
  );
  const [viewDealerProfilePermission, setViewDealerProfilePermission] =
    useState(false);
  const [viewLenderProfilePermission, setViewLenderProfilePermission] =
    useState(false);
  const [lenderRole, setLenderRole] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setViewDealerProfilePermission(viewDealerProfile);
  }, [viewDealerProfile]);
  useEffect(() => {
    setViewLenderProfilePermission(viewLenderProfile);
  }, [viewLenderProfile]);
  useEffect(() => {
    if (viewDealerProfilePermission && !viewLenderProfilePermission) {
      navigate(`${APP_ROUTES.Dealer_Configurations}`);
    } else {
      setLenderRole(true);
    }
  }, [viewDealerProfilePermission, viewLenderProfilePermission]);
  return lenderRole ? (
    <Validate
      permission={indexPermissions.ROUTING.LENDER_PROFILE}
      url={APP_ROUTES.COMPANY_PROFILE}
    >
      <Company />
    </Validate>
  ) : (
    <></>
  );
};

export default DefaultRoute;
