import { IBoxProps, unityTheme } from "@ntpkunity/controls";
import { DarkTheme, IndexTheme, StyleVariables } from "./theme";
import FbDarkmodeIcons from "../assets/images/fb-darkmode-icons.svg";
import { styled } from "@mui/system";

export const CustomTheme = styled("div")<Partial<IBoxProps>>(({ theme }) => ({
  "&.custom-theme": {
    ".MuiTypography-root": {
      fontFamily: StyleVariables.defaultFontFamily,
    },
    ".MuiButtonBase-root.MuiButton-root.btn": {
      "&.btn-primary": {
        border: "none",
        color: theme.palette.common.white,
        "&:hover": {
          background: theme.palette.grey[900] + "!important",
          backgroundColor: theme.palette.grey[900] + "!important",
          borderColor: theme.palette.grey[900] + "!important",
          color: [theme.palette.common.white] + "!important",
        },
        "&.Mui-disabled, &:disabled": {
          color: unityTheme.palette.grey[300],
        },
      },
      "&.with-icon:hover": {
        svg: {
          path: {
            stroke: theme.palette.common.white,
          },
        },
      },
      "&.btn-default": {
        "&.with-icon:hover": {
          svg: {
            path: {
              stroke: unityTheme.palette.grey[300],
            },
          },
        },
      },
    },
    ".MuiFormGroup-root.custom-checkbox-wrap .u-custom-control-label .custom-checkbox":
      {
        "&.Mui-disabled": {
          "svg .bg-color": {
            stroke: theme.palette.grey[200] + "!important",
            fill: theme.palette.grey[200] + "!important",
          },
        },
      },
    ".u-dialog.confirmation-dialog .u-dialog-action .btn.btn-secondary": {
      marginTop: 0,
    },
    ".MuiModal-root-MuiPopover-root-MuiMenu-root, .u-dropdown-menu": {
      ".u-dropdown-item": {
        "&:hover": {
          fontWeight: theme.typography.fontWeightRegular + "!important",
        },
      },
    },

    ".control-wrap": {
      ".table-wrap": {
        ".scroll-hide": {
          height: "82px !important",

          "&:after": {
            top: "44.5% !important",
          },
        },

        ".u-table-container": {
          scrollbarWidth: "initial !important",
          "@supports (-moz-appearance:none)": {
            scrollbarWidth: "thin",
          },
          "&::-webkit-scrollbar": {
            width: 7,
            height: 7,
            borderRadius: 8,
          },
          "&::-webkit-scrollbar-track": {
            padding: "0 1px",
            backgroundColor: "#f0f0f0",
            borderRadius: 8,
            marginTop: 90,
          },
          "&::-webkit-scrollbar-thumb": {
            transition: "all 0.3s",
            backgroundColor: "#cdcdcd",
            borderRadius: 8,
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#a6a6a6",
          },
        },
      },
    },
    ".u-phone-input": {
      ".react-tel-input input, .react-tel-input .form-control": {
        borderColor: StyleVariables.inputBorderColor,
        "&:hover": {
          borderColor: theme.palette.grey[900],
        },
        "&::placeholder": {
          color: StyleVariables.inputPlaceholder,
        },
        "&:focus": {
          borderColor: StyleVariables.primary,
        },
      },
      "&.u-input-focus": {
        ".react-tel-input input, .react-tel-input .form-control": {
          borderColor: StyleVariables.primary,
        },
      },
      ".u-input-label": {
        backgroundColor: theme.palette.common.white,
        color: StyleVariables.inputLableTextColor,
      },
      ".flag-dropdown": {
        ".selected-flag": {
          backgroundColor: "transparent",
        },
        "&.open": {
          width: "100%",
          ".country-list": {
            width: "100%",
            ".country": {
              "&:hover": {
                fontWeight: "initial",
              },
            },
          },
        },
      },
    },
    ".MuiButtonBase-root.MuiMenuItem-root.MuiTablePagination-menuItem": {
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
    },
    ".u-form-group": {
      ".MuiFormControl-root": {
        ".MuiInputBase-root.u-form-control.u-select ": {
          ".MuiPopover-root.MuiMenu-root.MuiModal-root": {
            ".MuiPaper-root.MuiMenu-paper": {
              ".MuiList-root.MuiMenu-list": {
                ".MuiButtonBase-root.MuiMenuItem-root.u-dropdown-item": {
                  transition: "all 200ms",
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.grey[100],
                    color: theme.palette.common.black,
                    fontWeight: theme.typography.fontWeightMedium,
                    ".custom-checkbox-wrap .u-custom-control-label .custom-checkbox.Mui-checked ~ .MuiFormControlLabel-label":
                      {
                        color: theme.palette.common.black,
                        fontWeight: theme.typography.fontWeightMedium,
                      },
                  },
                },
              },
            },
          },
        },
      },
      ".u-form-control:hover fieldset": {
        borderColor: theme.palette.grey[900],
      },
    },
    ".u-date-picker.u-form-group .u-form-control .MuiInputBase-formControl:hover fieldset":
      {
        borderColor: theme.palette.grey[900],
      },
    ".u-aside-wrap": {
      ".u-side-nav": {
        ".MuiDrawer-paper .side-nav-area-wrap .aside-content-wrap": {
          ".u-nav-wrap": {
            ".u-list-item:active, .u-list-item:focus": {
              backgroundColor: StyleVariables.primary,

              "svg path": {
                stroke: theme.palette.common.white + "!important",
              },
            },
          },
        },
      },
    },

    ".MuiTablePagination-actions .MuiButtonBase-root:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },

    //////////////////////////////////////////////////////////

    ".dark-mode": {
      body: {
        color: theme.palette.common.white,
      },
      ".MuiButtonBase-root.MuiButton-root.btn": {
        "&.btn-primary, &.btn-secondary": {
          "&:hover": {
            background: [theme.palette.common.white] + "!important",
            backgroundColor: [theme.palette.common.white] + "!important",
            color: unityTheme.palette.grey[900] + "!important",
          },
          "&.with-icon:hover": {
            svg: {
              path: {
                stroke: unityTheme.palette.grey[900] + "!important",
              },
            },
          },
        },
      },
      ".custom-buttons": {
        ".button-group .group-btn.btn-secondary": {
          "&:hover": {
            color: unityTheme.palette.grey[900],
          },
          "&.only-icon:hover": {
            svg: {
              path: {
                stroke: unityTheme.palette.grey[900],
              },
            },
          },
        },
        ".u-dropdown-menu .MuiPaper-root": {
          backgroundImage: "none",
          backgroundColor: StyleVariables.grey800,
          borderColor: StyleVariables.grey900,
          ".u-dropdown-item": {
            "&:hover, &.Mui-focusVisible": {
              backgroundColor: StyleVariables.grey700,
            },
          },
        },
      },
      ".u-form-group, .u-date-picker, .u-form-group.u-date-picker": {
        ".MuiInputBase-root, .u-form-control, .u-select, .MuiInputBase-root.u-form-control.u-select":
          {
            ".MuiOutlinedInput-notchedOutline, fieldset": {
              borderColor: "rgba(255,255,255,0.20)",
            },
            ".MuiInputLabel-formControl": {
              backgroundColor: unityTheme.palette.grey[900] + "!important",
              color: "rgba(255, 255, 255, 0.87) !important",
            },
            ".MuiInputBase-formControl .MuiInputBase-input, .MuiInputBase-input":
              {
                color: theme.palette.common.white,
              },
            ".MuiPopover-root.MuiMenu-root.MuiModal-root": {
              ".MuiPaper-root.MuiMenu-paper": {
                ".MuiList-root.MuiMenu-list": {
                  ".MuiButtonBase-root.MuiMenuItem-root.u-dropdown-item": {
                    "&.Mui-selected": {
                      color: theme.palette.common.white,
                      backgroundColor: unityTheme.palette.grey[500],
                      "&:hover": {
                        backgroundColor: unityTheme.palette.grey[500],
                      },
                      ".custom-checkbox-wrap .u-custom-control-label .custom-checkbox.Mui-checked ~ .MuiFormControlLabel-label":
                        {
                          color: theme.palette.common.white,
                        },
                    },
                    "&:hover": {
                      backgroundColor: unityTheme.palette.grey[600],
                    },
                  },
                },
              },
            },
            "&:hover fieldset": {
              borderColor: theme.palette.grey[400],
            },
            "&.Mui-error": {
              fieldset: {
                borderColor: theme.palette.error.main,
              },
              "~ .u-input-label, .MuiFormLabel-root.Mui-error": {
                color: theme.palette.error.main + "!important",
              },
            },
            ".MuiFormLabel-root.Mui-error": {
              color: theme.palette.error.main + "!important",
            },
          },
        ".MuiFormControl-root": {
          ".u-input-label": {
            backgroundColor: unityTheme.palette.grey[900] + "!important",
            color: "rgba(255, 255, 255, 0.87) !important",
          },
          ".MuiFormHelperText-root.u-help-text": {
            color: theme.palette.common.white,
          },
        },
        ".MuiPaper-root": {
          ".u-dropdown-item": {
            "&:hover": {
              backgroundColor: unityTheme.palette.grey[600],
            },
          },
        },
        ".MuiPopover-root .MuiPaper-root": {
          "&::-webkit-scrollbar-track": {
            backgroundColor: unityTheme.palette.grey[700],
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: unityTheme.palette.grey[500],
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: unityTheme.palette.grey[600],
          },
        },
        ".Mui-disabled, .u-form-control.Mui-disabled, .MuiInputBase-root.Mui-disabled":
          {
            color: StyleVariables.darkInputDisableColor,
            opacity: 0.65,
            fieldset: {
              borderColor: StyleVariables.darkInputDisableBorder,
            },
            "~ .MuiFormLabel-root, .MuiInputBase-input.Mui-disabled, .MuiSelect-select.Mui-disabled, .MuiInputBase-input":
              {
                color: StyleVariables.darkInputDisableColor,
                WebkitTextFillColor: StyleVariables.darkInputDisableColor,
              },
            ".MuiInputAdornment-root": {
              color: StyleVariables.darkInputDisableColor,
              WebkitTextFillColor: StyleVariables.darkInputDisableColor,
              opacity: 0.5,
              "svg path": {
                stroke: StyleVariables.darkInputDisableColor + "!important",
              },
            },
          },
        ".MuiFormLabel-root.Mui-disabled": {
          color: StyleVariables.darkInputDisableColor,
          WebkitTextFillColor: StyleVariables.darkInputDisableColor,
        },
      },

      ".u-custom-autocomplete": {
        ".MuiAutocomplete-popper .MuiPaper-root": {
          backgroundColor: unityTheme.palette.grey[800],
          borderColor: unityTheme.palette.grey[800],

          ".MuiAutocomplete-noOptions": {
            color: unityTheme.palette.grey[500],
          },

          ".MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused, .MuiAutocomplete-listbox .MuiAutocomplete-option:hover":
            {
              backgroundColor: unityTheme.palette.grey[600],
            },
        },
        "&.autocomplete-disabled": {
          ".MuiAutocomplete-root .u-form-group": {
            ".MuiFormLabel-root.Mui-disabled": {
              color: StyleVariables.darkInputDisableColor,
            },
            ".MuiInputBase-root.Mui-disabled": {
              ".MuiOutlinedInput-notchedOutline": {
                color: StyleVariables.darkInputDisableColor,
                borderColor: StyleVariables.darkInputDisableBorder,
              },
            },
          },
        },
        ".MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-inputRoot:hover fieldset":
          {
            borderColor: theme.palette.grey[400],
          },
      },
      ".MuiDialog-root, .u-dialog": {
        ".MuiDialog-container": {
          ".MuiPaper-root": {
            backgroundColor: unityTheme.palette.grey[800],
          },
        },
      },
      ".u-date-picker": {
        ".MuiPaper-root": {
          backgroundColor: unityTheme.palette.grey[800],

          ".MuiCalendarPicker-root": {
            color: theme.palette.common.white,

            ".MuiCalendarPicker-viewTransitionContainer": {
              ".MuiTypography-caption": {
                color: unityTheme.palette.grey[400],
              },
              ".PrivatePickersSlideTransition-root": {
                ".MuiPickersDay-root": {
                  backgroundColor: unityTheme.palette.grey[800],
                  color: unityTheme.palette.grey[200],

                  "&:hover": {
                    backgroundColor: unityTheme.palette.grey[700],
                  },

                  "&:not(.Mui-selected)": {
                    borderColor: unityTheme.palette.grey[500],
                  },
                },
              },
            },
          },
        },
      },
      ".submit-feedback": {
        ".radio-rating-group .custom-radio-wrap .MuiFormGroup-row .u-custom-control-label":
          {
            ".custom-radio:before": {
              backgroundImage: `url(${FbDarkmodeIcons})` + "!important",
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "top",
              backgroundSize: "173px",
              opacity: 1,
            },
            ".custom-radio:after": {
              backgroundColor: "transparent",
              background: StyleVariables.gradient,
            },
            "&.selected": {
              ".custom-radio:before": {
                backgroundPositionY: "-35px",
              },
            },
            "&:first-of-type": {
              ".custom-radio": {
                "&:before": {
                  backgroundPositionX: "left",
                },
              },
            },
            "&:nth-of-type(2)": {
              ".custom-radio": {
                "&:before": {
                  backgroundPositionX: "-35px",
                },
              },
            },
            "&:nth-of-type(3)": {
              ".custom-radio": {
                "&:before": {
                  backgroundPositionX: "-69px",
                },
              },
            },
            "&:nth-of-type(4)": {
              ".custom-radio": {
                "&:before": {
                  backgroundPositionX: "-103px",
                },
              },
            },
            "&:last-of-type": {
              ".custom-radio": {
                "&:before": {
                  backgroundPositionX: "right",
                },
              },
            },
          },
      },
      ".feedback-description .description img": {
        "&.fb-img-light": {
          display: "none",
        },
        "&.fb-img-dark": {
          display: "block",
        },
      },
      ".u-aside-wrap": {
        ".u-side-nav .MuiDrawer-paper .action-content .side-nav-actions .btn.with-icon:hover":
          {
            svg: {
              path: {
                stroke: theme.palette.grey[900],
              },
            },
          },
      },
      ".custom-drawer": {
        ".u-form-group": {
          ".u-input-label, .MuiFormLabel-root, .MuiFormLabel-root.MuiInputLabel-shrink":
            {
              backgroundColor: StyleVariables.darkPaperBg + "!important",
            },
          ".u-form-control": {
            ".MuiPaper-root": {
              backgroundColor: unityTheme.palette.grey[700],
              borderColor: unityTheme.palette.grey[700],
              backgroundImage: "none",
              ".MuiList-root": {
                ".nested-item:hover": {
                  backgroundColor: unityTheme.palette.grey[500],
                  ".custom-checkbox-wrap .u-custom-control-label .custom-checkbox svg":
                    {
                      fill: unityTheme.palette.grey[300],
                    },
                },
                ".MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiButtonBase-root":
                  {
                    "&:hover": {
                      backgroundColor: "transparent !important",
                    },
                    ".custom-checkbox-wrap:hover": {
                      backgroundColor: unityTheme.palette.grey[500],
                      ".u-custom-control-label .custom-checkbox svg": {
                        fill: unityTheme.palette.grey[300],
                      },
                    },
                  },
              },
            },
          },
          ".MuiPaper-root": {
            ".u-dropdown-item:hover": {
              backgroundColor: unityTheme.palette.grey[500],
            },
            ".u-dropdown-item.Mui-selected:hover": {
              backgroundColor: unityTheme.palette.grey[600],
            },
          },
          "&.u-date-picker .u-form-control .MuiFormLabel-root": {
            backgroundColor: StyleVariables.darkPaperBg + "!important",
          },
        },
        ".u-custom-autocomplete .MuiAutocomplete-popper .MuiPaper-root": {
          backgroundColor: unityTheme.palette.grey[700],
          borderColor: unityTheme.palette.grey[700],

          ".MuiAutocomplete-noOptions": {
            color: unityTheme.palette.grey[400],
          },

          ".MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused, .MuiAutocomplete-listbox .MuiAutocomplete-option:hover":
            {
              backgroundColor: unityTheme.palette.grey[500],
            },
        },
        ".u-date-picker .MuiPaper-root": {
          backgroundColor: unityTheme.palette.grey[700],
          ".MuiCalendarPicker-root .MuiCalendarPicker-viewTransitionContainer .PrivatePickersSlideTransition-root .MuiPickersDay-root":
            {
              backgroundColor: unityTheme.palette.grey[700],
            },
        },
        ".loader-wrap": {
          backgroundColor: "transparent",
        },
      },
      ".u-toast": {
        ".MuiSnackbarContent-root": {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
        },
        "&.u-toast-error .MuiSnackbarContent-root": {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
        },
      },
      ".control-wrap": {
        ".table-wrap": {
          ".scroll-hide, .u-table .u-table-head td, .u-table-body tr:nth-of-type(even) td":
            {
              backgroundColor: unityTheme.palette.grey[900],
            },
          ".u-table-container": {
            "&::-webkit-scrollbar-track": {
              backgroundColor: unityTheme.palette.grey[700],
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: unityTheme.palette.grey[500],
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: unityTheme.palette.grey[600],
            },
          },
        },
        ".custom-drawer": {
          ".MuiDrawer-paper": {
            backgroundColor: StyleVariables.darkPaperBg,
            borderColor: StyleVariables.darkPaperBorder,
            color: theme.palette.common.white,
          },
          ".u-form-group .MuiInputBase-root .MuiPopover-root.MuiMenu-root.MuiModal-root .MuiPaper-root.MuiMenu-paper .MuiList-root.MuiMenu-list .MuiButtonBase-root.MuiMenuItem-root.u-dropdown-item":
            {
              color: theme.palette.common.white,
            },
        },
        ".u-phone-input": {
          ".form-control": {
            borderColor: StyleVariables.darkInputBorder,
            "&::placeholder": {
              color: theme.palette.common.white,
            },
          },
          "&.u-input-focus .form-control": {
            borderColor: StyleVariables.primary,
          },
          ".MuiTypography-root": {
            backgroundColor: unityTheme.palette.grey[900],
            color: theme.palette.common.white,
          },
          ".flag-dropdown": {
            ".country-list": {
              backgroundColor: unityTheme.palette.grey[800],
              borderColor: unityTheme.palette.grey[800],
              ".country": {
                ".dial-code": {
                  color: unityTheme.palette.grey[300],
                },
                "&:hover": {
                  backgroundColor: unityTheme.palette.grey[700],
                  fontWeight: "initial",
                },
                "&.highlight": {
                  backgroundColor: unityTheme.palette.grey[600],
                },
              },
            },
          },
        },
        ".u-dropdown-menu .MuiMenu-paper": {
          borderColor: unityTheme.palette.grey[900],
          ".u-dropdown-item:hover": {
            backgroundColor: unityTheme.palette.grey[700],
          },
        },
      },
      ".u-phone-input": {
        ".react-tel-input input": {
          borderColor: StyleVariables.darkInputBorder,
          color: theme.palette.common.white,
          backgroundColor: "transparent",

          "&::placeholder": {
            color: theme.palette.common.white,
          },
        },
        ".MuiTypography-root": {
          backgroundColor: unityTheme.palette.grey[900],
          color: theme.palette.common.white,
        },
        ".flag-dropdown": {
          ".country-list": {
            backgroundColor: unityTheme.palette.grey[800],
            borderColor: unityTheme.palette.grey[800],
            ".country": {
              ".country-name": {
                color: theme.palette.common.white,
              },
              ".dial-code": {
                color: unityTheme.palette.grey[300],
              },
              "&:hover": {
                backgroundColor: unityTheme.palette.grey[700],
                fontWeight: "initial",
              },
              "&.highlight": {
                backgroundColor: unityTheme.palette.grey[600],
              },
            },
          },
        },
      },
      ".loader-wrap": {
        backgroundColor: unityTheme.palette.grey[800],
      },
      ".MuiTablePagination-actions .MuiButtonBase-root:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.09)",
      },
      ".img-wrap .btn-close": {
        backgroundColor: unityTheme.palette.grey[900],
      },
    },

    //////////////////////////////////////////////////////////

    ".MuiDrawer-docked.custom-drawer": {
      ".divider-with-text": {
        marginBottom: 16,
        ".divider-text": {
          top: -16,
        },
      },
      ".u-date-picker": {
        ".MuiPopperUnstyled-root": {
          transform: "translate(11px, 160px) !important",
        },
      },
    },
  },
}));
