import { FC, Dispatch, SetStateAction } from "react";
import { useTheme } from "@mui/material";
import {
  Accordion,
  Button,
  FileDragDrop,
  Grid,
  Icon,
  Input,
  PersistentDrawer,
  Select,
  Textarea,
} from "@ntpkunity/controls";

import {
  AccordionWrapSm,
  FileDragDropWrap,
  TextAreaWrap,
} from "@sharedComponents";

export declare type MarkupDrawerPopUp = {
  openPopUp: boolean;
  setPopUpState: Dispatch<SetStateAction<boolean>>;
  title: string;
};

const MarkupDrawer: FC<MarkupDrawerPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
}) => {
  const theme = useTheme();

  const AccordionContent: FC = () => {
    return (
      <Grid theme={theme} container columnSpacing={2} rowSpacing={3}>
        <Grid theme={theme} item xs={6}>
          <Input
            theme={theme}
            fullWidth={true}
            placeholder={"Type here.."}
            label={"Interest Rate From"}
            type="text"
            endAdornment="%"
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Input
            theme={theme}
            fullWidth={true}
            placeholder={"Type here.."}
            label={"Interest Rate To"}
            type="text"
            endAdornment="%"
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Input
            theme={theme}
            fullWidth={true}
            placeholder={"Type here.."}
            label={"Markup"}
            type="text"
            endAdornment="%"
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Input
            theme={theme}
            fullWidth={true}
            placeholder={"Type here.."}
            label={"Lender Fee"}
            type="text"
            startAdornment="$"
          />
        </Grid>
      </Grid>
    );
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  return (
    <>
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Select
              theme={theme}
              label={"Credit Score Range"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Lender"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Status"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <AccordionWrapSm theme={theme}>
              <Accordion
                theme={theme}
                varient="filters"
                items={[
                  {
                    content: <AccordionContent />,
                    isExpanded: true,
                    title: "Markup slab 1",
                    key: "string1",
                    actionBtn: (
                      <>
                        <Button
                          theme={theme}
                          defaultBtn
                          iconText={<Icon name="DeleteIcon" />}
                        />
                      </>
                    ),
                  },
                  {
                    content: <AccordionContent />,
                    isExpanded: true,
                    title: "Markup slab 2",
                    key: "string2",
                    actionBtn: (
                      <>
                        <Button
                          theme={theme}
                          defaultBtn
                          iconText={<Icon name="DeleteIcon" />}
                        />
                      </>
                    ),
                  },
                ]}
              />
            </AccordionWrapSm>
            <Button
              theme={theme}
              secondary
              text="Add New Markup Slab"
              fullWidth
            />
          </>
        }
        customFooter={<Button theme={theme} primary text="Save" fullWidth />}
      />
    </>
  );
};

export default MarkupDrawer;
