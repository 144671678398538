import React, { FC, useState } from "react";
import { useTheme, Link } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Icon,
  Menu,
  Input,
  DataTable,
  Switch,
} from "@ntpkunity/controls";

import VcImg from "../../../shared/assets/images/vc-img.png";

const MarkupTable: FC = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const ChildTr: FC = () => {
    return (
      <React.Fragment>
        <TableRow className="child-tr">
          <TableCell className="indent-cell" />
          <TableCell>CapitalOne</TableCell>
          <TableCell>0.75%, 1.5%</TableCell>
          <TableCell>
            <Switch theme={theme} varient={"basic"} label="Enabled" />
          </TableCell>
          <TableCell className="action-cell fixed-cell">
            <Menu
              theme={theme}
              options={[
                {
                  optionText: (
                    <>
                      <Icon className="menu-icon" name="EditIcon" /> Edit
                    </>
                  ),
                  optionValue: "edit",
                },
                {
                  optionText: (
                    <>
                      <Icon className="menu-icon" name="DeleteIcon" /> Delete
                    </>
                  ),
                  optionValue: "delete",
                },
              ]}
              render={(cb) => (
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="MoreIcon" />}
                  onClick={cb}
                />
              )}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const Tr: FC = () => {
    return (
      <>
        <TableRow className="child-tr">
          <TableCell>
            {open ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpen(!open)}
              />
            )}
            720 - 900
          </TableCell>
          <TableCell colSpan={3} />
          <TableCell className="action-cell fixed-cell">
            <Menu
              theme={theme}
              options={[
                {
                  optionText: (
                    <>
                      <Icon className="menu-icon" name="EditIcon" /> Edit
                    </>
                  ),
                  optionValue: "edit",
                },
                {
                  optionText: (
                    <>
                      <Icon className="menu-icon" name="DeleteIcon" /> Delete
                    </>
                  ),
                  optionValue: "delete",
                },
              ]}
              render={(cb) => (
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="MoreIcon" />}
                  onClick={cb}
                />
              )}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          </TableCell>
        </TableRow>
        {open ? (
          <>
            <ChildTr />
            <ChildTr />
            <ChildTr />
            <ChildTr />
          </>
        ) : null}
      </>
    );
  };
  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        sx={{ mb: 3 }}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Credit Score Range</TableCell>
              <TableCell>Lender Name</TableCell>
              <TableCell>Markup</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerNameSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerNameSearch"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"numberSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="numberFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"taxableSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="taxableFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerNameSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerNameSearch"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
          </>
        }
      />
    </>
  );
};
export default MarkupTable;
