import { useQuery, UseQueryResult } from "react-query";
import { QueryKeys } from "../../Enums/UseQueryKeys";
import { getUserByRole } from "../../services/dealer-management.service";
import {
  useGetAllPermissions,
  useHasPermissions,
  indexPermissions,
} from "@ntpkunity/controls-ums";
import { UserByRole } from "../../Types/userByRole";
import { PermissionTypes } from "../../Enums";

export const useGetUserByRole = (): UseQueryResult<UserByRole[]> => {
  const isBroker = useHasPermissions(
    indexPermissions.FIELDS.DEALER_DEFAULT_BROKER_FIELD,
    PermissionTypes.FIELD
  );
  const permissions = useGetAllPermissions();
  const roleId = permissions?.permissions?.[0]?.role_id;
  return useQuery<UserByRole[]>({
    queryKey: [QueryKeys.GET_USER_BY_ROLE],
    queryFn: () => getUserByRole(roleId),
    enabled: !!roleId && isBroker,
    retry: 2,
  });
};
