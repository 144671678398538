import { FC, useEffect, useState, useContext } from "react";
import { Controller } from "react-hook-form";
import { validation } from "shared/helper/methods";
import { useTheme } from "@mui/material";
import { useStoreContext } from "Stores/SetupStore/Store";
import { Input, Select, Grid, Box } from "@ntpkunity/controls";
import { Divider } from "@sharedComponents";
import { NEXT_SCREEN_URL } from "shared/config/constants";
import { useHasPermissions, indexPermissions } from "@ntpkunity/controls-ums";
import { useQueryClient } from "react-query";
import {
  useCreateUserService,
  useGetProductID,
  useGetRoleID,
  useGetUserById,
  useSaveDealer,
  useUpdateDealerById,
  useUpdateUserService,
  useCreateUserEntity,
  useGetUserSubscribedProducts,
  useCheckDealerAssociation,
  useGetEmailByUsername,
} from "services";
import { IDealer } from "Interface";
import {
  ValidationMessages,
  QueryKeys,
  ToastMessages,
  PermissionTypes,
} from "Enums";
import { useGetUserByRole } from "../../hooks/ums";
import { UserByRole } from "../../Types/userByRole";

const AddNewDealer: FC<{
  form: any;
  formData?: IDealer;
  isEditMode: boolean;
  setPopUpState: any;
  setIsEditMode: any;
  dealer_id: any;
}> = ({
  form,
  formData,
  isEditMode,
  setPopUpState,
  setIsEditMode,
  dealer_id,
}) => {
  const theme = useTheme();
  const {
    control,
    getFieldState,
    formState: { errors },
    getValues,
    setError,
    reset,
  } = form;
  const { mutate: saveDealer } = useSaveDealer();
  const { data: associatedBrokers } = useGetUserByRole();
  const { mutate: getProductId } = useGetProductID();
  const { mutate: getRoleId } = useGetRoleID();
  const { mutate: updateDealer } = useUpdateDealerById();
  const { mutate: updateUser } = useUpdateUserService();
  const { mutate: getUserById } = useGetUserById();
  const { mutate: getUserbyname } = useGetEmailByUsername();
  const { mutate: saveUser } = useCreateUserService();
  const { mutate: checkDealerAssociation } = useCheckDealerAssociation();
  const [tenantId, setTenantId] = useState<number>(null);
  const [dealexUser, setDealexUser] = useState<boolean>(false);
  const [dealerCodeStatus, setDealerCodeStatus] = useState(false);
  const [productId, setProductId] = useState<number>(null);
  const [roleId, setRoleId] = useState<number>(null);
  const [emailEnable, setEmailEnable] = useState<boolean>(false);
  const [userSubscribeProduct, setUserSubscribeProduct] = useState(false);
  const { actions } = useStoreContext();
  const settings = localStorage.getItem("settings");
  const access_token_local = settings ? JSON.parse(settings) : {};
  const isBrokerAdmin = access_token_local.role?.name === "Broker Admin";
  const isIndexAdmin = access_token_local.role?.name === "Admin";
  const isDealexUser = access_token_local.isDealex;

  const queryClient = useQueryClient();

  const companyDealers: Array<IDealer> = queryClient.getQueryData(
    QueryKeys.GET_ACTIVE_DEALERS
  );

  const { data: userProducts } = useGetUserSubscribedProducts();
  useEffect(() => {
    const found = userProducts?.some((item) => item?.name === "Lane");
    if (found) {
      setUserSubscribeProduct(true);
    }
  }, [userProducts]);

  useEffect(() => {
    if (!isEditMode) {
      setEmailEnable(false);
      return;
    }
    setTenantId(access_token_local?.tenant_id);
    if (isEditMode) {
      getUserbyname(
        {
          user_name:
            form?.formState?.defaultValues?.user_name ||
            form?.formState?.defaultValues?.email,
        },
        {
          onSuccess: (response) => {
            setEmailEnable(response?.data?.is_verified_email || false);
          },
        }
      );
    }
  }, [isEditMode]);

  useEffect(() => {
    setTenantId(access_token_local?.tenant_id);
    if (isDealexUser || isBrokerAdmin) {
      setDealexUser(true);
    }
    if (isDealexUser || isBrokerAdmin) {
      if (isEditMode) {
        getUserById(
          { user_id: form?.formState?.defaultValues?.user_id },
          {
            onSuccess: (response) => {
              setEmailEnable(response.data.is_verified_email);
            },
          }
        );
      }
    }
  }, [isEditMode]);

  const onSubmit = async (data: any) => {
    if (
      isDealexUser ||
      isBrokerAdmin ||
      (isIndexAdmin && userSubscribeProduct)
    ) {
      if (isEditMode) {
        if (
          getFieldState("contact_person_email").isTouched == true &&
          emailEnable != true &&
          data
        ) {
          updateUser(
            {
              user_id: form?.formState?.defaultValues?.user_id,
              user_name: data.contact_person_email,
              verification_url: NEXT_SCREEN_URL,
              product_id: productId,
              role: data?.role_id,
            },
            {
              onSuccess: () => {
                updateDealer(
                  {
                    ...data,
                  },
                  {
                    onSuccess: () => {
                      setIsEditMode(false);
                      reset();
                      onClose();
                    },
                    onError: (error) => {
                      if (error?.response?.status === 409) {
                        const errorDetail = error?.response?.data?.detail;
                        if (errorDetail.code)
                          setError("dealer_code", {
                            message: errorDetail.code,
                          });
                        if (errorDetail.email)
                          setError("contact_person_email", {
                            message: errorDetail.email,
                          });
                        if (errorDetail.user_name)
                          setError("user_name", {
                            message: errorDetail.user_name,
                          });
                        else {
                          setError("is_active", {
                            message: errorDetail,
                          });
                        }
                        return;
                      }
                      if (error?.response?.status === 410) {
                        actions.setToast({
                          toastMessage: error?.response?.data?.detail,
                          toastState: true,
                          variant: "error",
                        });
                      }
                    },
                  }
                );
              },
              onError: (error) => {},
            }
          );
        } else if (
          getFieldState("contact_person_email").isTouched == true &&
          emailEnable == true &&
          data
        ) {
          const user = {
            role_id: roleId,
            product_id: productId,
            tenant_id: tenantId,
            email: data.contact_person_email,
            verification_url: NEXT_SCREEN_URL,
          };
          saveUser(user, {
            onSuccess(response) {
              updateDealer(
                {
                  ...data,
                },
                {
                  onSuccess: () => {
                    setIsEditMode(false);
                    reset();
                    onClose();
                  },
                  onError: (error) => {
                    if (error?.response?.status === 409) {
                      const errorDetail = error?.response?.data?.detail;
                      if (errorDetail.code)
                        setError("dealer_code", { message: errorDetail.code });
                      if (errorDetail.email)
                        setError("contact_person_email", {
                          message: errorDetail.email,
                        });
                      if (errorDetail.user_name)
                        setError("user_name", {
                          message: errorDetail.user_name,
                        });
                      else {
                        setError("is_active", {
                          message: errorDetail,
                        });
                      }
                      return;
                    }
                  },
                }
              );
            },
            onError: (error) => {
              if (error?.response?.status === 409) {
                const errorDetail = error?.response?.data?.detail;
                setError("contact_person_email", {
                  message: errorDetail,
                });
                return;
              }
            },
          });
        } else {
          updateDealer(
            {
              ...data,
            },
            {
              onSuccess: () => {
                setIsEditMode(false);
                reset();
                onClose();
              },
              onError: (error) => {
                if (error?.response?.status === 409) {
                  const errorDetail = error?.response?.data?.detail;
                  if (errorDetail.code)
                    setError("dealer_code", { message: errorDetail.code });
                  if (errorDetail.email)
                    setError("contact_person_email", {
                      message: errorDetail.email,
                    });
                  if (errorDetail.user_name)
                    setError("user_name", {
                      message: errorDetail.user_name,
                    });
                  else {
                    setError("is_active", {
                      message: errorDetail,
                    });
                  }
                  return;
                }
                if (error?.response?.status === 410) {
                  actions.setToast({
                    toastMessage: error?.response?.data?.detail,
                    toastState: true,
                    variant: "error",
                  });
                }
              },
            }
          );
        }
      } else if (data) {
        const user = {
          role_id: roleId,
          product_id: productId,
          tenant_id: tenantId,
          email: data?.contact_person_email,
          verification_url: NEXT_SCREEN_URL,
          user_name: data?.user_name ?? data?.contact_person_email,
          first_name: data?.contact_person_first_name,
          last_name: data?.contact_person_last_name,
        };
        saveUser(user, {
          onSuccess(response) {
            setEmailEnable(response.data.verified_email);
            const userId = response?.data?.data?.user_id;
            saveDealer(
              {
                ...data,
                contact_person_role:
                  access_token_local.role.name === "Broker Admin"
                    ? "Broker Dealer Admin"
                    : "Dealer Admin",
                user_id: userId,
                role_id: roleId,
              },
              {
                onSuccess: (dealer_response) => {
                  reset();
                  onClose();
                },
                onError: (error) => {
                  if (error?.response?.status === 409) {
                    const errorDetail = error?.response?.data?.detail;
                    if (errorDetail.code)
                      setError("dealer_code", { message: errorDetail.code });
                    if (errorDetail.email)
                      setError("contact_person_email", {
                        message: errorDetail.email,
                      });
                    if (errorDetail.user_name)
                      setError("user_name", {
                        message: errorDetail.user_name,
                      });
                    return;
                  }
                  if (error?.response?.status === 410) {
                    actions.setToast({
                      toastMessage: error?.response?.data?.detail,
                      toastState: true,
                      variant: "error",
                    });
                  }
                },
              }
            );
          },
          onError: (error) => {
            if (error?.response?.status === 409) {
              actions.setToast({
                toastMessage: error?.response?.data?.detail,
                toastState: true,
                variant: "error",
              });
              return;
            }
            if (error?.response?.status === 410) {
              actions.setToast({
                toastMessage: error?.response?.data?.detail,
                toastState: true,
                variant: "error",
              });
            }
          },
        });
      }
    } else {
      if (data) {
        if (isEditMode) {
          updateDealer(
            {
              ...data,
            },
            {
              onSuccess: () => {
                setIsEditMode(false);
                reset();
                onClose();
              },
              onError: (error) => {
                if (error?.response?.status === 409) {
                  const errorDetail = error?.response?.data?.detail;
                  if (errorDetail.code)
                    setError("dealer_code", { message: errorDetail.code });
                  if (errorDetail.email)
                    setError("contact_person_email", {
                      message: errorDetail.email,
                    });
                  if (errorDetail.user_name)
                    setError("user_name", {
                      message: errorDetail.user_name,
                    });
                  return;
                }
                if (error?.response?.status === 410) {
                  actions.setToast({
                    toastMessage: error?.response?.data?.detail,
                    toastState: true,
                    variant: "error",
                  });
                }
              },
            }
          );
        } else {
          saveDealer(
            {
              ...data,
              contact_person_role:
                access_token_local.role.name === "Broker Admin"
                  ? "Broker Dealer Admin"
                  : "Dealer Admin",
            },
            {
              onSuccess: () => {
                reset();
                onClose();
                actions.setToast({
                  toastMessage: ToastMessages.Record_Added_Success,
                  toastState: true,
                });
              },

              onError: (error) => {
                reset(getValues());
                if (error?.response?.status === 409) {
                  const errorDetail = error?.response?.data?.detail;
                  if (errorDetail.code)
                    setError("dealer_code", { message: errorDetail.code });
                  if (errorDetail.email)
                    setError("contact_person_email", {
                      message: errorDetail.email,
                    });
                  if (errorDetail.user_name)
                    setError("user_name", {
                      message: errorDetail.user_name,
                    });
                  return;
                }
                if (error?.response?.status === 500) {
                  onClose();
                  actions.setToast({
                    toastMessage: error?.response?.data?.detail?.message,
                    toastState: true,
                    variant: "error",
                  });
                }
                if (error?.response?.status === 410) {
                  actions.setToast({
                    toastMessage: error?.response?.data?.detail,
                    toastState: true,
                    variant: "error",
                  });
                }
              },
            }
          );
        }
      }
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  useEffect(() => {
    var access_token_local = JSON.parse(localStorage.getItem("settings"));
    onSubmit(formData);
    if (dealexUser || isIndexAdmin) {
      getProductId(
        {
          product_name:
            access_token_local.role.name === "Broker Admin" ? "Link" : "Lane",
        },
        {
          onSuccess: (response) => {
            setProductId(response.data.id);
          },
        }
      );
      getRoleId(
        {
          role_name:
            access_token_local.role.name === "Broker Admin"
              ? "Broker Dealer Admin"
              : "Dealer Admin",
        },
        {
          onSuccess: (response) => {
            setRoleId(response.data.id);
          },
        }
      );
    }
  }, [formData, dealexUser, isIndexAdmin]);

  useEffect(() => {
    if (isEditMode) {
      checkDealerAssociation(
        { id: dealer_id },
        {
          onSuccess(response: any) {
            setDealerCodeStatus(response?.data);
          },
        }
      );
    } else {
      setDealerCodeStatus(false);
    }
  }, [isEditMode, dealer_id]);

  return (
    <>
      <Box
        theme={theme}
        className="divider-with-text"
        sx={{ mt: "0 !important" }}
      >
        <Box theme={theme} className="divider-text">
          Dealer Details
        </Box>
      </Box>
      <Controller
        name="dealer_name"
        control={control}
        defaultValue={""}
        rules={{
          maxLength: {
            value: 50,
            message: ValidationMessages.DEALER_NAME_MAX_LENGTH,
          },
          required: ValidationMessages.DEALER_NAME_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            id="dealerNameDrawer"
            name="dealerName"
            theme={theme}
            fullWidth={true}
            label={"Dealer Name"}
            type="text"
            startAdornment={undefined}
            endAdornment={undefined}
            {...field}
            value={field?.value?.trimStart()}
            error={errors?.dealer_name?.message}
          />
        )}
      />
      <Controller
        name="dealer_code"
        control={control}
        defaultValue={""}
        rules={{
          maxLength: {
            value: 50,
            message: ValidationMessages.DEALER_CODE_MAX_LENGTH,
          },
          required: ValidationMessages.DEALER_CODE_REQUIRED,
          validate: (value) => {
            if (isEditMode) {
              const dealerCodes = companyDealers
                ?.filter((x) => x.id !== form.getValues("id"))
                .map((x) => x.dealer_code.toLowerCase());

              if (dealerCodes && dealerCodes.includes(value.toLowerCase())) {
                return "Dealer code already exists";
              }
            } else {
              const dealerCodes = companyDealers?.map((x) =>
                x.dealer_code.toLowerCase()
              );

              if (dealerCodes && dealerCodes.includes(value.toLowerCase())) {
                return "Dealer code already exists";
              }
            }
            return true;
          },
        }}
        render={({ field }) => (
          <Input
            id="codeDrawer"
            name="codeDrawerName"
            theme={theme}
            fullWidth={true}
            label={"Code"}
            type="text"
            startAdornment={undefined}
            endAdornment={undefined}
            {...field}
            value={field?.value?.trimStart()}
            error={errors?.dealer_code?.message}
            disabled={dealerCodeStatus}
          />
        )}
      />
      <Controller
        name="chat"
        control={control}
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <Select
            id="selectDrawer"
            name="selectName"
            theme={theme}
            label={"Chat"}
            items={[
              { text: "Enabled", value: true },
              { text: "Disabled", value: false },
            ]}
            value={value}
            sxProps={""}
            onChange={onChange}
            selectError={errors?.chat?.message}
          ></Select>
        )}
      />
      {useHasPermissions(
        indexPermissions.FIELDS.DEALER_DEFAULT_BROKER_FIELD,
        PermissionTypes.FIELD
      ) && (
        <Controller
          name="associated_broker_id"
          control={control}
          defaultValue={null}
          render={({ field: { value, onChange } }) => (
            <Select
              id="selectAssociatedBroker"
              name="associatedBroker"
              theme={theme}
              label={"Associated Broker Admin"}
              items={associatedBrokers?.reduce(
                (verified, associatedBroker: UserByRole) => {
                  if (associatedBroker.is_verified_email) {
                    verified.push({
                      text: `${associatedBroker.first_name} ${
                        associatedBroker.last_name ?? ""
                      }`,
                      value: associatedBroker.user_id,
                    });
                  }
                  return verified;
                },
                []
              )}
              value={value}
              sxProps={""}
              onChange={onChange}
            ></Select>
          )}
        />
      )}

      <Box theme={theme} className="divider-with-text">
        <Box theme={theme} className="divider-text">
          Contact Person Details
        </Box>
      </Box>
      <Grid theme={theme} container rowSpacing={3} columnSpacing={2}>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="contact_person_first_name"
            control={control}
            defaultValue={""}
            rules={validation("First Name", true)}
            render={({ field }) => (
              <Input
                id="firstNameDrawer"
                name="firstName"
                theme={theme}
                fullWidth={true}
                label={"First Name"}
                type="text"
                startAdornment={undefined}
                endAdornment={undefined}
                {...field}
                value={field.value?.trimStart()}
                error={errors?.contact_person_first_name?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="contact_person_last_name"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Input
                id="lastNameDrawer"
                name="LastName"
                theme={theme}
                fullWidth={true}
                label={"Last Name (Optional)"}
                type="text"
                startAdornment={undefined}
                endAdornment={undefined}
                {...field}
                value={field?.value?.trimStart()}
                error={errors?.contact_person_last_name?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name="contact_person_email"
            control={control}
            defaultValue={""}
            rules={{
              required: ValidationMessages.EMAIL_REQUIRED,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: ValidationMessages.INVALID_EMAIL,
              },
            }}
            render={({ field }) => (
              <Input
                id="emailDrawer"
                name="emailName"
                theme={theme}
                disabled={emailEnable}
                fullWidth={true}
                label={"Email Address"}
                type="text"
                startAdornment={undefined}
                endAdornment={undefined}
                placeholder={"sample@sample.com"}
                {...field}
                value={field?.value?.trimStart()}
                error={errors?.contact_person_email?.message}
              />
            )}
          />
        </Grid>
        {useHasPermissions(indexPermissions.FIELDS.DEALER_USER_NAME) && (
          <Grid theme={theme} item xs={12}>
            <Controller
              name="user_name"
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  value: !!indexPermissions.FIELDS.DEALER_USER_NAME,
                  message: "User Name is required",
                },
                pattern: {
                  value: /^[^\s]+$/,
                  message: ValidationMessages.INVALID_USER_NAME,
                },
              }}
              render={({ field }) => (
                <Input
                  id="UserNameDrawer"
                  name="UserName"
                  theme={theme}
                  disabled={emailEnable}
                  fullWidth={true}
                  label={
                    indexPermissions.FIELDS.DEALER_USER_NAME
                      ? "User Name"
                      : "User Name (Optional)"
                  }
                  type="text"
                  startAdornment={undefined}
                  endAdornment={undefined}
                  {...field}
                  value={field?.value?.trimStart()}
                  error={errors?.user_name?.message}
                />
              )}
            />
          </Grid>
        )}
        <Grid theme={theme} item xs={12}>
          <Divider sx={{ mb: 3 }} />
          <Controller
            name="is_active"
            control={control}
            defaultValue={true}
            rules={{
              validate: (value) => {
                if (value == null) {
                  return "Status is required";
                }
                return true;
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                id="selectDrawer"
                name="selectName"
                theme={theme}
                label={"Status"}
                defaultValue={true}
                items={[
                  { text: "Enabled", value: true },
                  { text: "Disabled", value: false },
                ]}
                value={value}
                sxProps={""}
                onChange={onChange}
                selectError={errors?.is_active?.message}
              ></Select>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddNewDealer;
