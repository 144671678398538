import { FC, useContext, useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material";
import {
  Icon,
  PersistentDrawer,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog, Box } from "@sharedComponents";
import { validation } from "shared/helper/methods";
import { Controller, useForm } from "react-hook-form";
import {
  ActionOptions,
  QueryKeys,
  DialogMessages,
  ActionType,
  Provider,
} from "Enums";
import { useQueryClient } from "react-query";
import {
  UseQuery_getLenderIntegrations,
  useSaveIntegration,
  useUpdateLenderIntegration,
  UseGetHubexCredentials,
  UseSaveHubexCredentials,
  UseUpdateHubexCredentials,
  UseGetHubexCredentialsByProvider,
  useUpdateProviderStatus,
} from "services";
import { useStoreContext } from "Stores/SetupStore/Store";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import {
  usePenSetDealer,
  useStripeRegister,
  useStripeAccountLink,
  useGetStripeAccountById,
} from "services/integration-api.service";
import { logout } from "@ntpkunity/controls-ums";
import {
  useUpdatePenDealerId,
  useUpdateshiftDigitalDealerId,
  UseQuery_getAllStates,
  useUpdateStripeConnectId,
  useUnregisterDealerPen,
  useGetProfilebyDealerId,
} from "services/dealer-configurations.service";
import { useDeleteAllFinancialInsuranceByDealerId } from "services/financial-insurance.service";

import { IDealerProfile } from "Interface";
import integrationData from "utilities/IntegrationData";
import { getProviderName } from "utilities/common";
import {
  IntegrationType,
  Providers,
  IntegrationLevel,
} from "Enums/IntegrationTypesAndProviders";
import { DealerContext } from "context";
import DisableLoader from "shared/assets/images/loader-disabled.gif";
interface IFormInputs {
  id?: number;
  integration_type: string;
  provider_name: string;
  route: string;
  is_dealer_input_required: boolean;
  is_active: boolean;
  penDealerId: string;
  tax_service_type?: string;
  shiftDigitalId?: string;
  provider_configuration: object;
}
const defaultValues = {
  id: null,
  route: "",
  provider_name: "",
  is_active: true,
  tax_service_type: "",
};
const AddDealerIntegration: FC<any> = ({
  openPopUp,
  setPopUpState,
  title,
  dealer_code,
  actionType,
  selectedProvider,
  dealerIntegraions,
  penDealerId,
  isProfileDirty,
  dealerForm,
  addLenderProvider,
  setMarketScanCredentials,
  stripeDealerID,
}) => {
  const theme = useTheme();
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  UseQuery_getAllStates();
  const StatesData: any = queryClient.getQueryData(QueryKeys.GET_ALL_STATES);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
    watch,
  } = useForm<IFormInputs>();
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    reset();
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const { mutate: setDealer } = usePenSetDealer();
  const { mutate: updatePenDealerId } = useUpdatePenDealerId();
  const { mutate: updateShiftDigitalId } = useUpdateshiftDigitalDealerId();
  const { mutate: unRegisterDealerPen } = useUnregisterDealerPen();
  const { mutate: deleteAllFnI } = useDeleteAllFinancialInsuranceByDealerId();
  const {
    mutate: updateStripeConnectId,
    isLoading: updateStripeConnectIdLoading,
  } = useUpdateStripeConnectId();
  const { mutate: getStripeAccountID, isLoading: stipeRegisterLoading } =
    useStripeRegister();
  const { mutate: stripeAccountLink, isLoading: stripeAccountLinkLoading } =
    useStripeAccountLink();

  const { mutate: getHubexCredentials, data: hubexConfigurations } =
    UseGetHubexCredentials();
  const {
    mutate: getHubexCredentialsByProvider,
    data: hubexConfigurationsByProvider,
  } = UseGetHubexCredentialsByProvider();

  const { mutate: createIntegration } = useSaveIntegration();
  const { mutate: updateIntegration } = useUpdateLenderIntegration();
  const { mutateAsync: saveHubexCredentials } = UseSaveHubexCredentials();
  const { mutateAsync: updateHubexCredentials } = UseUpdateHubexCredentials();
  const { mutate: updateProviderStatus } = useUpdateProviderStatus();
  const navigate = useNavigate();
  const { mutate: getStripeAccountById } = useGetStripeAccountById();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [providerItems, setProviderItems] = useState([]);
  const [inputFields, setInputFields] = useState<any>(undefined);
  const [stateCode, setStateCode] = useState(null);
  const [saveButtonState, setSaveButtonState] = useState<boolean>(false);
  const integration_data_copy = JSON.parse(JSON.stringify(integrationData));
  const { dealerData } = useContext(DealerContext);
  const dealer_name = dealerData?.find(
    (x) => x.dealer_code == dealer_code
  )?.dealer_name;
  const [detailedSubmited, setDetailSubmitted] = useState<boolean>(false);
  const [stripeAccountLinkCreated, setStripeAccountLinkCreated] =
    useState<boolean>(false);
  const URL = useRef("");
  const [lenderIntegrations, setLenderIntegrations] = useState(
    JSON.parse(JSON.stringify(integrationData))
      .integration_types.filter(
        (integration) =>
          integration?.subscription === IntegrationLevel.DEALER ||
          integration?.subscription === IntegrationLevel.BOTH
      )
      .map((integration) => {
        if (
          integration.integration_type === IntegrationType.INVENTORY_MANAGEMENT
        ) {
          return {
            ...integration,
            provider_name: integration.provider_name.filter((provider) => {
              return (
                provider.value !== Providers.SHIFTDIGITAL ||
                provider.value !== Providers.HOMENET
              );
            }),
          };
        }
        if (
          integration.integration_type === IntegrationType.CUSTOMER_MANAGEMENT
        ) {
          return {
            ...integration,
            provider_name: integration.provider_name.filter(
              (provider) => provider.value !== Providers.ASCENT
            ),
          };
        }
        return integration;
      })
  );
  const [integrationOptions, setIntegrationOptions] = useState([]);

  useEffect(() => {
    setIntegrationOptions(
      lenderIntegrations.map((item) => {
        return {
          value: item?.integration_type,
          text: item?.integration_type,
        };
      })
    );
  }, [actionType]);
  useEffect(() => {
    const state = StatesData?.filter(
      (state) =>
        state.id === dealerForm.getValues("profile")?.dealer_address?.state_id
    );
    setStateCode(state && state.length > 0 ? state[0].code : null);
  }, [StatesData]);

  useEffect(() => {
    dealer_code && getHubexCredentials(dealer_code);
  }, [dealer_code]);
  const handleInputFields = (
    provider_name: string,
    integration_type = watch("integration_type")
  ) => {
    const providers: any = integration_data_copy?.integration_types?.find(
      (x) => x.integration_type == integration_type
    )?.provider_name;

    if (provider_name == "PEN" || provider_name == Providers.STRIPE) {
      setInputFields([]);
    } else if (provider_name == Providers.MARKETSCAN) {
      setInputFields(
        providers
          ?.find((x) => x.value == provider_name)
          ?.user_input.filter((item) => item.name == "account_number")
      );
    } else if (provider_name == Providers.SHIFTDIGITAL) {
      setInputFields(
        providers
          ?.find((x) => x.value == provider_name)
          ?.user_input.filter((item) => item.name == "shift_digital_id")
      );
    } else if (provider_name == Providers.PAYOFF_ROUTEONE) {
      setInputFields([]);
    } else if (provider_name == Providers.HOMENET) {
      setInputFields(
        providers
          ?.find((x) => x.value == provider_name)
          ?.user_input.filter((item) => item.name == "home_net_dealer_id")
      );
    } else {
      setInputFields(
        providers?.find((x) => x.value == provider_name)?.user_input
      );
    }
    const taxationServiceIntegration = lenderIntegrations.find(
      (integration) =>
        integration.integration_type ===
        IntegrationType.CALCULATION_AND_TAXATION
    );

    if (taxationServiceIntegration) {
      taxationServiceIntegration.providers.forEach((provider) => {
        if (provider.provider_name === Providers.MARKETSCAN) {
          setValue(
            "tax_service_type",
            taxationServiceIntegration.tax_service_type
          );
        }
      });
    }

    reset({ 
      integration_type: getValues("integration_type"),
      provider_name: provider_name,
      is_active: getValues("is_active"),
      route: getValues("route"),
      tax_service_type: getValues("tax_service_type"),
    });
  };
  const set_provider_status = () => {
    if (selectedProvider?.dealer_code) {
      return selectedProvider?.is_active;
    } else {
      if (selectedProvider?.dealer_providers.length > 0) {
        return selectedProvider?.dealer_providers.map(
          (provider) => provider.is_active
        );
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      const integration = dealerIntegraions?.find(
        (x) => x.integration_type == selectedProvider.integration_type
      );

      const provider = integration?.providers
        .filter(
          (provider) =>
            provider?.provider_name == selectedProvider.provider_name
        )
        .map(() =>
          getProviderName(
            selectedProvider.integration_type,
            selectedProvider.provider_name
          )
        );

      setIntegrationOptions([
        {
          text: selectedProvider.integration_type,
          value: selectedProvider.integration_type,
        },
      ]);
      setProviderItems([
        {
          text: provider[0],
          value: selectedProvider.provider_name,
        },
      ]);

      handleInputFields(
        selectedProvider.provider_name,
        integration?.integration_type
      );
      reset({
        integration_type: integration?.integration_type,
        provider_name: selectedProvider.provider_name,
        route: integration?.route,
        is_active: set_provider_status(),
        tax_service_type: integration?.tax_service_type,
      });
    } else if (
      actionType == ActionOptions.ADD_PROVIDER &&
      dealerIntegraions?.length > 0
    ) {
      const integration = dealerIntegraions?.find(
        (x) => x.integration_type == selectedProvider?.integration_type
      );
      const integrations = dealerIntegraions?.filter(
        (item) => item.integration_type === selectedProvider?.integration_type
      );
      const filteredProviders = integrations?.flatMap((item) =>
        item.providers
          ?.filter((x) => x.dealer_code != null)
          ?.map((provider) => provider.provider_name)
      );

      const filteredIntg = lenderIntegrations?.filter(
        (item) => item.integration_type === selectedProvider?.integration_type
      );

      const remainingProviders = filteredIntg
        ?.flatMap((item) =>
          item.provider_name?.map((provider) => ({
            name: provider.name,
            value: provider.value,
          }))
        )
        ?.filter(
          (providerName) => !filteredProviders?.includes(providerName.value)
        );

      const dropdownProvides = remainingProviders.map((provider) => ({
        text: provider.name,
        value: provider.value,
      }));

      setProviderItems(dropdownProvides);

      reset({
        integration_type: integration?.integration_type,
        route: integration?.route,
        is_active: selectedProvider?.is_active,
        tax_service_type: integration?.tax_service_type,
      });
    } else if (actionType == ActionOptions.ADD) {
      setInputFields(undefined);
      reset({
        id: null,
        tax_service_type: null,
        integration_type: null,
        provider_name: null,
        penDealerId: null,
        shiftDigitalId: null,
      });
      setProviderItems([]);
    }
  }, [selectedProvider, lenderIntegrations, dealerIntegraions, actionType]);

  const storeCredentialsAtHubex = async (data) => {
    let requestData: any = null;
    let isCredentialsAdded = true;
    let configuration_payload = null;
    switch (data.provider_name) {
      case Providers.BMW:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_id &&
          data?.client_secret &&
          data?.scope
        )
          requestData = {
            fni_products: {
              product_service: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                scope: data?.scope,
              },
            },
          };
        break;
      case Providers.SHIFTDIGITAL_LEADS:
        if (
          watch("integration_type") ===
          IntegrationType.LEAD_GENERATION_AND_MANAGEMENT
        ) {
          if (data?.api_url)
            requestData = {
              leads_management_system: {
                sd_leads: {
                  api_url: data?.api_url,
                },
              },
            };
        }

        break;
      case Providers.UPSCOPE:
        if (data?.script_key)
          requestData = {
            co_browsing: {
              upscope: {
                script_key: data?.script_key,
              },
            },
          };
        break;
      case Providers.GOOGLE:
        if (data?.api_secret_key)
          requestData = {
            address_verification: {
              google: {
                api_key: data?.api_secret_key,
              },
            },
          };
        break;
      case Providers.MARKETSCAN:
        if (
          hubexConfigurationsByProvider?.partner_id &&
          hubexConfigurationsByProvider?.api_url &&
          data?.account_number
        )
          requestData = {
            price_management_system: {
              market_scan: {
                partner_id: hubexConfigurationsByProvider?.partner_id,
                account_number: data?.account_number,
                api_url: hubexConfigurationsByProvider?.api_url,
              },
            },
          };
        break;
      case Providers.CDK:
        if (
          data?.api_url &&
          data?.api_secret &&
          data?.subscription_id &&
          data?.api_key &&
          data?.token_url 
        )
          if (
            getValues("integration_type") == IntegrationType.CUSTOMER_MANAGEMENT
          ) {
            requestData = {
              dealer_management_system: {
                cdk: {
                  token_url: data?.token_url,
                  api_url: data?.api_url,
                  api_secret: data?.api_secret,
                  api_key: data?.api_key,
                  subscription_id: data?.subscription_id
                },
              },
            };
          } else if (
            getValues("integration_type") == IntegrationType.DEAL_PUSH
          ) {
            requestData = {
              dealer_management_system: {
                cdk: {
                  token_url: data?.token_url,
                  api_url: data?.api_url,
                  api_secret: data?.api_secret,
                  api_key: data?.api_key,
                  subscription_id: data?.subscription_id,
                },
              },
            };
          } else {
            requestData = {
              dealer_management_system: {
                cdk: {
                  token_url: data?.token_url,
                  elead_customer_api: {
                    api_url: data?.api_url,
                    api_secret: data?.api_secret,
                    api_key: data?.api_key,
                    subscription_id: data?.subscription_id,
                    department_id: data?.department_id,
                  },
                },
              },
            };
          }
        break;
      case Providers.TEKION:
        if (
          data?.access_key &&
          data?.client_id &&
          data?.secret_key &&
          data?.api_url &&
          data?.dealer_id
        )
          requestData = {
            dealer_management_system: {
              tekion: {
                access_key: data?.access_key,
                client_id: data?.client_id,
                secret_key: data?.secret_key,
                api_url: data?.api_url,
                dealer_id: data?.dealer_id,
              },
            },
          };
        break;
      case Providers.DEALERTRACK:
        if (
          data?.username &&
          data?.password &&
          data?.enterprise_code &&
          data?.company_number &&
          data?.host &&
          data?.api_url
        )
          requestData = {
            dealer_management_system: {
              dealer_track: {
                dms: {
                  username: data?.username,
                  password: data?.password,
                  enterprise_code: data?.enterprise_code,
                  company_number: data?.company_number,
                  host: data?.host,
                  api_url: data?.api_url,
                },
              },
            },
          };

        break;
      case Providers.DEALER_TRACKML:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_secret &&
          data?.api_key &&
          data?.client_id &&
          data?.scope &&
          data?.grant_type
        )
          requestData = {
            dealer_management_system: {
              dealer_track: {
                multilender: {
                  api_key: data?.api_key,
                  client_id: data?.client_id,
                  api_url: data?.api_url,
                  client_secret: data?.client_secret,
                  scope: data?.scope,
                  grant_type: data?.grant_type,
                  token_url: data?.token_url,
                },
              },
            },
          };

        break;
      case Providers.DEALER_TRACK_LENDER:
        if (
          data?.partner_dealer_id &&
          data?.api_url &&
          data?.client_id &&
          data?.client_secret &&
          data?.token_url
        )
          requestData = {
            dealer_management_system: {
              dealer_track: {
                lender_info: {
                  partner_dealer_id: data?.partner_dealer_id,
                  client_id: data?.client_id,
                  api_url: data?.api_url,
                  client_secret: data?.client_secret,
                  token_url: data?.token_url,
                },
              },
            },
          };

        break;
      case Providers.PLAID:
        if (
          data?.client_secret &&
          data?.client_id &&
          data?.app_name &&
          data?.api_version &&
          data?.api_url
        )
          requestData = {
            account_verification: {
              plaid: {
                client_secret: data?.client_secret,
                client_id: data?.client_id,
                client_app_name: data?.app_name,
                client_webhook: "",
                api_url: data?.api_url,
                api_version: data?.api_version,
              },
            },
          };
        break;
      case Providers.STRIPE:
        if (data?.api_secret_key && data?.api_version)
          requestData = {
            payment: {
              stripe: {
                api_key: data?.api_secret_key,
                api_version: data?.api_version,
              },
            },
          };
      case Providers.PEN:
        if (data?.user_name && data?.password && data?.token && data?.api_url)
          requestData = {
            fni_products: {
              pen: {
                api_url: data?.api_url,
                user_name: data?.user_name,
                password: data?.password,
                user_token: data?.token,
              },
            },
          };
        break;
      case Providers.SOCURE:
        if (data?.api_secret_key && data?.api_url)
          requestData = {
            fraud_identification: {
              socure: {
                api_key: data?.api_secret_key,
                api_url: data?.api_url,
              },
            },
          };
        break;
      case Providers.MITEK:
        if (
          data?.client_id &&
          data?.client_secret &&
          data?.manual_client_id &&
          data?.manual_client_secret &&
          data?.manual_api_base_url &&
          data?.identity_api_base_url
        )
          requestData = {
            license_verification: {
              mitek: {
                mitek_account: {
                  identity_api_base_url: data?.identity_api_base_url,
                  identity_client_id: data?.client_id,
                  identity_client_secret: data?.client_secret,
                  manual_api_base_url: data?.manual_api_base_url,
                  manual_client_id: data?.manual_client_id,
                  manual_client_secret: data?.manual_client_secret,
                  retries_duration: 0,
                },
              },
            },
          };
        configuration_payload = {
          recapture_attempts_limit: data?.recapture_attempts_limit,
        };
        break;
      case Providers.KBB:
        if (data?.api_secret_key && data?.base_url)
          requestData = {
            vehicle_valuation: {
              kbb: {
                base_url: data?.base_url,
                api_key: data?.api_secret_key,
              },
            },
          };
        break;
      case Providers.WAY:
        if (data?.api_url && data?.auth_token && data?.webhook_url)
          requestData = {
            insurance_management: {
              way: {
                api_url: data?.api_url,
                auth_token: data?.auth_token,
                webhook_url: data?.webhook_url,
              },
            },
          };
        break;
      case Providers.ROUTEONE:
        if (
          data?.api_url &&
          data?.dealership_id &&
          data?.user_id &&
          data?.sha_body_content &&
          data?.hmac_ss &&
          data?.api_hash &&
          data?.hmac_id &&
          data?.webhook_url &&
          data?.partner_id
        )
          requestData = {
            dealer_management_system: {
              routeone: {
                api_url: data?.api_url,
                dealership_id: data?.dealership_id,
                user_id: data?.user_id,
                sha_body_content: data?.sha_body_content,
                hmac_ss: data?.hmac_ss,
                api_hash: data?.api_hash,
                hmac_id: data?.hmac_id,
                webhook_url: data?.webhook_url,
                partner_id: data?.partner_id,
              },
            },
          };
        break;
      default:
        isCredentialsAdded = true; //for now being
    }
    if (requestData) {
      hubexConfigurations
        ? await updateHubexCredentials(
            {
              dealer_code: dealer_code,
              data: requestData,
            },
            {
              onError: async () => {
                isCredentialsAdded = false;
                actions.setToast({
                  toastMessage: "An error occurred while saving credentials",
                  toastState: true,
                  variant: "error",
                });
                setSaveButtonState(false);
              },
            }
          )
        : await saveHubexCredentials(
            {
              dealer_code: dealer_code,
              data: requestData,
            },
            {
              onError: async (error) => {
                if (error?.code && error?.code === 403) {
                  actions.setToast({
                    toastMessage:
                      "Please Subscribe Hubex to add Integration(s)",
                    toastState: true,
                    variant: "error",
                  });
                } else {
                  actions.setToast({
                    toastMessage: "An error occurred while saving credentials",
                    toastState: true,
                    variant: "error",
                  });
                }
                isCredentialsAdded = false;
                setSaveButtonState(false);
              },
            }
          );
    }
    getHubexCredentialsByProvider({
      provider: "MarketScan",
      dealer_code: dealer_code,
    });
    return { isCredentialsAdded, configuration_payload };
  };

  const onSubmit = async (data) => {
    setSaveButtonState(true);
    if (
      data.integration_type == IntegrationType.DEALER_LENDER_ASSOCIATION &&
      addLenderProvider &&
      (actionType === ActionOptions.ADD ||
        actionType === ActionOptions.ADD_PROVIDER)
    ) {
      actions.setToast({
        toastMessage: "Only one provider can be added at a time",
        toastState: true,
        variant: "error",
      });
      setSaveButtonState(false);
      onClose();
      return;
    }
    data.dealer_code = dealer_code;
    const { isCredentialsAdded, configuration_payload } =
      await storeCredentialsAtHubex(data);
    if (data?.provider_name == Providers.MARKETSCAN) {
      setMarketScanCredentials(true);
    } else {
      setMarketScanCredentials(false);
    }
    if (!isCredentialsAdded) {
      onClose();
    }
    if (actionType == ActionOptions.ADD) {
      data.providers = [
        {
          is_active: data.is_active,
          provider_name: data.provider_name,
          integration_id: 0,
          dealer_code: dealer_code,
          provider_configuration: configuration_payload ?? null,
        },
      ];
      data.is_active = true;
      data.is_company_level = false;
      if (data?.provider_name !== Providers.MARKETSCAN) {
        data.tax_service_type = "";
      }
      delete data.provider_name;
      createIntegration(data, {
        onSuccess: () => {
          setSaveButtonState(false);
          reset(defaultValues);
          setProviderItems([]);
        },
        onError: () => {
          setSaveButtonState(false);
        },
      });
    } else if (actionType == ActionOptions.EDIT) {
      const integration = dealerIntegraions.find(
        (x) => x.id == selectedProvider.integration_id
      );
      if (selectedProvider?.dealer_code) {
        integration.providers.find(
          (x) =>
            x.provider_name == data.provider_name &&
            x?.dealer_code == dealer_code
        ).is_active = data.is_active;
      } else {
        integration.providers.find(
          (x) => x.provider_name == data.provider_name
        ).is_active = data.is_active;
      }
      if (
        watch("integration_type") === IntegrationType.INVENTORY_MANAGEMENT &&
        data.provider_name == Providers.SHIFTDIGITAL
      ) {
        updateShiftDigitalId({
          dealer_code,
          shift_dealer_code: data.shift_digital_id,
        });
        dealerForm.setValue(
          "profile.shift_digital_dealer_id",
          data.shift_digital_id
        );
      } else {
        if (selectedProvider?.dealer_code) {
          updateIntegration(integration, {
            onSuccess: () => {
              setSaveButtonState(false);
            },
            onError: () => {
              setSaveButtonState(false);
            },
          });
        } else {
          updateProviderStatus({
            ...selectedProvider,
            is_active:
              typeof getValues("is_active") === "boolean"
                ? getValues("is_active")
                : getValues("is_active")[0],
            dealer_code: dealer_code,
            payoff_dealer_id: data?.payoff_dealer_id,
            home_net_dealer_id: data?.home_net_dealer_id,
          });
        }
      }
    } else if (actionType == ActionOptions.ADD_PROVIDER) {
      const integration = dealerIntegraions.find(
        (x) => x.id == selectedProvider.id
      );
      integration.providers = [
        {
          is_active: data.is_active,
          provider_name: data.provider_name,
          integration_id: 0,
          dealer_code: dealer_code,
        },
      ];
      integration.is_company_level = false;
      delete data.provider_name;
      updateIntegration(integration, {
        onSuccess: () => {
          setSaveButtonState(false);
          reset(defaultValues);
          setProviderItems([]);
          const integrations = lenderIntegrations?.find(
            (x) => x.integration_type == selectedProvider.integration_type
          );
          //remove those providers which are already added by dealer
          const dealerproviders = dealerIntegraions?.find(
            (x) => x.integration_type == selectedProvider.integration_type
          )?.providers;
          const providersToRemove = new Set(
            dealerproviders?.map((item) => item.provider_name)
          );
          const savedProviders = new Set(
            selectedProvider?.providers?.map((item) => item.provider_name)
          );

          const mergedSet = new Set(
            Array.from(providersToRemove).concat(Array.from(savedProviders))
          );

          setProviderItems(
            integrations?.providers?.filter(
              (item) => !mergedSet.has(item.provider_name)
            )
          );
        },
        onError: () => {
          setSaveButtonState(false);
        },
      });
    }
    onClose();

    setSaveButtonState(false);
  };

  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  const registerFnI = () => {
    const dealer_profile_data: IDealerProfile = dealerForm.getValues(
      "profile"
    ) as IDealerProfile;
    const penRegisterData = {
      action: "REGISTER",
      dealer_code: dealer_code,
      address1: dealer_profile_data?.dealer_address?.address_line_1,
      address2: dealer_profile_data?.dealer_address?.address_line_2,
      city: dealer_profile_data?.dealer_address?.city,
      contactName: dealer_profile_data?.contact_person_name,
      penDealerId: 0,
      dealershipName: dealer_name,
      email: dealer_profile_data?.email,
      fax: dealer_profile_data?.fax_number,
      isTestDealer: false,
      observesDayLightSaving: dealer_profile_data?.day_light_saving,
      phone: dealer_profile_data?.contact_number,
      state: stateCode,
      timeZone: (() => {
        switch (dealer_profile_data?.time_zone) {
          case "(GMT-05:00) Eastern Standard Time (EST)":
            return "Eastern";
          case "(GMT-06:00) Central Standard Time (CST)":
            return "Central";
          case "(GMT-07:00) Mountain Standard Time (MST)":
            return "Mountain";
          case "(GMT-08:00) Pacific Standard Time (PST)":
            return "Pacific";
          case "(GMT-10:00) Hawaii-Aleutian Standard Time (HAST)":
            return "Hawaii";
          case "(GMT-09:00) Alaska Standard Time (AKST)":
            return "Alaska";
          case "(GMT-04:00) Atlantic Standard Time (AST)":
            return "Atlantic";
          case "(GMT-11:00) Samoa Standard Time (SST)":
            return "Samoa";
          case "(GMT+10:00) Chamorro Standard Time (ChST)":
            return "Chamorro";
          default:
            return dealer_profile_data?.time_zone;
        }
      })(),
      webSite: dealer_profile_data?.website,
      zipCode: dealer_profile_data?.dealer_address?.zip_code,
    };
    let is_data_missing = false;
    for (var i = 0; i < Object.values(penRegisterData).length; i++) {
      if (
        (Object.values(penRegisterData)[i] === null ||
          Object.values(penRegisterData)[i] === undefined ||
          Object.values(penRegisterData)[i] === "") &&
        Object.keys(penRegisterData)[i] !== "address2" &&
        Object.keys(penRegisterData)[i] !== "penDealerId" &&
        Object.keys(penRegisterData)[i] !== "isTestDealer" &&
        Object.keys(penRegisterData)[i] !== "fax"
      ) {
        is_data_missing = true;
        break;
      }
    }

    if (isProfileDirty) {
      actions.setToast({
        toastMessage:
          " Action can not be performed as there are unsaved changes on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    } else if (is_data_missing) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as mandatory information is missing on General tab of Dealer Profile",

        toastState: true,
        variant: "error",
      });

      return;
    }
    setDealer(
      { penRegisterData },
      {
        onSuccess(response: any) {
          if (response.success === "true") {
            dealerForm.setValue("profile.pen_dealer_id", response.dealer_id);

            updatePenDealerId(
              {
                dealer_code: dealer_code,
                pen_dealer_id: response.dealer_id,
              },
              {
                onSuccess() {
                  dealerForm.setValue(
                    "profile.pen_dealer_id",
                    response.dealer_id
                  );
                  actions.setToast({
                    toastMessage:
                      "The account has been registered successfully",
                    toastState: true,
                  });
                  onClose();
                },
              }
            );
          } else {
            actions.setToast({
              toastMessage: response?.error?.description,
              toastState: true,
              variant: "error",
            });
            return;
          }
        },
        onError(error) {
          actions.setToast({
            toastMessage: error?.error?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  const unRegisterFnI = () => {
    const dealer_profile_data: IDealerProfile = dealerForm.getValues(
      "profile"
    ) as IDealerProfile;
    const penUnRegisterData = {
      action: "UNREGISTER",
      dealer_code: dealer_code,
      address1: dealer_profile_data?.dealer_address?.address_line_1,
      address2: dealer_profile_data?.dealer_address?.address_line_2,
      city: dealer_profile_data?.dealer_address?.city,
      contactName: dealer_profile_data?.contact_person_name,
      penDealerId: penDealerId,
      dealershipName: dealer_name,
      email: dealer_profile_data?.email,
      fax: dealer_profile_data?.fax_number,
      isTestDealer: true,
      observesDayLightSaving: dealer_profile_data?.day_light_saving,
      phone: dealer_profile_data?.contact_number,
      state: stateCode,
      timeZone: (() => {
        switch (dealer_profile_data?.time_zone) {
          case "(GMT-05:00) Eastern Standard Time (EST)":
            return "Eastern";
          case "(GMT-06:00) Central Standard Time (CST)":
            return "Central";
          case "(GMT-07:00) Mountain Standard Time (MST)":
            return "Mountain";
          case "(GMT-08:00) Pacific Standard Time (PST)":
            return "Pacific";
          case "(GMT-10:00) Hawaii-Aleutian Standard Time (HAST)":
            return "Hawaii";
          case "(GMT-09:00) Alaska Standard Time (AKST)":
            return "Alaska";
          case "(GMT-04:00) Atlantic Standard Time (AST)":
            return "Atlantic";
          case "(GMT-11:00) Samoa Standard Time (SST)":
            return "Samoa";
          case "(GMT+10:00) Chamorro Standard Time (ChST)":
            return "Chamorro";
          default:
            return dealer_profile_data?.time_zone;
        }
      })(),
      webSite: dealer_profile_data?.website,
      zipCode: dealer_profile_data?.dealer_address.zip_code,
    };

    let is_data_missing = false;
    for (var i = 0; i < Object.values(penUnRegisterData).length; i++) {
      if (
        (Object.values(penUnRegisterData)[i] === null ||
          Object.values(penUnRegisterData)[i] === undefined ||
          Object.values(penUnRegisterData)[i] === "") &&
        Object.keys(penUnRegisterData)[i] !== "address2" &&
        Object.keys(penUnRegisterData)[i] !== "penDealerId" &&
        Object.keys(penUnRegisterData)[i] !== "isTestDealer" &&
        Object.keys(penUnRegisterData)[i] !== "fax"
      ) {
        is_data_missing = true;
        break;
      }
    }

    if (isProfileDirty) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as there are unsaved changes on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    } else if (is_data_missing) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as mandatory information is missing on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    }
    setDealer(
      { penRegisterData: penUnRegisterData },
      {
        onSuccess(response: any) {
          if (response.success === "true") {
            dealerForm.setValue("profile.pen_dealer_id", null);
            unRegisterDealerPen(dealer_code);
            deleteAllFnI(dealer_code);
            actions.setToast({
              toastMessage: "Your account has been unregistered",
              toastState: true,
            });
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER
            );
            onClose();
          } else {
            actions.setToast({
              toastMessage: response?.error?.description,
              toastState: true,
              variant: "error",
            });
            return;
          }
        },
        onError(error) {
          actions.setToast({
            toastMessage: error?.error?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  const updateFnI = () => {
    const dealer_profile_data: any = dealerForm.getValues(
      "profile"
    ) as IDealerProfile;

    const penUpdateData = {
      action: "UPDATE",
      dealer_code: dealer_code,
      address1: dealer_profile_data?.dealer_address?.address_line_1,
      address2: dealer_profile_data?.dealer_address?.address_line_2,
      city: dealer_profile_data?.dealer_address?.city,
      contactName: dealer_profile_data?.contact_person_name,
      penDealerId: penDealerId,
      dealershipName: dealer_name,
      email: dealer_profile_data?.email,
      fax: dealer_profile_data?.fax_number,
      isTestDealer: true,
      observesDayLightSaving: dealer_profile_data?.day_light_saving,
      phone: dealer_profile_data?.contact_number,
      state: stateCode,
      timeZone: (() => {
        switch (dealer_profile_data?.time_zone) {
          case "(GMT-05:00) Eastern Standard Time (EST)":
            return "Eastern";
          case "(GMT-06:00) Central Standard Time (CST)":
            return "Central";
          case "(GMT-07:00) Mountain Standard Time (MST)":
            return "Mountain";
          case "(GMT-08:00) Pacific Standard Time (PST)":
            return "Pacific";
          case "(GMT-10:00) Hawaii-Aleutian Standard Time (HAST)":
            return "Hawaii";
          case "(GMT-09:00) Alaska Standard Time (AKST)":
            return "Alaska";
          case "(GMT-04:00) Atlantic Standard Time (AST)":
            return "Atlantic";
          case "(GMT-11:00) Samoa Standard Time (SST)":
            return "Samoa";
          case "(GMT+10:00) Chamorro Standard Time (ChST)":
            return "Chamorro";
          default:
            return dealer_profile_data?.time_zone;
        }
      })(),
      webSite: dealer_profile_data?.website,
      zipCode: dealer_profile_data?.dealer_address.zip_code,
    };
    let is_data_missing = false;
    for (var i = 0; i < Object.values(penUpdateData).length; i++) {
      if (
        (Object.values(penUpdateData)[i] === null ||
          Object.values(penUpdateData)[i] === undefined ||
          Object.values(penUpdateData)[i] === "") &&
        Object.keys(penUpdateData)[i] !== "address2" &&
        Object.keys(penUpdateData)[i] !== "penDealerId" &&
        Object.keys(penUpdateData)[i] !== "isTestDealer" &&
        Object.keys(penUpdateData)[i] !== "fax"
      ) {
        is_data_missing = true;
        break;
      }
    }

    if (isProfileDirty) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as there are unsaved changes on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    } else if (is_data_missing) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as mandatory information is missing on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    }
    setDealer(
      { penRegisterData: penUpdateData },
      {
        onSuccess(response: any) {
          if (response.success === "true") {
            // User cannot update pen info until he saved the changes on dealer profile general tab
            // updateDealerProfilebyId(dealer_profile_data);
            actions.setToast({
              toastMessage: "The changes have been saved successfully",
              toastState: true,
            });
            onClose();
          } else {
            actions.setToast({
              toastMessage: response?.error?.description,
              toastState: true,
              variant: "error",
            });
            return;
          }
        },
        onError(error) {
          actions.setToast({
            toastMessage: error?.error?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const setProviderValues = (integration_type) => {
    const integrations = dealerIntegraions.filter(
      (integration) => integration.integration_type === integration_type
    );
    if (integrations) {
      removeAddedProviders(integration_type, integrations);
    }
    if (!integrations) {
      const filteredInt = lenderIntegrations?.filter(
        (item) => item.integration_type === integration_type
      );

      const dropdownItems = filteredInt[0]?.provider_name?.map((provider) => ({
        text: provider.name,
        value: provider.value,
      }));

      setProviderItems(dropdownItems);
    }
  };
  const removeAddedProviders = (integration_type, integrations) => {
    const filteredProviders = integrations?.flatMap((item) =>
      item.providers
        ?.filter((provider) => provider.dealer_code)
        ?.map((provider) => provider.provider_name)
    );

    const filteredIntg = lenderIntegrations?.filter(
      (item) => item.integration_type === integration_type
    );

    const remainingProviders = filteredIntg
      ?.flatMap((item) =>
        item.provider_name?.map((provider) => ({
          name: provider.name,
          value: provider.value,
        }))
      )
      ?.filter(
        (providerName) => !filteredProviders?.includes(providerName.value)
      );

    const dropdownProvides = remainingProviders.map((provider) => ({
      text: provider.name,
      value: provider.value,
    }));

    setProviderItems(dropdownProvides);
  };
  useEffect(() => {
    if (actionType === ActionOptions.EDIT) {
      if (watch("provider_name") == Providers.MARKETSCAN) {
        getHubexCredentialsByProvider({ provider: "MarketScan" });
      }
    }
    if (
      actionType === ActionOptions.EDIT &&
      watch("provider_name") == Providers.STRIPE
    ) {
      setSaveButtonState(true);
      if (stripeDealerID) {
        const request = {
          account_id: stripeDealerID,
        };
        getStripeAccountById(request, {
          onSuccess(response: any) {
            setDetailSubmitted(response.details_submitted);
            setSaveButtonState(false);
          },
        });
      }
    }
  }, [actionType]);

  const stripeReg = () => {
    const data = {
      payload: {
        type: "standard",
        metadata: {},
      },
      dealer_code: dealer_code,
    };
    if (dealerForm.getValues("profile.id")) {
      getStripeAccountID(data, {
        onSuccess(response: any) {
          if (response) {
            updateStripeConnectId(
              {
                dealer_code: dealer_code,
                stripe_connect_id: response.id,
              },
              {
                onSuccess() {
                  dealerForm.setValue("profile.stripe_dealer_id", response.id);
                  actions.setToast({
                    toastMessage:
                      "Stripe Account ID has been Saved successfully",
                    toastState: true,
                  });
                  onClose();
                },
              }
            );
          } else {
            actions.setToast({
              toastMessage: response?.error?.description,
              toastState: true,
              variant: "error",
            });
            return;
          }
        },
      });
    } else {
      actions.setToast({
        toastMessage:
          " Action can not be performed as there are unsaved changes on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    }
  };

  const handleContinueStripeRegistration = () => {
    const accountData = {
      payload: {
        type: "account_onboarding",
        account: stripeDealerID,
        return_url: `${process.env.NEXT_SCREEN_BASE_URL}/configuration/dealer-configurations`,
        refresh_url: `${process.env.NEXT_SCREEN_BASE_URL}/configuration/dealer-configurations`,
      },
    };
    const request = {
      account_id: stripeDealerID,
    };
    getStripeAccountById(request, {
      onSuccess(response: any) {
        {
          setDetailSubmitted(response.details_submitted);
        }
      },
    });
    stripeAccountLink(accountData, {
      onSuccess(response: any) {
        setStripeAccountLinkCreated(true);
        window.location.replace(response?.url);
      },
    });
  };

  const setButtonsforProviders = () => {
    if (watch("provider_name") == Providers.PEN && !penDealerId) {
      return (
        <Box theme={theme}>
          <Button
            theme={theme}
            primary
            text={"Register With PEN"}
            fullWidth
            disabled={
              actionType == ActionOptions.ADD ||
              actionType == ActionOptions.ADD_CRITERIA
            }
            onClick={registerFnI}
          />
        </Box>
      );
    } else if (watch("provider_name") == Providers.PEN && penDealerId) {
      return (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr" },
              gap: 1,
            }}
          >
            <Button
              theme={theme}
              primary
              text={"Update Info with PEN"}
              fullWidth
              onClick={updateFnI}
            />
            <Button
              theme={theme}
              primary
              text={"Un-register with PEN"}
              fullWidth
              onClick={unRegisterFnI}
            />
          </Box>
        </>
      );
    } else if (
      watch("provider_name") == Providers.STRIPE &&
      !stripeDealerID &&
      !detailedSubmited
    ) {
      return (
        <Box theme={theme}>
          <Button
            theme={theme}
            primary
            text={
              stipeRegisterLoading || updateStripeConnectIdLoading
                ? "Processing"
                : "Register"
            }
            fullWidth
            onClick={stripeReg}
            disabled={stipeRegisterLoading || updateStripeConnectIdLoading}
            endIcon={
              (stipeRegisterLoading || updateStripeConnectIdLoading) && (
                <img src={DisableLoader} alt="Loader" />
              )
            }
          />
        </Box>
      );
    } else if (
      watch("provider_name") == Providers.STRIPE &&
      stripeDealerID &&
      !detailedSubmited
    ) {
      return (
        <Box theme={theme}>
          <Button
            theme={theme}
            primary
            text={
              stripeAccountLinkLoading ? "Processing" : "Continue Registeration"
            }
            fullWidth
            onClick={handleContinueStripeRegistration}
            disabled={stripeAccountLinkLoading}
            endIcon={
              stripeAccountLinkLoading && (
                <img src={DisableLoader} alt="Loader" />
              )
            }
          />
        </Box>
      );
    } else if (
      watch("provider_name") == Providers.STRIPE &&
      stripeDealerID &&
      detailedSubmited
    ) {
      return (
        <Box theme={theme}>
          <Button
            theme={theme}
            primary
            text={stripeAccountLinkLoading ? "Processing" : "Registered"}
            fullWidth
            disabled={true}
          />
        </Box>
      );
    }
  };

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <form>
              <Controller
                name="integration_type"
                control={control}
                rules={validation("Integration Type", true)}
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={theme}
                    label={"Integration Type"}
                    placeholder={"Select"}
                    items={integrationOptions}
                    onChange={(e) => {
                      //Set Providers
                      const integration = lenderIntegrations?.find(
                        (x) => x.integration_type == e.target.value
                      );

                      setValue("provider_name", "");
                      setProviderValues(e.target.value);
                      setInputFields(null);
                      onChange(e);
                      watch("integration_type") ==
                      IntegrationType.PURCHASE_INSURANCE
                        ? setValue("route", "Index")
                        : setValue("route", "Hubex");
                    }}
                    value={value ?? ""}
                    sxProps={undefined}
                    selectError={errors?.integration_type?.message}
                    disabled={
                      actionType === ActionOptions.EDIT ||
                      actionType == ActionOptions.ADD_PROVIDER
                    }
                  />
                )}
              />
              <Controller
                name="route"
                control={control}
                defaultValue={"Hubex"}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={"Route"}
                    placeholder={"Select"}
                    items={
                      watch("integration_type") ===
                      IntegrationType.PURCHASE_INSURANCE
                        ? [{ text: "Index", value: "Index" }]
                        : watch("integration_type") ===
                          IntegrationType.INVENTORY_MANAGEMENT
                        ? [
                            { text: "Index", value: "Index" },
                            { text: "Hubex", value: "Hubex" },
                          ]
                        : [{ text: "Hubex", value: "Hubex" }]
                    }
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
              />
              <Controller
                name="provider_name"
                control={control}
                defaultValue={""}
                rules={validation("Provider", true)}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={theme}
                    label={"Provider"}
                    items={providerItems}
                    value={value ?? ""}
                    onChange={(e) => {
                      handleInputFields(e.target.value as string);
                      onChange(e);
                    }}
                    sxProps={undefined}
                    selectError={errors?.provider_name?.message}
                    disabled={actionType === ActionOptions.EDIT}
                  />
                )}
              />
              {getValues("provider_name") == Providers.MARKETSCAN &&
              watch("integration_type") ==
                IntegrationType.CALCULATION_AND_TAXATION ? (
                <Controller
                  name="tax_service_type"
                  control={control}
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      theme={theme}
                      label={"Service Enabled"}
                      items={[
                        {
                          text: "Tax and calculations",
                          value: "Tax and calculations",
                        },
                      ]}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      sxProps={undefined}
                      disabled={true}
                    />
                  )}
                />
              ) : (
                ""
              )}

              {inputFields &&
                inputFields?.map((inputField, index) => {
                  return (
                    <Controller
                      key={index}
                      name={inputField.name}
                      control={control}
                      defaultValue={""}
                      rules={{
                        required:
                          inputField.label !==
                          "Recapture Attempts Limit (Optional)"
                            ? {
                                value: true,
                                message: `${inputField.label} is required`,
                              }
                            : false,
                        validate: (value) => {
                          if (inputField.name === "recapture_attempts_limit") {
                            if (value === "") return true;
                            if (!/^\d+$/.test(value)) {
                              return "Only whole numbers (0-99) are allowed";
                            }
                            if (parseInt(value, 10) > 99) {
                              return "Number must be between 0 and 99";
                            }
                            return true;
                          }
                          if (value.trim() === "") {
                            return `${inputField.label} is required`;
                          }
                          if (
                            ["api_url", "token_url", "webhook_url"].includes(
                              inputField.name
                            ) &&
                            !/^(https?:\/\/|http:\/\/)[^\s$.?#].[^\s]*$/.test(
                              value
                            )
                          ) {
                            return "Please enter a valid URL";
                          }
                          return true;
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth={true}
                          label={inputField.label}
                          placeholder={"Type here..."}
                          type="text"
                          {...field}
                          value={field.value?.trimStart()}
                          error={errors?.[inputField.name]?.message}
                        />
                      )}
                    />
                  );
                })}
              <Controller
                name="is_active"
                control={control}
                defaultValue={true}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={"Status"}
                    placeholder={"Select"}
                    items={[
                      { text: "Enabled", value: true },
                      { text: "Disabled", value: false },
                    ]}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    sxProps={undefined}
                  />
                )}
              />
              {setButtonsforProviders()}
            </form>
          </>
        }
        customFooter={
          <>
            <Button
              theme={theme}
              primary
              type="submit"
              text={"Save"}
              fullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={saveButtonState}
            />
          </>
        }
      />
    </>
  );
};
export default AddDealerIntegration;
