import { FC, useEffect, useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material";
import {
  Box,
  FileDragDrop,
  FilePreview,
  Select,
  Textarea,
  CircleLoader,
  Autocomplete,
  Tooltip,
  NestedMultiSelect,
  MultiSelect,
  Grid,
  Input,
  ImageViewer,
} from "@ntpkunity/controls";
import { FileDragDropWrap, TextAreaWrap } from "@sharedComponents";
import { Controller } from "react-hook-form";
import { validation } from "shared/helper/methods";
import {
  ActionTypes,
  InstallationMode,
  QueryKeys,
  ToastMessages,
  ValidationMessages,
  Vehicle_Type,
} from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import {
  useGetAssetMakeTrims,
  useGetMasterListAccessories,
  useSaveAddOn,
  useUpdateAddOn,
} from "services/options.service";
import { IAddOns, IAssetCondition, IImage, ISelectItemList } from "Interface";
import { WEBSITE_REGEX } from "shared/config/constants";
import { useQueryClient } from "react-query";
import {
  UseQuery_GetAllAssetConditions,
  UseMutation_uploadS3Image,
  UseMutation_deleteS3Image,
  useMutation_CheckS3Credentials,
  UseQuery_getAllOptionCategorys,
  UseQuery_GetAllAssetTrims,
  UseQuery_check_preferred_accessories,
} from "services";
interface IFormInputs {
  id?: number;
  offered_by: string;
  description: string;
  product_name: string;
  category_id: number;
  specfic_category: string;
  vehicle_type: string;
  part_no: string;
  supplier: string;
  installation_mode: string;
  price: number;
  price_inclusive: boolean;
  rv_adder: number;
  compatible_models: any[];
  is_active: boolean;
  image?: string[];
  is_price_inclusive?: boolean;
  preferred?: string;
  dealer_cost?: string;
}

interface IImageResponse {
  location: string;
  key: string;
  size: string;
  name?: string;
}

const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "relative !important",
      transform: "initial !important",
    },
  },
}));

const NewOption: FC<{
  form: any;
  formData?: IFormInputs;
  isEditMode: boolean;
  defaultFormValues: IFormInputs;
  dealer_code: string;
  setProductId?: any;
  setPopUpState?: any;
  setFormDefaultData?: any;
  setIsEditMode?: any;
  productId?: number;
  setImages?: (e: any) => void;
  images?: IImage[];
}> = ({
  form,
  formData,
  isEditMode,
  defaultFormValues,
  dealer_code,
  setProductId,
  setPopUpState,
  setFormDefaultData,
  setIsEditMode,
  productId,
  setImages,
  images,
}) => {
  const theme = useTheme();
  const { actions } = useStoreContext();
  const [selectedCompatibleModels, setSelectedCompatibleModels] = useState([]);
  const [isOEMAddonSelected, setIsOEMAddonSelected] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [visibility, setVisibility] = useState(false);
  const [sliderImages, setSilderImages] = useState([]);
  const [index, setIndex] = useState(0);
  const { mutate: SaveAddOn } = useSaveAddOn();
  const { mutate: updateAddon } = useUpdateAddOn();
  const { mutate: uploadImage } = UseMutation_uploadS3Image();
  const { mutate: deleteImage } = UseMutation_deleteS3Image();
  const [isPreferredLimitExceed, setIsPreferredLimitExceed] =
    useState<boolean>(false);
  const { mutate: getAssetTrims, data: assetTrimsData } =
    useGetAssetMakeTrims();
  const { mutate: getMasterListAccessories, data: masterListAccessoriesData } =
    useGetMasterListAccessories();
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  UseQuery_getAllOptionCategorys();
  UseQuery_GetAllAssetConditions();
  UseQuery_GetAllAssetTrims();
  const { refetch } = UseQuery_check_preferred_accessories(dealer_code);
  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const preferredData = queryClient.getQueryData(
    QueryKeys.CHECK_PREFERRED_ACCESSORIES
  );
  const optionCategories: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_OPTION_CATEGORIES
  );

  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const masterListAccessoriesOrder =
    masterListAccessoriesData &&
    [...masterListAccessoriesData]?.sort((a, b) =>
      a.product_name > b.product_name ? 1 : -1
    );
  useEffect(() => {
    checkS3Credentials(JSON.parse(localStorage.getItem("settings"))?.tenant_id);
  }, []);
  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency?.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);
  useEffect(() => {
    refetch();
  }, [dealer_code]);
  const AssetCondition: Array<IAssetCondition> = queryClient.getQueryData(
    QueryKeys.Get_All_Asset_Conditions
  );
  const allTrims: any = queryClient.getQueryData(QueryKeys.GET_ALL_ASSET_TRIMS);

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    getValues,
    reset,
    setError,
    trigger,
  } = form;

  const handleChangeDelear = (_event: any) => {
    setValue("description", "");
    setValue("product_name", "");
    setValue("category_id", null);
    setValue("part_no", "");
    setValue("installation_mode", "");
    setValue("price", 0);
    setValue("supplier", "");
    setValue("compatible_models", []);
    setValue("rv_adder", 0);
    setValue("vehicle_type", "");
    setValue("image", []);
    setValue("video_url", "");
    setValue("brochure_url", "");
    setValue("dealer_cost", 0);
    setValue("preferred", "");
    setImages([]);
    setProductId(null);
  };
  const offeredBy = watch("offered_by");
  const installationMode = watch("installation_mode");

  const handleChange = (event: any) => {
    if (event != null) {
      setProductId(event?.label);
      const selectedMasterAddon = masterListAccessoriesData?.filter(
        (masterAddon: any) => masterAddon?.product_name === event?.label
      );
      clearErrors([
        "description",
        "category_id",
        "installation_mode",
        "price",
        "supplier",
        "compatible_models",
        "image",
      ]);
      setValue("description", selectedMasterAddon[0]?.description.toString());
      setValue("category_id", selectedMasterAddon[0]?.category_id);
      setValue("part_no", selectedMasterAddon[0]?.part_no);
      setValue("installation_mode", selectedMasterAddon[0]?.installation_mode);
      setValue("price", selectedMasterAddon[0]?.price.toString());
      setValue("supplier", selectedMasterAddon[0]?.offered_by);
      setValue(
        "is_price_inclusive",
        selectedMasterAddon[0]?.is_price_inclusive
      );
      setIsOEMAddonSelected(true);
      setValue(
        "compatible_models",
        selectedMasterAddon[0]?.compatible_models?.reduce(
          (previous, model: any) => {
            return [...previous, model.id];
          },
          []
        )
      );
      setValue("rv_adder", selectedMasterAddon[0]?.rv_adder);
      setValue("video_url", selectedMasterAddon[0]?.video_url);
      setValue("brochure_url", selectedMasterAddon[0]?.pdf_url);

      setValue("image", selectedMasterAddon[0]?.image);
      setImages(selectedMasterAddon?.[0]?.image);
      setValue("preferred", selectedMasterAddon[0]?.preferred);
    }
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    } else {
      setPopUpState(true);
    }
  };

  const checkPreferredFieldLimit = (value) => {
    const isLimitExceeded =
      preferredData &&
      (!isEditMode || (isEditMode && !defaultFormValues.preferred));

    if (value === true) {
      if (isLimitExceeded) {
        setError("preferred", {
          message: ValidationMessages.PREFERRED_LIMIT_EXCEEDED,
        });
        setIsPreferredLimitExceed(true);
      }
    } else {
      setIsPreferredLimitExceed(false);
      clearErrors("preferred");
    }
  };

  const onSubmit = async (data: any) => {
    if (data) {
      if (data.preferred === "") {
        data.preferred = undefined;
      }
      data.image = images;
      if (data?.installation_mode == "Optional") {
        data.is_price_inclusive = false;
      }

      if (isEditMode) {
        updateAddon(
          {
            ...data,
            dealer_code: dealer_code,
          },
          {
            onSuccess(response: IAddOns) {
              actions.setToast({
                toastMessage: ToastMessages.Record_Updated_Success,
                toastState: true,
              });
              reset();
              onClose();
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error?.error?.toString(),
                toastState: true,
                variant: "error",
              });
            },
          }
        );
      } else {
        SaveAddOn(
          {
            ...data,
            dealer_code: dealer_code,
          },
          {
            onSuccess(response: IAddOns) {
              setFormDefaultData({} as IFormInputs);
              setIsEditMode(false);
              actions.setToast({
                toastMessage: ToastMessages.Record_Added_Success,
                toastState: true,
              });
              reset();
              onClose();
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error?.error?.toString(),
                toastState: true,
                variant: "error",
              });
            },
          }
        );
      }
    }
  };

  const onimageUpload = (e: any) => {
    setImageLoader(true);
    if (e && e?.target && e?.target?.files && e?.target?.files?.length > 0) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          clearErrors("image");
          response.data.map((img: IImageResponse) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };

  const onimageDrop = (e: any) => {
    setImageLoader(true);
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files?.length > 0
    ) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          clearErrors("image");
          response.data.map((img: IImageResponse) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };

  const onRemoveFile = (fileKey: string, index: number) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      setImageLoader(true);
      deleteImage(obj, {
        onSuccess(response) {
          const imageArray = images;
          imageArray.splice(index, 1);
          setImages([...imageArray]);
          setImageLoader(false);
        },
      });
    }
  };

  const onClickIcon = (indexNumber) => {
    const image_url = images?.map((image) => {
      return image?.location;
    });
    setIndex(indexNumber);
    setSilderImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);

  useEffect(() => {
    getMasterListAccessories();
    getAssetTrims();
  }, []);

  useEffect(() => {
    onSubmit(formData);
  }, [formData]);
  useEffect(() => {
    if (isEditMode) {
      if (defaultFormValues.offered_by === "OEM") {
        setIsOEMAddonSelected(true);
        setProductId(defaultFormValues.product_name);
      }
      setSelectedCompatibleModels(defaultFormValues.compatible_models);
      setImages(defaultFormValues?.image);
      if (!!images?.length) clearErrors("image");
    }
  }, [isEditMode, defaultFormValues]);

  const supplierValidation = useMemo(() => {
    if (offeredBy) {
      if (offeredBy === "Dealer") return true;
      else isOEMAddonSelected;
    } else {
      return true;
    }
  }, [offeredBy]);

  return (
    <Grid theme={theme} container spacing={3}>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="offered_by"
          control={control}
          defaultValue={""}
          rules={validation("Offered By", true)}
          render={({ field: { value, onChange } }) => (
            <Select
              id="drawerOfferedBy"
              name="nameOfferedBy"
              theme={theme}
              label={"Offered By"}
              items={[
                { text: "OEM", value: "OEM" },
                { text: "Dealer", value: "Dealer" },
              ]}
              value={value}
              sxProps={""}
              onChange={(e) => {
                if (e.target.value === "Dealer") {
                  setIsOEMAddonSelected(false);

                  handleChangeDelear(e);
                }
                onChange(e);
              }}
              selectError={errors?.offered_by?.message}
            ></Select>
          )}
        />
      </Grid>
      {(!offeredBy || offeredBy === "Dealer") && (
        <Grid theme={theme} item xs={12}>
          <Controller
            name="product_name"
            control={control}
            defaultValue={""}
            rules={validation("Product Name", true)}
            render={({ field }) => (
              <Input
                theme={theme}
                label={"Product Name"}
                error={errors?.product_name?.message}
                type="text"
                id="drawerInputProductName"
                fullWidth
                {...field}
                value={field.value?.trimStart()}
              />
            )}
          />
        </Grid>
      )}
      {offeredBy && offeredBy === "OEM" && (
        <Grid theme={theme} item xs={12}>
          <Controller
            name="product_name"
            control={control}
            defaultValue={""}
            rules={validation("Product Name", true)}
            render={({ field }) => {
              return (
                <Autocomplete
                  disablePortal
                  id="drawerSelectProductName"
                  theme={theme}
                  items={masterListAccessoriesOrder?.map((option: any) => {
                    return {
                      label: option?.product_name,
                      id: option?.product_name,
                    };
                  })}
                  value={field.value}
                  {...field}
                  onChange={(_e: any, value: any) => {
                    field.onChange(value.label);
                    handleChange(value);
                  }}
                  error={errors?.product_name?.message}
                  label="Product Name"
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.label}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              );
            }}
          />
        </Grid>
      )}
      <Grid theme={theme} item xs={12}>
        <TextAreaWrap className="mb-0">
          <Controller
            name="description"
            control={control}
            rules={validation("Description", true)}
            render={({ field }) => (
              <Textarea
                theme={theme}
                label={"Description"}
                type="text"
                id="drawerTextDescription"
                name="drawerDescription"
                rows={3}
                fullWidth
                {...field}
                value={field.value?.trimStart()}
                error={errors?.description?.message}
              />
            )}
          />
        </TextAreaWrap>
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="category_id"
          control={control}
          rules={validation("Category", true)}
          defaultValue={""}
          render={({ field }) => (
            <Select
              id="drawerSelectCategoryId"
              theme={theme}
              label={"Category"}
              items={optionCategories
                ?.filter((x) => x.is_active || x.id == field.value)
                ?.map((category: any) => {
                  return {
                    value: category.id,
                    text: category.description,
                  };
                })}
              {...field}
              value={field.value}
              sxProps={""}
              selectError={errors?.category_id?.message as never}
              onChange={field.onChange}
            ></Select>
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="vehicle_type"
          control={control}
          defaultValue={""}
          rules={validation("Asset Condition", true)}
          render={({ field: { value, onChange } }) => (
            <Select
              id="drawerSelectVehicleType"
              theme={theme}
              label={"Asset Condition"}
              value={value}
              items={AssetCondition?.filter(
                (x) => x.is_active || x.id == value
              )?.map((asset) => ({
                value: asset.id,
                text: `${asset.description}`,
              }))}
              sxProps={""}
              onChange={onChange}
              selectError={errors?.vehicle_type?.message}
            ></Select>
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="part_no"
          control={control}
          render={({ field }) => (
            <Input
              theme={theme}
              label={"Part No (Optional)"}
              type="text"
              id="drawerInputPartNo"
              fullWidth
              {...field}
              value={field.value?.trimStart()}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="supplier"
          control={control}
          defaultValue={""}
          rules={validation("Supplier", supplierValidation)}
          render={({ field }) => (
            <Input
              theme={theme}
              label={"Supplier"}
              type="text"
              id="drawerInputSupplier"
              disabled={isOEMAddonSelected}
              fullWidth
              {...field}
              value={field.value?.trimStart()}
              error={errors?.supplier?.message}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="installation_mode"
          control={control}
          defaultValue={""}
          rules={validation("Installed Mode", true)}
          render={({ field }) => (
            <Select
              id="drawerSelectInstallationMode"
              theme={theme}
              label={"Installed Mode"}
              items={Object.entries(InstallationMode).map((item) => ({
                text: item[1],
                value: item[1],
              }))}
              {...field}
              value={field.value}
              sxProps={""}
              onChange={field.onChange}
              selectError={errors?.installation_mode?.message}
            ></Select>
          )}
        />
      </Grid>
      {installationMode && installationMode === InstallationMode.PRE_INSTALLED && (
        <>
          <Grid theme={theme} item xs={6}>
            <Controller
              name="price"
              control={control}
              rules={validation("Price", true)}
              render={({ field }) => (
                <Input
                  fullWidth
                  theme={theme}
                  label={"Price"}
                  type="text"
                  startAdornment={
                    <>
                      <span className="adornment-text">$</span>
                    </>
                  }
                  value={field.value ? parseFloat(field.value) : undefined}
                  id="drawerTextPrice"
                  masking
                  maskDecimalScale={2}
                  maskNumeric
                  {...field}
                  onChange={(e) => field.onChange(e)}
                  error={errors?.price?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={6}>
            <Controller
              name="is_price_inclusive"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Select
                  id="drawerSelectisPriceInclusive"
                  theme={theme}
                  label={""}
                  items={[
                    {
                      text: "Inclusive",
                      value: true,
                    },
                    {
                      text: "Exclusive",
                      value: false,
                    },
                  ]}
                  {...field}
                  value={field.value}
                  sxProps={""}
                  onChange={field.onChange}
                  selectError={errors?.is_price_inclusive?.message}
                ></Select>
              )}
            />
          </Grid>
        </>
      )}
      {(!installationMode ||
        installationMode === InstallationMode.OPTIONAL) && (
        <Grid theme={theme} item xs={12}>
          <Controller
            name="price"
            control={control}
            rules={validation("Price", true)}
            render={({ field }) => (
              <Input
                fullWidth
                id="drawerTextPriceSecond"
                theme={theme}
                label={"Price"}
                type="text"
                startAdornment={
                  <>
                    <span className="MuiInputAdornment-root">$</span>
                  </>
                }
                value={field.value ? parseFloat(field.value) : undefined}
                masking
                maskDecimalScale={2}
                maskNumeric
                {...field}
                onChange={(e) => field.onChange(e)}
                error={errors?.price?.message as never}
              />
            )}
          />
        </Grid>
      )}
      {offeredBy == "Dealer" && (
        <Grid theme={theme} item xs={12}>
          <Controller
            name="dealer_cost"
            control={control}
            render={({ field }) => (
              <Input
                fullWidth
                theme={theme}
                label={"Dealer Cost (Optional)"}
                type="text"
                startAdornment={
                  <>
                    <span className="MuiInputAdornment-root">$</span>
                  </>
                }
                value={field.value ? parseFloat(field.value) : undefined}
                masking
                maskDecimalScale={2}
                maskNumeric
                {...field}
                onChange={(e) => field.onChange(e)}
                error={errors?.dealer_cost?.message as never}
              />
            )}
          />
        </Grid>
      )}
      <Grid theme={theme} item xs={12}>
        <Controller
          name="rv_adder"
          control={control}
          render={({ field }) => (
            <Input
              fullWidth
              theme={theme}
              disabled={true}
              label={"RV On Option"}
              type="text"
              id="drawerTextRVAdder"
              startAdornment={
                <>
                  <span className="adornment-text">$</span>
                </>
              }
              value={field.value ? parseFloat(field.value) : undefined}
              masking
              maskDecimalScale={2}
              maskNumeric
              {...field}
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      </Grid>
      {assetTrimsData && (
        <Grid theme={theme} item xs={12}>
          <Controller
            name="compatible_models"
            control={control}
            rules={{
              validate: (_value) => {
                if (!_value || _value?.length < 1) {
                  return "Compatible Models are required";
                }
                return true;
              },
            }}
            render={({ field: { value, onChange } }) => (
              <MultiSelect
                theme={theme}
                id="drawerMultiSelectCompatibleModels"
                sxProps={{ m: 0, width: "100%" }}
                label={"Compatible Models"}
                placeholder={"Select"}
                items={assetTrimsData
                  ?.filter((row) => row.is_active)
                  ?.map((model: any) => {
                    return {
                      text: model.model_name,
                      value: model.id,
                    };
                  })}
                value={value ?? []}
                onChange={(e: any) => {
                  onChange(e);
                }}
                selectError={errors?.compatible_models?.message}
              />
            )}
          />
        </Grid>
      )}
      <Grid theme={theme} item xs={12}>
        <FileDragDropWrap>
          <Controller
            name="image"
            control={control}
            render={({ field }) => (
              <Tooltip
                theme={theme}
                title={
                  !s3Response?.data
                    ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                    : ""
                }
              >
                <FileDragDrop
                  {...field}
                  theme={theme}
                  width="300"
                  height="200px"
                  backgroundColor="white"
                  onDrop={onimageDrop}
                  onChange={onimageUpload}
                  allowMultiple={true}
                  disabled={!s3Response?.data}
                  error={errors?.image?.message}
                />
              </Tooltip>
            )}
          />
          <FilePreview
            files={images?.length > 0 ? images : []}
            onRemoveFile={onRemoveFile}
            viewIcon={true}
            theme={theme}
            onClickIcon={onClickIcon}
            preview="image"
          />
          {imageLoader && (
            <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
              <CircleLoader theme={theme} size="xs" />
            </ImgLoader>
          )}
        </FileDragDropWrap>
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="video_url"
          control={control}
          rules={validation(
            "Video Url",
            false,
            true,
            WEBSITE_REGEX,
            "Valid URL is required."
          )}
          render={({ field }) => (
            <Input
              theme={theme}
              label={"Video URL (Optional)"}
              type="text"
              id="drawerVideoUrl"
              fullWidth
              {...field}
              value={field.value?.trimStart()}
              error={errors?.video_url?.message}
            />
          )}
        />
        <Controller
          name="brochure_url"
          control={control}
          rules={validation(
            "Brochure Url",
            false,
            true,
            WEBSITE_REGEX,
            "Valid URL is required."
          )}
          render={({ field }) => (
            <Input
              theme={theme}
              label={"Brochure URL (Optional)"}
              type="text"
              id="drawerBrochureUrl"
              fullWidth
              {...field}
              value={field.value?.trimStart()}
              error={errors?.brochure_url?.message}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="preferred"
          control={control}
          defaultValue={""}
          rules={{
            validate: {
              checkPreferredLimit: (value) => {
                const isLimitExceeded =
                  preferredData &&
                  (!isEditMode || (isEditMode && !defaultFormValues.preferred));
                if (value === true && isLimitExceeded) {
                  return ValidationMessages.PREFERRED_LIMIT_EXCEEDED;
                }
                return true;
              },
            },
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              id="drawerSelect"
              name="preferred"
              theme={theme}
              label={"Preferred (Optional)"}
              items={[
                { text: "Yes", value: true },
                { text: "No", value: false },
              ]}
              value={value}
              sxProps={""}
              onChange={(e) => {
                onChange(e);
                checkPreferredFieldLimit(e.target.value);
              }}
              selectError={errors?.preferred?.message}
            ></Select>
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name="is_active"
          control={control}
          defaultValue={true}
          render={({ field: { value, onChange } }) => (
            <Select
              id="drawerSelect"
              theme={theme}
              label={"Status"}
              defaultValue={true}
              items={[
                { text: "Enabled", value: true },
                { text: "Disabled", value: false },
              ]}
              value={value}
              sxProps={""}
              onChange={onChange}
            ></Select>
          )}
        />
      </Grid>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={sliderImages}
        isLoading={false}
        visible={visibility}
        onClose={handleClose}
        overlayCheck={true}
        index={index}
        setIndex={setIndex}
      />
    </Grid>
  );
};

export default NewOption;
