import { FC, useEffect, useState } from "react";
import { INewStipulationPopUp } from "./AddNewStipulation.type";
import { useTheme } from "@mui/material";
import {
  PersistentDrawer,
  Icon,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "@sharedComponents";
import { Controller, useForm } from "react-hook-form";
import { IStipulation, ISelectItemList, IDocumentTypes } from "Interface";
import {
  ActionOptions,
  QueryKeys,
  Stipulation,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import {
  UseMutation_AddStipulation,
  UseMutation_UpdateStipulation,
} from "services";
import { useQueryClient } from "react-query";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";

const AddNewStipulation: FC<INewStipulationPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  enabledPin,
  stipulationData,
}) => {
  const [state] = useSetupsStore();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<IStipulation>();
  const theme = useTheme();
  const { mutate: AddStipulation } = UseMutation_AddStipulation();
  const { mutate: UpdateStipulation } = UseMutation_UpdateStipulation(
    getValues("id")
  );
  const [, dispatch] = useSetupsStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [parameterValues, setParameterValues] =
    useState<Array<ISelectItemList>>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [showDocumentType, setShowDocumentType] = useState<boolean>(false);
  const documentTypes: Array<IDocumentTypes> = queryClient.getQueryData(
    QueryKeys.GET_ALL_DOCUMENT_TYPES
  );
  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", stipulationData?.id);
    }
    setValue("stipulation_code", stipulationData?.stipulation_code);
    setValue("description", stipulationData?.description);
    setValue("stipulation_type", stipulationData?.stipulation_type);
    changeParameterValues(stipulationData?.stipulation_type);
    setValue("parameter", stipulationData?.parameter);
    setShowDocumentType(
      stipulationData?.parameter === Stipulation.UPLOAD_DOCUMENT ||
        stipulationData?.stipulation_type === Stipulation.GENERAL
    );
    if (stipulationData?.stipulation_type !== Stipulation.GENERAL) {
      setValue("document_type_id", stipulationData?.document_type_id);
    }
    if (stipulationData?.stipulation_type !== Stipulation.FINANCIAL) {
      setShowDocumentType(false)
    }
    if (stipulationData?.stipulation_type !== Stipulation.NON_FINANCIAL) {
      setShowDocumentType(false);
    }
    if (stipulationData?.stipulation_type !== Stipulation.GENERAL) {
      clearErrors("parameter")
      clearErrors("description")
    }
  }, [stipulationData]);

  const changeParameterValues = (stipulation_type_value) => {
    if (stipulation_type_value == Stipulation.FINANCIAL) {
      setParameterValues([
        {
          value: Stipulation.FINANCED_AMOUNT,
          text: "Financed Amount",
        },
        {
          value: Stipulation.PERIODIC_PAYMENTS,
          text: "Periodic Payment",
        },
        {
          value: Stipulation.TERMS,
          text: "Terms",
        },
      ]);
      setShowDocumentType(false)
      setValue("parameter", "")
    } else if (stipulation_type_value == Stipulation.NON_FINANCIAL) {
      setParameterValues([
        {
          value: Stipulation.UPLOAD_DOCUMENT,
          text: "Upload Document",
        },
        {
          value: Stipulation.CUSTOMER_CREDIT_UNFREEZE,
          text: "Customer Credit Unfreeze",
        },
      ]);
      setShowDocumentType(false)
      setValue("parameter", "")
    } else if (stipulation_type_value == Stipulation.GENERAL) {
      setParameterValues([{ value: Stipulation.ALL, text: "All" }]);
      setShowDocumentType(true);
      setValue("stipulation_code", "00000");
      setValue("parameter", Stipulation.ALL);
      setValue("document_type_id", Stipulation.ALL);
      setValue("description", "");
      clearErrors("parameter")
      clearErrors("description")
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };

  const onSubmit = (data: IStipulation) => {
    if (data.stipulation_type === Stipulation.GENERAL) {
      data.stipulation_code = "00000";
      delete data.document_type_id;
    }
    if (data.id && data.id > 0) {
      UpdateStipulation(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error: any) => {
          if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("stipulation_code", { message: "Code should be unique" });
            return;
          }
        },
      });
    } else {
      AddStipulation(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("stipulation_code", { message: "Code should be unique" });
            return;
          }
        },
      });
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="stipulation_code"
              control={control}
              rules={{
                maxLength: {
                  value: 50,
                  message: ValidationMessages.STIPULATION_CODE_MAX_LENGTH,
                },
                required:
                  getValues("stipulation_type") === Stipulation.GENERAL
                    ? false
                    : ValidationMessages.STIPULATION_CODE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerStipulationCode"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Stipulation Code"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.stipulation_code?.message}
                  disabled={
                    getValues("stipulation_type") === Stipulation.GENERAL
                  }
                />
              )}
            />
            <Controller
              name="stipulation_type"
              control={control}
              rules={{ required: ValidationMessages.STIPULATION_TYPE_REQUIRED }}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerStipulationType"
                  theme={theme}
                  label={"Stipulation Type"}
                  placeholder={"Stipulation Type"}
                  items={[
                    { text: "Financial", value: Stipulation.FINANCIAL },
                    { text: "Non-Financial", value: Stipulation.NON_FINANCIAL },
                    { text: "General", value: Stipulation.GENERAL },
                  ]}
                  value={value ?? ""}
                  onChange={(e) => {
                    onChange(e);
                    changeParameterValues(e.target.value);
                  }}
                  selectError={errors?.stipulation_type?.message as never}
                />
              )}
            />
            <Controller
              name="parameter"
              control={control}
              rules={{
                required: ValidationMessages.STIPULATION_PARAMAETER_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerParameter"
                  theme={theme}
                  label={"Parameter"}
                  placeholder={"Parameter"}
                  items={parameterValues}
                  value={value ?? ""}
                  onChange={(e) => {
                    onChange(e);
                    if (e.target.value == Stipulation.UPLOAD_DOCUMENT)
                      setShowDocumentType(true);
                    else {
                      setValue("document_type_id", null);
                      setShowDocumentType(false);
                    }
                  }}
                  selectError={errors?.parameter?.message as never}
                  disabled={
                    getValues("stipulation_type") === Stipulation.GENERAL
                  }
                />
              )}
            />
            {showDocumentType && (
              <Controller
                name="document_type_id"
                control={control}
                rules={{
                  required: {
                    value: showDocumentType,
                    message:
                      ValidationMessages.STIPULATION_DOCUMENT_TYPE_REQUIRED,
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    id="drawerDocumentId"
                    theme={theme}
                    label={"Document Type"}
                    placeholder={"Document Type"}
                    items={[
                      ...(getValues("stipulation_type") === Stipulation.GENERAL
                        ? [{ value: Stipulation.ALL, text: "All" }]
                        : []),
                      ...documentTypes
                        ?.filter(
                          (document) =>
                            document.is_active || document.id === value
                        )
                        ?.filter(
                          (document) =>
                            getValues("stipulation_type") !==
                              Stipulation.NON_FINANCIAL ||
                            (document.id.toString() !== Stipulation.ALL &&
                              document.description !== "All")
                        )
                        ?.map((document) => ({
                          value: document.id,
                          text: document.description,
                        })),
                    ]}
                    value={
                      getValues("stipulation_type") === Stipulation.GENERAL
                        ? Stipulation.ALL
                        : value ?? ""
                    }
                    onChange={onChange}
                    selectError={errors?.document_type_id?.message as never}
                    disabled={
                      getValues("stipulation_type") === Stipulation.GENERAL
                    }
                  />
                )}
              />
            )}
            <Controller
              name="description"
              control={control}
              rules={{
                required:
                  getValues("stipulation_type") === Stipulation.GENERAL
                    ? false
                    : ValidationMessages.DESCRIPTION_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerDescription"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Message Description"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.description?.message}
                  disabled={
                    getValues("stipulation_type") === Stipulation.GENERAL
                  }
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            id="saveStipulation"
            primary
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewStipulation;
