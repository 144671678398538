import TableCell from "@mui/material/TableCell";
import React, { FC, useState } from "react";
import TableRow from "@mui/material/TableRow";
import { Switch } from "@sharedComponents";
import { Icon, Menu, Button, Box, Input } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { ActionOptions } from "Enums";
import { useUpdateLenderIntegration } from "services";
import { getProviderName } from "utilities/common";
import { IntegrationType, Providers } from "Enums/IntegrationTypesAndProviders";

const TBProviderRow: FC<{
  onDelete: (data) => unknown;
  onProviderMapping: (id, integration_type) => unknown;
  integrationRow: any;
  integrationProviders: any;
  setParentIntegrationState?: any;
}> = ({
  onDelete,
  integrationRow,
  integrationProviders,
  setParentIntegrationState,
  onProviderMapping,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [providerId, setProviderId] = useState<number | undefined>(undefined);

  const tableOptions: any = [
    {
      optionText: (
        <>
          <Icon className="menu-icon" name="DeleteIcon" />
          {ActionOptions.DELETE}
        </>
      ),
      optionkey: ActionOptions.DELETE,
      optionValue: {},
    },
  ];

  if (
    integrationRow.integration_type === IntegrationType.CREDIT_DECISIONING ||
    integrationRow.integration_type === IntegrationType.CONTRACTING ||
    integrationRow.integration_type === IntegrationType.PROPOSAL_SUBMISSION
  ) {
    tableOptions.push({
      optionText: (
        <>
          <Icon className="menu-icon" name="EditIcon" />
          {ActionOptions.PROVIDER_MAPPING}
        </>
      ),
      optionkey: ActionOptions.PROVIDER_MAPPING,
      optionValue: {},
    });
  }

  const { mutate: updateLenderIntegration } = useUpdateLenderIntegration();
  const theme = useTheme();

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.DELETE:
        setParentIntegrationState(true);
        onDelete(value);
        return;
      case ActionOptions.PROVIDER_MAPPING:
        setParentIntegrationState(true);
        onProviderMapping(value.id, integrationRow.integration_type);
        return;
    }
  };

  const handleToggleButton = (provider, event) => {
    updateLenderIntegration({
      ...integrationRow,
      providers: [
        {
          ...provider,
          is_active: event.target.checked,
          id: provider.id,
          provider_name: provider.provider_name,
        },
      ],
    });
  };

  return (
    <>
      {integrationProviders?.map((provider, index) => (
        <React.Fragment key={`child${provider.id}`}>
          <TableRow key={`childData${index}`} className="child-tr">
            <TableCell className="indent-cell" colSpan={2}>
              <Box
                theme={theme}
                display="flex"
                className="group-title"
                sx={{ ml: 6 }}
              >
                {getProviderName(
                  integrationRow.integration_type,
                  provider.provider_name
                )}
              </Box>
            </TableCell>
            <TableCell className="action-cell fixed-cell">
              <Menu
                id={"ellipses" + provider.id}
                theme={theme}
                options={tableOptions.map((option) => ({
                  optionText: option.optionText,
                  optionkey: option.optionkey,
                  optionValue: null,
                }))}
                handleOptionClick={(e, key, value) =>
                  handleSelection(e, key, provider)
                }
                render={(onMenuSelection) => (
                  <Button
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={onMenuSelection}
                  />
                )}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </>
  );
};
export default TBProviderRow;
