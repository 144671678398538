import { FC, useState, useEffect } from "react";
import { TablePagination, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ChevronDown from "shared/assets/images/chevron-down";
import {
  Icon,
  Menu,
  DataTable,
  Button,
  Input,
  Box,
  Switch,
} from "@ntpkunity/controls";
import { ConfirmationDialog, PaginationWrap } from "@sharedComponents";
import {
  UseQuery_getAllRebate_filter,
  UseMutation_UpdateRebate,
  UseMutation_DeleteRebate,
} from "services";
import { IImage, IRebate, IRebateFilter } from "Interface";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { NewCompanyRebate } from "components";
import { IRebateProps } from "./rebate.type";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useQueryClient } from "react-query";
const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const RebateTable: FC<IRebateProps> = ({ onEdit }) => {
  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}&module=Company`
  );
  const { data } = UseQuery_getAllRebate_filter(columnFilters);
  const [rebateName, setRebateName] = useState("");
  const [rebateCode, setRebateCode] = useState("");
  const [rebateAmount, setRebateAmount] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const [state, dispatch] = useSetupsStore();
  const rebateFilter: IRebateFilter = data;
  const [tradingAgeState, setTradingAgeState] = useState<boolean>(false);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Rebate");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IRebate>(undefined);
  const { mutate: deleteRebate } = UseMutation_DeleteRebate();
  const { mutate: updateRebateData } = UseMutation_UpdateRebate();
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}&module=Company`;
    if (rebateName.trimStart() != null && rebateName.trimStart() != "") {
      query_string = query_string.concat(`&rebate_name=${rebateName}`);
    }
    if (rebateCode.trimStart() != null && rebateCode.trimStart() != "") {
      query_string = query_string.concat(`&rebate_code=${rebateCode}`);
    }
    if (rebateAmount.trimStart() != null && rebateAmount.trimStart() != "") {
      query_string = query_string.concat(`&rebate_amount=${rebateAmount}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled"
          .toLowerCase()
          .startsWith(statusFilter.slice(0, Math.max("enabled".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled"
          .toLowerCase()
          .startsWith(statusFilter.slice(0, Math.max("disabled".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  useEffect(() => {
    if (tradingAgeState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [tradingAgeState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };
  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);
  const onDeleteConfirm = (e: any) => {
    deleteRebate(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  const handleEnableChange = (data: IRebate, event) => {
    let updatedData = {
      ...data,
      is_active: event?.target?.checked,
    };
    updateRebateData(updatedData);
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        variant={"basic"}
        theadChildren={
          <>
            <TableRow key={0}>
              <TableCell>Rebate Name</TableCell>
              <TableCell>Rebate Code</TableCell>
              <TableCell>Rebate Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
            <TableRow key={1} className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"rebateName"}
                    onChange={(value) => {
                      setRebateName(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={rebateName?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"rebateCode"}
                    onChange={(value) => {
                      setRebateCode(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={rebateCode?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"rebateAmount"}
                    onChange={(value) => {
                      setRebateAmount(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={rebateAmount?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={""}
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {rebateFilter?.result &&
              rebateFilter?.result?.map((item, index) => (
                <TableRow className="child-tr" key={index}>
                  <TableCell>{item?.rebate_name}</TableCell>
                  <TableCell>{item?.rebate_code}</TableCell>
                  <TableCell>
                    {defaultCurrency} {item?.rebate_amount?.toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Switch
                      id={"ListDataStatus" + item?.id}
                      theme={theme}
                      varient={"basic"}
                      switchEnabled={item?.is_active}
                      onChange={(event) => handleEnableChange(item, event)}
                      label={item?.is_active ? "Enabled" : "Disabled"}
                    />
                  </TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={tableOptions.map((option) => {
                        return {
                          optionText: option.optionText,
                          optionkey: option.optionkey,
                          optionValue: item,
                        };
                      })}
                      handleOptionClick={handleSelection}
                      render={(onMenuSelection) => (
                        <Button
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={rebateFilter ? rebateFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default RebateTable;
