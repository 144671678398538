import { useTheme } from "@mui/material";
import { Grid, Input, Select } from "@ntpkunity/controls";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
export const FinanceRates: FC<{
  form: any;
  popUpOpenState: string;
  disabled: boolean;
  setCompanyFieldState?: any;
}> = ({ form, disabled, setCompanyFieldState }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = form;
  const theme = useTheme();

  const validatePercentageForFinance = (value: string) => {
    if (value === "") return true;
    const percentage = parseFloat(value);
    if (isNaN(percentage) || percentage < 0 || percentage > 100) {
      return "Please enter a value between 0 and 100.";
    }
    return true;
  };

  const validateDownPaymentRangeForFinance = (value: string, field: string) => {
    const minDownPayment = parseFloat(getValues("finance_mindownpaymentrate"));
    const maxDownPayment = parseFloat(getValues("finance_maxdownpaymentrate"));
    const standardDownPayment = parseFloat(getValues("finance_downPayment"));
    const downPayment = parseFloat(value);

    if (validatePercentageForFinance(value) !== true) {
      return validatePercentageForFinance(value);
    }

    if (field === "finance_mindownpaymentrate" && !isNaN(standardDownPayment)) {
      if (downPayment > standardDownPayment) {
        return "Min Down Payment should be less than or equal to Standard Down Payment.";
      }
    }

    if (field === "finance_downPayment") {
      if (!isNaN(minDownPayment) && downPayment < minDownPayment) {
        return "Standard Down Payment should be greater than or equal to Min Down Payment.";
      }

      if (
        !isNaN(maxDownPayment) &&
        maxDownPayment > 0 &&
        downPayment > maxDownPayment
      ) {
        return "Standard Down Payment should be less than or equal to Max Down Payment.";
      }
    }

    if (field === "finance_maxdownpaymentrate" && !isNaN(standardDownPayment)) {
      if (downPayment < standardDownPayment) {
        return "Max Down Payment should be greater than or equal to Standard Down Payment.";
      }
    }

    return true;
  };

  return (
    <>
      <Grid theme={theme} container columnSpacing={2} rowSpacing={3} pb={3}>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="finance_downPayment"
            control={control}
            defaultValue={"0.00"}
            rules={{
              validate: (value) =>
                validateDownPaymentRangeForFinance(
                  value,
                  "finance_downPayment"
                ),
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                placeholder={"Type here.."}
                label={"Down Payment (Optional)"}
                type="text"
                startAdornment=""
                id="markupDrawer"
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                value={value}
                endAdornment={<span className="MuiInputAdornment-root">%</span>}
                masking={true}
                maskNumeric={true}
                maskDecimalScale={2}
                maskAllowNegative
                error={errors.finance_downPayment?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="finance_mindownpaymentrate"
            control={control}
            defaultValue={"0.00"}
            rules={{
              validate: (value) =>
                validateDownPaymentRangeForFinance(
                  value,
                  "finance_mindownpaymentrate"
                ),
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                placeholder={"Type here.."}
                label={"Min Down Payment (Optional)"}
                type="text"
                startAdornment=""
                id="markupDrawer"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                value={value}
                endAdornment={<span className="MuiInputAdornment-root">%</span>}
                masking={true}
                maskNumeric={true}
                disabled={disabled}
                maskDecimalScale={2}
                maskAllowNegative
                error={errors.finance_mindownpaymentrate?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="finance_maxdownpaymentrate"
            control={control}
            defaultValue={"0.00"}
            rules={{
              validate: (value) =>
                validateDownPaymentRangeForFinance(
                  value,
                  "finance_maxdownpaymentrate"
                ),
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                placeholder={"Type here.."}
                label={"Max Down Payment (Optional)"}
                type="text"
                startAdornment=""
                id="markupDrawer"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                value={value}
                endAdornment={<span className="MuiInputAdornment-root">%</span>}
                masking={true}
                maskNumeric={true}
                disabled={disabled}
                maskDecimalScale={2}
                maskAllowNegative
                error={errors.finance_maxdownpaymentrate?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="finacnce_rvBalloon"
            control={control}
            defaultValue={"0.00"}
            render={({ field: { value, onChange } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                placeholder={"Type here.."}
                label={"RV/Balloon (Optional)"}
                type="text"
                startAdornment=""
                id="markupDrawer"
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                value={value}
                endAdornment={<span className="MuiInputAdornment-root">%</span>}
                masking={true}
                maskNumeric={true}
                maskDecimalScale={6}
                maskAllowNegative
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="finance_customerRate"
            control={control}
            defaultValue={"0.00"}
            render={({ field: { value, onChange } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                placeholder={"Type here.."}
                label={"Customer Rate (Optional)"}
                type="text"
                startAdornment=""
                disabled={disabled}
                id="markupDrawer"
                onChange={(e) => {
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                value={value}
                endAdornment={<span className="MuiInputAdornment-root">%</span>}
                masking={true}
                maskNumeric={true}
                maskDecimalScale={6}
                maskAllowNegative
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
