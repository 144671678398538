import React, { FC, useEffect, useState } from "react";
import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  DataTable,
  Icon,
  Input,
  Select,
  Tooltip,
} from "@ntpkunity/controls";
import { Controller } from "react-hook-form";
import { BorderedTableWrap, MultiSelectAll } from "@sharedComponents";
import { IFPCategoryMappingProps } from "Interface";

const FinancialInsuranceTable: FC<IFPCategoryMappingProps> = ({
  form,
  setUpdatedFPCategoryRows,
  financeTypes,
  fpCategoryMappings,
  categoriesArray,
  setFpMappingData,
}) => {
  const theme = useTheme();
  const {
    setValue,
    getValues,
    control,
    formState: { errors },
    clearErrors,
  } = form;

  const financeTypeItems =
    financeTypes?.map((finance_type) => ({
      text: finance_type.name,
      value: finance_type.id,
    })) || [];

  const categories = categoriesArray.map(({ code, name }) => ({
    text: name,
    value: name,
  }));

  const handleRowChange = (index) => {
    setUpdatedFPCategoryRows((prev) => new Set(prev).add(index));
  };

  const handleFieldChange = (index, field, value) => {
    const errorMessage = errors?.feesMapping?.[index]?.[field]?.message;
    if (errorMessage !== "This combination already exists") {
      clearErrors(`feesMapping.${index}.${field}`);
    }
    setFpMappingData((prevMappings) => {
      const updatedMappings = [...prevMappings];
      updatedMappings[index] = {
        ...updatedMappings[index],
        [field]: value,
      };
      return updatedMappings;
    });
  };

  return (
    <BorderedTableWrap theme={theme} className="custom-table-wrap">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell className="sr-num"></TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Finance Type</TableCell>
              <TableCell>External Code</TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={fpCategoryMappings?.map((item, index) => {
          return (
            <React.Fragment key={index + 1}>
              <TableRow className="child-tr">
                <TableCell className="sr-num">{index + 1}</TableCell>
                <TableCell
                  className={`input-cell ${
                    errors?.fpMapping?.[index]?.category ? "error" : ""
                  }`}
                >
                  {errors?.fpMapping?.[index]?.category && (
                    <Tooltip
                      theme={theme}
                      title={
                        errors?.fpMapping?.[index]?.category?.message || ""
                      }
                      placement="top"
                    >
                      <Icon name="InfoErrorIcon" />
                    </Tooltip>
                  )}
                  <Controller
                    name={`fpMapping.${index}.category`}
                    control={control}
                    defaultValue={item?.category || ""}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        theme={theme}
                        id={`fpMapping.${index}.category`}
                        name={`fpMapping.${index}.category`}
                        disablePortal={false}
                        items={categories}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleRowChange(index);
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      />
                    )}
                  />
                </TableCell>
                <Controller
                  name={`fpMapping.${index}.id`}
                  control={control}
                  defaultValue={item.id}
                  render={({ field }) => <input type="hidden" {...field} />}
                />
                <TableCell
                  className={`input-cell ${
                    errors?.fpMapping?.[index]?.finance_type ? "error" : ""
                  }`}
                >
                  {errors?.fpMapping?.[index]?.finance_type && (
                    <Tooltip
                      theme={theme}
                      title={
                        errors?.fpMapping?.[index]?.finance_type?.message || ""
                      }
                      placement="top"
                    >
                      <Icon name="InfoErrorIcon" />
                    </Tooltip>
                  )}

                  <Controller
                    name={`fpMapping.${index}.finance_type`}
                    control={control}
                    defaultValue={item?.finance_type}
                    render={({ field: { value, onChange } }) => (
                      <MultiSelectAll
                        id={`fpMapping.${index}.finance_type`}
                        name={`fpMapping.${index}.finance_type`}
                        theme={theme}
                        isSelectAll={item?.finance_type ? false : true}
                        getValues={getValues}
                        setValue={setValue}
                        items={financeTypeItems}
                        value={value}
                        onChange={(e) => {
                          handleFieldChange(
                            index,
                            "finance_type",
                            e.target.value
                          );
                          handleRowChange(index);
                        }}
                        disablePortal={false}
                      />
                    )}
                  />
                </TableCell>
                <TableCell
                  className={`input-cell ${
                    errors?.fpMapping?.[index]?.external_code ? "error" : ""
                  }`}
                >
                  {errors?.fpMapping?.[index]?.external_code && (
                    <Tooltip
                      theme={theme}
                      title={
                        errors?.fpMapping?.[index]?.external_code?.message || ""
                      }
                      placement="top"
                    >
                      <Icon name="InfoErrorIcon" />
                    </Tooltip>
                  )}

                  <Controller
                    name={`fpMapping.${index}.external_code`}
                    control={control}
                    defaultValue={item?.external_code}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Type here..."}
                        type="string"
                        id={`fpMapping.${index}.external_code`}
                        onChange={(e) => {
                          onChange(e);
                          handleRowChange(index);
                        }}
                        value={value}
                      ></Input>
                    )}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      />
    </BorderedTableWrap>
  );
};

export default FinancialInsuranceTable;
