import React, { FC, useState } from "react";
import { useTheme, Link } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button, Icon, Menu, Switch } from "@ntpkunity/controls";
import ProductRow from "./product-row";
import { useUpdateFinancialInsuranceBundleStatus } from "services/financial-insurance.service";
import { ActionOptions } from "Enums";

const BundleRow: FC<{
  bundleData: any;
  dealer_code?: any;
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  handleImage: (data) => unknown;
  handleSelection: any;
}> = ({
  bundleData,
  dealer_code,
  onEdit,
  onDelete,
  handleImage,
  handleSelection,
}) => {
  const theme = useTheme();
  const { mutate: UpdateFinancialInsuranceBundle } =
    useUpdateFinancialInsuranceBundleStatus();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className="child-tr">
        <TableCell id={"image" + bundleData?.bundle_name} className="img-cell">
          {bundleData?.image?.length > 0 && (
            <Link
              onClick={() => {
                handleImage(bundleData?.image);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                className="prd-img"
                src={bundleData.image[0]?.location}
                alt="Car"
              />
            </Link>
          )}
        </TableCell>
        <TableCell>
          {open ? (
            <Icon
              name="ChevronDown"
              className="toggle-btn ch-right"
              onClick={() => setOpen(!open)}
            />
          ) : (
            <Icon
              name="ChevronDown"
              className="toggle-btn"
              onClick={() => setOpen(!open)}
            />
          )}
          {bundleData?.bundle_name}
        </TableCell>
        <TableCell>{bundleData?.description}</TableCell>
        <TableCell>{bundleData?.markup}%</TableCell>
        <TableCell></TableCell>
        <TableCell />
        <TableCell>
          <Switch
            theme={theme}
            varient={"basic"}
            switchEnabled={bundleData?.is_active}
            label={bundleData?.is_active ? "Enabled" : "Disabled"}
            onChange={(event) => {
              const updatedBundleData = {
                id: bundleData.id,
                is_active: event.target.checked,
              };
              UpdateFinancialInsuranceBundle(updatedBundleData);
            }}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="EditIcon" />
                    {ActionOptions.EDIT}
                  </>
                ),
                optionkey: ActionOptions.EDIT,
                optionValue: bundleData,
              },
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="DeleteIcon" />
                    {ActionOptions.DELETE}
                  </>
                ),
                optionkey: ActionOptions.DELETE,
                optionValue: bundleData?.id,
              },
            ]}
            handleOptionClick={handleSelection}
            render={(cb) => (
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      {open ? (
        <>
          {bundleData.financial_products &&
            bundleData?.financial_products.map((productRow, index) => {
              return (
                <React.Fragment key={productRow?.id + "child"}>
                  <ProductRow
                    productRow={productRow}
                    bundle_id={bundleData?.id}
                    dealer_code={dealer_code}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    handleImage={handleImage}
                    handleSelection={handleSelection}
                  />
                </React.Fragment>
              );
            })}
        </>
      ) : null}
    </>
  );
};

export default BundleRow;
