import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Dialog, Box, IBoxProps, DuoTab, Button } from "@ntpkunity/controls";
import { styled } from "@mui/material/styles";
import FinancialInsuranceTable from "./financialInsuranceTable";
import OptionsTable from "./optionsTable";
import FeesTable from "./feesTable";
import FinanceTypeTable from "./financeTypeTable";
import { IProviderMappingProps, IFinanceTypeModel, IStates } from "Interface";
import { useForm } from "react-hook-form";
import {
  UseQuery_GetAllFinanceTypes,
  UseMutation_AddFeesMappings,
  UseMutation_AddOptionMappings,
  UseMutation_AddFPCategoryMappings,
  useMutation_GetAllOptionMappings,
  useMutation_GetFPCategoryMappings,
  useMutation_GetAllFeesMappings,
  useMutation_GetFinanceMappings,
  UseMutation_AddFinanceMappings,
} from "services";
import { QueryKeys } from "Enums";
import { useQueryClient } from "react-query";
import { UseQuery_getAllStates } from "services/dealer-configurations.service";
import { IntegrationType, Providers } from "Enums/IntegrationTypesAndProviders";
import { isFeeCombinationsUnique, isFPCombinationsUnique } from "./helper";

const DuoTabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.duo-tab-wrap": {
    "&.align-center": {
      ".TabsUnstyled-root": {
        ".TabsListUnstyled-root": {
          justifyContent: "center",
          margin: "0 auto 24px auto",
          width: "max-content",
          backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[100] : "#f2f2f2",
          ".TabUnstyled-root": {
            width: "auto",
          },
        },
      },
    },
    "&.sm": {
      ".TabsUnstyled-root": {
        ".TabsListUnstyled-root": {
          ".TabUnstyled-root": {
            padding: "4px 12px 4px 12px",
            "&.Mui-selected": {
              fontWeight: theme.typography.fontWeightMedium,
            },
          },
        },
      },
    },
  },
}));

const ProviderMapping: FC<IProviderMappingProps> = ({
  providerId,
  integrationType,
  setProviderMappingState,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [mappingDialog, setMappingDialog] = useState(true);

  const [updatedFeesRows, setUpdatedFeesRows] = useState(new Set());
  const [updatedOptionRows, setUpdatedOptionRows] = useState(new Set());
  const [updatedFPCategoryRows, setUpdatedFPCategoryRows] = useState(new Set());
  const [updatedFinanceTypeRows, setUpdatedFinanceTypeRows] = useState(
    new Set()
  );
  const [feesMappingData, setFeesMappingData] = useState([]);
  const [fpMappingData, setFpMappingData] = useState([]);
  const [optionMappingData, setOptionMappingData] = useState([]);
  const [financeMappingData, setFinanceMappingData] = useState([]);

  const [categoriesArray, setCategoriesArray] = useState([
    { code: "-1", name: "Others" },
    { code: "2", name: "GAP Insurance" },
    { code: "3", name: "Tire & Wheel Protection" },
    { code: "4", name: "EVP/EMP- Extended Service Contract" },
    { code: "5", name: "Windshield Protection" },
    { code: "6", name: "Dent Protection" },
    { code: "7", name: "Lease Protection" },
    { code: "8", name: "Key Protection" },
    { code: "9", name: "CPO Wrap" },
    { code: "10", name: "Presence Protection" },
    { code: "11", name: "All Surface Protection" },
    { code: "12", name: "BMW Bundle" },
    { code: "13", name: "3rd Party Bundle" },
    { code: "14", name: "Maintenance Agreement 1" },
    { code: "27", name: "Maintenance Agreement 2" },
    { code: "28", name: "Other 1" },
    { code: "29", name: "Other 2" },
    { code: "30", name: "Other 3" },
  ]);
  const [activeTab, setActiveTab] = useState(0);

  const form = useForm();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = form;

  const handleMappingClose = () => {
    setMappingDialog(false);
    setProviderMappingState(false);
  };

  UseQuery_GetAllFinanceTypes();
  UseQuery_getAllStates();

  const { mutate: AddFeesMappings } = UseMutation_AddFeesMappings();
  const { mutate: AddOptionMappings } = UseMutation_AddOptionMappings();
  const { mutate: AddFPCategoryMappings } = UseMutation_AddFPCategoryMappings();
  const { mutate: AddFinanceMappings } = UseMutation_AddFinanceMappings();

  const { mutate: fetchFPCategoryMappings, data: fpCategoryMappings } =
    useMutation_GetFPCategoryMappings(providerId);
  const { mutate: fetchOptionMappings, data: optionMappings } =
    useMutation_GetAllOptionMappings(providerId);
  const { mutate: fetchFeesMappings, data: feesMappings } =
    useMutation_GetAllFeesMappings(providerId);
  const { mutate: fetchFinanceMappings, data: financeMappings } =
    useMutation_GetFinanceMappings(providerId);

  const financeTypes: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const statesData: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );

  useEffect(() => {
    if (
      integrationType === IntegrationType.CREDIT_DECISIONING ||
      integrationType === IntegrationType.CONTRACTING ||
      integrationType === IntegrationType.DEAL_PUSH
    ) {
      fetchFPCategoryMappings();
    }
  }, [fetchFPCategoryMappings, providerId]);

  useEffect(() => {
    if (
      integrationType === IntegrationType.CREDIT_DECISIONING ||
      integrationType === IntegrationType.CONTRACTING ||
      integrationType === IntegrationType.DEAL_PUSH
    ) {
      fetchOptionMappings();
    }
  }, [fetchOptionMappings, providerId]);

  useEffect(() => {
    if (
      integrationType === IntegrationType.CREDIT_DECISIONING ||
      integrationType === IntegrationType.CONTRACTING ||
      integrationType === IntegrationType.DEAL_PUSH
    ) {
      fetchFeesMappings();
    }
  }, [fetchFeesMappings, providerId]);

  useEffect(() => {
    if (integrationType === IntegrationType.PROPOSAL_SUBMISSION) {
      fetchFinanceMappings();
    }
  }, [fetchFinanceMappings, providerId]);

  useEffect(() => {
    if (feesMappings) {
      setFeesMappingData(feesMappings?.data);
    }
  }, [feesMappings]);

  useEffect(() => {
    if (optionMappings) {
      setOptionMappingData(optionMappings?.data);
    }
  }, [optionMappings]);

  useEffect(() => {
    if (fpCategoryMappings) {
      const updatedMappingData = categoriesArray.map((category) => {
        const categoryMappings = fpCategoryMappings.data?.filter(
          (mapping) => mapping.category === category.name
        );
        if (categoryMappings.length > 0) {
          return categoryMappings.map((mapping) => ({
            category: category.name,
            finance_type: mapping.finance_type,
            external_code: mapping.external_code,
            id: mapping.id,
          }));
        } else {
          return [
            {
              category: category.name,
              finance_type: null,
              external_code: null,
              id: null,
            },
          ];
        }
      });
      setFpMappingData(updatedMappingData.flat());
    }
  }, [fpCategoryMappings, categoriesArray]);

  useEffect(() => {
    if (financeMappings) {
      setFinanceMappingData(financeMappings?.data);
    }
  }, [financeMappings]);

  const onSubmit = (data) => {
    let hasFeesError = false;
    let hasFpError = false;

    let modifiedFeesMappingRows = [];
    let modifiedOptionMappingRows = [];
    let modifiedFPMappingRows = [];
    let modifiedFinanceMappingRows = [];

    if (
      integrationType === IntegrationType.CREDIT_DECISIONING ||
      integrationType === IntegrationType.CONTRACTING ||
      integrationType === IntegrationType.DEAL_PUSH
    ) {
      hasFeesError = isFeeCombinationsUnique(data, setError);
      hasFpError = isFPCombinationsUnique(data, setError);
    }

    if (hasFeesError || hasFpError) {
      return;
    }

    modifiedFeesMappingRows = Array.from(updatedFeesRows)?.map((index: any) => {
      return {
        ...data.feesMapping[index],
        provider_id: providerId,
      };
    });

    modifiedOptionMappingRows = Array.from(updatedOptionRows)?.map(
      (index: any) => {
        return {
          ...data.optionMapping[index],
          provider_id: providerId,
        };
      }
    );

    modifiedFPMappingRows = Array.from(updatedFPCategoryRows)?.map(
      (index: any) => {
        const categoryName = data.fpMapping[index].category;
        const matchingCategory = categoriesArray.find(
          (category) => category.name === categoryName
        );
        return {
          ...data.fpMapping[index],
          provider_id: providerId,
          category_code: matchingCategory ? matchingCategory.code : null,
        };
      }
    );

    modifiedFinanceMappingRows = Array.from(updatedFinanceTypeRows)?.map(
      (index: any) => {
        return {
          ...data.financeMapping[index],
          provider_id: providerId,
        };
      }
    );

    if (modifiedFeesMappingRows.length > 0) {
      AddFeesMappings(modifiedFeesMappingRows, {
        onSuccess: async (response) => {
          if (response) {
            const updatedData = response?.data;
            updatedData.forEach((updatedItem, index) => {
              setValue(`feesMapping.${index}.id`, updatedItem.id);
              setValue(
                `feesMapping.${index}.fee_name_id`,
                updatedItem.fee_name_id || ""
              );
              setValue(
                `feesMapping.${index}.finance_type`,
                updatedItem.finance_type || []
              );
              setValue(`feesMapping.${index}.state`, updatedItem.state || []);
              setValue(
                `feesMapping.${index}.external_code`,
                updatedItem.external_code || ""
              );
            });
            setFeesMappingData(updatedData);
            setUpdatedFeesRows(new Set());
          }
        },
      });
    }

    if (modifiedOptionMappingRows.length > 0) {
      AddOptionMappings(modifiedOptionMappingRows, {
        onSuccess: async (response) => {
          if (response) {
            const updatedData = response?.data;
            updatedData.forEach((updatedItem, index) => {
              setValue(`optionMapping.${index}.id`, updatedItem.id);
              setValue(
                `optionMapping.${index}.option_category_id`,
                updatedItem.option_category_id
              );
              setValue(`optionMapping.${index}.category`, updatedItem.category);
              setValue(
                `optionMapping.${index}.external_code`,
                updatedItem.external_code || ""
              );
            });
            setOptionMappingData(updatedData);
            setUpdatedOptionRows(new Set());
          }
        },
      });
    }

    if (modifiedFPMappingRows.length > 0) {
      AddFPCategoryMappings(modifiedFPMappingRows, {
        onSuccess: async (response) => {
          if (response) {
            const updatedMappingData = categoriesArray.map((category) => {
              const categoryMappings = response?.data?.filter(
                (mapping) => mapping.category === category.name
              );

              if (categoryMappings.length > 0) {
                return categoryMappings.map((mapping) => ({
                  category: category.name,
                  finance_type: mapping.finance_type,
                  external_code: mapping.external_code,
                  id: mapping.id,
                }));
              } else {
                return [
                  {
                    category: category.name,
                    finance_type: null,
                    external_code: null,
                    id: null,
                  },
                ];
              }
            });
            const mergedData = updatedMappingData.flat();

            mergedData.forEach((item, index) => {
              setValue(`fpMapping.${index}.category`, item.category);
              setValue(
                `fpMapping.${index}.finance_type`,
                item.finance_type || []
              );
              setValue(
                `fpMapping.${index}.external_code`,
                item.external_code || ""
              );
              setValue(`fpMapping.${index}.id`, item.id || null);
            });

            setFpMappingData(mergedData);
            setUpdatedFPCategoryRows(new Set());
          }
        },
      });
    }

    if (modifiedFinanceMappingRows.length > 0) {
      AddFinanceMappings(modifiedFinanceMappingRows, {
        onSuccess: async (response) => {
          if (response) {
            const updatedData = response?.data;
            updatedData.forEach((updatedItem, index) => {
              setValue(`financeMapping.${index}.id`, updatedItem.id);
              setValue(
                `financeMapping.${index}.finance_type_id`,
                updatedItem.finance_type_id
              );
              setValue(
                `financeMapping.${index}.external_code`,
                updatedItem.external_code || ""
              );
            });
            setFinanceMappingData(updatedData);
            setUpdatedFinanceTypeRows(new Set());
          }
        },
      });
    }
  };

  const handleAddNewRow = () => {
    if (activeTab == 0) {
      setFpMappingData((prevMappings) => [
        ...prevMappings,
        {
          category: "",
          finance_type: [],
          external_code: "",
          category_code: "",
        },
      ]);
    }
    if (activeTab == 2) {
      setFeesMappingData((prevMappings) => [
        ...prevMappings,
        {
          fee_name: "",
          finance_type: [],
          state: [],
          external_code: "",
        },
      ]);
    }
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Dialog
        variant={undefined}
        size="xl"
        title="Provider Mapping"
        open={mappingDialog}
        onCloseDialog={handleMappingClose}
        disablePortal={true}
        customFooter={
          <>
            <Box
              theme={theme}
              sx={{ display: "flex", justifyContent: "center", gap: 2 }}
            >
              {(integrationType === IntegrationType.CREDIT_DECISIONING ||
                integrationType === IntegrationType.CONTRACTING ||
                integrationType === IntegrationType.DEAL_PUSH) && (
                <Button
                  theme={theme}
                  secondary
                  text="Add New"
                  onClick={() => handleAddNewRow()}
                />
              )}
              <Button
                theme={theme}
                primary
                text="Save Changes"
                onClick={handleSubmit(onSubmit)}
              />
            </Box>
          </>
        }
        theme={theme}
        children={
          <>
            <DuoTabWrap theme={theme} className="duo-tab-wrap align-center sm">
              <form onSubmit={(e) => e.preventDefault()}>
                <DuoTab
                  theme={theme}
                  defaultTabIndex={0}
                  onChange={handleChange}
                  items={
                    integrationType === IntegrationType.CREDIT_DECISIONING ||
                    integrationType === IntegrationType.CONTRACTING ||
                    integrationType === IntegrationType.DEAL_PUSH
                      ? [
                          {
                            title: "F&I Products",
                            content: (
                              <FinancialInsuranceTable
                                form={form}
                                setUpdatedFPCategoryRows={
                                  setUpdatedFPCategoryRows
                                }
                                financeTypes={financeTypes}
                                fpCategoryMappings={fpMappingData}
                                categoriesArray={categoriesArray}
                                setFpMappingData={setFpMappingData}
                              />
                            ),
                          },
                          {
                            title: "Add-Ons",
                            content: (
                              <OptionsTable
                                form={form}
                                setUpdatedOptionRows={setUpdatedOptionRows}
                                optionMappings={optionMappingData}
                              />
                            ),
                          },
                          {
                            title: "Fees",
                            content: (
                              <FeesTable
                                form={form}
                                setUpdatedFeesRows={setUpdatedFeesRows}
                                financeTypes={financeTypes}
                                statesData={statesData}
                                feesMappings={feesMappingData}
                                setFeesMappingData={setFeesMappingData}
                              />
                            ),
                          },
                        ]
                      : integrationType === IntegrationType.PROPOSAL_SUBMISSION
                      ? [
                          {
                            title: "Finance Type",
                            content: (
                              <FinanceTypeTable
                                form={form}
                                setUpdatedFinanceTypeRows={
                                  setUpdatedFinanceTypeRows
                                }
                                financeMappings={financeMappingData}
                              />
                            ),
                          },
                        ]
                      : []
                  }
                />
              </form>
            </DuoTabWrap>
          </>
        }
      />
    </>
  );
};

export default ProviderMapping;
