import { FC } from "react";
import { useTheme } from "@mui/material";
import {
  Button,
  MultiSelect,
  PersistentDrawer,
  Select,
} from "@ntpkunity/controls";
import { Controller, useForm } from "react-hook-form";
import {
  UseQuery_GetActiveDealers,
  UseQuery_GetAllAssetCategory,
  UseQuery_GetAllAssetType,
  UseQuery_GetAllCustomerType,
  UseQuery_getAllFinancedAmount,
  UseQuery_GetAllFinanceType,
  UseQuery_getAllLender,
  UseQuery_GetAllProductType,
} from "../../services";
import { ILenderSelectionRule } from "../../Interface/LenderSelectionRule/ILenderSelectionRules";
import { useGetAllLenderSelectioRules } from "../../hooks/lenderSelectionRules";
import { SearchParams } from "../../Types/lenderSelectionRules";
const AddNew: FC<{
  open: boolean;
  setOpen: any;
  onSubmit: any;
  isEdit: boolean;
  defaultValues: ILenderSelectionRule;
  queryParams: SearchParams;
  callInProgress: boolean;
}> = ({
  open,
  setOpen,
  onSubmit,
  isEdit,
  defaultValues,
  queryParams,
  callInProgress,
}) => {
  const { isLoading } = useGetAllLenderSelectioRules(queryParams);
  const { data: financeTypes } = UseQuery_GetAllFinanceType();
  const { data: productTypes } = UseQuery_GetAllProductType();
  const { data: financeAmountRanges } = UseQuery_getAllFinancedAmount();
  const { data: customerTypes } = UseQuery_GetAllCustomerType();
  const { data: assetCategories } = UseQuery_GetAllAssetCategory();
  const { data: assetTypesData } = UseQuery_GetAllAssetType();
  const { data: dealers } = UseQuery_GetActiveDealers();
  const { data: lenders } = UseQuery_getAllLender();

  const { control, handleSubmit, formState, watch, setValue } =
    useForm<ILenderSelectionRule>({
      defaultValues,
      mode: "onChange",
    });

  const formAssetCategories = watch("asset_categories");
  const formAssetTypes = watch("asset_types");
  const theme = useTheme();
  const isFormDirty = formState.isDirty;

  const filteredAssetTypes = (categories) => {
    const types = assetTypesData?.filter((type) =>
      assetCategories?.some(
        (category) =>
          categories?.includes(category.description) &&
          category.id == type.asset_category_id
      )
    );
    return types;
  };

  const assetTypes = filteredAssetTypes(formAssetCategories);

  return (
    <>
      <PersistentDrawer
        openPopUp={open}
        setPopUpState={setOpen}
        title={
          isEdit
            ? "Edit Lender Selection Rule"
            : "Add New Lender Selection Rule"
        }
        theme={theme}
        children={
          <>
            <Controller
              name="product_types"
              control={control}
              rules={{ required: "Product Type is required" }}
              render={({ field, fieldState }) => (
                <MultiSelect
                  id="drawer_product_types"
                  theme={theme}
                  fullWidth
                  label="Product Type"
                  items={
                    productTypes?.map((productType) => {
                      return {
                        text: productType.name,
                        value: productType.name,
                      };
                    }) ?? []
                  }
                  {...field}
                  selectError={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="finance_types"
              control={control}
              rules={{ required: "Finance Type is required" }}
              render={({ field, fieldState }) => (
                <MultiSelect
                  id="drawer_finance_types"
                  theme={theme}
                  fullWidth
                  label="Finance Type"
                  {...field}
                  items={
                    financeTypes?.map((financeType) => {
                      return {
                        text: financeType.name,
                        value: financeType.name,
                      };
                    }) ?? []
                  }
                  selectError={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="finance_amount_range"
              control={control}
              rules={{ required: "Finance Amount Range is required" }}
              render={({ field, fieldState }) => (
                <MultiSelect
                  id="drawer_finance_amount_range"
                  theme={theme}
                  fullWidth
                  label="Finance Amount Range"
                  {...field}
                  items={
                    financeAmountRanges?.map((financeAmountRange) => {
                      return {
                        text: `$${financeAmountRange.financed_amount_from}-$${financeAmountRange.financed_amount_to}`,
                        value: `$${financeAmountRange.financed_amount_from}-$${financeAmountRange.financed_amount_to}`,
                      };
                    }) ?? []
                  }
                  selectError={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="customer_types"
              control={control}
              rules={{ required: "Customer Type is required" }}
              render={({ field, fieldState }) => (
                <MultiSelect
                  id="drawer_customer_types"
                  theme={theme}
                  fullWidth
                  label="Customer Type"
                  {...field}
                  items={
                    customerTypes?.map((customerType) => {
                      return {
                        text: customerType.description,
                        value: customerType.description,
                      };
                    }) ?? []
                  }
                  selectError={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="asset_categories"
              control={control}
              rules={{ required: "Asset Category is required" }}
              render={({ field, fieldState }) => (
                <MultiSelect
                  id="drawer_asset_categories"
                  theme={theme}
                  fullWidth
                  label="Asset Category"
                  items={
                    assetCategories?.map((assetCategory) => {
                      return {
                        text: assetCategory.description,
                        value: assetCategory.description,
                      };
                    }) ?? []
                  }
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    const types = filteredAssetTypes(e.target.value);
                    const filterAssetTypes = types?.reduce((acc, assetType) => {
                      if (formAssetTypes?.includes(assetType.name)) {
                        acc.push(assetType.name);
                      }
                      return acc;
                    }, []);
                    setValue("asset_types", filterAssetTypes);
                  }}
                  selectError={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="asset_types"
              control={control}
              rules={{ required: "Asset Type is required" }}
              render={({ field, fieldState }) => (
                <MultiSelect
                  id="drawer_asset_types"
                  theme={theme}
                  fullWidth
                  label="Asset Type"
                  disabled={formAssetCategories.length < 1}
                  {...field}
                  items={
                    assetTypes?.map((assetType) => {
                      return {
                        text: assetType.name,
                        value: assetType.name,
                      };
                    }) ?? []
                  }
                  selectError={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="dealer_ids"
              control={control}
              rules={{ required: "Dealer Selection is required" }}
              render={({ field, fieldState }) => (
                <MultiSelect
                  id="drawer_dealer_ids"
                  theme={theme}
                  fullWidth
                  label="Dealer Selection"
                  {...field}
                  items={
                    dealers?.map((dealer) => {
                      return {
                        text: dealer.dealer_name,
                        value: dealer.id,
                      };
                    }) ?? []
                  }
                  selectError={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="lender_id"
              control={control}
              rules={{ required: "Lender Selection is required" }}
              render={({ field, fieldState }) => (
                <Select
                  id="drawer_lender_id"
                  theme={theme}
                  fullWidth
                  label="Lender Selection"
                  {...field}
                  items={
                    lenders?.map((lender) => {
                      return {
                        text: lender.name,
                        value: lender.id,
                      };
                    }) ?? []
                  }
                  selectError={fieldState?.error?.message}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            primary
            theme={theme}
            text={
              isEdit
                ? "Update Lender Selection Rule"
                : "Save Lender Selection Rule"
            }
            onClick={handleSubmit(onSubmit)}
            fullWidth={true}
            disabled={!isFormDirty || isLoading || callInProgress}
          />
        }
      />
    </>
  );
};

export default AddNew;
