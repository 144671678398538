import { FC, Dispatch, SetStateAction } from "react";
import { useTheme } from "@mui/material";
import {
  Accordion,
  Box,
  Button,
  Icon,
  Input,
  MultiSelect,
  PersistentDrawer,
  Select,
  Textarea,
  TextDivider,
  Typography,
} from "@ntpkunity/controls";

import { AccordionWrapSm } from "@sharedComponents";

export declare type LendersDrawerPopUp = {
  openPopUp: boolean;
  setPopUpState: Dispatch<SetStateAction<boolean>>;
  title: string;
};

const LendersDrawer: FC<LendersDrawerPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
}) => {
  const theme = useTheme();

  const AccordionContent: FC = () => {
    return (
      <>
        <Select
          theme={theme}
          label={"Tier Name"}
          placeholder={"Select"}
          items={[
            { text: "Option 1", value: "1" },
            { text: "Option 2", value: "2" },
            { text: "Option 3", value: "3" },
          ]}
        />
        <Select
          theme={theme}
          label={"Finance Type"}
          placeholder={"Select"}
          items={[
            { text: "Option 1", value: "1" },
            { text: "Option 2", value: "2" },
            { text: "Option 3", value: "3" },
          ]}
        />
        <Input
          theme={theme}
          fullWidth={true}
          placeholder={"Type here.."}
          label={"Margin Rate"}
          type="text"
          endAdornment="%"
        />
      </>
    );
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  return (
    <>
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Lender Name"}
              type="text"
            />
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"External Code"}
              type="text"
            />
            <Select
              theme={theme}
              label={"Status"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Default"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <MultiSelect
              theme={theme}
              label={"Finance Type(s)"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Pricing Program"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Lease Rate Type"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Down Payment"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Credit Decisioning"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Credit Decisioning Provider"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Contracting"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Contracting Provider"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Signature Type"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Event"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Select
              theme={theme}
              label={"Contracting Response"}
              placeholder={"Select"}
              items={[
                { text: "Option 1", value: "1" },
                { text: "Option 2", value: "2" },
                { text: "Option 3", value: "3" },
              ]}
            />
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Excess Mileage Rate"}
              type="text"
              startAdornment="$"
            />
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Credit Decision Expiry"}
              type="text"
              endAdornment="days"
            />
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Minimum Financed Amount"}
              type="text"
              startAdornment="$"
            />
            <TextDivider
              theme={theme}
              title={
                <>
                  <Typography
                    theme={theme}
                    variant="subtitle1"
                    component="span"
                    mb={3}
                    className="text-uppercase"
                    children="CONTACT PERSON DETAILS"
                    sx={{ fontSize: "12px !important" }}
                  />
                </>
              }
            />
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Name"}
              type="text"
            />
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Email Address"}
              type="email"
            />
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Contact Number"}
              type="number"
            />
            <AccordionWrapSm theme={theme} mb={3}>
              <Accordion
                theme={theme}
                varient="filters"
                items={[
                  {
                    content: <AccordionContent />,
                    isExpanded: true,
                    title: "Credit Tier Margin 1",
                    key: "string1",
                    actionBtn: (
                      <>
                        <Button
                          theme={theme}
                          defaultBtn
                          iconText={<Icon name="DeleteIcon" />}
                        />
                      </>
                    ),
                  },
                  {
                    content: <AccordionContent />,
                    isExpanded: true,
                    title: "Credit Tier Margin 2",
                    key: "string2",
                    actionBtn: (
                      <>
                        <Button
                          theme={theme}
                          defaultBtn
                          iconText={<Icon name="DeleteIcon" />}
                        />
                      </>
                    ),
                  },
                ]}
              />
            </AccordionWrapSm>
            <Box theme={theme} mb={3}>
              <Button
                theme={theme}
                secondary
                text="Add Credit Tier Margin"
                fullWidth
              />
            </Box>
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Min. Duration of Current Address"}
              type="number"
              endAdornment="Months"
            />
          </>
        }
        customFooter={<Button theme={theme} primary text="Save" fullWidth />}
      />
    </>
  );
};

export default LendersDrawer;
