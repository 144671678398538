import { useContext, useState, useEffect } from "react";
import { DealerContext } from "context";
import { DefaultUnAuthComponent } from "@ntpkunity/controls-ums";
import { UserRole } from "Enums";
import { CircleLoader, Box } from "@ntpkunity/controls";
import { useTheme, styled } from "@mui/material";

export const ValidateDealerUser = ({ children }) => {
  const {
    associatedDealers,
    isLoadingActiveDealers,
    isLoadingAssociatedDealers,
  } = useContext(DealerContext);
  const [userInUserRole, setUserInUserRole] = useState(false);
  const ums_base_url = process.env.MARKETPLACE_BASE_URL;
  const theme = useTheme();
  useEffect(() => {
    const access_token_local = JSON.parse(localStorage.getItem("settings"));
    const roleName = access_token_local?.role?.name;
    if (roleName && Object.values(UserRole).includes(roleName)) {
      setUserInUserRole(true);
    }
  }, []);

  return (
    <>
      {isLoadingActiveDealers || isLoadingAssociatedDealers ? (
        <CircleLoader theme={theme} size="lg" />
      ) : (
        <>
          {associatedDealers?.length === 0 && userInUserRole ? (
            <DefaultUnAuthComponent homePageUrl={ums_base_url} />
          ) : (
            children
          )}
          <input
            type="text"
            value={process.env.next_screen_base_url}
            readOnly
            style={{ display: "none" }}
          />
          <input
            type="text"
            value={ums_base_url}
            readOnly
            style={{ display: "none" }}
          />
        </>
      )}
    </>
  );
};
export default ValidateDealerUser;
