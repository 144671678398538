import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const BorderedTableWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.custom-table-wrap": {
    ".u-table-wrap": {
      ".u-table-container": {
        maxHeight: "calc(100vh - 390px)",
        borderRadius: 8,
        "&::-webkit-scrollbar-track": {
          padding: "0 1px",
          backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[300] : "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
          transition: "all 0.3s",
          backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[100] : "#cdcdcd",
          borderRadius: 10,
        },
        ".u-table": {
          ".u-table-head": {
            tr: {
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[100]
                  : "#f2f2f2",
              td: {
                backgroundColor: "transparent",
                borderColor: "#cccccc",
                borderRight:
                  theme.palette.mode === "dark"
                    ? "1px solid" + theme.palette.grey[100]
                    : "1px solid #cccccc",
                height: 40,
                "&.MuiTableCell-root": {
                  borderBottomColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey[100]
                      : "#cccccc",
                },
                ":last-child": {
                  borderRight: "0",
                },
              },
            },
          },
          ".u-table-body": {
            "tr:nth-of-type(odd)": {
              td: {
                backgroundColor: "transparent",
              },
            },
            tr: {
              td: {
                borderRight:
                  theme.palette.mode === "dark"
                    ? "1px solid" + theme.palette.grey[100]
                    : "1px solid #cccccc",
                "&.input-cell": {
                  padding: 0,
                  ".error-state": {
                    backgroundColor: "rgba(255, 59, 48, 0.08)",
                    height: "40px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                },
                ".u-form-group": {
                  ".u-form-control": {
                    height: 40,
                    padding: "8px 16px",
                    "&.u-select": {
                      ".MuiSelect-select.MuiSelect-multiple": {
                        paddingRight: 30,
                        color: theme.palette.grey[600],
                      },
                    },
                    ".MuiInputBase-input": {
                      fontSize: theme.typography.body2.fontSize,
                      padding: 0,
                      color: theme.palette.grey[600],
                    },
                    ".MuiSelect-select": {
                      minHeight: "100%",
                      height: "100%",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                      borderRadius: 0,
                      borderWidth: 0,
                    },
                  },
                  ".MuiFormHelperText-root": {
                    display: "none",
                  },
                },
                ":last-child": {
                  borderRight: "0",
                },
                ":first-child": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey[100]
                      : "#f2f2f2",
                },
              },
              ":hover": {
                td: {
                  backgroundColor: "initial",
                },
                "td:first-child": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey[100]
                      : "#f2f2f2",
                },
              },
            },
          },
          ".sr-num": {
            width: 40,
            minWidth: 40,
            fontWeight: 600,
          },
        },
      },
    },
    ".scroll": {
      width: "100%",
      position: "relative",
      ".scroll-hide": {
        backgroundColor:
          theme.palette.mode === "dark" ? theme.palette.grey[100] : "#f2f2f2",
        borderBottom: theme.palette.grey[50],
        position: "absolute",
        width: 7,
        height: 100,
        right: 1,
        top: 1,
        display: "block",
        borderRadius: "0 8px 0 0",

        "&.spr-border": {
          "&::after": {
            display: "inline-block",
            content: `''`,
            backgroundColor: theme.palette.divider,
            width: 7,
            height: 1,
            position: "absolute",
            bottom: 0,
          },
          "&.chart-header": {
            fontWeight: theme.customVariables.fontWeightSemiBold,
          },
        },

        "&.sh-fixed-cell": {
          height: 40,
          top: 0.5,
        },
      },
    },
  },
}));
