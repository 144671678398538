import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  Input,
  Button,
  ConfirmationDialog,
  MultiSelectWrap,
} from "@sharedComponents";
import { INewBaseChartsPopUp } from "./AddNewBaseRateChart.type";
import { IBaseChart } from "Interface";
import {
  UseMutation_UpdateBaseCharts,
  UseMutation_AddBaseRateCharts,
} from "services";
import { Controller, useForm } from "react-hook-form";
import { ActionOptions, QueryKeys, DialogMessages, ChartTypes } from "Enums";
import {
  PersistentDrawer,
  Icon,
  DatePicker,
  Select,
  MultiSelect,
} from "@ntpkunity/controls";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
import { UseQuery_GetAllBaseRateSource } from "services/charts.service";

const NewBaseRateChart: FC<INewBaseChartsPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  baseRateChart,
}) => {
  const theme = useTheme();
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IBaseChart>();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const { mutate: EditBaseRateChart } = UseMutation_UpdateBaseCharts();
  const { mutate: addBaseRateChart } = UseMutation_AddBaseRateCharts();
  const { data: sourceData } = UseQuery_GetAllBaseRateSource();

  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  useEffect(() => {
    if (actionType == ActionOptions.EDIT && baseRateChart) {
      reset(baseRateChart);
    }
  }, [actionType, baseRateChart]);

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
    reset();
  };

  function onSave(data: IBaseChart) {
    data.chart_type_id = ChartTypes.BaseRateChart;
    data.id
      ? EditBaseRateChart(data, {
          onSuccess: () => {
            onClose();
          },
        })
      : addBaseRateChart(data, {
          onSuccess: () => {
            onClose();
          },
        });
  }
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSave)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };

  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        title={title}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="base_rate_source"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  id="sources"
                  name="base_rate_source"
                  theme={theme}
                  label={"Source (Optional)"}
                  {...field}
                  value={field.value}
                  items={sourceData?.map((source) => {
                    return {
                      text: source.title,
                      value: source.title,
                    };
                  })}
                />
              )}
            />
            <Controller
              name="tenure"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="baseRatetenure"
                  theme={theme}
                  label={"Tenure"}
                  placeholder={"Select"}
                  items={[
                    { text: "7 days", value: "7" },
                    { text: "15 days", value: "15" },
                    { text: "30 days", value: "30" },
                    { text: "90 days", value: "90" },
                    { text: "180 days", value: "180" },
                  ]}
                  value={value ?? ""}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="base_rate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Base Rate is required",
                },
              }}
              render={({ field }) => (
                <Input
                  fullWidth
                  theme={theme}
                  id="drawerMargin"
                  label={"Base Rate"}
                  type="text"
                  {...field}
                  onChange={(val) => {
                    if (val <= 100 && val >= 0) {
                      field.onChange(val);
                    }
                  }}
                  adornmentOnFocus
                  endAdornment={
                    <>
                      <span className="MuiInputAdornment-root">%</span>
                    </>
                  }
                  masking
                  maskDecimalScale={4}
                  maskNumeric
                  error={errors?.base_rate?.message}
                />
              )}
            />
            <Controller
              name="effective_date"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Effective Date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <DatePicker
                    theme={theme}
                    label={"Effective Date"}
                    value={value ?? ""}
                    clearable
                    onChange={(e: Date) => {
                      setValue(
                        "effective_date",
                        e.getFullYear() === 1970 ? null : e.toISOString(),
                        {
                          shouldDirty: true,
                        }
                      );
                      onChange(e.toISOString());
                    }}
                    error={errors?.effective_date?.message}
                  />
                </>
              )}
            />
          </>
        }
        customFooter={
          <Button
            primary
            text={"Save"}
            fullWidth={true}
            style={{ paddingRight: "82px", paddingLeft: "82px" }}
            onClick={handleSubmit(onSave)}
          />
        }
      />
    </>
  );
};

export default NewBaseRateChart;
