import { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTheme, SelectChangeEvent } from "@mui/material";
import {
  Select,
  IBoxProps,
  Grid,
  Input,
  Box,
  Typography,
} from "@ntpkunity/controls";
import { styled } from "@mui/material/styles";
import {
  useGetAllowedMileage,
  useGetContractTerms,
} from "services/dealer-configurations.service";

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.tab-wrap": {
    width: "100%",
    display: "inline-block",

    hr: {
      opacity: 0.4,
    },

    ".title-icon": {
      width: "100%",
      display: "inline-block",
      "span, Icon": {
        float: "left",
      },
      span: {
        marginRight: 10,
      },
    },
    ".buttons-row": {
      display: "inline-block",
      textAlign: "center",
      width: "100%",

      Button: {
        margin: "0 20px 20px 0",

        "&:last-of-type": {
          marginRight: 0,
        },
      },
    },
  },
}));

const Preferences: FC<{
  form: any;
}> = ({ form }) => {
  const theme = useTheme();
  const { mutate: getContractTerms, data: contractTermsData } =
    useGetContractTerms();
  const { mutate: getAllowedMileage, data: allowedMileageData } =
    useGetAllowedMileage();
  const {
    control,
    formState: { errors },
    watch,
  } = form;
  const mileageId = watch("preferences.default_mileage_id");
  const termId = watch("preferences.default_term_id");
  useEffect(() => {
    getContractTerms();
    getAllowedMileage();
  }, []);

  return (
    <TabWrap className="tab-wrap" theme={theme}>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mb: 2.7 }}
        theme={theme}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="preferences.default_mileage_id"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                theme={theme}
                label={"Default Mileage"}
                items={allowedMileageData
                  ?.filter((x) => x.is_active || x.id == mileageId)
                  ?.map((mileage: any) => {
                    return {
                      text: mileage.value,
                      value: mileage.id,
                    };
                  })}
                {...field}
                value={field.value ?? ""}
                onChange={field.onChange}
                sxProps={""}
                selectError={errors?.preferences?.default_mileage_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="preferences.default_term_id"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                theme={theme}
                disablePortal
                label={"Default Term"}
                items={contractTermsData
                  ?.filter((x) => x.is_active || x.id == termId)
                  ?.map((term: any) => {
                    return {
                      text: term.term,
                      value: term.id,
                    };
                  })}
                sxProps={""}
                {...field}
                value={field.value ?? ""}
                onChange={field.onChange}
                selectError={errors?.preferences?.default_term_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="preferences.quotation_expiry_days"
            control={control}
            rules={{
              maxLength: {
                value: 3,
                message: "Length should be less than 4",
              },
              min: {
                value: 0,
                message: "Quotation Expiry Days is invalid",
              },
              max: {
                value: 999,
                message: "Quotation Expiry Days is invalid",
              },
            }}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here.."}
                label={"Quotation Expiry Days (Optional)"}
                type="number"
                {...field}
                value={field.value ?? ""}
                error={errors?.preferences?.quotation_expiry_days?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="preferences.application_expiry_days"
            control={control}
            rules={{
              maxLength: {
                value: 3,
                message: "Length should be less than 4",
              },
              min: {
                value: 0,
                message: "Application Expiry Days is invalid",
              },
              max: {
                value: 999,
                message: "Application Expiry Days is invalid",
              },
            }}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here..."}
                label={"Application Expiry Days (Optional)"}
                type="number"
                {...field}
                value={field.value ?? ""}
                error={errors?.preferences?.application_expiry_days?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="preferences.default_margin_rate_lease"
            control={control}
            rules={{
              pattern: {
                value: /^\d{0,5}\.\d{0,6}$/,
                message: "Upto 6 decimal points are allowed",
              },
            }}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"0.0"}
                label={"Default Margin Rate (Lease)"}
                type="number"
                {...field}
                onBlur={(e) => {
                  e =
                    e.target.value.length > 6
                      ? parseFloat(e.target.value.toString()).toFixed(6)
                      : e;
                  field.onChange(e);
                }}
                value={field.value ?? ""}
                error={errors?.preferences?.default_margin_rate_lease?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="preferences.default_margin_rate_finance"
            control={control}
            rules={{
              pattern: {
                value: /^\d{0,5}\.\d{0,6}$/,
                message: "Upto 6 decimal points are allowed",
              },
            }}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"0.0"}
                label={"Default Margin Rate (Finance)"}
                type="number"
                {...field}
                onBlur={(e) => {
                  e =
                    e.target.value.length > 6
                      ? parseFloat(e.target.value.toString()).toFixed(6)
                      : e;
                  field.onChange(e);
                }}
                value={field.value ?? ""}
                error={
                  errors?.preferences?.default_margin_rate_finance?.message
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="preferences.credit_application_timer"
            control={control}
            defaultValue={"00"}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"00"}
                label={"Credit Application Timer (Optional)"}
                endAdornment={
                  <span className="MuiInputAdornment-root">min</span>
                }
                type="number"
                {...field}
                value={field.value ?? ""}
                error={errors?.preferences?.credit_application_timer?.message}
              />
            )}
          />
        </Grid>
      </Grid>

      {watch("profile.available_finance_types")
        ?.map((type) => type.toLowerCase())
        .includes("lease") && (
        <Box theme={theme}>
          <Box sx={{ mb: 3 }} theme={theme}>
            <hr />
          </Box>

          <Typography
            className="title-icon"
            variant="h4"
            component="h4"
            theme={theme}
            mb={2}
          >
            <span>Standard Lease Rates</span>
          </Typography>

          <Grid
            container
            rowSpacing={3}
            theme={theme}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mb: 2.7 }}
          >
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_lease_rates.down_payment_rate"
                control={control}
                defaultValue={"0.00"}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="downPaymentRate"
                    label={"Down Payment (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_lease_rates
                        ?.down_payment_rate?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_lease_rates.min_down_payment_rate"
                control={control}
                defaultValue={"0.00"}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="mindownPaymentRate"
                    label={"Min Down Payment (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_lease_rates
                        ?.min_down_payment_rate?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_lease_rates.max_down_payment_rate"
                control={control}
                defaultValue={"0.00"}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="maxdownPaymentRate"
                    label={"Max Down Payment (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_lease_rates
                        ?.max_down_payment_rate?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_lease_rates.rv_balloon_rate"
                control={control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="rvBalloonRate"
                    label={"RV/Balloon Rate (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={6}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_lease_rates?.rv_balloon_rate
                        ?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_lease_rates.customer_rate"
                control={control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="customerRate"
                    label={"Customer Rate (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={6}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_lease_rates?.customer_rate
                        ?.margin?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {watch("profile.available_finance_types")
        ?.map((type) => type.toLowerCase())
        .includes("finance") && (
        <Box theme={theme}>
          <Box sx={{ mb: 3 }} theme={theme}>
            <hr />
          </Box>

          <Typography
            className="title-icon"
            variant="h4"
            component="h4"
            theme={theme}
            mb={2}
          >
            <span>Standard Finance Rates</span>
          </Typography>

          <Grid
            container
            rowSpacing={3}
            theme={theme}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mb: 2.7 }}
          >
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_finance_rates.down_payment_rate"
                control={control}
                defaultValue={"0.00"}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="downPaymentRate"
                    label={"Down Payment (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_finance_rates
                        ?.down_payment_rate?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_finance_rates.min_down_payment_rate"
                control={control}
                defaultValue={"0.00"}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="mindownPaymentRate"
                    label={"Min Down Payment (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_finance_rates
                        ?.min_down_payment_rate?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_finance_rates.max_down_payment_rate"
                control={control}
                defaultValue={"0.00"}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="maxdownPaymentRate"
                    label={"Max Down Payment (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_finance_rates
                        ?.max_down_payment_rate?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_finance_rates.rv_balloon_rate"
                control={control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="rvBalloonRate"
                    label={"RV/Balloon Rate (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={6}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_finance_rates
                        ?.rv_balloon_rate?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
              <Controller
                name="preferences.standard_finance_rates.customer_rate"
                control={control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="customerRate"
                    label={"Customer Rate (Optional)"}
                    type="text"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    adornmentOnFocus
                    endAdornment={
                      <span className="MuiInputAdornment-root">%</span>
                    }
                    masking
                    maskDecimalScale={6}
                    maskNumeric
                    maskAllowNegative
                    error={
                      errors?.preferences?.standard_finance_rates?.customer_rate
                        ?.margin?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </TabWrap>
  );
};
export default Preferences;
