import { useUpdateIntegrationStatus } from "services";
import DealerTableChildren from "./dealer-table-children";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, Button, Icon, Menu, Tooltip } from "@ntpkunity/controls";
import { FC, useState } from "react";
import { Switch } from "@sharedComponents";
import { ActionOptions } from "Enums";

const DealerTableRow: FC<{
  onEdit: (data: any, actionType: ActionOptions) => unknown;
  integrationsRow: any;
  penDealerId?: any;
  dealer_code;
  detailedSubmited?: any;
  onDelete: (data) => unknown;
  marketScanConfig?: any;
  shiftDigitalId?: any;
  marketScanCredentials: boolean;
  setMarketScanCredentials: any;
  onProviderMapping: (id) => unknown;
}> = ({
  integrationsRow,
  onEdit,
  penDealerId,
  dealer_code,
  detailedSubmited,
  onDelete,
  marketScanConfig,
  shiftDigitalId,
  marketScanCredentials,
  setMarketScanCredentials,
  onProviderMapping,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { mutate: updateIntegrationStatus } = useUpdateIntegrationStatus();

  return (
    <>
      <TableRow key={`parent${integrationsRow.id}`} className="child-tr">
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            {open ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpen(!open)}
              />
            )}
            <Box theme={theme} display="flex" alignItems="center">
              {integrationsRow.integration_type}
            </Box>
          </Box>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            id={"ellipses" + integrationsRow.id}
            theme={theme}
            options={[
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="EditIcon" />
                    {ActionOptions.ADD_PROVIDER}
                  </>
                ),
                optionkey: ActionOptions.EDIT,
                optionValue: integrationsRow,
              },
            ]}
            handleOptionClick={(e, key, value) => {
              onEdit(value, ActionOptions.ADD_PROVIDER);
            }}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      {open && (
        <DealerTableChildren
          dealer_code={dealer_code}
          key={integrationsRow.id}
          integrationsRow={integrationsRow}
          onEdit={(data, actionType) => onEdit(data, actionType)}
          onDelete={(data) => onDelete(data)}
          onProviderMapping={(id) => onProviderMapping(id)}
          penDealerId={penDealerId}
          marketScanConfig={marketScanConfig}
          detailedSubmited={detailedSubmited}
          shiftDigitalId={shiftDigitalId}
          marketScanCredentials={marketScanCredentials}
          setMarketScanCredentials={setMarketScanCredentials}
        />
      )}
    </>
  );
};
export default DealerTableRow;
