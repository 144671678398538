import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setFeeNamesInitialData } from "Stores/SetupStore/Actions";
import { IFeeName, IFeeNameFilter } from "Interface";

export const getAllFeeNameFromServer = () => {
  return ConfigurationAPI.get("/fee-names").then((res) => {
    return res.data;
  });
};

export const getAllFeeNameFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(`/fee-name/filter?`.concat(columnFilters)).then(
    (response) => {
      return response.data;
    }
  );
};

export const UseQuery_getAllFeeName = () => {
  const [, dispatch] = useSetupsStore();

  return useQuery(QueryKeys.GET_ALL_FEE_NAMES, async () => {
    return getAllFeeNameFromServer().then((data) => {
      dispatch(setFeeNamesInitialData(data));
      return data;
    });
  });
};

export const UseQuery_getAllFeeName_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_FEE_NAMES_FILTER, columnFilters],
    async () => {
      return getAllFeeNameFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddFeeName = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IFeeName>(
    (fee_name) => {
      return ConfigurationAPI.post("/fee-names", fee_name);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEE_NAMES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_MAPPINGS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEE_NAMES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_UpdateFeeName = (feeNameId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IFeeName>(
    (fee_name: any) => {
      return ConfigurationAPI.patch(`/fee-names/${fee_name.id}`, fee_name);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEE_NAMES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_MAPPINGS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEE_NAMES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeleteFeeName = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IFeeName>(
    (feeNameId) => {
      return ConfigurationAPI.delete(`/fee-names/${feeNameId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEE_NAMES_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEES_MAPPINGS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_FEE_NAMES);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};
