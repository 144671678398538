import { FC, useEffect, useState } from "react";
import { styled, useTheme, TablePagination, Link } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Icon,
  Menu,
  Input,
  DataTable,
  ImageViewer,
  Switch,
} from "@ntpkunity/controls";
import {
  ConfirmationDialog,
  CustomSwitch,
  DataTableWrap,
  PaginationWrap,
} from "@sharedComponents";
import { useStoreContext } from "Stores/SetupStore/Store";
import {
  useUpdateFinancialInsuranceById,
  useDeleteFinancialInsuranceById,
  UseQuery_getAllFinancialInsuranceProduct_filter,
} from "services/financial-insurance.service";
import { IFinancialInsurance, IFinancialInsuranceFilter } from "Interface";
import { ActionOptions, DialogMessages } from "Enums";
import ChevronDown from "../../shared/assets/images/chevron-down";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const FinancialInsuranceTable: FC<{
  setPopUpState?: any;
  setActionData?: any;
  setActionType?: any;
  setActionTitle?: any;
  actionData?: any;
  dealer_code?: any;
}> = ({
  setPopUpState,
  setActionData,
  setActionType,
  setActionTitle,
  actionData,
  dealer_code,
}) => {
  const { states, actions } = useStoreContext();

  const theme = useTheme();
  const { mutate: updateFinancialInsuranceRow } =
    useUpdateFinancialInsuranceById();
  const { mutate: deleteFinancialInsuranceRow } =
    useDeleteFinancialInsuranceById();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [providerFilter, setProviderFilter] = useState("");
  const [providerCodeFilter, setProviderCodeFilter] = useState("");
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [productNameFilter, setProductNameFilter] = useState("");
  const [markupFilter, setMarkupFilter] = useState();
  const [taxableFilter, setTaxableFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [MSPFilter, setMSPFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [images, setImages] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [index, setIndex] = useState(0);
  const [columnFilters, setColumnFilters] = useState(
    `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
  );
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `dealer_code=${dealer_code}&page_number=${pgNo}&page_size=${pgSize}`;
    if (
      providerFilter.trimStart() != null &&
      providerFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&provider_name=${providerFilter}`);
    }
    if (
      providerCodeFilter.trimStart() != null &&
      providerCodeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&provider_code=${providerCodeFilter}`
      );
    }
    if (
      productTypeFilter.trimStart() != null &&
      productTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&product_type=${productTypeFilter}`);
    }
    if (
      productNameFilter.trimStart() != null &&
      productNameFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&product_name=${productNameFilter}`);
    }
    if (taxableFilter.trimStart() != null && taxableFilter.trimStart() != "") {
      query_string = query_string.concat(
        `&taxable=${
          taxableFilter.toLocaleLowerCase().startsWith("n") ? false : true
        }`
      );
    }
    if (markupFilter) {
      query_string = query_string.concat(`&markup=${+markupFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    if (MSPFilter.trimStart() != null && MSPFilter.trimStart() != "") {
      query_string = query_string.concat(
        `&MSP=${MSPFilter.toLocaleLowerCase().startsWith("n") ? false : true}`
      );
    }
    return query_string;
  };
  const { data } =
    UseQuery_getAllFinancialInsuranceProduct_filter(columnFilters);
  const financialInsuranceProductFilter: IFinancialInsuranceFilter = data;
  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    }
  }, [dealer_code]);

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.EDIT:
        if (!value.brochure_url) {
          value.brochure_url = "";
        }
        if (!value.video_url) {
          value.video_url = "";
        }
        setActionType(ActionOptions.EDIT);
        setPopUpState(true);
        setActionData(value);
        setActionTitle("Edit Product");
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteFinancialInsuranceRow(
      { id: actionData?.id },
      {
        onSuccess: (data, variables, context) => {
          actions.deleteFinancialInsuranceRow(data);
        },
      }
    );
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const handleImage = (imagesData) => {
    const image_url = imagesData?.map((image) => {
      return image?.location;
    });

    setImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);
  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        sx={{ mb: 3 }}
        theadChildren={
          <>
            <TableRow>
              <TableCell align="center" valign="middle" className="img-cell">
                <Icon name="ImageIcon" />
              </TableCell>
              <TableCell id="providerHeader">Provider</TableCell>
              <TableCell id="providerHeader">Provider Code</TableCell>
              <TableCell id="productTypeHeader">Product Type</TableCell>
              <TableCell id="productNameHeader">Product Name</TableCell>
              <TableCell id="markupPercentageHeader">
                Markup Percentage
              </TableCell>
              <TableCell id="taxableHeader">Taxable</TableCell>
              <TableCell id="taxableHeader">
                MSP (Maintenance & Services Plan)
              </TableCell>
              <TableCell id="statusHeader">Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell className="img-cell"></TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setProviderFilter(value);
                    }}
                    value={providerFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setProviderCodeFilter(value);
                    }}
                    value={providerCodeFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerCodeSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerCodeFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setProductTypeFilter(value);
                    }}
                    value={productTypeFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerTypeSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerTypeFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setProductNameFilter(value);
                    }}
                    value={productNameFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerNameSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerNameSearch"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    onChange={(value) => {
                      setMarkupFilter(value);
                    }}
                    value={markupFilter}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"numberSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="numberFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setTaxableFilter(value);
                    }}
                    value={taxableFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"taxableSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="taxableFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setMSPFilter(value);
                    }}
                    value={MSPFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"maintenanceServicesSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="maintenanceFilter"
                    iconText={<Icon name="IconFilter" />}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"statusSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="statusFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {financialInsuranceProductFilter?.result &&
              financialInsuranceProductFilter.result.map(
                (data: IFinancialInsurance, index: number) => (
                  <TableRow className="child-tr" key={index}>
                    <TableCell
                      id={"image" + data.provider_name}
                      className="img-cell"
                    >
                      {data?.image?.length > 0 && (
                        <Link
                          onClick={() => {
                            handleImage(data?.image);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            className="prd-img"
                            src={data.image[0]?.location}
                            alt="Car"
                          />
                        </Link>
                      )}
                    </TableCell>
                    <TableCell id={"providerName" + data.provider_name}>
                      {data.provider_name}
                    </TableCell>
                    <TableCell id={"providerCode" + data.provider_code}>
                      {data.provider_code}
                    </TableCell>
                    <TableCell id={"productType" + data.provider_name}>
                      {data.product_type}
                    </TableCell>
                    <TableCell id={"productName" + data.provider_name}>
                      {data.product_name}
                    </TableCell>
                    <TableCell id={"markup" + data.provider_name}>
                      {data.markup}%
                    </TableCell>
                    <TableCell id={"taxable" + data.provider_name}>
                      {data.taxable ? "Applicable" : "Not Applicable"}
                    </TableCell>
                    <TableCell>
                      <CustomSwitch
                        id={"MSP" + data.MSP}
                        theme={theme}
                        varient={"basic"}
                        label={data.MSP ? "Yes" : "No"}
                        switchEnabled={data.MSP}
                        onChange={(event) => {
                          updateFinancialInsuranceRow({
                            ...data,
                            MSP: event.target.checked,
                          });
                          actions.updateFinancialInsuranceRow({
                            ...data,
                            MSP: event.target.checked,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomSwitch
                        id={"is_active" + data.provider_name}
                        theme={theme}
                        varient={"basic"}
                        label={data.is_active ? "Enabled" : "Disabled"}
                        switchEnabled={data.is_active}
                        onChange={(event) => {
                          updateFinancialInsuranceRow({
                            ...data,
                            is_active: event.target.checked,
                          });
                          actions.updateFinancialInsuranceRow({
                            ...data,
                            is_active: event.target.checked,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={theme}
                        options={tableOptions.map((option: any) => {
                          return {
                            optionText: option.optionText,
                            optionkey: option.optionkey,
                            optionValue: data,
                          };
                        })}
                        handleOptionClick={handleSelection}
                        render={(onMenuSelection) => (
                          <Button
                            defaultBtn
                            id={"menuButton" + data.provider_name}
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={
            financialInsuranceProductFilter
              ? financialInsuranceProductFilter?.total_results
              : -1
          }
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={images}
        isLoading={false}
        singleTriggerNode={true}
        overlayCheck={true}
        visible={visibility}
        index={index}
        setIndex={setIndex}
        onClose={handleClose}
      />
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};
export default FinancialInsuranceTable;
