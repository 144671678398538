import { FC, useEffect, useState, Dispatch, SetStateAction } from "react";
import { useTheme, styled } from "@mui/material";
import {
  Button,
  FileDragDrop,
  Input,
  PersistentDrawer,
  Select,
  Textarea,
  Tooltip,
  CircleLoader,
  Box,
  FilePreview,
  MultiSelect,
  ImageViewer,
} from "@ntpkunity/controls";
import { FileDragDropWrap, TextAreaWrap } from "@sharedComponents";
import { Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  IImage,
  IFinanceTypeModel,
  IFinancialInsurance,
  IFinancialInsuranceBundle,
} from "Interface";
import {
  QueryKeys,
  ValidationMessages,
  ActionOptions,
  ActionTypes,
} from "Enums";
import {
  UseMutation_uploadS3Image,
  UseMutation_deleteS3Image,
  UseQuery_GetAllFinanceTypes,
} from "services";
import {
  UseQuery_getAllFinancialInsuranceProductByFinancetype,
  useSaveFinancialInsuranceBundle,
  useUpdateFinancialInsuranceBundleById,
  useCheckBundleDuplication,
} from "services/financial-insurance.service";
import { validation } from "shared/helper/methods";
import { WEBSITE_REGEX } from "shared/config/constants";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { Action } from "history";

export declare type INewProductBundlesPopUp = {
  openPopUp: boolean;
  setPopUpState: Dispatch<SetStateAction<boolean>>;
  title: string;
  form: any;
  setImages?: (e: any) => void;
  images?: IImage[];
  dealer_code?: string;
  fpbundleData: IFinancialInsuranceBundle;
  actionType: string;
  isDisabled: boolean;
  s3Response: any;
};
const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "relative !important",
      transform: "initial !important",
    },
  },
}));

const AddNewProductBundles: FC<INewProductBundlesPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  form,
  setImages,
  images,
  dealer_code,
  fpbundleData,
  actionType,
  isDisabled,
  s3Response,
}) => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = form;

  const queryClient = useQueryClient();
  const finacial_products: Array<IFinancialInsurance> =
    queryClient.getQueryData([
      QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BY_FINANCETYPE,
    ]);
  const { data } =
    UseQuery_getAllFinancialInsuranceProductByFinancetype(dealer_code);
  const { mutate: uploadImage } = UseMutation_uploadS3Image();
  const { mutate: deleteImage } = UseMutation_deleteS3Image();
  const { mutate: checkBundleDuplication } = useCheckBundleDuplication();
  const [state, dispatch] = useSetupsStore();
  const { actions } = useStoreContext();
  const { mutate: SaveFinancialInsuranceBundle } =
    useSaveFinancialInsuranceBundle();
  const { mutate: UpdateFinancialInsuranceBundle } =
    useUpdateFinancialInsuranceBundleById();
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [bundleNameValidation, setBundlenameValidation] = useState(false);
  const [financeTypes, setFinanceTypes] = useState([]);
  const [financialProducts, setfinancialProducts] = useState([]);
  const [sliderImages, setSilderImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const onimageDrop = (e: any) => {
    setImageLoader(true);
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };

  const onimageUpload = (e: any) => {
    setImageLoader(true);
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };

  const onRemoveFile = (fileKey: string, index: number) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      setImageLoader(true);
      deleteImage(obj, {
        onSuccess(response) {
          const imageArray = images;
          imageArray.splice(index, 1);
          setImages([...imageArray]);
          setImageLoader(false);
        },
      });
    }
  };

  const onClickIcon = (indexNumber) => {
    const image_url = images?.map((image) => {
      return image?.location;
    });
    setIndex(indexNumber);
    setSilderImages(image_url);
    setVisibility(true);
  };
  const onSubmit = (data: IFinancialInsuranceBundle) => {
    data.image = images;
    data.dealer_code = dealer_code;
    data.financial_products = financialProducts;
    if (actionType === ActionOptions.ADD) {
      SaveFinancialInsuranceBundle(data, {
        onSuccess() {
          reset();
          onClose();
          setfinancialProducts([]);
          setImages([]);
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error?.error?.detail.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
    } else if (actionType === ActionOptions.EDIT) {
      UpdateFinancialInsuranceBundle(data, {
        onSuccess() {
          reset();
          onClose();
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error?.error?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };
  const handleClose = () => {
    setVisibility(false);
    setIndex(0);
  };

  const handlefinancialProducts = () => {
    const financialProcduct = watch("financial_products");
    setfinancialProducts(financialProcduct);
  };

  const checkBundleName = (bundle_name) => {
    checkBundleDuplication(
      {
        bundle_name: bundle_name,
        dealer_code: dealer_code,
      },
      {
        onSuccess: (response) => {
          setBundlenameValidation(response?.data);
          if (response?.data) {
            setError("bundle_name", {
              message: ValidationMessages.UNIQUE_BUNDLE_NAME,
            });
          } else {
            clearErrors("bundle_name");
          }
        },
        onError: (error) => {
          setBundlenameValidation(false);
          actions.setToast({
            toastMessage: "Network error",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  useEffect(() => {
    if (actionType === ActionOptions.EDIT) {
      setImages(fpbundleData?.image);
      setValue(
        "financial_products",
        fpbundleData?.financial_products?.map((product) => product?.id)
      );
      handlefinancialProducts();
    } else {
      reset();
      setfinancialProducts([]);
    }
  }, [fpbundleData]);

  return (
    <>
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="bundle_name"
              control={control}
              rules={{
                validate: {
                  checkUniqueSlug: () => {
                    if (bundleNameValidation) {
                      return ValidationMessages.UNIQUE_BUNDLE_NAME;
                    }
                    return true;
                  },
                },
                pattern: {
                  value: /^[^\s]/,
                  message: ValidationMessages.BUNDLE_NAME_REQUIRED,
                },
                required: ValidationMessages.BUNDLE_NAME_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={"Bundle Name"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.bundle_name?.message}
                  onBlur={(e) => {
                    if (e.target.value.trim() !== "") {
                      checkBundleName(e.target.value);
                    } else {
                      clearErrors("bundle_name");
                    }
                  }}
                  disabled={isDisabled}
                />
              )}
            />
            <TextAreaWrap>
              <Controller
                name="description"
                control={control}
                rules={{
                  pattern: {
                    value: /^[^\s]/,
                    message: "Description is required",
                  },
                  required: "Description is required",
                }}
                render={({ field }) => (
                  <Textarea
                    theme={theme}
                    label={"Package Description"}
                    type="text"
                    id="descriptionDrawer"
                    fullWidth
                    {...field}
                    onChange={field.onChange}
                    value={field.value?.trimStart()}
                    error={errors?.description?.message}
                    disabled={isDisabled}
                  />
                )}
              />
            </TextAreaWrap>
            <Controller
              name="financial_products"
              control={control}
              defaultValue={financialProducts ?? []}
              rules={{
                validate: (value) =>
                  (value && value.length > 0) ||
                  "At least one product must be selected",
              }}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  disablePortal
                  id={"financial_products"}
                  theme={theme}
                  label={"F&I Products"}
                  placeholder={""}
                  items={finacial_products?.map((item) => {
                    return { text: item.product_name, value: item.id };
                  })}
                  value={financialProducts ?? []}
                  onChange={(e: any) => {
                    onChange(e);
                    handlefinancialProducts();
                  }}
                  selectError={errors?.financial_products?.message}
                  disabled={isDisabled}
                />
              )}
            />
            <Controller
              name="markup"
              defaultValue={0}
              control={control}
              rules={{
                required: "Markup value is required",
                min: {
                  value: -100,
                  message: "Value should be in range -100 to 100",
                },
                max: {
                  value: 100,
                  message: "Value should be in range -100 to 100",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here.."}
                  label={"Markup Percentage"}
                  type="text"
                  endAdornment={
                    <>
                      <div className="MuiInputAdornment-root">%</div>
                    </>
                  }
                  startAdornment=""
                  id="markupDrawer"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  masking={true}
                  maskNumeric={true}
                  maskDecimalScale={2}
                  maskAllowNegative
                  error={errors?.markup?.message}
                  disabled={isDisabled}
                />
              )}
            />
            <FileDragDropWrap className="mb-24">
              <Controller
                name="image"
                control={control}
                render={({ field }) => (
                  <Tooltip
                    theme={theme}
                    title={
                      !s3Response?.data
                        ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                        : ""
                    }
                  >
                    <FileDragDrop
                      {...field}
                      hoverLabel="Drag and drop product images, or browse"
                      width="300"
                      height="200px"
                      backgroundColor="white"
                      onDrop={onimageDrop}
                      onChange={onimageUpload}
                      disabled={!s3Response?.data}
                      theme={theme}
                    />
                  </Tooltip>
                )}
              />
              <FilePreview
                files={images.length > 0 ? images : []}
                onRemoveFile={onRemoveFile}
                theme={theme}
                viewIcon={true}
                onClickIcon={onClickIcon}
                preview="image"
              />
              {imageLoader && (
                <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                  <CircleLoader theme={theme} size="xs" />
                </ImgLoader>
              )}
            </FileDragDropWrap>
            <Controller
              name="video_url"
              control={control}
              defaultValue={""}
              rules={validation(
                "Video Url",
                false,
                true,
                WEBSITE_REGEX,
                "Valid URL is required."
              )}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  label={"Video URL (Optional)"}
                  type="text"
                  id="videoUrl"
                  fullWidth
                  onChange={onChange}
                  value={value?.trimStart()}
                  error={errors?.video_url?.message}
                  disabled={isDisabled}
                />
              )}
            />
            <Controller
              name="brochure_url"
              control={control}
              rules={validation(
                "Brochure Url",
                false,
                true,
                WEBSITE_REGEX,
                "Valid URL is required."
              )}
              render={({ field }) => (
                <Input
                  theme={theme}
                  label={"Brochure URL (Optional)"}
                  type="text"
                  id="brochureUrl"
                  fullWidth
                  {...field}
                  value={field?.value?.trimStart()}
                  error={errors?.brochure_url?.message}
                  disabled={isDisabled}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  sxProps={""}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                  disabled={isDisabled}
                ></Select>
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            onClick={handleSubmit(onSubmit)}
            text="Save"
            fullWidth
          />
        }
      />
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={sliderImages}
        isLoading={false}
        visible={visibility}
        onClose={handleClose}
        overlayCheck={true}
        index={index}
        setIndex={setIndex}
      />
    </>
  );
};

export default AddNewProductBundles;
