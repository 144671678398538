import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@mui/material";
import {
  FAVICON,
  THEME_BUILDER_CSS,
  EMAIL_REGEX,
  WEBSITE_REGEX,
} from "shared/config/constants";
import { LayoutWithSideNav } from "layout";
import {
  Icon,
  Button,
  Typography,
  Grid,
  Box,
  ScrollableTabs,
} from "@ntpkunity/controls";
import {
  DrawerWrap,
  PageHeader,
  ConfirmationDialog,
  ThemeBuilder,
} from "@sharedComponents";
import {
  DealerFees,
  DealerProfile,
  DealerTradeIn,
  Preferences,
  DealerPayment,
  DealerSocial,
  CustomLinks,
  IndividualizedAgreement,
  DealerChat,
} from "modules/DealerConfigurations";
import { useForm } from "react-hook-form";
import {
  useGetPreferenceByDealerId,
  useGetDealerChatByDealerId,
  useSaveDealerPreference,
  useUpdateDealerPreference,
  useSaveDealerTradeIn,
  useUpdateDealerTradeIn,
  useGetTradeInByDealerId,
  useSaveDealerProfile,
  useSaveDealerChat,
  useGetProfilebyDealerId,
  useUpdateDealerProfilebyId,
  useUpdateDealerChatbyId,
  useSaveDealerPayment,
  useGetPaymentByDealerId,
  useUpdateDealerPaymentById,
} from "services/dealer-configurations.service";
import {
  logout,
  useHasPermissions,
  indexPermissions,
} from "@ntpkunity/controls-ums";
import { DealerContext } from "context";
import {
  IAddress,
  IDealerPreference,
  IDealerProfile,
  IDealerTradeIn,
  IDealerPayment,
  ICountry,
  IThemeBuilder,
  IDealerChat,
  IAssetCondition,
} from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  UseMutation_AddThemeBuilder,
  useMutation_ThemeBuilderByEmail,
  UseMutation_UpsertThemeBuilder,
  UseQuery_GetAll_Countries,
  useQuery_GetAllDealers,
  useUpdateDealerById,
  UseQuery_getThemeBuilderbyEmail,
  useMutation_CheckS3Credentials,
  UseQuery_GetAllAssetConditions,
} from "services";
import { useQueryClient } from "react-query";
import {
  QueryKeys,
  ToastMessages,
  DialogMessages,
  PermissionTypes,
  TimeZone,
} from "Enums";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { setStyleSheet, setFavicon, setLogo } from "favicon";
import Logo from "shared/assets/images/index-logo.svg";
import DealerIntergrations from "../modules/DealerConfigurations/DealerIntegrations/dealer-integrations";
import {
  UseQueryGetDealerIntergrations,
  UseQueryGetDealerProviders,
} from "services/dealer-integration.service";
import {
  IntegrationType,
  Providers,
} from "../Enums/IntegrationTypesAndProviders";
interface IStandardRates {
  down_payment_rate?: string | null;
  rv_balloon_rate?: string | null;
  customer_rate?: string | null;
  min_down_payment_rate?: string | null;
  max_down_payment_rate?: string | null;
}

interface IFormInputs {
  preferences?: {
    id?: number | null;
    default_mileage_id?: number | null;
    availability_check?: string | null;
    default_term_id?: number | null;
    coordinates?: string | null;
    quotation_expiry_days?: number | null;
    application_expiry_days?: number | null;
    dealer_code?: string | null;
    default_margin_rate_lease?: number | null;
    default_margin_rate_finance?: number | null;
    standard_lease_rates?: IStandardRates | null;
    standard_finance_rates?: IStandardRates | null;
  };
  profile?: {
    id?: number | null;
    available_finance_types?: string | null;
    schedule_option?: string | null;
    contact_number?: string | undefined;
    customer_support_number?: string | null | undefined;
    time_zone?: string | null;
    fax_number?: string | null;
    email?: string | null;
    website?: string | null;
    contact_person_name?: string | null | undefined;
    day_light_saving?: boolean | null;
    initials?: string | null;
    dealer_address?: IAddress | undefined;
    dealer_code?: string | null;
    pen_dealer_id?: string | null;
    is_active?: boolean;
    is_deleted?: boolean;
    facebook?: string | null;
    instagram?: string | null;
    linkedin?: string | null;
    twitter?: string | null;
    youtube?: string | null;
    google?: string | null;
    sub_domain?: string | null;
    vehicle_condition?: string[];
  };
  payment?: {
    id?: number | null;
    stripe_registered_email?: string | null;
    bank_account_limit?: number | null;
    credit_card_limit?: number | null;
    debit_card_limit?: number | null;
    dealer_code?: string | null;
    email_verified?: boolean | null;
    account_number: string;
    aba_number: string;
    account_title: string;
    bank_name: string;
  };

  tradein?: {
    id?: number | null;
    partner_name?: string;
    dealer_code?: string | null;
    percentage?: number | null;
  };
  theme?: {
    id?: number | null;
    tenant_id?: number | null;
    stylesheet?: string | null;
    primary_color?: string;
    secondary_color?: string;
    favicon: string | object;
    logo: string | object;
    user_email: string | null;
    is_company_theme_active: boolean | null;
  };
  chat?: {
    id?: number | null;
    welcome_chat?: string | null;
    offline_message?: string | null;
    dealer_code?: string | null;
  };
}

const rateValidationSchema = yup
  .object()
  .nullable()
  .shape({
    down_payment_rate: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .max(100, "Down Payment should be less than or equal to 100%")
      .test("is-valid-percentage", "Invalid percentage format", (value) => {
        if (value === null || value === undefined) return true;
        return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
      }),

    min_down_payment_rate: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .max(100, "Min Down Payment should be less than or equal to 100%")
      .test("is-valid-percentage", "Invalid percentage format", (value) => {
        if (value === null || value === undefined) return true;
        return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
      })
      .max(
        yup.ref("down_payment_rate"),
        "Min Down Payment should be less than Down Payment"
      ),

    max_down_payment_rate: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .max(100, "Max Down Payment should be less than or equal to 100%")
      .test("is-valid-percentage", "Invalid percentage format", (value) => {
        if (value === null || value === undefined) return true;
        return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
      })
      .min(
        yup.ref("down_payment_rate"),
        "Max Down Payment should be greater than or equal to Down Payment"
      ),
  });

const schema = yup.object().shape({
  preferences: yup
    .object()
    .nullable()
    .shape({
      default_mileage_id: yup
        .string()
        .nullable()
        .required("Default Mileage is required"),
      default_term_id: yup
        .string()
        .nullable()
        .required("Default Term is required"),

      quotation_expiry_days: yup
        .mixed()
        .nullable()
        .test(
          "max",
          "Quotation Expiry Days should be less than or equal to 999",
          (value) => {
            if (value === null) return true;
            return value <= 999;
          }
        ),
      application_expiry_days: yup
        .mixed()
        .nullable()
        .test(
          "max",
          "Application Expiry Days should be less than or equal to 999",
          (value) => {
            if (value === null) return true;
            return value <= 999;
          }
        ),
      standard_lease_rates: rateValidationSchema.nullable(),
      standard_finance_rates: rateValidationSchema.nullable(),
    }),
  profile: yup
    .object()
    .shape(
      {
        available_finance_types: yup
          .array()
          .nullable()
          .required("Available Finance Type is required")
          .typeError("Available Finance Type is required")
          .min(1, "Available Finance Type is required"),
        schedule_option: yup
          .array()
          .nullable()
          .required("Schedule Option is required")
          .typeError("")
          .min(1, "Schedule Option is required"),
        country_code: yup.string().nullable(),
        contact_number: yup
          .string()
          .nullable()
          .test({
            name: "min",
            exclusive: false,
            params: {},
            message: "Contact Number is required",
            test: function (value) {
              if (this.parent.country_code)
                return value > this.parent.country_code.length;
              else if (value) return value.length > 1;
            },
          }),
        customer_support_number: yup
          .string()
          .nullable()
          .test({
            name: "min",
            exclusive: false,
            params: {},
            message: "Customer Support Number is required",
            test: function (value) {
              if (this.parent.country_code)
                return value > this.parent.country_code.length;
              else if (value) return value.length > 1;
            },
          }),
        time_zone: yup.string().nullable().required("Time Zone is required"),
        email: yup
          .string()
          .nullable()
          .when("email", {
            is: (email) => email,
            then: yup
              .string()
              .nullable()
              .required()
              .matches(EMAIL_REGEX, "Valid Email is Required."),
            otherwise: yup.string().nullable().required("Email is required"),
          }),
        website: yup
          .string()
          .nullable()
          .when("website", {
            is: (website) => website,
            then: yup
              .string()
              .nullable()
              .required()
              .matches(WEBSITE_REGEX, "Valid Website is Required."),
            otherwise: yup.string().nullable().required("Website is required"),
          }),
        facebook: yup
          .string()
          .nullable()
          .transform((value, originalValue) =>
            originalValue === "" ? null : value
          )
          .matches(WEBSITE_REGEX, "Valid URL is required."),
        instagram: yup
          .string()
          .nullable()
          .transform((value, originalValue) =>
            originalValue === "" ? null : value
          )
          .matches(WEBSITE_REGEX, "Valid URL is required."),
        linkedin: yup
          .string()
          .nullable()
          .transform((value, originalValue) =>
            originalValue === "" ? null : value
          )
          .matches(WEBSITE_REGEX, "Valid URL is required."),
        youtube: yup
          .string()
          .nullable()
          .transform((value, originalValue) =>
            originalValue === "" ? null : value
          )
          .matches(WEBSITE_REGEX, "Valid URL is required."),
        google: yup
          .string()
          .nullable()
          .transform((value, originalValue) =>
            originalValue === "" ? null : value
          )
          .matches(WEBSITE_REGEX, "Valid URL is required."),
        twitter: yup
          .string()
          .nullable()
          .transform((value, originalValue) =>
            originalValue === "" ? null : value
          )
          .matches(WEBSITE_REGEX, "Valid URL is required."),

        contact_person_name: yup
          .string()
          .nullable()
          .required("Contact Person Name is required"),
        day_light_saving: yup
          .boolean()
          .nullable()
          .required("Daylight Saving Applicable is required")
          .typeError("Daylight Saving Applicable is required")
          .oneOf([true, false], "Day Light Saving is required"),
        initials: yup.string().nullable().required("Initials is required"),
        dealer_address: yup
          .object()
          .shape({
            address_line_1: yup
              .string()
              .nullable()
              .required("Address Line 1 is required"),
            apply_zip_code_validation: yup.boolean(),
            state_id: yup.number().nullable().required("State is required"),
            state_name: yup.string().nullable(),
            zip_code: yup
              .string()
              .nullable()
              .when("apply_zip_code_validation", {
                is: true,
                then: yup
                  .string()
                  .nullable()
                  .required("Must enter email address"),
                otherwise: yup.string().nullable().notRequired(),
              }),
            // county: yup.string().nullable().required("County is required"),
            city: yup.string().nullable().required("City is required"),
            field5: yup.string().nullable(),
            field6: yup.string().nullable(),
            country_id: yup.object().nullable().required("Country is required"),
          })
          .required(),
      },
      [
        ["email", "email"],
        ["website", "website"],
      ]
    )
    .required(),
  payment: yup
    .object()
    .nullable()
    .shape({
      //stripe_registered_email: yup.string().required(""),
      bank_account_limit: yup
        .number()
        .nullable()
        .required("Bank Account Limit is required")
        .typeError("Bank Account Limit is required"),
      credit_card_limit: yup
        .number()
        .nullable()
        .required("Credit Card Limit is required")
        .typeError("Credit Card Limit is required"),

      debit_card_limit: yup
        .number()
        .nullable()
        .required("Debit Card Limit is required")
        .typeError("Debit Card Limit is required"),
      account_number: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .nullable()
        .test(
          "len",
          "Account number shouldn't exceed 20 digits",
          (val) => val && val.toString().length < 21
        ),

      aba_number: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .nullable()
        .test(
          "len",
          "ABA number shouldn't exceed 9 digits",
          (val) => val && val.toString().length < 10
        ),
    }),
  theme: yup.object().shape({
    primary_color: yup
      .string()
      .nullable()
      .required("Primary Color is required")
      .test(
        "len",
        "Please provide valid hex code",
        (val) => val && val.length > 2
      ),
    secondary_color: yup
      .string()
      .nullable()
      .required("Secondary Color is required")
      .test(
        "len",
        "Please provide valid hex code",
        (val) => val && val.length > 2
      ),
    favicon: yup.object().nullable().required("Favicon is required"),
    logo: yup.object().nullable().required("Logo is required"),
  }),
  tradein: yup
    .object()
    .nullable()
    .shape({
      partner_name: yup.string().nullable().notRequired(),
      percentage: yup
        .number()
        .nullable()
        .when("partner_name", {
          is: (partner_name) =>
            partner_name == undefined ||
            partner_name == null ||
            partner_name == "",
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .notRequired(),
          otherwise: yup
            .number()
            .required("Percentage is required")
            .typeError("Percentage is required")
            .min(0, "Minimum Percentage is invalid")
            .max(
              100.0,
              "Maximum Percentage should be less than or equal to 100.00"
            ),
        }),
    }),
});
const DealerConfigurations: FC = () => {
  const { dealerData } = useContext(DealerContext);
  UseQuery_GetAll_Countries();
  const { dealer_code } = useContext(DealerContext);
  const { data: dealerIntegrations } =
    UseQueryGetDealerIntergrations(dealer_code);
  const { data: dealerProvider } = UseQueryGetDealerProviders(dealer_code);
  const queryClient = useQueryClient();
  const countriesData: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_COUNTRIES
  );
  var access_token_local = JSON.parse(localStorage.getItem("settings"));
  const user_email = access_token_local?.email;
  const user_role = access_token_local?.role?.name;
  const [dealerEmail, setDealerEmail] = useState<string>();
  const [dealerThemeBuilder, setDealerThemeBuilder] = useState<IThemeBuilder>();
  const [popUpState, setPopUpState] = useState(false);
  const [companyCountries, setCompanyCountries] = useState<Array<ICountry>>([]);
  const [isAddressValidationActive, setIsAddressValidationActive] =
    useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const { actions } = useStoreContext();
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);
  const [socialDirty, setSocialDirty] = useState(false);
  const formDefaultData = useRef<IFormInputs>({});
  const { mutate: addDealerPreference } = useSaveDealerPreference();
  const { mutate: getDealerPreference } = useGetPreferenceByDealerId();
  const { mutate: updateDealerPreference } = useUpdateDealerPreference();
  const { mutate: updateDealer } = useUpdateDealerById();
  const { mutate: addDealerTradeIn } = useSaveDealerTradeIn();
  const { mutate: getDealerTradeIn } = useGetTradeInByDealerId();
  const { mutate: updateDealerTradeIn } = useUpdateDealerTradeIn();
  const { mutate: saveDealerProfile } = useSaveDealerProfile();
  const { mutate: saveDealerChat } = useSaveDealerChat();
  const { mutate: getDealerProfile } = useGetProfilebyDealerId();
  const { mutate: updateDealerProfile } = useUpdateDealerProfilebyId();
  const { mutate: updateDealerChat } = useUpdateDealerChatbyId();
  const { mutate: saveDealerPayment } = useSaveDealerPayment();
  const { mutate: getDealerPayment } = useGetPaymentByDealerId();
  const { mutate: updateDealerPayment } = useUpdateDealerPaymentById();
  const { mutate: AddThemeBuilder } = UseMutation_AddThemeBuilder();
  const { mutate: UpsertThemeBuilder } = UseMutation_UpsertThemeBuilder();
  const { mutate: getDealerTheme } = useMutation_ThemeBuilderByEmail();
  const [preferencesData, setPreferencesData] = useState<any>();
  const [profileData, setProfileData] = useState<any>();
  const [dealerChat, setDealerChat] = useState<any>();
  const [paymentData, setPaymentData] = useState<any>();
  const [tradeInData, setTradeInData] = useState<any>();
  const [themeData, setThemeData] = useState<any>();
  const [isAddressDirty, setIsAddressDirty] = useState(false);
  const [partner, setPartner] = useState([]);
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [kbbState, setKbbState] = useState<boolean>(false);
  const [dealerTradeInPartner, setDealerTradeInPartner] = useState(false);
  const [providerByCompany, setProviderByCompany] = useState(false);
  const [activeAssetConditions, setActiveAssetConditions] = useState(undefined);
  const defaultThemeValues = {
    primary_color: "3952F5",
    secondary_color: "D8DAE5",
    favicon: {
      location: `${process.env.DMS_BUCKET_BASE_URL + FAVICON}`,
    },
    logo: { location: Logo },
    is_company_theme_active: true,
  };

  const settings: any = localStorage?.getItem("settings");
  const parsedSettingsObject = JSON.parse(settings);

  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  useEffect(() => {
    checkS3Credentials(parsedSettingsObject?.tenant_id);
  }, []);

  useQuery_GetAllDealers();
  UseQuery_GetAllAssetConditions();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const { data: lenderThemes } = UseQuery_getThemeBuilderbyEmail(
    companyProfile?.email
  );
  const AssetCondition: Array<IAssetCondition> = queryClient.getQueryData(
    QueryKeys.Get_All_Asset_Conditions
  );

  useEffect(() => {
    if (AssetCondition) {
      const allActiveAssets = AssetCondition?.filter((x) => x.is_active).map(
        (asset) => asset.description
      );
      setValue("profile.vehicle_condition", allActiveAssets);
      setActiveAssetConditions(allActiveAssets);
    }
  }, [AssetCondition]);

  useEffect(() => {
    if (companyProfile?.countries) {
      let arr: Array<ICountry> = [];
      for (let i = 0; i < companyProfile?.countries?.length; i++) {
        arr.push(
          countriesData?.find((x) => x.id == companyProfile?.countries[i])
        );
      }
      setCompanyCountries(arr);
    }
  }, [companyProfile, countriesData]);

  const defaultValues: IFormInputs = useMemo(() => {
    setPreferencesData(formDefaultData.current.preferences);
    setProfileData(formDefaultData.current.profile);
    setDealerChat(formDefaultData.current.chat);
    setPaymentData(formDefaultData.current.payment);
    setTradeInData(formDefaultData.current.tradein);
    setThemeData(formDefaultData.current.theme);
    return {
      payment: formDefaultData.current.payment,
      preferences: formDefaultData.current.preferences,
      profile: formDefaultData.current.profile,
      chat: formDefaultData.current.chat,
      tradein: formDefaultData.current.tradein,
      theme: formDefaultData.current.theme,
    };
  }, [formDefaultData.current]) as unknown as IFormInputs;
  function isIntegrationActive(integration_type, provider_name) {
    let is_active = false;
    const integration = dealerIntegrations?.find(
      (integration) => integration.integration_type === integration_type
    );
    if (
      provider_name == Providers.GOOGLE &&
      integration?.integration_type == IntegrationType.LOCATION_SERVICE
    ) {
      integration?.providers?.map((provider) => {
        if (provider?.dealer_code && provider.is_active) {
          is_active = provider?.is_active;
          setProviderByCompany(false);
          return;
        } else {
          dealerProvider?.map((item) => {
            if (item?.company_provider_id == provider?.id) {
              is_active = item?.is_active;
              setProviderByCompany(item?.is_active);
            }
          });
        }
      });
    } else {
      integration?.providers?.map((provider) => {
        if (provider?.provider_name == provider_name) {
          is_active = provider?.is_active;
        }
      });
    }
    return is_active;
  }
  useEffect(() => {
    if (dealerIntegrations && dealerProvider) {
      let isKbbActive = isIntegrationActive(
        IntegrationType.TRADEIN_VALUATION,
        Providers.KBB
      );
      if (isKbbActive && dealerTradeInPartner) {
        setPartner([
          {
            text: "KBB",
            value: 1,
          },
        ]);
        setValue("tradein.partner_name", "1");
        setKbbState(true);
      }
      if (!isKbbActive && dealerTradeInPartner) {
        setPartner([
          {
            text: "KBB",
            value: 1,
          },
        ]);
        setValue("tradein.partner_name", "1");
        setKbbState(false);
      }
      if (!isKbbActive && !dealerTradeInPartner) {
        setPartner([
          {
            text: null,
            value: null,
          },
        ]);
        setValue("tradein.partner_name", null);

        setKbbState(false);
      }
      if (isKbbActive && !dealerTradeInPartner) {
        setPartner([
          {
            text: "KBB",
            value: 1,
          },
        ]);
        setValue("tradein.partner_name", "1");
        setKbbState(true);
      }
      setIsAddressValidationActive(
        isIntegrationActive(IntegrationType.LOCATION_SERVICE, Providers.GOOGLE)
      );
    }
  }, [
    dealerIntegrations,
    formDefaultData.current.tradein,
    pageLoaded,
    dealerProvider,
  ]);

  const form = useForm<IFormInputs>({
    defaultValues,
    shouldUnregister: false,
    mode: "all",
    resolver: yupResolver(schema),
  });
  const {
    trigger,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = form;

  function checkKeys(obj, keys) {
    return keys.some((key) => key in obj);
  }

  function checkKeysNotExist(obj, keys) {
    return Object.keys(obj).some((key) => !keys.includes(key));
  }

  const showerror = (errortype) => {
    const keysToCheck = [
      "facebook",
      "instagram",
      "linkedin",
      "youtube",
      "google",
      "twitter",
    ];
    if (errors?.profile) {
      if (errortype === "social") {
        const keyExists = checkKeys(errors?.profile, keysToCheck);
        if (keyExists) {
          return { social: true };
        } else {
          return { social: false };
        }
      } else if (errortype === "general") {
        const keyNotExists = checkKeysNotExist(errors.profile, keysToCheck);
        if (keyNotExists) {
          return { general: true };
        } else {
          return { general: false };
        }
      }
    }
  };

  const tabs = [
    {
      title: (
        <Box
          className={showerror("general")?.general ? "error" : ""}
          theme={theme}
        >
          General
        </Box>
      ),
      content: (
        <DealerProfile
          form={form}
          dealer_code={dealer_code}
          companyCountries={companyCountries}
          setIsAddressDirty={setIsAddressDirty}
          isAddressDirty={isAddressDirty}
          isAddressValidationActive={isAddressValidationActive}
          providerByCompany={providerByCompany}
        />
      ),
    },
    {
      title: (
        <Box
          className={Object.keys(errors).includes("preferences") ? "error" : ""}
          theme={theme}
        >
          Preferences
        </Box>
      ),
      content: <Preferences form={form} />,
    },
    {
      title: (
        <Box
          className={Object.keys(errors).includes("payment") ? "error" : ""}
          theme={theme}
        >
          Payment
        </Box>
      ),
      content: <DealerPayment form={form} />,
    },
    ...(useHasPermissions(indexPermissions.MENU.TRADE_IN, PermissionTypes.VIEW)
      ? [
          {
            title: (
              <Box
                className={
                  Object.keys(errors).includes("tradein") ? "error" : ""
                }
                theme={theme}
              >
                Trade-In
              </Box>
            ),
            content: (
              <DealerTradeIn
                form={form}
                partner={partner}
                kbbState={kbbState}
              />
            ),
          },
        ]
      : []),
    {
      title: (
        <Box
          className={Object.keys(errors).includes("theme") ? "error" : ""}
          theme={theme}
        >
          Personalization
        </Box>
      ),
      content: (
        <ThemeBuilder
          form={form}
          theme={theme}
          defaultSwitch={true}
          value={defaultThemeValues}
          s3Response={s3Response}
          setDealerThemeBuilder={setDealerThemeBuilder}
          showFontFileControl={false}
        />
      ),
    },
    ...(useHasPermissions(
      indexPermissions.MENU.DEALER_FEES,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Dealer Fees",
            content: (
              <DealerFees
                form={form}
                onPopupStateChange={(popUpState) => {
                  setPopUpState(popUpState);
                }}
                dealer_code={dealer_code}
              />
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      indexPermissions.MENU.INTEGRATIONS,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Integrations",
            content: (
              <DealerIntergrations
                form={form}
                dealer_code={dealer_code}
                isProfileDirty={dirtyFields?.profile}
                onPopupStateChange={(popUpState) => {
                  setPopUpState(popUpState);
                }}
              />
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      indexPermissions.MENU.CUSTOM_LINKS,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Custom Links",
            content: (
              <CustomLinks
                profileData={profileData}
                setProfileData={setProfileData}
                onPopupStateChange={(popUpState) => {
                  setPopUpState(popUpState);
                }}
              />
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      indexPermissions.MENU.INDIVDUAL_AGREEMENT,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Individualized Agreement",
            content: (
              <IndividualizedAgreement
                dealer_code={dealer_code}
                onPopupStateChange={(popUpState) => {
                  setPopUpState(popUpState);
                }}
              />
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      indexPermissions.MENU.SOCIAL_LINKS,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: (
              <Box
                className={showerror("social")?.social ? "error" : ""}
                theme={theme}
              >
                Social Links
              </Box>
            ),
            content: (
              <DealerSocial
                form={form}
                profiledata={defaultValues.profile}
                setSocialDirty={setSocialDirty}
              />
            ),
          },
        ]
      : []),
    ...(useHasPermissions(indexPermissions.MENU.CHAT, PermissionTypes.VIEW)
      ? [
          {
            title: "Chat",
            content: (
              <DealerChat
                form={form}
                dealer_code={dealer_code}
                formDefaultData={formDefaultData}
              />
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (dealerData) {
      const dealer = dealerData.find(
        (dealer) => dealer.dealer_code === dealer_code
      );
      setDealerEmail(dealer?.email);
    }
  }, [dealer_code, dealerData]);
  const handleButtonClick = async (stylesheetlink: any) => {
    const s3FileLink = stylesheetlink;

    const response = await fetch(s3FileLink, { mode: "cors", method: "GET" });
    const cssContent = await response.text();
    const primaryColorRegex = /--primary:\s*#?([a-fA-F0-9]+)\b/;
    const secondaryColorRegex = /--secondary:\s*#?([a-fA-F0-9]+)\b/;

    // Extract primary and secondary color values from the CSS string
    const primaryColorMatch = cssContent.match(primaryColorRegex);
    const secondaryColorMatch = cssContent.match(secondaryColorRegex);
    // const matches = cssContent.match(colorRegex);
    const primaryColor = primaryColorMatch ? primaryColorMatch[1].trim() : null;
    const secondaryColor = secondaryColorMatch
      ? secondaryColorMatch[1].trim()
      : null;

    if (primaryColor && secondaryColor) {
      setValue("theme.primary_color", primaryColor);
      setValue("theme.secondary_color", secondaryColor);
    } else {
      setValue("theme.primary_color", defaultThemeValues?.primary_color);
      setValue("theme.secondary_color", defaultThemeValues?.secondary_color);
    }
    return { primaryColor: primaryColor, secondaryColor: secondaryColor };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (dealerThemeBuilder) {
        const colors = await handleButtonClick(dealerThemeBuilder?.stylesheet);
        setValue("theme.primary_color", colors.primaryColor);
        setValue("theme.secondary_color", colors.secondaryColor);
        formDefaultData.current = {
          ...formDefaultData.current,
          theme: {
            stylesheet: dealerThemeBuilder?.stylesheet,
            favicon: { location: dealerThemeBuilder?.favicon.location },
            logo: { location: dealerThemeBuilder?.logo.location },
            user_email: dealerThemeBuilder?.user_email,
            primary_color: colors.primaryColor,
            secondary_color: colors.secondaryColor,
            is_company_theme_active: dealerThemeBuilder.is_company_theme_active,
          },
        };
      } else {
        setValue("theme.favicon", defaultThemeValues?.favicon);
        setValue("theme.logo", defaultThemeValues?.logo);
        setValue("theme.primary_color", defaultThemeValues?.primary_color);
        setValue("theme.secondary_color", defaultThemeValues?.secondary_color);
      }
    };

    fetchData();
  }, [dealerThemeBuilder]);

  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  async function convertCssToBase64(
    primaryColor: string,
    secondaryColor: string
  ): Promise<string> {
    try {
      const response = await fetch(
        `${process.env.DMS_BUCKET_BASE_URL + THEME_BUILDER_CSS}`,
        { mode: "cors", method: "GET" }
      );
      const cssContent = await response.text();
      const modifiedCss = modifyCssContent(
        cssContent,
        primaryColor,
        secondaryColor
      );

      const blob = new Blob([modifiedCss], { type: "text/css" });

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const base64Css = btoa(event.target.result as string);
            resolve(base64Css);
          } else {
            reject(new Error("Error converting CSS to base64."));
          }
        };
        reader.readAsBinaryString(blob);
      });
    } catch (error: any) {
      throw new Error("Error fetching or updating CSS file: " + error.message);
    }
  }

  function modifyCssContent(
    cssContent: any,
    primaryColor: string,
    secondaryColor: string
  ) {
    const modifiedCss = cssContent
      .replace(/--primary:\s*(.*?);/, `--primary: #${primaryColor};`)
      .replace(/--secondary:\s*(.*?);/, `--secondary: #${secondaryColor};`);

    return modifiedCss;
  }
  var base64Css: string = null;
  const saveStylesheet = async () => {
    if (dealerData) {
      form.setValue("theme.user_email", dealerEmail);
      base64Css = await convertCssToBase64(
        getValues("theme.primary_color"),
        getValues("theme.secondary_color")
      );
      form.setValue("theme.stylesheet", base64Css);
    }
  };

  const beforeSubmit = () => {
    let isAddressVerified = form.getValues("profile.dealer_address.verified");
    if (!isAddressVerified && isAddressValidationActive) {
      actions.setToast({
        toastMessage: ToastMessages.VERIFY_ADDRESS,
        toastState: true,
        variant: "error",
      });
      return;
    }
    let required_validation_failed = false;
    let other_validation_failed = false;
    Object.entries(errors).map((val) => {
      val.forEach((entry) => {
        if (typeof entry !== typeof "") {
          Object.values(entry)
            .filter((errorVal) => errorVal.valueOf())
            .map((error) => {
              if ((error as { [key: string]: any })["type"]) {
                if ((error as { [key: string]: any })["type"] === "required") {
                  required_validation_failed = true;
                }
                if (
                  error["ref"]?.["name"] == "theme.secondary_color" ||
                  error["ref"]?.["name"] == "theme.primary_color" ||
                  error["ref"]?.["name"] == "theme.favicon" ||
                  error["ref"]?.["name"] == "theme.logo"
                ) {
                  other_validation_failed = true;
                } else {
                  other_validation_failed = true;
                }
              } else {
                Object.entries(error).map((entry) => {
                  if (typeof entry !== typeof "") {
                    Object.values(entry)
                      .filter((errorVal) => errorVal.valueOf())
                      .map((error) => {
                        if ((error as { [key: string]: any })["type"]) {
                          if (
                            (error as { [key: string]: any })["type"] ===
                            "required"
                          ) {
                            required_validation_failed = true;
                          } else {
                            other_validation_failed = true;
                          }
                        }
                      });
                  }
                });
              }
            });
        }
      });
    });
    if (required_validation_failed && !other_validation_failed) {
      onSubmit(form.getValues());
      actions.setToast({
        toastMessage:
          "Changes have been saved but mandatory information required to activate your profile.",
        toastState: true,
        showIcon: true,
      });
      reset(form.getValues(), { keepErrors: true });
    } else if (other_validation_failed) {
      actions.setToast({
        toastMessage: "There is some missing mandatory information",
        toastState: true,
        variant: "error",
        showIcon: true,
      });
    } else if (!required_validation_failed && !other_validation_failed) {
      onSubmit(form.getValues());
      actions.setToast({
        toastMessage: ToastMessages.Record_Updated_Success,
        toastState: true,
      });

      reset(form.getValues(), { keepErrors: false });
    }
  };
  const onSubmit = async (formValues: any): Promise<void> => {
    const getTimeZoneDifference = (
      timeZoneKey: string | undefined
    ): string | null => {
      if (!timeZoneKey) return null;
      return TimeZone[timeZoneKey as keyof typeof TimeZone] || null;
    };
    if (formValues.profile.dealer_address.location) {
      formValues.profile.dealer_address.coordinates =
        formValues.profile.dealer_address.location.lat +
        "," +
        formValues.profile.dealer_address.location.lng;
      delete formValues.profile.dealer_address.location;
    }
    await saveStylesheet();
    if (formValues) {
      const financeTypes = formValues.profile?.available_finance_types || [];

      const isFinanceType = financeTypes.includes("Finance");
      const isLeaseType = financeTypes.includes("Lease");
      let standard_lease_rates;
      let standard_finance_rates;

      if (isFinanceType) {
        standard_finance_rates = {
          down_payment_rate: formValues.preferences?.standard_finance_rates
            ?.down_payment_rate
            ? formValues.preferences?.standard_finance_rates?.down_payment_rate
            : null,
          rv_balloon_rate: formValues.preferences?.standard_finance_rates
            ?.rv_balloon_rate
            ? formValues.preferences?.standard_finance_rates?.rv_balloon_rate
            : null,
          customer_rate: formValues.preferences?.standard_finance_rates
            ?.customer_rate
            ? formValues.preferences?.standard_finance_rates?.customer_rate
            : null,
          min_down_payment_rate: formValues.preferences?.standard_finance_rates
            ?.min_down_payment_rate
            ? formValues.preferences?.standard_finance_rates
                ?.min_down_payment_rate
            : null,
          max_down_payment_rate: formValues.preferences?.standard_finance_rates
            ?.max_down_payment_rate
            ? formValues.preferences?.standard_finance_rates
                ?.max_down_payment_rate
            : null,
          finance_type:
            formValues.profile?.available_finance_types?.find(
              (item) => item === "Finance"
            ) || null,
        };
      }
      if (isLeaseType) {
        standard_lease_rates = {
          down_payment_rate: formValues.preferences?.standard_lease_rates
            ?.down_payment_rate
            ? formValues.preferences?.standard_lease_rates?.down_payment_rate
            : null,
          rv_balloon_rate: formValues.preferences?.standard_lease_rates
            ?.rv_balloon_rate
            ? formValues.preferences?.standard_lease_rates?.rv_balloon_rate
            : null,
          customer_rate: formValues.preferences?.standard_lease_rates
            ?.customer_rate
            ? formValues.preferences?.standard_lease_rates?.customer_rate
            : null,
          min_down_payment_rate: formValues.preferences?.standard_lease_rates
            ?.min_down_payment_rate
            ? formValues.preferences?.standard_lease_rates
                ?.min_down_payment_rate
            : null,
          max_down_payment_rate: formValues.preferences?.standard_lease_rates
            ?.max_down_payment_rate
            ? formValues.preferences?.standard_lease_rates
                ?.max_down_payment_rate
            : null,
          finance_type:
            formValues.profile?.available_finance_types?.find(
              (item) => item === "Lease"
            ) || null,
        };
      }
      formValues.preferences = {
        id: formValues.preferences?.id ? formValues.preferences?.id : null,
        default_mileage_id: formValues.preferences?.default_mileage_id
          ? formValues.preferences?.default_mileage_id
          : null,
        default_term_id: formValues.preferences?.default_term_id
          ? formValues.preferences?.default_term_id
          : null,
        quotation_expiry_days: formValues.preferences?.quotation_expiry_days
          ? parseInt(formValues.preferences?.quotation_expiry_days.toString())
          : null,
        application_expiry_days: formValues.preferences?.application_expiry_days
          ? parseInt(formValues.preferences?.application_expiry_days.toString())
          : null,
        availability_check: formValues.preferences?.availability_check
          ? formValues.preferences?.availability_check
          : null,
        default_margin_rate_lease: formValues.preferences
          ?.default_margin_rate_lease
          ? parseFloat(
              formValues.preferences?.default_margin_rate_lease.toString()
            )
          : null,
        default_margin_rate_finance: formValues.preferences
          ?.default_margin_rate_finance
          ? parseFloat(
              formValues.preferences?.default_margin_rate_finance.toString()
            )
          : null,
      };
      formValues.preferences = {
        ...formValues.preferences,
        dealer_code: dealer_code,
        ...(isLeaseType && { standard_lease_rates }),
        ...(isFinanceType && { standard_finance_rates }),
      };
      formValues.profile = {
        id: formValues.profile?.id ? formValues.profile?.id : null,
        available_finance_types: formValues.profile?.available_finance_types
          ? formValues.profile?.available_finance_types
          : null,
        schedule_option: formValues.profile?.schedule_option
          ? formValues.profile?.schedule_option
          : null,
        contact_number: formValues.profile?.contact_number
          ? formValues.profile?.contact_number
          : null,
        customer_support_number: formValues.profile?.customer_support_number
          ? formValues.profile?.customer_support_number
          : null,
        pen_dealer_id: formValues.profile?.pen_dealer_id
          ? formValues.profile?.pen_dealer_id
          : null,
        time_zone: formValues?.profile?.time_zone
          ? formValues?.profile?.time_zone
          : null,
        time_zone_difference: getTimeZoneDifference(
          formValues?.profile?.time_zone
        ),
        fax_number: formValues.profile?.fax_number
          ? formValues.profile?.fax_number
          : null,
        email: formValues.profile?.email ? formValues.profile?.email : null,
        website: formValues.profile?.website
          ? formValues.profile?.website
          : null,
        contact_person_name: formValues.profile?.contact_person_name
          ? formValues.profile?.contact_person_name
          : null,
        day_light_saving: formValues.profile
          ? formValues.profile?.day_light_saving
          : null,
        initials: formValues.profile?.initials
          ? formValues.profile?.initials
          : null,
        dealer_address: formValues.profile?.dealer_address
          ? formValues.profile?.dealer_address
          : null,
        is_active: formValues.profile?.is_active,
        is_deleted: formValues.profile?.is_deleted,
        facebook: formValues.profile?.facebook
          ? formValues.profile?.facebook
          : null,
        instagram: formValues.profile?.instagram
          ? formValues.profile?.instagram
          : null,
        google: formValues.profile?.google ? formValues.profile?.google : null,
        twitter: formValues.profile?.twitter
          ? formValues.profile?.twitter
          : null,
        youtube: formValues.profile?.youtube
          ? formValues.profile?.youtube
          : null,
        linkedin: formValues.profile?.linkedin
          ? formValues.profile?.linkedin
          : null,
        trading_as: formValues.profile?.trading_as
          ? formValues.profile?.trading_as
          : null,
        contact_person_email: formValues.profile?.contact_person_email
          ? formValues.profile?.contact_person_email
          : null,
        contact_person_phone_number: formValues.profile
          ?.contact_person_phone_number
          ? formValues.profile?.contact_person_phone_number
          : null,
        sub_domain: formValues.profile?.sub_domain
          ? formValues.profile?.sub_domain
          : null,
        vehicle_condition: formValues.profile?.vehicle_condition
          ? formValues.profile?.vehicle_condition
          : null,
      };
      formValues.profile.dealer_address.country_id =
        formValues.profile.dealer_address.country_id?.id;
      formValues.profile = {
        ...formValues.profile,
        dealer_code: dealer_code,
      };
      formValues.chat = {
        id: formValues.chat?.id ? formValues.chat?.id : null,
        welcome_chat: formValues.chat?.welcome_chat
          ? formValues.chat?.welcome_chat
          : null,
        offline_message: formValues.chat?.offline_message
          ? formValues.chat?.offline_message
          : null,
      };
      formValues.chat = {
        ...formValues.chat,
        dealer_code: dealer_code,
      };
      formValues.tradein = {
        id: formValues.tradein?.id ? formValues.tradein?.id : null,
        partner_name: partner.find(
          (x) =>
            x.value ==
            +(formValues?.tradein?.partner_name
              ? formValues.tradein.partner_name
              : "")
        )?.text,
        percentage: formValues.tradein?.percentage
          ? formValues.tradein?.percentage
          : null,
      };
      formValues.tradein = {
        ...formValues.tradein,
        dealer_code: dealer_code,
      };
      formValues.payment = {
        id: formValues.payment?.id ? formValues.payment?.id : null,
        stripe_registered_email: "sample@gmail.com",
        account_number: formValues.payment?.account_number
          ? formValues.payment?.account_number
          : null,
        aba_number: formValues.payment?.aba_number
          ? formValues.payment?.aba_number
          : null,
        account_title: formValues.payment?.account_title
          ? formValues.payment?.account_title
          : null,
        bank_name: formValues.payment?.bank_name
          ? formValues.payment?.bank_name
          : null,
        bank_account_limit: formValues.payment?.bank_account_limit
          ? formValues.payment?.bank_account_limit
          : null,
        credit_card_limit: formValues.payment?.credit_card_limit
          ? formValues.payment?.credit_card_limit
          : null,
        debit_card_limit: formValues.payment?.debit_card_limit
          ? formValues.payment?.debit_card_limit
          : null,
        email_verified: false,
      };
      formValues.payment = {
        ...formValues.payment,
        dealer_code: dealer_code,
      };
      if (formValues?.theme?.favicon_url) {
        formValues.theme.favicon = {
          url: formValues?.theme?.favicon_url.url,
          key: formValues?.theme?.favicon_url.key,
        };
        delete formValues?.theme?.favicon_url;
      } else if (formValues.theme.favicon.location) {
        formValues.theme.favicon = {
          location: formValues.theme.favicon.location,
          key: null,
        };
      }
      if (formValues?.theme?.logo_url) {
        formValues.theme.logo = {
          url: formValues?.theme?.logo_url.url,
          key: formValues?.theme?.logo_url.key,
        };
        delete formValues?.theme?.logo_url;
      } else if (formValues.theme.logo.location) {
        formValues.theme.logo = {
          location: formValues.theme.logo.location,
          key: null,
        };
      }
      formValues.theme = {
        ...formValues.theme,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
        stylesheet: base64Css,
      };
    }
    const is_integration_active = isIntegrationActive(
      IntegrationType.TRADEIN_VALUATION,
      Providers.KBB
    );
    if (isEditMode) {
      const modifiedData = { ...formValues.theme };
      delete modifiedData.id;
      s3Response
        ? UpsertThemeBuilder(modifiedData, {
            onSuccess: (response: any) => {
              setIsEditMode(true);
              if (response?.data) {
                formDefaultData.current = {
                  ...formDefaultData.current,
                  theme: {
                    ...formDefaultData.current.theme,
                    stylesheet: response?.data?.stylesheet,
                    favicon: {
                      location:
                        response?.data?.favicon.location ||
                        response?.data?.favicon.url,
                    },
                    logo: {
                      location:
                        response?.data?.logo.url ||
                        response?.data?.logo.location,
                    },
                    user_email: response?.data?.user_email,
                    is_company_theme_active:
                      response?.data.is_company_theme_active,
                  },
                };
                if (user_role == "Index Dealer Admin User") {
                  setStyleSheet(response?.data?.stylesheet);
                  setFavicon(response?.data?.favicon?.location);
                  setLogo(response?.data?.logo?.location);
                }
              }
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: "error",
              });
            },
          })
        : null;
      updateDealerPreference(formValues.preferences, {
        onSuccess(response) {
          setIsEditMode(true);
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              preferences: response,
              theme: getValues("theme"),
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
      updateDealerProfile(formValues.profile, {
        onSuccess(response: IDealerProfile) {
          setIsEditMode(true);
          if (response) {
            let country_id = response.dealer_address.country_id;
            response.dealer_address.country_id = {
              label: companyCountries?.find((x) => x.id == country_id).name,
              id: country_id,
            };
            formDefaultData.current = {
              ...formDefaultData.current,
              profile: response,
              theme: getValues("theme"),
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
      if (formValues.chat?.id) {
        updateDealerChat(formValues.chat, {
          onSuccess(response) {
            setIsEditMode(true);
            if (response) {
              formDefaultData.current = {
                ...formDefaultData.current,
                chat: response,
                theme: getValues("theme"),
              };
            }
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: "error",
            });
          },
        });
      } else if (dirtyFields?.chat) {
        saveDealerChat(formValues.chat, {
          onSuccess(response: IDealerChat) {
            setIsEditMode(true);
            if (response) {
              formDefaultData.current = {
                ...formDefaultData.current,
                chat: response,
              };
            }
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: "error",
            });
          },
        });
      }
      updateDealerTradeIn(formValues.tradein, {
        onSuccess(response: any) {
          setIsEditMode(true);
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              theme: getValues("theme"),
              tradein: {
                id: response?.id,
                dealer_code: (response as any)?.dealer_code,
                percentage: response?.percentage,
                partner_name: is_integration_active
                  ? response?.partner_name ?? "1"
                  : null,
              },
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
      updateDealerPayment(formValues.payment, {
        onSuccess(response: IDealerPayment) {
          // actions.setToast({
          //   toastMessage: "Record Updated Successfully",
          //   toastState: true,
          // });
          setIsEditMode(true);
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              payment: response,
              theme: getValues("theme"),
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
    } else {
      s3Response
        ? AddThemeBuilder(formValues.theme, {
            onSuccess: (response: any) => {
              setDealerThemeBuilder(response?.data);
              setIsEditMode(true);
              handleButtonClick(response.data.stylesheet);
              // updateFormValues(response);
              if (user_role == "Index Dealer Admin User") {
                handleButtonClick(response.data.stylesheet);
                setStyleSheet(response?.stylesheet);
                setFavicon(response?.favicon?.location);
                setLogo(response?.logo?.location);
              }
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: "error",
              });
            },
          })
        : null;
      addDealerPreference(formValues.preferences, {
        onSuccess(response: IDealerPreference) {
          setIsEditMode(true);
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              preferences: response,
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
      addDealerTradeIn(formValues.tradein, {
        onSuccess(response: IDealerTradeIn) {
          actions.setToast({
            toastMessage: "Record Saved Successfully",
            toastState: true,
          });
          setIsEditMode(true);
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              tradein: {
                id: response?.id,
                dealer_code: (response as any)?.dealer_code,
                percentage: response?.percentage,
                partner_name: is_integration_active
                  ? response?.partner_name ?? "1"
                  : null,
              },
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
      saveDealerProfile(formValues.profile, {
        onSuccess(response: IDealerProfile) {
          setIsEditMode(true);
          if (response) {
            let country_id = response.dealer_address.country_id;
            response.dealer_address.country_id = {
              label: companyCountries?.find((x) => x.id == country_id).name,
              id: country_id,
            };
            formDefaultData.current = {
              ...formDefaultData.current,
              profile: response,
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
      saveDealerPayment(formValues.payment, {
        onSuccess(response: IDealerPayment) {
          setIsEditMode(true);
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              payment: response,
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
      saveDealerChat(formValues.chat, {
        onSuccess(response: IDealerChat) {
          setIsEditMode(true);
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              chat: response,
            };
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
    }
    setIsAddressDirty(false);
  };

  useEffect(() => {
    if (
      dealer_code !== null &&
      companyProfile &&
      countriesData &&
      dealerIntegrations
    ) {
      formDefaultData.current = {
        payment: null,
        preferences: null,
        profile: null,
        tradein: null,
        chat: null,
        // theme: null,
      };
      getDealerPreference(
        { id: dealer_code },
        {
          onSuccess(data: IDealerPreference) {
            if (data) {
              formDefaultData.current = {
                ...formDefaultData.current,
                preferences: data,
                theme: getValues("theme"),
              };
              setIsEditMode(true);
            } else {
              const response: IFormInputs["preferences"] = {
                id: null,
                default_mileage_id: null,
                default_term_id: null,
                quotation_expiry_days: null,
                application_expiry_days: null,
                default_margin_rate_lease: null,
                default_margin_rate_finance: null,
              };
              formDefaultData.current = {
                ...formDefaultData.current,
                preferences: response,
              };
              setIsEditMode(false);
            }
          },
        }
      );
      getDealerProfile(
        { id: dealer_code },
        {
          onSuccess(data: IDealerProfile) {
            let arr: Array<ICountry> = [];
            if (companyProfile?.countries) {
              for (let i = 0; i < companyProfile?.countries?.length; i++) {
                arr.push(
                  countriesData?.find(
                    (x) => x.id == companyProfile?.countries[i]
                  )
                );
              }
              setCompanyCountries(arr);
            }
            if (data) {
              let country_id = data.dealer_address.country_id;
              let foundCountry = arr?.find((x) => x?.id == country_id);
              if (foundCountry) {
                data.dealer_address.country_id = {
                  label: foundCountry?.name,
                  id: country_id,
                };
              } else {
                data.dealer_address = {
                  ...data.dealer_address,
                  address_line_1: "",
                  address_line_2: "",
                  city: "",
                  zip_code: "",
                  verified: false,
                  state_name: "",
                  state_id: null,
                  county: "",
                  contact_number: "",
                  address_type: data?.dealer_address?.address_type,
                  id: data?.dealer_address?.id,
                  country_id:
                    arr?.length > 0
                      ? { label: arr[0]?.name, id: arr[0]?.id }
                      : { label: "", id: "" },
                };
              }
              formDefaultData.current = {
                ...formDefaultData.current,
                profile: {
                  ...data,
                },
                theme: getValues("theme"),
              };
              setIsEditMode(true);
            } else {
              const allActiveAssets =
                AssetCondition?.filter((x) => x.is_active).map(
                  (asset) => asset.description
                ) || [];
              const response: IFormInputs["profile"] = {
                id: null,
                available_finance_types: null,
                schedule_option: null,
                contact_number: null,
                customer_support_number: null,
                time_zone: null,
                fax_number: null,
                email: null,
                website: null,
                contact_person_name: null,
                day_light_saving: null,
                initials: null,
                dealer_code: null,
                pen_dealer_id: null,
                dealer_address: {
                  country_id: {
                    label: arr?.[0]?.name,
                    id: arr?.[0]?.id,
                  },
                },
                vehicle_condition: getValues("profile.vehicle_condition"),
              };
              formDefaultData.current = {
                ...formDefaultData.current,
                profile: response,
                theme: getValues("theme"),
              };
              setIsEditMode(false);
            }
          },
        }
      );
      getDealerTradeIn(
        { id: dealer_code },
        {
          onSuccess(data: IDealerTradeIn) {
            if (data) {
              if (data.partner_name == "KBB") {
                setDealerTradeInPartner(true);
                formDefaultData.current = {
                  ...formDefaultData.current,
                  tradein: {
                    id: data?.id,
                    dealer_code: (data as any)?.dealer_code,
                    percentage: data.percentage,
                    partner_name: data?.partner_name ?? "1",
                  },
                  theme: getValues("theme"),
                };
              } else {
                setDealerTradeInPartner(false);
                formDefaultData.current = {
                  ...formDefaultData.current,
                  tradein: {
                    id: data?.id,
                    dealer_code: (data as any)?.dealer_code,
                    percentage: data.percentage,
                    partner_name: data?.partner_name ?? null,
                  },
                  theme: getValues("theme"),
                };
              }

              setIsEditMode(true);
            } else {
              setDealerTradeInPartner(false);
              const response: IFormInputs["tradein"] = {
                id: null,
                partner_name: null,
                dealer_code: null,
                percentage: null,
              };
              formDefaultData.current = {
                ...formDefaultData.current,
                tradein: response,
                theme: getValues("theme"),
              };
              setIsEditMode(false);
            }
          },
        }
      );
      getDealerPayment(
        { id: dealer_code },
        {
          onSuccess(data: IDealerPayment) {
            if (data) {
              formDefaultData.current = {
                ...formDefaultData.current,
                payment: data,
                theme: getValues("theme"),
              };
              setIsEditMode(true);
            } else {
              const response: IFormInputs["payment"] = {
                id: null,
                stripe_registered_email: null,
                bank_account_limit: null,
                credit_card_limit: null,
                debit_card_limit: null,
                dealer_code: null,
                email_verified: null,
                account_number: "",
                aba_number: "",
                account_title: "",
                bank_name: "",
              };
              formDefaultData.current = {
                ...formDefaultData.current,
                payment: response,
              };
              setIsEditMode(false);
            }
          },
        }
      );
      if (dealer_code && dealerEmail) {
        getDealerTheme(
          { user_email: dealerEmail },
          {
            onSuccess(response: any) {
              // setIsEditMode(true);
              if (response.data != null) {
                handleButtonClick(response?.data?.stylesheet);
                formDefaultData.current = {
                  ...formDefaultData.current,
                  theme: response?.data,
                };
              } else {
                const themes = {
                  id: null,
                  tenant_id: null,
                  stylesheet: null,
                  primary_color: defaultThemeValues.primary_color,
                  secondary_color: defaultThemeValues.secondary_color,
                  favicon: defaultThemeValues.favicon,
                  logo: defaultThemeValues.logo,
                  user_email: null,
                  is_company_theme_active: true,
                };
                formDefaultData.current = {
                  ...formDefaultData.current,
                  theme: themes,
                };
              }
            },
          }
        );
      }
    }
  }, [
    dealer_code,
    companyProfile,
    countriesData,
    dealerIntegrations,
    dealerEmail,
    dealerProvider,
  ]);
  useEffect(() => {
    reset(defaultValues, { keepDirty: true });
  }, [
    preferencesData,
    profileData,
    dealerChat,
    paymentData,
    tradeInData,
    themeData,
    lenderThemes,
  ]);
  const onSaveConfirm = async (e: any) => {
    await trigger([
      "profile",
      "preferences",
      "payment",
      "tradein",
      "chat",
      "theme",
    ]);
    beforeSubmit();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(form.getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <DrawerWrap open={popUpState}>
        <LayoutWithSideNav theme={theme}>
          <PageHeader
            className="main-page-header border-bottom"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ fontSize: 36 }}
                  rowGap={6}
                  mt={1}
                  data-testid="heading"
                  theme={theme}
                >
                  Dealer Profile
                </Typography>
              </Grid>
              <Grid
                theme={theme}
                item
                xs={12}
                lg={5}
                sm={12}
                md={6}
                textAlign="right"
              >
                {(isDirty || isAddressDirty || socialDirty) && (
                  <Button
                    primary
                    theme={theme}
                    text={"Save Changes"}
                    type="submit"
                    onClick={async () => {
                      await trigger([
                        "profile",
                        "preferences",
                        "chat",
                        "payment",
                        "tradein",
                        "theme",
                      ]);
                      beforeSubmit();
                      setSocialDirty(false);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </PageHeader>
          <form onSubmit={(e) => e.preventDefault()}>
            <ScrollableTabs
              theme={theme}
              items={tabs}
              defaultTabIndex={0}
              scrollButtons={"auto"}
            />
          </form>
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default DealerConfigurations;
