import { useTheme } from "@mui/material";
import {
  Accordion,
  Input,
  MultiSelect,
  Select,
  Button,
  Icon,
  Box,
  IBoxProps,
  TextDivider,
  Typography,
} from "@ntpkunity/controls";
import { FC, useState } from "react";
import { Controller } from "react-hook-form";
import {
  ActionOptions,
  Module,
  QueryKeys,
  StationeryType,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { AccordionWrapSm, ConfirmationDialog } from "@sharedComponents";
import { ICreditTierMargin, IFinanceTypeModel } from "Interface";
import { CreditTier } from "./CreditTier";
import { FinanceCharts } from "./FinanceCharts";
import { FinanceRates } from "./FinanceRates";
import { LeaseRates } from "./LeaseRates";
import {
  UseQuery_GetAllFinanceTypes,
  UseQuery_getLenderIntegrations,
  useGetCompanyLenderDetails,
} from "services";
import { useQueryClient } from "react-query";
import {
  CREDIT_DECISION,
  E_CONTRACTING,
  PRICING_PROGRAM,
} from "../../shared/constants";
import { UseQuery_GetAllEvents } from "services/asset-condition.service";
import { getProviderName } from "utilities/common";
import { styled } from "@mui/material/styles";

const AddNewLenderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-lender-wrap": {
    ".inner-section": {
      borderBottom: "1px solid" + theme.palette.grey[100],
      marginBottom: 24,
      ".u-custom-autocomplete": {
        marginBottom: 0,
      },
    },
    ".divider": {
      span: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.customVariables.fontWeightSemiBold,
      },
    },
  },
}));

export const AddNewLender: FC<{
  form: any;
  popUpOpenState: string;
  module?: Module;
}> = ({ form, popUpOpenState, module }) => {
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = form;
  const { data: companyLenderExists } = useGetCompanyLenderDetails();
  let displayIndex = 0;
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);

  const onSaveConfirm = () => {
    setValue("default_lender", true);
    setConfirmationPopupState(false);
  };
  const creditTierMargins = watch("credit_tier_margins");
  const financeType = watch("finance_type");
  const handleDeleteItem = (index: number) => {
    const ctm = getValues("credit_tier_margins");
    if (ctm && ctm?.length > 0) {
      const itemToUpdate = ctm[index];

      const itemToInsert = {
        ...(itemToUpdate || {}),
        is_active: false,
        is_deleted: true,
      };

      ctm[index] = itemToInsert;
      setValue("credit_tier_margins", ctm);
    }
  };
  const handleAddItem = () => {
    const ctm = getValues("credit_tier_margins") || [];
    ctm?.push({
      tier_name: "PRPL",
      finance_type: "Lease",
      margin_rate: undefined,
    });
    setValue("credit_tier_margins", ctm);
  };
  const selectedContracting = watch("e_contracting");
  const selectedCreditDecision = watch("credit_decisioning");
  const selectedPricingProgram = watch("program");
  UseQuery_GetAllFinanceTypes();
  const { data: eventsData } = UseQuery_GetAllEvents();

  const available_finance: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const { data: allIntegrations } = UseQuery_getLenderIntegrations();
  const pricingProviders = allIntegrations?.find(
    (integration) => integration.integration_type === "Pricing Program"
  );
  const creditDecisionProviders = allIntegrations?.find(
    (integration) => integration.integration_type === "Credit Decisioning"
  );
  const contractingProviders = allIntegrations?.find(
    (integration) => integration.integration_type === "Contracting"
  );
  const targetEventNames = [
    "Credit Application Approved",
    "Insurance Received",
  ];
  const filteredEvents = eventsData
    ?.filter((event) => targetEventNames.includes(event.event_name))
    .map((event) => ({
      text: event.event_name,
      value: event.event_name,
    }));
  const disabled =
    popUpOpenState == ActionOptions.EDIT &&
    getValues("lender_provider") == "Company" &&
    module == Module.DEALER_MODULE;
  return (
    <AddNewLenderWrap theme={theme} className="add-lender-wrap">
      <Controller
        name="name"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.NAME_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Lender Name"}
            type="text"
            {...field}
            disabled={disabled || module == Module.DEALER_MODULE}
            error={errors?.name?.message}
          />
        )}
      />
      <Controller
        name="external_code"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.EXTERNAL_CODE_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"External Code"}
            type="text"
            disabled={disabled || module == Module.DEALER_MODULE}
            {...field}
            error={errors?.external_code?.message}
          />
        )}
      />
      <Controller
        name="finance_type"
        control={control}
        rules={{
          required: ValidationMessages.Finance_type_required,
        }}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <MultiSelect
            id="financeType"
            name="finance_type"
            theme={theme}
            label={"Finance Type(s)"}
            items={available_finance?.map((item) => {
              return { text: item.name, value: item.name };
            })}
            value={value ?? []}
            onChange={(e) => {
              const selectedFinanceTypes = Array.isArray(e.target.value)
                ? e.target.value
                : [e.target.value];
              onChange(selectedFinanceTypes);
              if (!selectedFinanceTypes.includes("Lease")) {
                setValue("lease_rate_type", "");
              }
            }}
            disabled={disabled}
            selectError={errors?.finance_type?.message as string}
          />
        )}
      />
      <Controller
        name="program"
        control={control}
        defaultValue={"Internal Program Evaluation"}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Pricing Program"}
            placeholder={"Select"}
            items={
              module === Module.DEALER_MODULE
                ? PRICING_PROGRAM.filter(
                    (x) => x == "Standard Rates" || x == value
                  ).map((program) => ({
                    text: program,
                    value: program,
                  }))
                : PRICING_PROGRAM.map((program) => ({
                    text: program,
                    value: program,
                  }))
            }
            value={value ?? []}
            onChange={(e) => {
              onChange(e);
              setValue("finance_downPayment", 0);
              setValue("finacnce_rvBalloon", 0);
              setValue("finance_customerRate", 0);
              setValue("finance_mindownpaymentrate", 0);
              setValue("finance_maxdownpaymentrate", 0);
              setValue("lease_downPayment", 0);
              setValue("lease_rvBalloon", 0);
              setValue("lease_customerRate", 0);
              setValue("lease_mindownpaymentrate", 0);
              setValue("lease_maxdownpaymentrate", 0);
              setValue("commission_chart_id", null);
              setValue("interest_rate_chart_id", null);
              setValue("rv_balloon_rate_chart_id", null);
              setValue("fees_chart_id", null);
              setValue("pricing_program_provider", "");
            }}
            disabled={disabled}
          />
        )}
      />
      {financeType?.includes("Lease") && (
        <Controller
          name="lease_rate_type"
          control={control}
          rules={{
            required: ValidationMessages.Lease_Rate_Type,
          }}
          defaultValue={[]}
          render={({ field: { value, onChange } }) => (
            <Select
              id="LeaseRateType"
              name="lease_rate_type"
              theme={theme}
              label={"Lease Rate Type"}
              items={[
                {
                  text: "APR",
                  value: "APR",
                },
                {
                  text: "Money Factor",
                  value: "Money Factor",
                },
              ]}
              value={value ?? []}
              onChange={(e) => {
                onChange(e);
              }}
              disabled={disabled}
              selectError={errors?.lease_rate_type?.message as string}
            />
          )}
        />
      )}
      <Controller
        name="down_payment"
        control={control}
        rules={{
          required: ValidationMessages.Down_payment_required,
        }}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <Select
            id="DownPayment"
            name="down_payment"
            theme={theme}
            label={"Down Payment"}
            items={
              watch("program") === "Standard Charts"
                ? [
                    {
                      text: "Dealer Preferences",
                      value: "Dealer Preferences",
                    },
                  ]
                : [
                    {
                      text: "Pricing ",
                      value: "Pricing ",
                    },
                    {
                      text: "Dealer Preferences",
                      value: "Dealer Preferences",
                    },
                  ]
            }
            value={value ?? []}
            onChange={(e) => {
              onChange(e);
            }}
            disabled={disabled}
            selectError={errors?.down_payment?.message as string}
          />
        )}
      />
      {selectedPricingProgram === "Standard Rates" && (
        <Box
          theme={theme}
          className={
            financeType.includes("Finance") || financeType.includes("Lease")
              ? "inner-section"
              : ""
          }
        >
          {financeType.includes("Finance") && !financeType.includes("Lease") && (
            <AccordionWrapSm theme={theme}>
              <Accordion
                theme={theme}
                varient="filters"
                items={[
                  {
                    content: (
                      <FinanceRates
                        form={form}
                        popUpOpenState={""}
                        disabled={disabled}
                      />
                    ),
                    isExpanded: true,
                    title: "Finance Rates",
                    key: "string1",
                  },
                ]}
              />
            </AccordionWrapSm>
          )}
          {financeType.includes("Lease") && !financeType.includes("Finance") && (
            <AccordionWrapSm theme={theme}>
              <Accordion
                theme={theme}
                varient="filters"
                items={[
                  {
                    content: (
                      <LeaseRates
                        form={form}
                        popUpOpenState={""}
                        disabled={disabled}
                      />
                    ),
                    isExpanded: true,
                    title: "Lease Rates",
                    key: "string2",
                  },
                ]}
              />
            </AccordionWrapSm>
          )}
          {financeType.includes("Finance") && financeType.includes("Lease") && (
            <>
              <AccordionWrapSm theme={theme}>
                <Accordion
                  theme={theme}
                  varient="filters"
                  items={[
                    {
                      content: (
                        <FinanceRates
                          form={form}
                          popUpOpenState={""}
                          disabled={disabled}
                        />
                      ),
                      isExpanded: true,
                      title: "Finance Rates",
                      key: "string1",
                    },
                  ]}
                />
              </AccordionWrapSm>
              <AccordionWrapSm theme={theme}>
                <Accordion
                  theme={theme}
                  varient="filters"
                  items={[
                    {
                      content: (
                        <LeaseRates
                          form={form}
                          popUpOpenState={""}
                          disabled={disabled}
                        />
                      ),
                      isExpanded: true,
                      title: "Lease Rates",
                      key: "string2",
                    },
                  ]}
                />
              </AccordionWrapSm>
            </>
          )}
        </Box>
      )}
      {selectedPricingProgram === "Standard Charts" && (
        <Box theme={theme} className="inner-section">
          <AccordionWrapSm theme={theme}>
            <Accordion
              theme={theme}
              varient="filters"
              items={[
                {
                  content: (
                    <FinanceCharts
                      form={form}
                      popUpOpenState={""}
                      disabled={disabled}
                      setCompanyFieldState={() => {}}
                    />
                  ),
                  isExpanded: true,
                  title: "Charts",
                  key: "string3",
                },
              ]}
            />
          </AccordionWrapSm>
        </Box>
      )}
      {selectedPricingProgram === "External" && (
        <Controller
          name="pricing_program_provider"
          control={control}
          rules={{
            required: ValidationMessages.PRICING_PROGRAM_PROVIDER,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Pricing Program Provider"}
              placeholder={"Select"}
              items={[
                ...(pricingProviders?.providers?.map((provider) => ({
                  text: getProviderName(
                    pricingProviders?.integration_type,
                    provider?.provider_name
                  ),
                  value: provider.provider_name,
                })) || []),
              ]}
              disabled={disabled}
              value={value ?? []}
              onChange={onChange}
              selectError={errors?.pricing_program_provider?.message}
            />
          )}
        />
      )}
      <Controller
        name="credit_decisioning"
        defaultValue={"Internal"}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Credit Decisioning"}
            placeholder={"Select"}
            items={
              module === Module.DEALER_MODULE
                ? CREDIT_DECISION.filter(
                    (x) => x == "External" || x == value
                  ).map((credit) => ({
                    text: credit,
                    value: credit,
                  }))
                : CREDIT_DECISION.map((credit) => ({
                    text: credit,
                    value: credit,
                  }))
            }
            disabled={disabled}
            value={value ?? []}
            onChange={(e) => {
              onChange(e);
              setValue("credit_decisioning_provider", "");
            }}
          />
        )}
      />
      {selectedCreditDecision === "External" && (
        <Controller
          name="credit_decisioning_provider"
          control={control}
          defaultValue={"Internal"}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Credit Decisioning Provider"}
              placeholder={"Select"}
              items={[
                ...(creditDecisionProviders?.providers?.map((provider) => ({
                  text: getProviderName(
                    creditDecisionProviders?.integration_type,
                    provider?.provider_name
                  ),
                  value: provider.provider_name,
                })) || []),
              ]}
              value={value ?? []}
              disabled={disabled}
              onChange={onChange}
            />
          )}
        />
      )}
      <Controller
        name="e_contracting"
        control={control}
        defaultValue={"Internal"}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Contracting"}
            placeholder={"Select"}
            items={
              module === Module.DEALER_MODULE
                ? E_CONTRACTING.filter(
                    (x) => x == "Not Required" || x == value
                  ).map((e_contracting) => ({
                    text: e_contracting,
                    value: e_contracting,
                  }))
                : E_CONTRACTING.map((e_contracting) => ({
                    text: e_contracting,
                    value: e_contracting,
                  }))
            }
            value={value ?? []}
            disabled={disabled}
            onChange={(e) => {
              onChange(e);
              setValue("contracting_provider", "");
              setValue("signature_type", "");
              setValue("event", "");
            }}
          />
        )}
      />
      {(selectedContracting === "External & Internal" || selectedContracting === "External") && (
        <Controller
          name="contracting_provider"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Contracting Provider"}
              placeholder={"Select"}
              items={[
                ...(contractingProviders?.providers?.map((provider) => ({
                  text: getProviderName(
                    contractingProviders?.integration_type,
                    provider?.provider_name
                  ),
                  value: provider.provider_name,
                })) || []),
              ]}
              value={value ?? []}
              disabled={disabled}
              onChange={onChange}
            />
          )}
        />
      )}
      {(selectedContracting === "External & Internal"  || selectedContracting === "External") && (
        <Controller
          name="signature_type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Signature Type"}
              placeholder={"Select"}
              items={Object.values(StationeryType).map((provider) => ({
                text: provider,
                value: provider,
              }))}
              disabled={disabled}
              value={value ?? []}
              onChange={onChange}
            />
          )}
        />
      )}
      {(selectedContracting === "External & Internal" || selectedContracting === "External") && (
        <Controller
          name="event"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Event"}
              placeholder={"Select"}
              items={filteredEvents}
              disabled={disabled}
              value={value ?? []}
              onChange={onChange}
            />
          )}
        />
      )}
      <Controller
        name="default_lender"
        control={control}
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Default"}
            placeholder={"Select"}
            items={[
              { text: "Yes", value: true },
              { text: "No", value: false, disabled: true },
            ]}
            disabled={
              disabled ||
              (companyLenderExists && module === Module.DEALER_MODULE)
            }
            value={value ?? []}
            onChange={(e) => {
              !companyLenderExists && module === Module.LENDER_MODULE
                ? setValue("default_lender", true)
                : setConfirmationPopupState(true);
            }}
          />
        )}
      />
      <Controller
        name="credit_decision_expiry"
        control={control}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"00"}
            label={"Credit Decision Expiry (Optional)"}
            type="text"
            {...field}
            onChange={(val) => {
              const parsedValue = val ? parseInt(val || "") : val;
              field.onChange(isNaN(parsedValue) ? "" : parsedValue);
            }}
            disabled={disabled}
            value={field.value ?? ""}
            error={errors?.credit_decision_expiry?.message}
            endAdornment="days"
          />
        )}
      />
      <Controller
        name="is_active"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Status"}
            placeholder={"Select"}
            items={[
              { text: "Enabled", value: true },
              { text: "Disabled", value: false },
            ]}
            disabled={disabled}
            value={value ?? []}
            onChange={onChange}
          />
        )}
      />
      <TextDivider
        theme={theme}
        title={
          <>
            <Typography
              theme={theme}
              variant="subtitle1"
              component="span"
              mb={3}
              className="text-uppercase"
            >
              CONTACT PERSON DETAILS
            </Typography>
          </>
        }
      />
      <Controller
        name="contact_person_name"
        control={control}
        defaultValue={""}
        rules={{
          pattern: {
            value: /^[a-zA-Z\s]+$/,
            message: ValidationMessages.CONTACT_PERSON_NAME_VALIDATION,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Name (Optional)"}
            type="text"
            value={value?.trimStart()}
            onChange={onChange}
            disabled={disabled}
            error={errors?.contact_person_name?.message}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        defaultValue={""}
        rules={{
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: ValidationMessages.INVALID_EMAIL,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Email Address (Optional)"}
            type="text"
            value={value?.trimStart()}
            onChange={onChange}
            disabled={disabled}
            error={errors?.email?.message}
          />
        )}
      />
      <Controller
        name="contact_person_phone_number"
        control={control}
        defaultValue={""}
        render={({ field: { onChange, value } }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Contact Number (Optional)"}
            type="text"
            value={value?.trimStart()}
            onChange={onChange}
            disabled={disabled}
            error={errors?.contact_person_phone_number?.message}
          />
        )}
      />
      {creditTierMargins?.map((item: ICreditTierMargin, index: number) => {
        item.is_active !== false && displayIndex++;
        return (
          item.is_active !== false && (
            <AccordionWrapSm theme={theme} key={index}>
              <Accordion
                theme={theme}
                varient="filters"
                items={[
                  {
                    content: (
                      <CreditTier
                        form={form}
                        popUpOpenState={""}
                        index={index}
                        disabled={disabled}
                      />
                    ),
                    isExpanded: true,
                    title: `Credit Tier Margin ${displayIndex}`,
                    key: `credit-margin-${index}`,
                    actionBtn: (
                      <>
                        <Button
                          theme={theme}
                          defaultBtn
                          onClick={() => handleDeleteItem(index)}
                          iconText={<Icon name="DeleteIcon" />}
                        />
                      </>
                    ),
                  },
                ]}
              />
            </AccordionWrapSm>
          )
        );
      })}
      <Box theme={theme} sx={{ mt: creditTierMargins?.length > 0 ? 0 : 3 }}>
        <Button
          theme={theme}
          secondary
          disabled={disabled}
          text="Add Credit Tier Margin"
          fullWidth
          onClick={handleAddItem}
        />
      </Box>
      <ConfirmationDialog
        openPopUp={confirmationPopupState}
        onConfirm={onSaveConfirm}
        setPopUpState={setConfirmationPopupState}
        confirmationTitle={DialogMessages.lenderConfirmationTitle}
        confirmationText={DialogMessages.confirmationLenderText}
        primaryBtnText={DialogMessages.updateBtnText}
        icon={<Icon name="DialogAlertIcon" />}
      />
    </AddNewLenderWrap>
  );
};
