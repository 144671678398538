import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { NewCompanyRebate } from "components";
import { ActionOptions } from "Enums";
import { IImage, IRebate } from "Interface";
import RebateTable from "./rebate-table";
import { IRebateProps } from "./rebate.type";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

const Rebate: FC<IRebateProps> = () => {
  const [tradingAgeState, setTradingAgeState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Rebate");
  const [state, dispatch] = useSetupsStore();
  const [images, setImages] = useState<Array<IImage>>([]);
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IRebate>(undefined);

  useEffect(() => {
    if (tradingAgeState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [tradingAgeState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Rebate");
        setActionData(value);
        setTradingAgeState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IRebate) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Rebate");
    setActionData(formData);
    setTradingAgeState(true);
    dispatch(setOpenDrawer(true));
  };
  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <RebateTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Rebate"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {tradingAgeState && (
          <NewCompanyRebate
            openPopUp={tradingAgeState}
            setPopUpState={setTradingAgeState}
            title={actionTitle}
            actionType={actionType}
            tradingAgeData={actionData}
            setImages={setImages}
            images={images}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default Rebate;
