import React, { FC } from "react";
import { useTheme, Link } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Icon, Menu, Switch } from "@ntpkunity/controls";
import { useUpdateBundleProductStatus } from "services/financial-insurance.service";
import { ActionOptions } from "Enums";

const ProductRow: FC<{
  productRow: any;
  bundle_id: number;
  dealer_code?: any;
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  handleImage: (data) => unknown;
  handleSelection: any;
}> = ({ productRow, bundle_id, handleImage, handleSelection }) => {
  const theme = useTheme();
  const { mutate: updateBundleProductstatus } = useUpdateBundleProductStatus();

  return (
    <React.Fragment>
      <TableRow className="child-tr">
        <TableCell id={"image" + productRow?.product_name} className="img-cell">
          {productRow?.image?.length > 0 && (
            <Link
              onClick={() => {
                handleImage(productRow?.image);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                className="prd-img"
                src={productRow.image[0]?.location}
                alt="Car"
              />
            </Link>
          )}
        </TableCell>
        <TableCell className="indent-cell">
          <Box theme={theme} ml={6}>
            {productRow?.product_name}
          </Box>
        </TableCell>
        <TableCell>{productRow?.description}</TableCell>
        <TableCell></TableCell>
        <TableCell>
          <Switch
            theme={theme}
            varient={"basic"}
            switchEnabled={productRow?.taxable}
            label={productRow?.taxable ? "Yes" : "No"}
            disabled={true}
          />
        </TableCell>
        <TableCell>
          {productRow?.applicable_finance_type
            .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
            .join(", ")}
        </TableCell>
        <TableCell>
          <Switch
            theme={theme}
            varient={"basic"}
            switchEnabled={productRow?.is_active}
            label={productRow?.is_active ? "Enabled" : "Disabled"}
            onChange={(event) => {
              updateBundleProductstatus({
                bundle_id: bundle_id,
                product_id: productRow?.id,
                is_active: event.target.checked,
              });
            }}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="DeleteIcon" />
                    {ActionOptions.DELETE}
                  </>
                ),
                optionkey: ActionOptions.DELETE,
                optionValue: {
                  bundle_id: bundle_id,
                  product_id: productRow?.id,
                },
              },
            ]}
            handleOptionClick={handleSelection}
            render={(cb) => (
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ProductRow;
