import { useTheme } from "@mui/material";
import {
  Input,
  MultiSelect,
  Select,
  Icon,
  Box,
  IBoxProps,
  Tooltip,
  FileDragDrop,
  FilePreview,
  CircleLoader,
  ImageViewer,
} from "@ntpkunity/controls";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  ActionOptions,
  Module,
  QueryKeys,
  StationeryType,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { ConfirmationDialog, FileDragDropWrap } from "@sharedComponents";
import {
  UseMutation_deleteS3Image,
  UseMutation_uploadS3Image,
  UseQuery_GetAllFinanceTypes,
  UseQuery_getAllDocumentTypes,
} from "services";
import {
  UseQuery_getAllStates,
  useGetContractTerms,
} from "services/dealer-configurations.service";
import { useQueryClient } from "react-query";
import { styled } from "@mui/material/styles";
import {
  IContractTerms,
  IDocumentTypes,
  IFinanceTypeModel,
  IImage,
  IStates,
} from "Interface";

const AddNewLenderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-lender-wrap": {
    ".inner-section": {
      borderBottom: "1px solid" + theme.palette.grey[100],
      marginBottom: 24,
      ".u-custom-autocomplete": {
        marginBottom: 0,
      },
    },
    ".divider": {
      span: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.customVariables.fontWeightSemiBold,
      },
    },
  },
}));

const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "relative !important",
      transform: "initial !important",
    },
  },
}));

export const AddNewRebate: FC<{
  form: any;
  popUpOpenState: string;
  module?: Module;
  setImages;
  images;
}> = ({ form, popUpOpenState, module, setImages, images }) => {
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = form;
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);

  const onSaveConfirm = () => {
    setConfirmationPopupState(false);
  };
  UseQuery_GetAllFinanceTypes();
  UseQuery_getAllStates();
  UseQuery_getAllDocumentTypes();
  const { mutate: getContractTerms, data: contractTermsData } =
    useGetContractTerms();
  const { mutate: uploadImage } = UseMutation_uploadS3Image();
  const { mutate: deleteImage } = UseMutation_deleteS3Image();
  const [states, setStates] = useState([]);
  useEffect(() => {
    getContractTerms();
  }, []);
  const available_finance: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const usaStates: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );
  const documentTypes: Array<IDocumentTypes> = queryClient.getQueryData(
    QueryKeys.GET_ALL_DOCUMENT_TYPES
  );
  useEffect(() => {
    if (usaStates) {
      const sortedStates = [...usaStates].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setStates(
        sortedStates?.map((x) => {
          return {
            text: x.name,
            value: x.id,
          };
        })
      );
    }
  }, [usaStates]);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [sliderImages, setSilderImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);
  const onimageDrop = (e: any) => {
    setImageLoader(true);
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };
  const onimageUpload = (e: any) => {
    setImageLoader(true);
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };
  const onRemoveFile = (fileKey: string, index: number) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      setImageLoader(true);
      deleteImage(obj, {
        onSuccess(response) {
          const imageArray = images;
          imageArray.splice(index, 1);
          setImages([...imageArray]);
          setImageLoader(false);
        },
      });
    }
  };
  const onClickIcon = (indexNumber) => {
    const image_url = images?.map((image) => {
      return image?.location;
    });
    setIndex(indexNumber);
    setSilderImages(image_url);
    setVisibility(true);
  };
  const handleClose = () => {
    setVisibility(false);
    setIndex(0);
  };
  return (
    <AddNewLenderWrap theme={theme} className="add-lender-wrap">
      <Controller
        name="rebate_type"
        control={control}
        rules={{
          required: ValidationMessages.REBATE_TYPE,
        }}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <Select
            id="RebateType"
            name="rebate_type"
            theme={theme}
            label={"Rebate Type"}
            items={[
              {
                text: "Dealer Rebate",
                value: "Dealer Rebate",
              },
              {
                text: "EV Tax Credit",
                value: "EV Tax Credit",
              },
            ]}
            value={value ?? []}
            onChange={(e) => {
              onChange(e);
            }}
            selectError={errors?.rebate_type?.message as string}
          />
        )}
      />
      <Controller
        name="rebate_name"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.REBATE_NAME_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Rebate Name"}
            type="text"
            {...field}
            error={errors?.rebate_name?.message}
          />
        )}
      />
      <Controller
        name="rebate_description"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.REBATE_DESCRIPTION_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Rebate Description (Optional)"}
            type="text"
            {...field}
            error={errors?.rebate_description?.message}
          />
        )}
      />
      <Controller
        name="rebate_code"
        control={control}
        defaultValue={""}
        rules={{
          maxLength: {
            value: 5,
            message: ValidationMessages.EXTERNAL_CODE_MAX_LENGTH,
          },
          required: ValidationMessages.REBATE_CODE_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Rebate Code"}
            type="text"
            {...field}
            error={errors?.rebate_code?.message}
          />
        )}
      />
      <Controller
              name="rebate_amount"
              control={control}
              defaultValue={""}
              rules={{
                required: ValidationMessages.REBATE_AMOUNT_REQUIRED,
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here.."}
                  label={"Rebate Amount"}
                  type="text"
                  {...field}
                  value={
                    field.value ? parseFloat(field.value) : undefined
                  }
                  startAdornment={
                    <>
                      <span className="adornment-text">
                        {defaultCurrency}
                      </span>
                    </>
                  }
                  masking
                  // scale={2}
                  maskDecimalScale={2}
                  maskNumeric
                  // numeric
                  error={errors?.rebate_amount?.message}
                />
              )}
            />
      <Controller
        name="required_documents"
        control={control}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <MultiSelect
            id="requiredDocuments"
            name="required_documents"
            theme={theme}
            label={"Required Documents (Optional)"}
            items={documentTypes?.map((item) => {
              return {
                text: item.description,
                value: item.description,
              };
            })}
            value={value ?? []}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="finance_type"
        control={control}
        rules={{
          required: ValidationMessages.Finance_type_required,
        }}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <MultiSelect
            id="financeType"
            name="finance_type"
            theme={theme}
            label={"Finance Type"}
            items={available_finance?.map((item) => {
              return { text: item.name, value: item.name };
            })}
            value={value ?? []}
            onChange={onChange}
            selectError={errors?.finance_type?.message as string}
          />
        )}
      />
      <Controller
        name="payment_term"
        control={control}
        rules={{
          required: ValidationMessages.PAYMENT_TERM_REQUIRED,
        }}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <MultiSelect
            id="paymentTerm"
            name="payment_term"
            theme={theme}
            label={"Payment Term"}
            items={contractTermsData
              ?.filter((x) => {
                return x.is_active;
              })
              ?.map((term) => {
                return {
                  text: term.term,
                  value: term.id,
                };
              })}
            value={value ?? []}
            onChange={onChange}
            selectError={errors?.payment_term?.message as string}
          />
        )}
      />
      <Controller
        name={"applicable_states"}
        control={control}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <MultiSelect
            id="applicableStates"
            name="applicable_states"
            theme={theme}
            label={"Applicable States (Optional)"}
            items={states}
            value={value ?? []}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name="is_active"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Status"}
            placeholder={"Select"}
            items={[
              { text: "Enabled", value: true },
              { text: "Disabled", value: false },
            ]}
            value={value ?? []}
            onChange={onChange}
          />
        )}
      />
      <FileDragDropWrap className="mb-24">
        <Controller
          name="rebate_images"
          control={control}
          render={({ field }) => (
            <Tooltip theme={theme} title={""}>
              <FileDragDrop
                {...field}
                hoverLabel="Drag and drop product images, or browse"
                width="300"
                height="200px"
                backgroundColor="white"
                onDrop={onimageDrop}
                onChange={onimageUpload}
                theme={theme}
              />
            </Tooltip>
          )}
        />
        <FilePreview
          files={images?.length > 0 ? images : []}
          onRemoveFile={onRemoveFile}
          theme={theme}
          viewIcon={true}
          onClickIcon={onClickIcon}
          preview="image"
        />
        {imageLoader && (
          <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
            <CircleLoader theme={theme} size="xs" />
          </ImgLoader>
        )}
      </FileDragDropWrap>
      <ConfirmationDialog
        openPopUp={confirmationPopupState}
        onConfirm={onSaveConfirm}
        setPopUpState={setConfirmationPopupState}
        confirmationTitle={DialogMessages.lenderConfirmationTitle}
        confirmationText={DialogMessages.confirmationLenderText}
        primaryBtnText={DialogMessages.updateBtnText}
        icon={<Icon name="DialogAlertIcon" />}
      />
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={sliderImages}
        isLoading={false}
        visible={visibility}
        onClose={handleClose}
        overlayCheck={true}
        index={index}
        setIndex={setIndex}
      />
    </AddNewLenderWrap>
  );
};
