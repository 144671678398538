import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Main, PageHeader } from "@sharedComponents";
import { LayoutWithSideNav } from "layout";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { DuoTab, Typography } from "@ntpkunity/controls";
import { Lenders, Markup } from "modules";

const LenderManagement: FC = () => {
  const theme = useTheme();
  const [state] = useSetupsStore();
  const { openDrawerSt } = state;
  const [popUpState] = useState(false);
  useEffect(() => {}, [openDrawerSt]);

  const tabs = [
    {
      title: "Lenders",
      content: <Lenders />,
    },
    {
      title: "Markup",
      content: <Markup />,
    },
  ];
  return (
    <Main open={openDrawerSt ? openDrawerSt : false}>
      <LayoutWithSideNav theme={theme}>
        <PageHeader theme={theme} className="main-page-header border-bottom">
          <Typography
            variant="h2"
            component="h2"
            rowGap={5}
            data-testid="heading"
            theme={theme}
            children="Lender Management"
          />
        </PageHeader>
        <DuoTab
          theme={theme}
          varient={"underline"}
          items={tabs}
          defaultTabIndex={0}
        />
      </LayoutWithSideNav>
    </Main>
  );
};

export default LenderManagement;
