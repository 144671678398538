import { useState, FC, useEffect } from "react";
import { InputAdornment, styled, useTheme } from "@mui/material";
import {
  Box,
  Button,
  Grid,
  Dialog,
  DuoTab,
  Icon,
  Input,
  unityTheme,
} from "@ntpkunity/controls";
import { useUpdateVehicleDetailByVid } from "services/inventory-management.service";
import { useStoreContext } from "Stores/SetupStore/Store";
import { InstallationMode, QueryKeys } from "Enums";
import AddNewInventoryOption from "./add-remove-options";
import { IAddOns, IVehicleDetail } from "Interface";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const StickyDuoTab = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "inline-block",
  ".tab-list-underline": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? unityTheme.palette.grey[800]
        : [theme.palette.common.white],
    position: "sticky",
    top: -40,
    left: 0,
    zIndex: 1,
    width: "100%",
  },
  ".TabPanelUnstyled-root": {
    overflow: "hidden",
    paddingTop: 16,
    marginTop: -16,
  },
}));
const Total = styled(Box)(({ theme }) => ({
  "&.total": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? unityTheme.palette.grey[800]
        : [theme.palette.common.white],
    position: "sticky",
    left: 0,
    bottom: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900],
    zIndex: 100,
    ".primary": {
      color: theme.palette.primary.main,
    },
    ".spr": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.divider,
      height: 1,
    },
  },
}));
const InventoryOptionPopup: FC<{
  openPopup: boolean;
  vehicleData: IVehicleDetail;
  setopenOptionDialog: any;
  optionData: IAddOns[];
  dealer_code: string;
  setIsChangesSaved: any;
}> = ({
  openPopup,
  vehicleData,
  setopenOptionDialog,
  optionData,
  dealer_code,
  setIsChangesSaved,
}) => {
  const { actions, states } = useStoreContext();
  const { mutate: updateInventoryOption } = useUpdateVehicleDetailByVid();

  interface IFormInputs {
    search: string;
  }
  const { control } = useForm<IFormInputs>();
  const theme = useTheme();
  const [listChanged, setlistChanged] = useState(false);
  const [finalOption, setfinalOption] = useState(vehicleData?.dealer_options);
  const [addOption, setAddOption] = useState<any[]>(
    vehicleData?.dealer_options
  );
  useEffect(() => {
    if (finalOption) {
      const uniqueValues = finalOption?.filter(
        (value) => !addOption?.includes(value)
      );
      setAddOption((prevAddOption) => [...prevAddOption, ...uniqueValues]);
    }
  }, [finalOption]);
  const queryClient = useQueryClient();
  const handleCloseOptionDialog = () => {
    setAddOption(vehicleData.dealer_options);
    setopenOptionDialog(false);
  };
  const handleOptionData = () => {
    vehicleData.dealer_options = addOption;
  };
  const unique = [
    ...Array.from(
      new Set(
        optionData
          ?.filter((x) => x.is_active == true)
          ?.map((item: any) => item.category_name)
      )
    ),
  ];

  const preinstalled = [
    {
      title: "Pre-Installed",
      content: (
        <AddNewInventoryOption
          addedOption={addOption}
          setAddedOption={setAddOption}
          setfinalOption={setfinalOption}
          setcategory={optionData?.filter((item: IAddOns) => {
            return item.category_name
              ? item.installation_mode == "Pre-Installed" &&
                  item.is_active == true
              : null;
          })}
          vehicleData={vehicleData}
          setlistChanged={setlistChanged}
          dealer_code={dealer_code}
        />
      ),
    },
  ];
  const categories_data = unique?.map((result: any) => {
    {
      return {
        title: result,
        content: (
          <AddNewInventoryOption
            addedOption={addOption}
            setAddedOption={setAddOption}
            setfinalOption={setfinalOption}
            setcategory={optionData?.filter((item: IAddOns) => {
              return item.category_name
                ? item.category_name == result &&
                    item.installation_mode !== InstallationMode.PRE_INSTALLED &&
                    item.is_active == true
                : null;
            })}
            vehicleData={vehicleData}
            setlistChanged={setlistChanged}
            dealer_code={dealer_code}
          />
        ),
      };
    }
  });
  const tabs = preinstalled.concat(categories_data);

  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  return (
    <Dialog
      variant={undefined}
      size="xl"
      title="Dealer Add-Ons"
      open={openPopup}
      onCloseDialog={handleCloseOptionDialog}
      disablePortal
      theme={theme}
      customFooter={
        <>
          <Grid theme={theme} item xs={6}>
            <Button
              primary
              theme={theme}
              id="saveChangesDialog"
              text="Save Changes"
              onClick={(_e: any) => {
                setIsChangesSaved(true);
                setopenOptionDialog(false);
                handleOptionData();
                updateInventoryOption(vehicleData, {
                  onSuccess(response: any) {
                    actions.setToast({
                      toastMessage: "Record Updated Successfully",
                      toastState: true,
                    });
                    queryClient.invalidateQueries(
                      QueryKeys.GET_ALL_INVENTORY_FILTER
                    );
                    actions.setAssetInventoryInitialData(response);
                  },
                  onError(error: any) {
                    actions.setToast({
                      toastMessage: error.error.toString(),
                      toastState: true,
                      variant: "error",
                    });
                  },
                });
              }}
              disabled={!listChanged}
            />
          </Grid>
        </>
      }
    >
      <StickyDuoTab theme={theme}>
        <DuoTab
          theme={theme}
          varient={"underline"}
          items={tabs}
          defaultTabIndex={0}
        />
      </StickyDuoTab>
      <Total theme={theme} className="total" sx={{ pt: 2 }}>
        <Grid theme={theme} spacing={1} container>
          <Grid theme={theme} item xs={6}>
            Total
          </Grid>
          <Grid
            theme={theme}
            id="priceSingle"
            item
            xs={6}
            className="primary"
            textAlign="right"
          >
            {" "}
            {defaultCurrency}
            {optionData
              ?.filter(
                (option: IAddOns) =>
                  addOption?.includes(option.id) &&
                  option.is_active === true &&
                  !option?.is_price_inclusive
              )
              .reduce((prev, next) => prev + next.price, 0)}
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Box theme={theme} className="spr" />
          </Grid>
        </Grid>
      </Total>
    </Dialog>
  );
};

export default InventoryOptionPopup;
