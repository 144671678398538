import { FC, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { TablePagination, useTheme, Link } from "@mui/material";
import ChevronDown from "../../shared/assets/images/chevron-down";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  Menu,
  Button,
  Input,
  Box,
  Switch,
  DataTable,
  ImageViewer,
} from "@ntpkunity/controls";
import {
  DataTableWrap,
  ConfirmationDialog,
  PaginationWrap,
} from "@sharedComponents";
import {
  useAddonsAccessories,
  useDeleteAddonById,
  useGetAddonById,
  useUpdateAddOn,
  UseQuery_getAllOptions_filter,
} from "services/options.service";
import { useGetProfilebyDealerId } from "services/dealer-configurations.service";
import { UseQuery_GetAllAssetConditions } from "services/asset-condition.service";
import { UseQuery_getAllOptionCategorys } from "services/order-category.service";
import {
  IAddOns,
  IAddOnsFilter,
  IAssetCondition,
  IOptionCategory,
  ISelectItemList,
  IImage,
} from "Interface";
import { DialogMessages, QueryKeys, ToastMessages, Vehicle_Type } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";

const OptionsTable: FC<{
  onEdit: (data: any) => unknown;
  dealer_code: string;
  isEditMode: boolean;
}> = ({ onEdit, dealer_code, isEditMode }) => {
  const { states, actions } = useStoreContext();
  const { mutate: getAddonAccessories } = useAddonsAccessories();
  const { mutate: updateDealerAddon } = useUpdateAddOn();
  const { mutate: deleteDealerAddon } = useDeleteAddonById();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState("");

  const { mutate: getAddonById } = useGetAddonById();

  const [product_nameFilter, setProduct_nameFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [vehicle_typeFilter, setVehicle_typeFilter] = useState("");
  const [offered_byFilter, setOffered_byFilter] = useState("");
  const [supplierFilter, setSupplierFilter] = useState("");
  const [part_noFilter, setPart_noFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [installation_modeFilter, setInstallation_modelFilter] = useState("");
  const [compatible_modelsFilter, setCompatible_modelsFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [rvFilter, setRvFilter] = useState("");
  const [images, setImages] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [index, setIndex] = useState(0);
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `dealer_code=${dealer_code}&page_number=${pgNo}&page_size=${pgSize}`;
    if (
      product_nameFilter.trimStart() != null &&
      product_nameFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&product_name=${product_nameFilter}`);
    }
    if (
      descriptionFilter.trimStart() != null &&
      descriptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&description=${descriptionFilter}`);
    }
    if (
      categoryFilter.trimStart() != null &&
      categoryFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&category=${categoryFilter}`);
    }
    if (
      vehicle_typeFilter.trimStart() != null &&
      vehicle_typeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&vehicle_type=${vehicle_typeFilter}`);
    }
    if (priceFilter) {
      query_string = query_string.concat(`&price=${priceFilter}`);
    }
    if (rvFilter) {
      query_string = query_string.concat(`&rv_adder=${rvFilter}`);
    }
    if (
      offered_byFilter.trimStart() != null &&
      offered_byFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&offered_by=${offered_byFilter}`);
    }
    if (
      installation_modeFilter.trimStart() != null &&
      installation_modeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&installation_mode=${installation_modeFilter}`
      );
    }
    if (
      compatible_modelsFilter.trimStart() != null &&
      compatible_modelsFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&compatible_models=${compatible_modelsFilter}`
      );
    }
    if (
      supplierFilter.trimStart() != null &&
      supplierFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&supplier=${supplierFilter}`);
    }
    if (part_noFilter.trimStart() != null && part_noFilter.trimStart() != "") {
      query_string = query_string.concat(`&part_no=${part_noFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    }
  }, [dealer_code]);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [rowId, setRowId] = useState<number>();

  const queryClient = useQueryClient();
  UseQuery_GetAllAssetConditions();
  const { data } = UseQuery_getAllOptions_filter(columnFilters);
  const dealerOptionsFilter: IAddOnsFilter = data;
  const AssetCondition: Array<IAssetCondition> = queryClient.getQueryData(
    QueryKeys.Get_All_Asset_Conditions
  );
  const {
    mutate: getDealerProfile,
    data: dealerProfileData,
    isLoading,
  } = useGetProfilebyDealerId();

  UseQuery_getAllOptionCategorys();
  const optionCategoryData: Array<IOptionCategory> = queryClient.getQueryData(
    QueryKeys.GET_ALL_OPTION_CATEGORIES
  );
  const [categoryDropDownData, setCategoryDropDownData] =
    useState<Array<ISelectItemList>>();

  useEffect(() => {
    queryClient.invalidateQueries(QueryKeys.GET_ALL_OPTION_CATEGORIES);
    setCategoryDropDownData(
      optionCategoryData?.map((currentOptionCategory) => ({
        value: currentOptionCategory.id,
        text: currentOptionCategory.description,
      }))
    );
  }, [optionCategoryData]);
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const onDeleteConfirm = (e: any) => {
    deleteDealerAddon(
      { id: rowId, dealer_code },
      {
        onSuccess: () => {
          actions.setToast({
            toastMessage: ToastMessages.Record_Deleted_Success,
            toastState: true,
          });
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  const handleImage = (imagesData) => {
    const image_url = imagesData?.map((image) => {
      return image?.location;
    });

    setImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);

  useEffect(() => {
    if (isEditMode) {
      queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_OPTIONS_FILTER);
    }
  }, [isEditMode]);

  const theme = useTheme();

  return (
    <>
      <DataTableWrap theme={theme} className="table-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          sx={{ mb: 3 }}
          theadChildren={
            <>
              <TableRow>
                {/* <TableCell className="checkbox-cell fixed-left">
                <Checkbox label=""></Checkbox>
              </TableCell> */}
                <TableCell align="center" valign="middle" className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Vehicle Condition</TableCell>
                <TableCell>Offered By</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Part No.</TableCell>
                <TableCell>Installation Mode</TableCell>
                <TableCell>Compatible Models</TableCell>
                <TableCell>Price</TableCell>
                <TableCell> RV On Option</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                {/* <TableCell className="checkbox-cell"></TableCell> */}
                <TableCell className="img-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="productNameSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setProduct_nameFilter(value);
                      }}
                      value={product_nameFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="productNameSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="descriptionSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setDescriptionFilter(value);
                      }}
                      value={descriptionFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="descriptionSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="categorySearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setCategoryFilter(value);
                      }}
                      value={categoryFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="categorySearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="statusSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setStatusFilter(value);
                      }}
                      value={statusFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="statusSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="vehicleConditionSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setVehicle_typeFilter(value);
                      }}
                      value={vehicle_typeFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="vehicleConditionSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="offeredBySearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setOffered_byFilter(value);
                      }}
                      value={offered_byFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="offeredBySearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="supplierSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setSupplierFilter(value);
                      }}
                      value={supplierFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="supplierSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="partNoSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setPart_noFilter(value);
                      }}
                      value={part_noFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="partNoSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="installationModesearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setInstallation_modelFilter(value);
                      }}
                      value={installation_modeFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="installationModesearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="compatibleAssetSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setCompatible_modelsFilter(value);
                      }}
                      value={compatible_modelsFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="compatibleAssetSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="priceSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="number"
                      onChange={(value) => {
                        setPriceFilter(value);
                      }}
                      value={priceFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="priceSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="RVSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="number"
                      onChange={(value) => {
                        setRvFilter(value);
                      }}
                      value={rvFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    ></Input>
                    <Button
                      theme={theme}
                      defaultBtn
                      id="RVSearchFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {dealerOptionsFilter?.result &&
                dealerOptionsFilter.result.map(
                  (addOnDetail: IAddOns, index: number) => {
                    return (
                      <TableRow key={index} className="child-tr">
                        {/* <TableCell className="checkbox-cell fixed-left">
                        <Checkbox label=""></Checkbox>
                      </TableCell> */}
                        <TableCell className="img-cell">
                          {addOnDetail?.image?.length > 0 && (
                            <Link
                              onClick={() => {
                                handleImage(addOnDetail.image);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                className="prd-img"
                                src={addOnDetail.image[0]?.location}
                                alt="Car"
                              />
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>{addOnDetail?.product_name}</TableCell>
                        <TableCell>{addOnDetail?.description}</TableCell>
                        <TableCell>
                          {categoryDropDownData?.find(
                            (x) => x.value == addOnDetail?.category_id
                          )?.text ?? "-"}
                        </TableCell>

                        <TableCell>
                          <Switch
                            theme={theme}
                            varient={"basic"}
                            label={
                              addOnDetail.is_active ? "Enabled" : "Disabled"
                            }
                            switchEnabled={addOnDetail.is_active}
                            onChange={(event) => {
                              updateDealerAddon(
                                {
                                  ...addOnDetail,
                                  compatible_models:
                                    addOnDetail.compatible_models.map(
                                      (trim) => trim.id
                                    ),
                                  is_active: event.target.checked,
                                },
                                {
                                  onSuccess: () => {
                                    actions.setToast({
                                      toastMessage:
                                        ToastMessages.Record_Updated_Success,
                                      toastState: true,
                                    });
                                  },
                                  onError: (error: any) => {
                                    actions.setToast({
                                      toastMessage: error.error.toString(),
                                      toastState: true,
                                      variant: "error",
                                    });
                                  },
                                }
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {AssetCondition &&
                            AssetCondition.filter(
                              (x) => x.id == addOnDetail?.vehicle_type
                            ).map((asset) => " " + asset.description)}
                        </TableCell>
                        <TableCell>{addOnDetail?.offered_by}</TableCell>
                        <TableCell>{addOnDetail?.supplier}</TableCell>
                        <TableCell>{addOnDetail?.part_no}</TableCell>
                        <TableCell>{addOnDetail.installation_mode}</TableCell>
                        <TableCell>
                          {addOnDetail?.compatible_models.map((model, index) =>
                            index > 0
                              ? ", " + (model?.model_name ?? model?.text)
                              : model?.model_name ?? model?.text
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Box theme={theme} sx={{ mr: 4.3 }}>
                            {defaultCurrency}{" "}
                            {addOnDetail?.price.toFixed(2).toLocaleString()}{" "}
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Box theme={theme} sx={{ mr: 4.3 }}>
                            {defaultCurrency}{" "}
                            {addOnDetail?.rv_adder.toFixed(2).toLocaleString()}{" "}
                          </Box>
                        </TableCell>
                        <TableCell className="action-cell fixed-cell">
                          <Menu
                            theme={theme}
                            options={[
                              {
                                optionText: "Edit",
                                optionValue: "edit",
                              },
                              {
                                optionText: "Delete",
                                optionValue: "delete",
                              },
                            ]}
                            handleOptionClick={(_event, _key, value) => {
                              switch (value) {
                                case "delete":
                                  setRowId(addOnDetail.id);
                                  setConfirmationPopupState(true);
                                  break;
                                case "edit":
                                  onEdit(addOnDetail);
                                  break;
                              }
                            }}
                            render={(onMenuSelection) => (
                              <Button
                                theme={theme}
                                defaultBtn
                                iconText={<Icon name="MoreIcon" />}
                                onClick={onMenuSelection}
                              />
                            )}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </>
          }
        />
        <PaginationWrap>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={dealerOptionsFilter ? dealerOptionsFilter.total_results : -1}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: ChevronDown,
              MenuProps: { disablePortal: true },
            }}
          />
        </PaginationWrap>
      </DataTableWrap>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={images}
        isLoading={false}
        singleTriggerNode={true}
        visible={visibility}
        overlayCheck={true}
        onClose={handleClose}
        index={index}
        setIndex={setIndex}
      />
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default OptionsTable;
