import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { IAutocompleteProps, IOption } from "./Autocomplete.types";
import clsx from "clsx";
import FormHelperText from "@mui/material/FormHelperText";
import { Icon, muiTheme, unityLightTheme } from "@ntpkunity/controls";

const AutocompleteWrap = styled(
  "div",
  {}
)<Partial<IAutocompleteProps>>(({ theme }) => ({
  "&.u-custom-autocomplete": {
    marginBottom: 24,
    ".MuiAutocomplete-root": {
      ".u-form-group": {
        ".MuiInputBase-root.MuiInputBase-formControl": {
          minHeight: 48,
          color: "rgba(0,0,0,0.87)",
          borderRadius: theme.shape.borderRadius,
          padding: "10px 16px 5px 16px",
          fontFamily: theme.typography.fontFamily,
          ".MuiInputBase-input": {
            padding: "12px 16px",
            color:
              theme.palette.mode === "light"
                ? "rgba(0,0,0,0.87)"
                : "rgba(255,255,255,0.87)",
            borderRadius: theme.shape.borderRadius,
          },
          "&:hover": {
            fieldset: {
              borderColor:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : theme.palette.grey[600],
            },
          },
          "&.Mui-focused": {
            fieldset: {
              borderColor: theme.palette.primary.main,
            },
          },
          "&.MuiInputBase-adornedStart": {
            ".MuiInputBase-input": {
              padding: "12px 16px 12px 0px",
            },
            fieldset: {
              legend: {
                maxWidth: 0,
              },
            },
            "&.Mui-focused": {
              fieldset: {
                legend: {
                  maxWidth: "100%",
                },
              },
            },
          },
          "&.MuiInputBase-adornedEnd": {
            ".MuiInputBase-input": {
              padding: 0,
            },
          },
          "&.Mui-error": {
            fieldset: {
              borderWidth: 2,
              borderColor: theme.palette.error.main,
            },
            "&:hover": {
              fieldset: {
                borderColor: theme.palette.error.main,
              },
            },
            "&.Mui-focused": {
              fieldset: {
                borderColor: theme.palette.error.main,
              },
            },
            "~": {
              ".MuiInputLabel-formControl": {
                color: theme.palette.error.main,
                "&.Mui-focused": {
                  color: theme.palette.error.main,
                },
                "&.MuiFormLabel-filled": {
                  color: theme.palette.error.main,
                },
              },
            },
          },
          ".MuiInputAdornment-root": {
            ".MuiButtonBase-root": {
              "&:hover": {
                backgroundColor: "transparent",
              },
              ".MuiTouchRipple-root": {
                display: "none",
              },
            },
          },
          "&.Mui-disabled": {
            ".MuiFormLabel-root, .MuiOutlinedInput-notchedOutline, .MuiInputBase-input, .MuiInputAdornment-root":
              {
                color: "rgba(0, 0, 0, 0.38)",
                cursor: "not-allowed",
              },
            "svg, .MuiInputAdornment-root svg": {
              opacity: 0.3,
            },
            fieldset: {
              borderColor: "rgba(0, 0, 0, 0.12)",
            },
          },
          ".MuiButtonBase-root": {
            backgroundColor: "transparent",
            color:
              theme.palette.mode === "light"
                ? [theme.palette.common.black]
                : [theme.palette.common.white],
            "&.MuiAutocomplete-popupIndicator": {
              "svg.MuiSvgIcon-root": {
                fill: "none",
                width: 24,
                height: 24,
                path: {
                  d: 'path("M6 9L12 15L18 9")',
                  stroke:
                    theme.palette.mode === "light"
                      ? theme.palette.grey[300]
                      : theme.palette.grey[200],
                  strokeWidth: "2",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                },
              },
            },
            "&.MuiAutocomplete-clearIndicator": {
              display: "none",
            },
            ".MuiTouchRipple-root, .MuiChip-deleteIcon": {
              display: "none",
            },
            "&.MuiAutocomplete-tag": {
              margin: 0,
              paddingRight: 3,
              height: "auto",
              ".MuiChip-label": {
                paddingRight: 0,
                paddingLeft: 0,
                "&:after": {
                  display: "inline-block",
                  content: "','",
                },
              },
              "&:last-of-type, &:nth-last-child(-n + 4)": {
                ".MuiChip-label": {
                  "&:after": {
                    display: "none",
                  },
                },
              },
            },
          },
        },
        "&.capitalize-label": {
          ".MuiInputLabel-formControl": {
            textTransform: "capitalize",
          },
        },
        "&.uppercase-label": {
          ".MuiInputLabel-formControl": {
            textTransform: "uppercase",
          },
        },
        ".MuiInputLabel-formControl": {
          color:
            theme.palette.mode === "light"
              ? "rgba(0,0,0,0.87)"
              : "rgba(255, 255, 255, 0.87)",
          lineHeight: theme.typography.body1.lineHeight,
          transform: "translate(14px, 12px) scale(1)",
          padding: "0 5px 0 0px",
          backgroundColor:
            theme.palette.mode === "light"
              ? [theme.palette.common.white]
              : [theme.palette.common.black],
          "&.Mui-focused": {
            color: theme.palette.text.secondary,
            transform: "translate(14px, -9px) scale(0.85)",
          },
          "&.MuiFormLabel-filled": {
            color: theme.palette.text.secondary,
            transform: "translate(14px, -9px) scale(0.85)",
          },
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38)",
          },
          "&.Mui-error": {
            color: theme.palette.error.main,
          },
        },
        ".MuiAutocomplete-input": {
          padding: "0 !importamnt",
        },
        ".MuiAutocomplete-tag": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.common.black
              : [theme.palette.common.white],
        },
      },
      "&.MuiAutocomplete-hasClearIcon": {
        ".u-form-group": {
          ".MuiInputLabel-formControl": {
            transform: "translate(14px, -9px) scale(0.85)",
            color: theme.palette.text.secondary,
          },
          ".MuiInputBase-formControl": {
            overflow: "hidden",
          },
        },
      },
    },
    ".MuiAutocomplete-popper": {
      ".MuiPaper-root": {
        backgroundColor:
          theme.palette.mode === "light"
            ? [theme.palette.common.white]
            : theme.palette.grey[800],
        borderColor:
          theme.palette.mode === "light"
            ? [theme.palette.common.white]
            : theme.palette.grey[800],
        backgroundImage: "none",

        ".MuiAutocomplete-listbox": {
          padding: "8px 0px",
          overflowX: "hidden",
          overflowY: "auto",
          "@supports (-moz-appearance:none)": {
            scrollbarWidth: "thin",
          },
          "&::-webkit-scrollbar": {
            width: 7,
            height: 7,
            borderRadius: 8,
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: 8,
            padding: "0 1px",
            backgroundColor:
              theme.palette.mode === "light"
                ? "#f0f0f0"
                : theme.palette.grey[700],
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            transition: "all 0.3s",
            backgroundColor:
              theme.palette.mode === "light"
                ? "#cdcdcd"
                : theme.palette.grey[500],
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor:
              theme.palette.mode === "light"
                ? "#a6a6a6"
                : theme.palette.grey[600],
          },

          ".MuiAutocomplete-option": {
            padding: "12px 24px 12px 16px",
            borderRadius: "8px",
            whiteSpace: "normal",
            margin: "0 8px",
            fontSize: theme.typography.htmlFontSize,
            fontWeight: theme.typography.fontWeightRegular,
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : "rgba(255, 255, 255, 0.87)",
            "&.Mui-focused, &:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? muiTheme.customVariables.tableStripedBg
                  : unityLightTheme.palette.grey[600],
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[900]
                  : theme.palette.common.white,
            },
            "&[aria-selected=true]": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#F2F4FA"
                  : theme.palette.grey[100],
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[900]
                  : [theme.palette.common.white],
              fontWeight: theme.typography.fontWeightMedium,
            },
            ".MuiButtonBase-root.MuiCheckbox-root": {
              padding: 0,
              marginRight: 8,
              color: theme.palette.grey[300],
              "&::after": {
                width: 15,
                height: 15,
                content: '" "',
                boxShadow: "0px 0px 0px 4px rgba(0,0,0,0.08)",
                position: "absolute",
                left: 3.5,
                top: 4,
                borderRadius: 1,
                display: "none",
              },
              "&:hover": {
                "&::after": {
                  display: "block",
                },
              },
              "svg path.bg-color": {
                fill: theme.palette.primary.main,
                stroke: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
    ".u-help-text": {
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".u-error-text": {
      color: theme.palette.error.main,
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    "&.autocomplete-disabled": {
      ".u-help-text": {
        color: "rgba(0, 0, 0, 0.38)",
      },
    },
  },
}));

const MenuSx = {
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#EBECF2",
  boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
  padding: "0",
  color: "#515466",
  ".MuiAutocomplete-listbox": {
    padding: "8px",
    "@supports (-moz-appearance:none)": {
      scrollbarWidth: "thin",
    },
    "&::-webkit-scrollbar": {
      width: 7,
      height: 7,
      borderRadius: 8,
    },
    "&::-webkit-scrollbar-track": {
      padding: "0 1px",
      backgroundColor: "#f0f0f0",
      borderRadius: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      transition: "all 0.3s",
      backgroundColor: "#cdcdcd",
      borderRadius: 8,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a6a6a6",
    },
  },
  ".MuiAutocomplete-option": {
    padding: "12px 24px 12px 16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 36, 156, 0.05)",
      "&:hover": {
        backgroundColor: "rgba(0, 36, 156, 0.05)",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(0, 36, 156, 0.05)",
      },
    },
    ".MuiTouchRipple-root": {
      display: "none",
    },
  },
};

const CustomAutocomplete: React.FC<IAutocompleteProps> = ({
  placeholder,
  open,
  disablePortal,
  endAdornment,
  freeSolo,
  theme,
  options,
  label,
  items,
  onChange,
  capitalizeLabel,
  uppercaseLabel,
  disabled,
  helperText,
  error,
  searchMatchFrom,
  value,
  isMarketplace = false,
  multiple = false,
  onKeyDown,
  limitTags,
  ...props
}) => {
  const [defaultCountry, setDefaultCountry] = useState({});
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  // const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const checkedIcon = <Icon name="CheckBoxChecked" />;

  useEffect(() => {
    if (isMarketplace) {
      if (typeof value === "string") {
        setDefaultCountry("");
        return;
      }
      if (value) {
        const c = items?.find((item) => item.id === value);
        setDefaultCountry(c);
      } else {
        setDefaultCountry("");
      }
    }
  }, [items, value]);

  return (
    <AutocompleteWrap
      theme={theme}
      className={clsx({
        "u-custom-autocomplete": true,
        "autocomplete-disabled": disabled,
      })}
    >
      <Autocomplete
        multiple={multiple}
        limitTags={limitTags}
        open={open}
        id="free-solo-demo"
        freeSolo={freeSolo}
        disablePortal={disablePortal}
        componentsProps={{
          paper: {
            sx: MenuSx,
          },
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        options={items || []}
        defaultValue={
          multiple ? value ?? [] : isMarketplace ? defaultCountry : value
        }
        renderOption={(props, option: IOption, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              // endAdornment: (
              //   <>
              //     {endAdornment ?? <Icon name="ChevronDown" />}
              //   </>
              // )
            }}
            error={!!error}
            className={clsx({
              "u-form-group": true,
              "capitalize-label": capitalizeLabel,
              "uppercase-label": uppercaseLabel,
            })}
            placeholder={placeholder}
            label={label}
          />
        )}
        disabled={disabled}
        {...props}
      />
      {helperText && (
        <FormHelperText className="u-help-text">{helperText}</FormHelperText>
      )}
      {error && (
        <FormHelperText className="u-error-text">{error}</FormHelperText>
      )}
    </AutocompleteWrap>
  );
};

export default CustomAutocomplete;
