import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Dialog, Box, IBoxProps, DuoTab, Button } from "@ntpkunity/controls";
import { styled } from "@mui/material/styles";
import FinancialInsuranceTable from "./financialInsuranceTable";
import OptionsTable from "./optionsTable";
import FeesTable from "./feesTable";
import FinanceTypeTable from "./financeTypeTable";
import IntroducerMappingTable from "./introducerMapping";
import { IProviderMappingProps, IFinanceTypeModel, IStates } from "Interface";
import { useForm } from "react-hook-form";
import {
  UseQuery_GetAllFinanceTypes,
  UseMutation_AddFeesMappings,
  UseMutation_AddOptionMappings,
  UseMutation_AddFPCategoryMappings,
  useMutation_GetAllOptionMappings,
  useMutation_GetFPCategoryMappings,
  useMutation_GetAllFeesMappings,
  useMutation_GetFinanceMappings,
  UseMutation_AddFinanceMappings,
  UseMutation_GetAllIntroducerMappings,
  UseMutation_AddIntroducerMappings,
} from "services";
import { QueryKeys } from "Enums";
import { useQueryClient } from "react-query";
import { UseQuery_getAllStates } from "services/dealer-configurations.service";
import { IntegrationType, Providers } from "Enums/IntegrationTypesAndProviders";
import { isFeeCombinationsUnique, isFPCombinationsUnique } from "./helper";
import { ValidationMessages } from "Enums";

const DuoTabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.duo-tab-wrap": {
    "&.align-center": {
      ".TabsUnstyled-root": {
        ".TabsListUnstyled-root": {
          justifyContent: "center",
          margin: "0 auto 24px auto",
          width: "max-content",
          backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[100] : "#f2f2f2",
          ".TabUnstyled-root": {
            width: "auto",
          },
        },
      },
    },
    "&.sm": {
      ".TabsUnstyled-root": {
        ".TabsListUnstyled-root": {
          ".TabUnstyled-root": {
            padding: "4px 12px 4px 12px",
            "&.Mui-selected": {
              fontWeight: theme.typography.fontWeightMedium,
            },
          },
        },
      },
    },
  },
}));

const ProviderMapping: FC<IProviderMappingProps> = ({
  providerId,
  integrationType,
  setProviderMappingState,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [mappingDialog, setMappingDialog] = useState(true);

  const [updatedFeesRows, setUpdatedFeesRows] = useState(new Set());
  const [updatedOptionRows, setUpdatedOptionRows] = useState(new Set());
  const [updatedFPCategoryRows, setUpdatedFPCategoryRows] = useState(new Set());
  const [updatedFinanceTypeRows, setUpdatedFinanceTypeRows] = useState(
    new Set()
  );
  const [updatedIntroducerRows, setUpdatedIntroducerRows] = useState(new Set());
  const [feesMappingData, setFeesMappingData] = useState([]);
  const [fpMappingData, setFpMappingData] = useState([]);
  const [optionMappingData, setOptionMappingData] = useState([]);
  const [financeMappingData, setFinanceMappingData] = useState([]);
  const [introducerMappingData, setIntroducerMappingData] = useState([]);

  const [callsCount, setCallsCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [showDefaultError, setShowDefaultError] = useState("");
  const [categoriesArray, setCategoriesArray] = useState([
    { code: "-1", name: "Others" },
    { code: "2", name: "GAP Insurance" },
    { code: "3", name: "Tire & Wheel Protection" },
    { code: "4", name: "EVP/EMP- Extended Service Contract" },
    { code: "5", name: "Windshield Protection" },
    { code: "6", name: "Dent Protection" },
    { code: "7", name: "Lease Protection" },
    { code: "8", name: "Key Protection" },
    { code: "9", name: "CPO Wrap" },
    { code: "10", name: "Presence Protection" },
    { code: "11", name: "All Surface Protection" },
    { code: "12", name: "BMW Bundle" },
    { code: "13", name: "3rd Party Bundle" },
    { code: "14", name: "Maintenance Agreement 1" },
    { code: "27", name: "Maintenance Agreement 2" },
    { code: "28", name: "Other 1" },
    { code: "29", name: "Other 2" },
    { code: "30", name: "Other 3" },
  ]);
  const [activeTab, setActiveTab] = useState(0);

  const form = useForm();

  const { handleSubmit, formState, setError, reset, getValues, clearErrors } =
    form;

  const handleMappingClose = () => {
    setMappingDialog(false);
    setProviderMappingState(false);
  };

  UseQuery_GetAllFinanceTypes();
  UseQuery_getAllStates();

  const { mutate: AddFeesMappings } = UseMutation_AddFeesMappings();
  const { mutate: AddOptionMappings } = UseMutation_AddOptionMappings();
  const { mutate: AddFPCategoryMappings } = UseMutation_AddFPCategoryMappings();
  const { mutate: AddFinanceMappings } = UseMutation_AddFinanceMappings();
  const { mutate: AddIntroducerMappings } = UseMutation_AddIntroducerMappings();

  const { mutate: fetchFPCategoryMappings, data: fpCategoryMappings } =
    useMutation_GetFPCategoryMappings(providerId);
  const { mutate: fetchOptionMappings, data: optionMappings } =
    useMutation_GetAllOptionMappings(providerId);
  const { mutate: fetchFeesMappings, data: feesMappings } =
    useMutation_GetAllFeesMappings(providerId);
  const { mutate: fetchFinanceMappings, data: financeMappings } =
    useMutation_GetFinanceMappings(providerId);
  const { mutate: fetchIntroducerMappings, data: introducerMappings } =
    UseMutation_GetAllIntroducerMappings(providerId);

  const financeTypes: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const statesData: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );

  useEffect(() => {
    if (
      integrationType === IntegrationType.CREDIT_DECISIONING ||
      integrationType === IntegrationType.CONTRACTING ||
      integrationType === IntegrationType.DEAL_PUSH
    ) {
      fetchFPCategoryMappings();
    }
  }, [fetchFPCategoryMappings, providerId]);

  useEffect(() => {
    if (
      integrationType === IntegrationType.CREDIT_DECISIONING ||
      integrationType === IntegrationType.CONTRACTING ||
      integrationType === IntegrationType.DEAL_PUSH
    ) {
      fetchOptionMappings();
    }
  }, [fetchOptionMappings, providerId]);

  useEffect(() => {
    if (
      integrationType === IntegrationType.CREDIT_DECISIONING ||
      integrationType === IntegrationType.CONTRACTING ||
      integrationType === IntegrationType.DEAL_PUSH
    ) {
      fetchFeesMappings();
    }
  }, [fetchFeesMappings, providerId]);

  useEffect(() => {
    if (integrationType === IntegrationType.PROPOSAL_SUBMISSION) {
      fetchFinanceMappings();
      fetchIntroducerMappings();
    }
  }, [fetchFinanceMappings, fetchIntroducerMappings, providerId]);

  useEffect(() => {
    if (feesMappings) {
      setFeesMappingData(feesMappings?.data);
    }
  }, [feesMappings]);

  useEffect(() => {
    if (optionMappings) {
      setOptionMappingData(optionMappings?.data);
    }
  }, [optionMappings]);

  useEffect(() => {
    if (fpCategoryMappings) {
      const updatedMappingData = categoriesArray.map((category) => {
        const categoryMappings = fpCategoryMappings.data?.filter(
          (mapping) => mapping.category === category.name
        );
        if (categoryMappings.length > 0) {
          return categoryMappings.map((mapping) => ({
            category: category.name,
            finance_type: mapping.finance_type,
            external_code: mapping.external_code,
            id: mapping.id,
          }));
        } else {
          return [
            {
              category: category.name,
              finance_type: null,
              external_code: null,
              id: null,
            },
          ];
        }
      });
      setFpMappingData(updatedMappingData.flat());
    }
  }, [fpCategoryMappings, categoriesArray]);

  useEffect(() => {
    if (financeMappings) {
      setFinanceMappingData(financeMappings?.data);
    }
  }, [financeMappings]);

  useEffect(() => {
    if (introducerMappings) {
      setIntroducerMappingData(introducerMappings?.data);
    }
  }, [introducerMappings]);

  useEffect(() => {
    if (callsCount === successCount && callsCount > 0) {
      handleMappingClose();
    }
  }, [callsCount, successCount]);

  const onSubmit = (data) => {
    let hasFeesError = false;
    let hasFpError = false;

    let modifiedFeesMappingRows = [];
    let modifiedOptionMappingRows = [];
    let modifiedFPMappingRows = [];
    let modifiedFinanceMappingRows = [];
    let modifiedIntroducerRows = [];

    if (
      integrationType === IntegrationType.CREDIT_DECISIONING ||
      integrationType === IntegrationType.CONTRACTING ||
      integrationType === IntegrationType.DEAL_PUSH
    ) {
      hasFeesError = isFeeCombinationsUnique(data, setError);
      hasFpError = isFPCombinationsUnique(data, setError);
    }

    if (hasFeesError || hasFpError) {
      return;
    }

    modifiedFeesMappingRows = Array.from(updatedFeesRows)?.map((index: any) => {
      return {
        ...data.feesMapping[index],
        provider_id: providerId,
      };
    });

    modifiedOptionMappingRows = Array.from(updatedOptionRows)?.map(
      (index: any) => {
        return {
          ...data.optionMapping[index],
          provider_id: providerId,
        };
      }
    );

    modifiedFPMappingRows = Array.from(updatedFPCategoryRows)?.map(
      (index: any) => {
        const categoryName = data.fpMapping[index].category;
        const matchingCategory = categoriesArray.find(
          (category) => category.name === categoryName
        );
        return {
          ...data.fpMapping[index],
          provider_id: providerId,
          category_code: matchingCategory ? matchingCategory.code : null,
        };
      }
    );

    modifiedFinanceMappingRows = Array.from(updatedFinanceTypeRows)?.map(
      (index: any) => {
        return {
          ...data.financeMapping[index],
          provider_id: providerId,
        };
      }
    );

    modifiedIntroducerRows = Array.from(updatedIntroducerRows)?.map(
      (index: any) => {
        return {
          ...data.introducerMapping[index],
          provider_id: providerId,
        };
      }
    );

    const formValues = getValues();

    let introducerMapping = formValues?.introducerMapping;

    if (Array.isArray(introducerMapping) && introducerMapping.length > 0) {
      let defaultCount = 0;
      let nonDefaultCount = 0;
      const defaultIndexes = [];
      const invalidEmailIndexes = [];
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      for (let index = 0; index < introducerMapping.length; index++) {
        const row = introducerMapping[index];
        if (row.email_address && !emailRegex.test(row.email_address)) {
          invalidEmailIndexes.push(index);
        }
        if (row.is_default === true) {
          defaultCount++;
          defaultIndexes.push(index);
        } else {
          nonDefaultCount++;
        }
      }

      invalidEmailIndexes.forEach((index) => {
        setError(`introducerMapping.${index}.email_address`, {
          type: "manual",
          message: ValidationMessages.INVALID_EMAIL,
        });
      });

      if (nonDefaultCount === introducerMapping.length) {
        setShowDefaultError(
          "At least one record should be marked as default at a time"
        );
      } else if (defaultCount > 1) {
        defaultIndexes.forEach((index) => {
          setError(`introducerMapping.${index}.is_default`, {
            type: "manual",
            message: "Only one record can be marked as default at a time",
          });
        });
        setShowDefaultError("");
      } else {
        setShowDefaultError("");
      }

      if ((defaultCount > 1) || (nonDefaultCount === introducerMapping.length) || (invalidEmailIndexes.length)){
        return
      }
    }

    if (modifiedFeesMappingRows.length > 0) {
      AddFeesMappings(modifiedFeesMappingRows);
    }

    if (modifiedOptionMappingRows.length > 0) {
      AddOptionMappings(modifiedOptionMappingRows);
    }

    if (modifiedFPMappingRows.length > 0) {
      AddFPCategoryMappings(modifiedFPMappingRows);
    }

    if (modifiedFinanceMappingRows.length > 0) {
      setCallsCount((prev) => prev + 1);
      AddFinanceMappings(modifiedFinanceMappingRows, {
        onSuccess: (data) => {
          setUpdatedFinanceTypeRows(new Set());
          reset({ financeMapping: data?.data });
          setFinanceMappingData(data?.data);
          setSuccessCount((prev) => prev + 1);
        },
        onError: (error) => {
          setCallsCount(0);
          setSuccessCount(0);
        },
      });
    }

    if (modifiedIntroducerRows.length > 0) {
      const sanitizedModifiedIntroducerRows = modifiedIntroducerRows.map(
        (row) => {
          return {
            ...row,
            is_default: row.is_default === "" ? false : row.is_default,
          };
        }
      );

      setCallsCount((prev) => prev + 1);

      AddIntroducerMappings(sanitizedModifiedIntroducerRows, {
        onSuccess: (data) => {
          setUpdatedIntroducerRows(new Set());
          reset({ introducerMapping: data?.data });
          setSuccessCount((prev) => prev + 1);
        },
        onError: (error) => {
          setCallsCount(0);
          setSuccessCount(0);
          setShowDefaultError(error?.response?.data?.detail?.message);
        },
      });
    }
    if (
      [
        IntegrationType.CREDIT_DECISIONING,
        IntegrationType.CONTRACTING,
        IntegrationType.DEAL_PUSH,
      ].includes(integrationType)
    ) {
      handleMappingClose();
    }
  };

  const handleAddNewRow = () => {
    if (activeTab == 0) {
      setFpMappingData((prevMappings) => [
        ...prevMappings,
        {
          category: "",
          finance_type: [],
          external_code: "",
          category_code: "",
        },
      ]);
    }
    if (activeTab == 2) {
      setFeesMappingData((prevMappings) => [
        ...prevMappings,
        {
          fee_name: "",
          finance_type: [],
          state: [],
          external_code: "",
        },
      ]);
    }
    if (
      integrationType == IntegrationType.PROPOSAL_SUBMISSION &&
      activeTab == 1
    ) {
      setIntroducerMappingData((prevMappings) => [
        ...prevMappings,
        {
          name: "",
          email_address: "",
          unique_id: "",
          is_default: false,
          external_code: "",
        },
      ]);
    }
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Dialog
        variant={undefined}
        size="xl"
        title="Provider Mapping"
        open={mappingDialog}
        onCloseDialog={handleMappingClose}
        disablePortal={true}
        customFooter={
          <>
            <Box
              theme={theme}
              sx={{ display: "flex", justifyContent: "center", gap: 2 }}
            >
              {((integrationType === IntegrationType.CREDIT_DECISIONING ||
                integrationType === IntegrationType.CONTRACTING ||
                integrationType === IntegrationType.DEAL_PUSH) &&
                activeTab !== 1) ||
              (integrationType === IntegrationType.PROPOSAL_SUBMISSION &&
                activeTab === 1) ? (
                <Button
                  theme={theme}
                  secondary
                  text="Add New"
                  onClick={() => handleAddNewRow()}
                />
              ) : null}
              <Button
                theme={theme}
                primary
                text="Save Changes"
                onClick={handleSubmit(onSubmit)}
              />
            </Box>
          </>
        }
        theme={theme}
        children={
          <>
            <DuoTabWrap theme={theme} className="duo-tab-wrap align-center sm">
              <form onSubmit={(e) => e.preventDefault()}>
                <DuoTab
                  theme={theme}
                  defaultTabIndex={0}
                  onChange={handleChange}
                  items={
                    integrationType === IntegrationType.CREDIT_DECISIONING ||
                    integrationType === IntegrationType.CONTRACTING ||
                    integrationType === IntegrationType.DEAL_PUSH
                      ? [
                          {
                            title: "F&I Products",
                            content: (
                              <FinancialInsuranceTable
                                form={form}
                                setUpdatedFPCategoryRows={
                                  setUpdatedFPCategoryRows
                                }
                                financeTypes={financeTypes}
                                fpCategoryMappings={fpMappingData}
                                categoriesArray={categoriesArray}
                                setFpMappingData={setFpMappingData}
                              />
                            ),
                          },
                          {
                            title: "Add-Ons",
                            content: (
                              <OptionsTable
                                form={form}
                                setUpdatedOptionRows={setUpdatedOptionRows}
                                optionMappings={optionMappingData}
                              />
                            ),
                          },
                          {
                            title: "Fees",
                            content: (
                              <FeesTable
                                form={form}
                                setUpdatedFeesRows={setUpdatedFeesRows}
                                financeTypes={financeTypes}
                                statesData={statesData}
                                feesMappings={feesMappingData}
                                setFeesMappingData={setFeesMappingData}
                              />
                            ),
                          },
                        ]
                      : integrationType === IntegrationType.PROPOSAL_SUBMISSION
                      ? [
                          {
                            title: "Finance Type",
                            content: (
                              <FinanceTypeTable
                                form={form}
                                setUpdatedFinanceTypeRows={
                                  setUpdatedFinanceTypeRows
                                }
                                financeMappings={financeMappingData}
                              />
                            ),
                          },
                          {
                            title: "Introducer Mapping",
                            content: (
                              <IntroducerMappingTable
                                form={form}
                                setUpdatedIntroducerRows={
                                  setUpdatedIntroducerRows
                                }
                                introducerMappings={introducerMappingData}
                                showDefaultError={showDefaultError}
                              />
                            ),
                          },
                        ]
                      : []
                  }
                />
              </form>
            </DuoTabWrap>
          </>
        }
      />
    </>
  );
};

export default ProviderMapping;
