export const EP_GET_REFERENCE_DATA_BY_DEALER_CODE =
  "inventory/reference-data-by-dealer-code";
export const EP_VEHICLE = "vehicle";
export const EP_GET_VEHICLES_BY_DEALER_CODE = "vehicle/by-dealer-code";
export const EP_GET_VEHICLES_BY_FILTER = "inventory/active/filter";
export const EP_GET_VEHICLES_INVENTORY_BY_DEALER_CODE = "inventory/filter";
export const EP_REFERRAL_INVITE = "referral";
export const EP_VEHICLE_DETAIL = "vehicle-detail";
export const EP_GET_INVENTORY_OPTIONS_DETAIL = "inventory/attached-option";
export const EP_UPDATE_VEHICLE_DETAIL = "vehicle-detail";
export const EP_ADD_ON_ACCESSORIES = "dealer/option/filter";
export const EP_GET_MASTER_LIST_ACCESSORIES = "master-list-accessories";
export const EP_GET_FILTER_MASTER_LIST = "master-list-accessorie/filter";
export const EP_GET_OPTIONS = "dealer/option-model-name";
export const EP_GET_DEALER_BY_EMAIL = "dealer/find/by-tenant";
export const EP_SAVE_ADD_ON = "dealer/option";
export const EP_SAVE_OEM_OPTION = "master-list-accessories";
export const EP_GET_TRIMS = "asset-model-trim";
export const EP_GET_DEALER_FEE = "dealer/fee";
export const EP_DEALER_PREFERENCE = "dealer/preference";
export const EP_DEALER_PAYMENT = "dealer/payment-detail";
export const EP_DEALER_PAYMENT_BY_DEALER_CODE =
  "dealer/payment-detail_by_dealer_code";
export const EP_DEALER_PREFERENCE_BY_DEALER_CODE =
  "dealer/preference-by-dealer-code";
export const EP_DEALER_CHAT_BY_DEALER_CODE = "dealers-chat";
export const EP_DEALER_TRADEIN = "dealer/tradein";
export const EP_DEALER_TRADEIN_BY_DEALER_CODE = "dealer/tradein-by-dealer-code";
export const EP_SAVE_DEALER_PROFILE = "dealer/profile";
export const EP_SAVE_DEALER_CHAT = "dealers-chat";
export const EP_SAVE_DEALER_RESERVATION = "reservation-deposit";
export const EP_SAVE_PEN_DEALER_ID = "dealer/profile/pen-dealer-id";
export const EP_GET_FEES = "fee-names";
export const EP_GET_ACTIVE_DEALERS = "dealers/active";
export const EP_GET_All_DEALERS = "dealer";
export const EP_DEALER_CONFIGURATIONS = "dealer/configurations";
export const EP_GET_STATES = "state";
export const EP_GET_CONFIG_CONTRACT_TEMRS = "contract-term";
export const EP_GET_CONFIG_ALLOWED_MILEAGE = "allowed-mileage";
export const EP_PEN_GET_PROVIDER = "get-providers";
export const EP_PEN_GET_PRODUCT = "get-provider-products";
export const EP_PEN_SET_DEALER = "set-dealer";
export const EP_PEN_PRODUCT_REGISTER = "set-dealer-fni-products";
export const EP_GET_FINANCIAL_INSURANCE_PRODUCTS =
  "financial-insurance-products";
export const EP_GET_FINANCIAL_INSURANCE = "financial-insurance";
export const EP_GET_VERIFY_ADDRESS = "api/verify-address";
export const EP_LENDER_INTEGRATION = "integration/company";
export const EP_TENANT_CONFIGURATION = "tenant/configuration";
export const EP_PROVIDER_STATUS = "integration/dealer/provider";
export const EP_PROVIDER_INTEGRATION = "integration/provider";
export const EP_UPDATE_LENDER_INTEGRATION_STATUS = "integration/status";
export const EP_SAVE_SHIFT_DIGITAL_DEALER_ID =
  "dealer/profile/shift-digital-dealer-id";
export const EP_DEALER_BY_DEALER_ID = "dealer/by-dealer-id";
export const EP_CHECK_DEALER_REGISTRATION_WITH_PEN =
  "/integrations/dealer/check-dealer-pen-registration";
export const EP_CHECK_DEALER_REGISTRATION_WITH_STRIPE =
  "/integrations/dealer/check-dealer-stripe-registration";
export const EP_UNREGISTER_WITH_PEN = "dealer/profile/unregister-pen-dealer";
export const EP_CONTRACT_ALLOWED_MILEAGE = "contract-allowed-annum-mileage";
export const EP_CREATE_STRIPE_ACCOUNT = "payment/accounts";
export const EP_SAVE_STRIPE_ACCOUNT_ID = "dealer/profile/stripe-dealer-id";
export const EP_STRIPE_ACCOUNT_LINK = "payment/account-links";
export const EP_DEALER_PROFILE_BY_DEALER_ID = "dealer/profile";
export const EP_DELETE_ALL_FINANCIAL_INSURANCE =
  "financial-insurance/delete-all";
export const EP_GET_STRIPE_ACCOUNT_BY_ACCOUNT_ID = "payment/accounts";
export const EP_GET_HUBEX_CREDENTIALS = "configuration";
export const EP_GET_DEALER_INDIVIDUALIZED_AGREEMENT =
  "dealer/{{dealer_code}}/individualized_agreement";
export const EP_DEALER_INDIVIDUALIZED_AGREEMENT =
  "dealer/individualized_agreement";
export const EP_DEALER_CUSTOM_LINK = "dealer/custom_link";
export const EP_SEND_EMAIL = "notification/send-email";
export const EP_UPLOAD_DOCUMENT_S3 = "/bucket/upload-view-file/";
export const EP_VERIFY_EMAIL = "notification/verify-emails";
export const EP_GET_FINANCIAL_INSURANCE_BUNDLE = "financial-insurance-bundle";
export const EP_GET_DEALER_MARKUPS = "dealer-markups";
export const EP_ADD_DELETE_LENDER_SELECTION_RULE =
  "/company/lender-selection-rules";
export const EP_GET_LENDER_SELECTION_RULE = "/company/lender-selection-rules/";
export const EP_UPDATE_LENDER_SELECTION_RULE =
  "/company/update/lender-selection-rules";
export const EP_GET_USER_BY_ROLE = "/ums/um/get-user-by-role";
