export enum StType {
  EMAIL = "Email",
  SMS = "SMS",
  DOCUMENT = "Document",
}

export enum StationeryRole {
  APPLICANT = "Applicant",
  CO_APPLICANT = "Co-applicant",
  DEALER_ADMIN = "Dealer Admin",
  COMPANY_CONTACT_PERSON = "Company Contact Person",
  CRM = "CRM",
  BROKER_ADMIN = "Broker Admin",
  BROKER_USER = "Broker User",
  BROKER_DEALER_ADMIN = "Broker Dealer Admin",
  BROKER_DEALER_USER = "Broker Dealer User",
  DEALER_CONTACT_PERSON = "Dealer Contact Person"
}

export enum StationeryType {
  ELECTRONIC = "Electronic",
  ON_SCREEN = "On screen",
  WET = "Wet",
}

export enum StationeryAction {
  NEEDS_TO_SIGN = "Needs to Sign",
  IN_pERSON_SIGNER = "In Person Signer",
  RECEIVE_cOPY = "Receives a Copy",
  NEEDS_TO_VIEW = "Needs to View",
}

export enum StationeryEvent {
  END_CUSTOMER_EMAIL_VERIFICATION = "End Customer Email Verification",
  CUSTOMER_SIGN_UP_COMPLETION = "Customer Sign up completion",
  ASSET_REFERRAL = "Asset Referral",
  SEND_INVITATION_EMAIL = "Send Invitation Email",
  SEND_ORDER_LINK = "Send Order Link",
  CREDIT_APPLICATION_SUBMISSION = "Credit Application Submission",
  CREDIT_APPLICATION_APPROVED = "Credit Application Approved",
  CREDIT_APPLICATION_CONDITIONED = "Credit Application Conditioned",
  ORDER_CANCELLATION = "Order Cancellation",
  PAYMENT_SUCCESSFUL = "Payment Successful",
  ORDER_COMPLETION = "Order Completion",
  LEAD_CREATION = "Lead Creation",
  ORDER_WITHDRAWN = "Order Withdrawn",
  ORDER_DECLINED = "Order Declined",
}
