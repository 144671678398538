import Http from "shared/helper/http-api";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  EP_PEN_GET_PRODUCT,
  EP_PEN_GET_PROVIDER,
  EP_PEN_SET_DEALER,
  EP_PEN_PRODUCT_REGISTER,
  EP_GET_VERIFY_ADDRESS,
  EP_CREATE_STRIPE_ACCOUNT,
  EP_STRIPE_ACCOUNT_LINK,
  EP_GET_STRIPE_ACCOUNT_BY_ACCOUNT_ID,
  EP_GET_HUBEX_CREDENTIALS,
  EP_SEND_EMAIL,
} from "shared/config/endpoints";
import { ConfigurationAPI } from "./http-common";
import { QueryKeys, ToastMessages } from "../Enums";
import { IntegrationAPI } from "services";
export const usePenSetDealer = (): any => {
  const { data, isLoading, mutate } = useMutation<
    {
      action: string;
      address1: string;
      address2: string;
      city: string;
      contactName: string;
      penDealerId: 0;
      dealershipName: string;
      email: string;
      fax: string;
      isTestDealer: true;
      observesDayLightSaving: boolean;
      phone: string;
      state: string;
      timeZone: string;
      webSite: string;
      zipCode: string;
    },
    any
  >(
    ({ penRegisterData }: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      return apiService.post<any>(`${EP_PEN_SET_DEALER}`, penRegisterData);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const usePenProductRegister = (): any => {
  const { data, isLoading, mutate } = useMutation<
    {
      action: string;
      pen_dealer_id: number;
      pen_product_id: number;
      provider_dealer_code: string;
      provider_product_code: string;
      contract_prefix_override: string;
      dealer_code: string;
    },
    any
  >(
    (data: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      return apiService.post<any>(
        `${EP_PEN_PRODUCT_REGISTER}`,
        data?.penProductRegisterData
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetProvider = (): any => {
  const { data, isLoading, mutate } = useMutation<{ credentials: any }, any>(
    async (dealer_code: any): Promise<any> => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      let promiseResult: any;
      await apiService
        .post<any>(`${EP_PEN_GET_PROVIDER}`, {})
        .then((response) => {
          promiseResult = response;
        });
      const providers: any = promiseResult?.providers?.provider;
      return Array.isArray(providers) ? providers : [providers];
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetProductByProviderId = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { id: string; credentials: any },
    any
  >(
    async (body: any): Promise<any> => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      let promiseResult: any;
      await apiService
        .post<any>(`${EP_PEN_GET_PRODUCT}`, {
          pen_provider_id: body.id,
        })
        .then((response) => {
          promiseResult = response;
        });
      const product: any = promiseResult?.products?.product;
      return Array.isArray(product) ? product : [product];
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
export const useGetVerifyAddress = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { address: string; dealer_code: string },
    any
  >(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      let promiseResult: any;

      await apiService
        .get<any>(`${EP_GET_VERIFY_ADDRESS}/${body.address}`, "", {
          headers: {
            "x-dealer-code": body.dealer_code,
          },
        })
        .then((response) => {
          promiseResult = response;
        });
      return promiseResult;
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useStripeRegister = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (data: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      return apiService.post<any>(`${EP_CREATE_STRIPE_ACCOUNT}`, data.payload, {
        headers: {
          dealer_code: data.dealer_code,
        },
      });
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useStripeAccountLink = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (accountData: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      return apiService.post<any>(
        `${EP_STRIPE_ACCOUNT_LINK}`,
        accountData.payload
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetStripeAccountById = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      return apiService.get<any>(
        `${EP_GET_STRIPE_ACCOUNT_BY_ACCOUNT_ID}/${body.account_id}`,
        ""
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const UseGetHubexCredentials = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((dealer_code: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.INTEGRATION_API_GATEWAY,
    });
    if (dealer_code) {
      return apiService.get<any>(`${EP_GET_HUBEX_CREDENTIALS}`, "", {
        headers: {
          "x-dealer-code": dealer_code,
        },
      });
    } else {
      return apiService.get<any>(`${EP_GET_HUBEX_CREDENTIALS}`, "");
    }
  });
  return { data, isLoading, mutate };
};

export const UseGetHubexCredentialsByProvider = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((providerData: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.INTEGRATION_API_GATEWAY,
    });
    if (!providerData?.dealer_code) {
      return apiService.get<any>(`${EP_GET_HUBEX_CREDENTIALS}`, "", {
        headers: {
          "x-service-provider": providerData.provider,
        },
      });
    } else {
      return apiService.get<any>(`${EP_GET_HUBEX_CREDENTIALS}`, "", {
        headers: {
          "x-service-provider": providerData.provider,
          "x-dealer-code": providerData.dealer_code,
        },
      });
    }
  });
  return { data, isLoading, mutate };
};

export const UseSaveHubexCredentials = (): any => {
  const { data, isLoading, mutateAsync } = useMutation<any>(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      if (body?.dealer_code) {
        return await apiService.post<any>(
          `${EP_GET_HUBEX_CREDENTIALS}`,
          body.data,
          {
            headers: {
              "x-dealer-code": body.dealer_code,
            },
          }
        );
      } else {
        return await apiService.post<any>(
          `${EP_GET_HUBEX_CREDENTIALS}`,
          body.data
        );
      }
    }
  );
  return { data, isLoading, mutateAsync };
};

export const UseUpdateHubexCredentials = (): any => {
  const { data, isLoading, mutateAsync } = useMutation<any>(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      if (body?.dealer_code) {
        return await apiService.patch<any>(
          `${EP_GET_HUBEX_CREDENTIALS}`,
          body.data,
          {
            headers: {
              "x-dealer-code": body.dealer_code,
            },
          }
        );
      } else {
        return await apiService.patch<any>(
          `${EP_GET_HUBEX_CREDENTIALS}`,
          body.data
        );
      }
    }
  );
  return { data, isLoading, mutateAsync };
};

export const UseDeleteHubexCredentials = (): any => {
  const { data, isLoading, mutateAsync } = useMutation<any>(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      if (body?.dealer_code) {
        return await apiService.delete<any>(
          `${EP_GET_HUBEX_CREDENTIALS}`,
          body.data,
          {
            headers: {
              "x-dealer-code": body.dealer_code,
              "x-service-provider": body.provider_name,
            },
          }
        );
      } else {
        return await apiService.delete<any>(
          `${EP_GET_HUBEX_CREDENTIALS}`,
          body.data,
          {
            headers: {
              "x-service-provider": body.provider_name,
            },
          }
        );
      }
    }
  );
  return { data, isLoading, mutateAsync };
};

export const useSendInventoryEmailReferral = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (data: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY,
      });
      return apiService.post<any>(`${EP_SEND_EMAIL}`, data, {
        headers: {
          "x-service-provider": "SES",
        },
      });
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
