import { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TablePagination, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  Menu,
  DataTable,
  Button,
  Box,
  Grid,
  Typography,
  PersistentDrawer,
  Input,
} from "@ntpkunity/controls";
import { LayoutWithSideNav } from "layout";
import {
  DataTableWrap,
  DrawerWrap,
  PageHeader,
  PaginationWrap,
  Switch,
} from "@sharedComponents";
import ChevronDown from "shared/assets/images/chevron-down";
import { AddNewLender } from "../../../components/AddNewLender/AddNewLender";
import {
  UseQuery_getAllLender_filter,
  UseMutation_UpdateLender,
  UseMutation_AddLender,
  UseQuery_GetAllFinanceTypes,
  UseMutation_ImportDealerLenders,
  UseQuery_getAllCharts,
} from "services";
import {
  ILender,
  ILenderFilter,
  ICreditTierMargin,
  IFinanceTypeModel,
  IStandardCharts,
  IStandardRates,
  ICharts,
} from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ActionOptions } from "../../../Enums/ActionOptions";
import { Module, QueryKeys, ValidationMessages } from "Enums";
import { useQueryClient } from "react-query";
import DisableLoader from "shared/assets/images/loader-disabled.gif";

interface IFormInputs {
  id?: number;
  name: string;
  dealer_code?: string;
  external_code: string;
  finance_type: string[];
  lease_rate_type: string;
  program: string;
  down_payment: string;
  lender_provider?: string;
  evaluation: string;
  e_contracting: boolean;
  minimum_finance_amount: number;
  default_lender: boolean;
  is_active: boolean;
  email: string;
  contact_person_name: string;
  contact_person_phone_number: string;
  pricing_program_provider: string;
  credit_decisioning: string;
  credit_decisioning_provider: string;
  contracting_provider: string;
  signature_type: string;
  event: string;
  credit_tier_margins: ICreditTierMargin[];
  min_months_stay_at_address: number;
  configured_charts: IStandardCharts[];
  configured_rates: IStandardRates[];
}

const LenderProfile: FC<{
  module: Module;
  dealer_code?: string;
  provider?: any;
}> = ({ module, dealer_code, provider }) => {
  const [popUpState, setPopUpState] = useState(false);
  const [popUpOpenState, setPopUpOpenState] = useState("");
  const handleshow = () => {
    reset({} as IFormInputs);
    form.reset({} as any);
    setPopUpOpenState(ActionOptions.ADD);
    setPopUpState(true);
  };
  const { actions } = useStoreContext();
  const theme = useTheme();
  const form = useForm<IFormInputs>({ mode: "all" });
  const { getValues, reset, setValue, handleSubmit, setError, watch } = form;
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState<string>();
  useEffect(() => {
    let queryString: string = `show_credit_margins=true&page_number=${pageNumber}&page_size=${pageSize}`;
    if (dealer_code) {
      queryString = queryString.concat(`&dealer_code=${dealer_code}`);
    }
    setColumnFilters(queryString);
  }, [dealer_code]);

  const { data } = UseQuery_getAllLender_filter(columnFilters);
  const [lenderName, setLenderNameFilter] = useState("");
  const [externalCode, setExternalCodeFilter] = useState("");
  const [source, setSource] = useState("");
  const [financeType, setFinanceTypeFilter] = useState("");
  const [program, setProgramFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `show_credit_margins=true&page_number=${pgNo}&page_size=${pgSize}`;
    if (dealer_code) {
      query_string = query_string.concat(`&dealer_code=${dealer_code}`);
    }
    if (lenderName.trimStart() != null && lenderName.trimStart() != "") {
      query_string = query_string.concat(`&name=${lenderName}`);
    }
    if (externalCode.trimStart() != null && externalCode.trimStart() != "") {
      query_string = query_string.concat(`&external_code=${externalCode}`);
    }
    if (source.trimStart() != null && source.trimStart() != "") {
      query_string = query_string.concat(`&lender_provider=${source}`);
    }
    if (financeType.trimStart() != null && financeType.trimStart() != "") {
      query_string = query_string.concat(`&finance_type=${financeType}`);
    }
    if (program.trimStart() != null && program.trimStart() != "") {
      query_string = query_string.concat(`&program=${program}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled"
          .toLowerCase()
          .startsWith(statusFilter.slice(0, Math.max("enabled".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled"
          .toLowerCase()
          .startsWith(statusFilter.slice(0, Math.max("disabled".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const lenderFilter: ILenderFilter = data;
  const { mutate: updateLenderData } = UseMutation_UpdateLender();
  const { mutate: importDealerLender, isLoading } =
    UseMutation_ImportDealerLenders();
  const { mutate: addLender } = UseMutation_AddLender();
  UseQuery_GetAllFinanceTypes();
  const queryClient = useQueryClient();
  UseQuery_getAllCharts();
  const charts: Array<ICharts> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CHARTS
  );
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleEnableChange = (data: ILender, event) => {
    updateLenderData({
      id: data?.id,
      is_active: event.target.checked,
    });
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
    reset({} as IFormInputs);
  };

  const handleError = (error) => {
    if (
      error?.response?.data?.detail?.message ===
      "Lender name and External code should be unique"
    ) {
      setError("name", { message: "Lender name should be unique" });
      setError("external_code", {
        message: "External code should be unique",
      });
      return;
    } else if (
      error?.response?.data?.detail?.message === "Lender name should be unique"
    ) {
      setError("name", { message: "Lender name should be unique" });
      return;
    } else if (
      error?.response?.data?.detail?.message ===
      "External code should be unique"
    ) {
      setError("external_code", {
        message: "External code should be unique",
      });
      return;
    } else if (
      error?.response?.data?.detail?.message === "Default lender already exists"
    ) {
      actions.setToast({
        toastMessage: "Only 1 lender can be marked as default at a time",
        toastState: true,
        variant: "error",
      });
    } else {
      onClose();
      actions.setToast({
        toastMessage: error?.response?.data?.detail?.message,
        toastState: true,
        variant: "error",
      });
    }
  };

  const handleSaveLender = (form: any) => {
    const creditTierMargins = getValues("credit_tier_margins");

    if (creditTierMargins && creditTierMargins?.length > 0) {
      const hasDuplicates = creditTierMargins.some(
        (firstRecord: ICreditTierMargin, firstIndex: number) =>
          creditTierMargins.some(
            (secondRecord: ICreditTierMargin, secondIndex: number) =>
              secondIndex !== firstIndex &&
              secondRecord.is_active !== false &&
              firstRecord.is_active !== false &&
              firstRecord.finance_type === secondRecord.finance_type &&
              firstRecord.tier_name === secondRecord.tier_name
          )
      );

      if (hasDuplicates) {
        actions.setToast({
          toastMessage: ValidationMessages.DUPLICATED_CREDIT_TIER_MARGIN,
          toastState: true,
          variant: "error",
        });
        return;
      }
    }
    const data = {
      name: form.name,
      external_code: form.external_code,
      finance_type: form.finance_type,
      lease_rate_type: form.lease_rate_type,
      program: form.program,
      down_payment: form.down_payment,
      evaluation: form.evaluation,
      pricing_program: form.e_contracting,
      minimum_financed_amount: form.minimum_finance_amount,
      default_lender:
        lenderFilter?.result?.length === 0 ? true : form.default_lender,
      is_active: form.is_active,
      email: form.email,
      contact_person_name: form.contact_person_name,
      contact_person_phone_number: form.contact_person_phone_number,
      pricing_program_provider: form.pricing_program_provider,
      credit_decisioning: form.credit_decisioning,
      credit_decisioning_provider: form.credit_decisioning_provider,
      contracting_provider: form.contracting_provider,
      signature_type: form.signature_type,
      event: form.event,
      lender_provider: "Company",
      credit_tier_margins: creditTierMargins?.filter(
        (record) => record.is_active !== false
      ),
      min_months_stay_at_address: form.min_months_stay_at_address,
      ...(form.program?.includes("Standard Charts") && {
        configured_charts: [
          {
            interest_rate_chart_id: form.interest_rate_chart_id?.id,
            commission_chart_id: form.commission_chart_id?.id,
            rv_balloon_rate_chart_id: form.rv_balloon_rate_chart_id?.id,
            fees_chart_id: form.fees_chart_id?.id,
          },
        ],
      }),
      ...(form.program?.includes("Standard Rates") && {
        configured_rates: form?.finance_type?.map((fp) => {
          if (fp === "Finance") {
            return {
              finance_type: "Finance",
              customer_rate:
                form.finance_customerRate !== ""
                  ? form.finance_customerRate
                  : 0,
              down_payment_rate:
                form.finance_downPayment !== "" ? form.finance_downPayment : 0,
              rv_balloon_rate:
                form.finacnce_rvBalloon !== "" ? form.finacnce_rvBalloon : 0,
              min_down_payment_rate:
                form.finance_mindownpaymentrate !== ""
                  ? form.finance_mindownpaymentrate
                  : 0,
              max_down_payment_rate:
                form.finance_maxdownpaymentrate !== ""
                  ? form.finance_maxdownpaymentrate
                  : 0,
            };
          } else if (fp === "Lease") {
            return {
              finance_type: "Lease",
              customer_rate:
                form.lease_customerRate !== "" ? form.lease_customerRate : 0,
              down_payment_rate:
                form.lease_downPayment !== "" ? form.lease_downPayment : 0,
              rv_balloon_rate:
                form.lease_rvBalloon !== "" ? form.lease_rvBalloon : 0,
              min_down_payment_rate:
                form.lease_mindownpaymentrate !== ""
                  ? form.lease_mindownpaymentrate
                  : 0,
              max_down_payment_rate:
                form.lease_maxdownpaymentrate !== ""
                  ? form.lease_maxdownpaymentrate
                  : 0,
            };
          } else {
            return {
              Finance: {
                customer_rate:
                  form.finance_customerRate !== ""
                    ? form.finance_customerRate
                    : 0,
                down_payment_rate:
                  form.finance_downPayment !== ""
                    ? form.finance_downPayment
                    : 0,
                rv_balloon_rate:
                  form.finacnce_rvBalloon !== "" ? form.finacnce_rvBalloon : 0,
                min_down_payment_rate:
                  form.finance_mindownpaymentrate !== ""
                    ? form.finance_mindownpaymentrate
                    : 0,
                max_down_payment_rate:
                  form.finance_maxdownpaymentrate !== ""
                    ? form.finance_maxdownpaymentrate
                    : 0,
              },
              Lease: {
                customer_rate:
                  form.lease_customerRate !== "" ? form.lease_customerRate : 0,
                down_payment_rate:
                  form.lease_downPayment !== "" ? form.lease_downPayment : 0,
                rv_balloon_rate:
                  form.lease_rvBalloon !== "" ? form.lease_rvBalloon : 0,
                min_down_payment_rate:
                  form.lease_mindownpaymentrate !== ""
                    ? form.lease_mindownpaymentrate
                    : 0,
                max_down_payment_rate:
                  form.lease_maxdownpaymentrate !== ""
                    ? form.lease_maxdownpaymentrate
                    : 0,
              },
            };
          }
        }),
      }),
    };
    if (popUpOpenState === ActionOptions.ADD) {
      addLender(data, {
        onSuccess() {
          actions.setToast({
            toastMessage: "Record Added Successfully",
            toastState: true,
          });
          onClose();
        },
        onError: (error) => {
          handleError(error);
        },
      });
    } else {
      updateLenderData(
        {
          name: form.name,
          external_code: form.external_code,
          finance_type: form.finance_type,
          lease_rate_type: form.lease_rate_type,
          program: form.program,
          down_payment: form.down_payment,
          evaluation: form.evaluation,
          dealer_code: dealer_code,
          pricing_program: form.e_contracting,
          minimum_financed_amount: form.minimum_finance_amount,
          default_lender: form.default_lender,
          is_active: form.is_active,
          email: form.email,
          contact_person_name: form.contact_person_name,
          contact_person_phone_number: form.contact_person_phone_number,
          id: getValues("id"),
          pricing_program_provider: form.pricing_program_provider,
          credit_decisioning: form.credit_decisioning,
          credit_decisioning_provider: form.credit_decisioning_provider,
          contracting_provider: form.contracting_provider,
          signature_type: form.signature_type,
          event: form.event,
          lender_provider: form.lender_provider,
          credit_tier_margins: creditTierMargins?.filter(
            (record) => !(record.is_active === false && !record.id)
          ),
          min_months_stay_at_address: form.min_months_stay_at_address,
          ...(form.program?.includes("Standard Charts") && {
            configured_charts: [
              {
                interest_rate_chart_id:
                  form.interest_rate_chart_id?.id !== ""
                    ? form.interest_rate_chart_id?.id
                    : null,
                commission_chart_id:
                  form.commission_chart_id?.id !== ""
                    ? form.commission_chart_id?.id
                    : null,
                rv_balloon_rate_chart_id:
                  form.rv_balloon_rate_chart_id?.id !== ""
                    ? form.rv_balloon_rate_chart_id?.id
                    : null,
                fees_chart_id:
                  form.fees_chart_id?.id !== "" ? form.fees_chart_id?.id : null,
              },
            ],
          }),
          ...(form.program?.includes("Standard Rates") && {
            configured_rates: form?.finance_type?.map((fp) => {
              if (fp === "Finance") {
                return {
                  finance_type: "Finance",
                  customer_rate:
                    form.finance_customerRate !== ""
                      ? form.finance_customerRate
                      : 0,
                  down_payment_rate:
                    form.finance_downPayment !== ""
                      ? form.finance_downPayment
                      : 0,
                  rv_balloon_rate:
                    form.finacnce_rvBalloon !== ""
                      ? form.finacnce_rvBalloon
                      : 0,
                  min_down_payment_rate:
                    form.finance_mindownpaymentrate !== ""
                      ? form.finance_mindownpaymentrate
                      : 0,
                  max_down_payment_rate:
                    form.finance_maxdownpaymentrate !== ""
                      ? form.finance_maxdownpaymentrate
                      : 0,
                };
              } else if (fp === "Lease") {
                return {
                  finance_type: "Lease",
                  customer_rate:
                    form.lease_customerRate !== ""
                      ? form.lease_customerRate
                      : 0,
                  down_payment_rate:
                    form.lease_downPayment !== "" ? form.lease_downPayment : 0,
                  rv_balloon_rate:
                    form.lease_rvBalloon !== "" ? form.lease_rvBalloon : 0,
                  min_down_payment_rate:
                    form.lease_mindownpaymentrate !== ""
                      ? form.lease_mindownpaymentrate
                      : 0,
                  max_down_payment_rate:
                    form.lease_maxdownpaymentrate !== ""
                      ? form.lease_maxdownpaymentrate
                      : 0,
                };
              } else {
                return {
                  Finance: {
                    customer_rate:
                      form.finance_customerRate !== ""
                        ? form.finance_customerRate
                        : 0,
                    down_payment_rate:
                      form.finance_downPayment !== ""
                        ? form.finance_downPayment
                        : 0,
                    rv_balloon_rate:
                      form.finacnce_rvBalloon !== ""
                        ? form.finacnce_rvBalloon
                        : 0,
                    min_down_payment_rate:
                      form.finance_mindownpaymentrate !== ""
                        ? form.finance_mindownpaymentrate
                        : 0,
                    max_down_payment_rate:
                      form.finance_maxdownpaymentrate !== ""
                        ? form.finance_maxdownpaymentrate
                        : 0,
                  },
                  Lease: {
                    customer_rate:
                      form.lease_customerRate !== ""
                        ? form.lease_customerRate
                        : 0,
                    down_payment_rate:
                      form.lease_downPayment !== ""
                        ? form.lease_downPayment
                        : 0,
                    rv_balloon_rate:
                      form.lease_rvBalloon !== "" ? form.lease_rvBalloon : 0,
                    min_down_payment_rate:
                      form.lease_mindownpaymentrate !== ""
                        ? form.lease_mindownpaymentrate
                        : 0,
                    max_down_payment_rate:
                      form.lease_maxdownpaymentrate !== ""
                        ? form.lease_maxdownpaymentrate
                        : 0,
                  },
                };
              }
            }),
          }),
        },
        {
          onSuccess() {
            reset({} as IFormInputs);
            setPopUpState(false);
            actions.setToast({
              toastMessage: "Record Updated Successfully",
              toastState: true,
            });
          },
          onError(error: any) {
            setPopUpState(true);
            handleError(error);
          },
        }
      );
    }
  };
  const handleEditLenderProfile = (item: any) => {
    setValue("id", item?.id);
    const selectedCommisionChart = charts?.find(
      (x) => x.id === item?.configured_charts?.[0]?.commission_chart_id
    );
    const selectedInterestChart = charts?.find(
      (x) => x.id === item?.configured_charts?.[0]?.interest_rate_chart_id
    );
    const selectedRVBalloonChart = charts?.find(
      (x) => x.id === item?.configured_charts?.[0]?.rv_balloon_rate_chart_id
    );
    const selectedFeesChart = charts?.find(
      (x) => x.id === item?.configured_charts?.[0]?.fees_chart_id
    );
    reset({
      ...item,
      e_contracting: item?.pricing_program,
      contact_person_name: item?.contact_person_name,
      email: item?.email,
      contact_person_phone_number: item?.contact_person_phone_number,
      ...item?.configured_rates?.reduce((result, rate) => {
        if (rate.finance_type === "Finance") {
          return {
            ...result,
            finance_downPayment: rate?.down_payment_rate,
            finacnce_rvBalloon: rate?.rv_balloon_rate,
            finance_customerRate: rate?.customer_rate,
            finance_mindownpaymentrate: rate?.min_down_payment_rate,
            finance_maxdownpaymentrate: rate?.max_down_payment_rate,
          };
        } else {
          return {
            ...result,
            lease_downPayment: rate?.down_payment_rate,
            lease_rvBalloon: rate?.rv_balloon_rate,
            lease_customerRate: rate?.customer_rate,
            lease_mindownpaymentrate: rate?.min_down_payment_rate,
            lease_maxdownpaymentrate: rate?.max_down_payment_rate,
          };
        }
      }, {}),
      commission_chart_id: {
        label: selectedCommisionChart?.chart_name ?? "",
        id: selectedCommisionChart?.id ?? "",
      },
      interest_rate_chart_id: {
        label: selectedInterestChart?.chart_name ?? "",
        id: selectedInterestChart?.id ?? "",
      },
      rv_balloon_rate_chart_id: {
        label: selectedRVBalloonChart?.chart_name ?? "",
        id: selectedRVBalloonChart?.id ?? "",
      },
      fees_chart_id: {
        label: selectedFeesChart?.chart_name ?? "",
        id: selectedFeesChart?.id ?? "",
      },
    });
    setPopUpState(true);
    setPopUpOpenState(ActionOptions.EDIT);
  };
  // Function to capitalize the first letter of each word
  const capitalizeFirstLetter = (word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  };
  return (
    <>
      <DrawerWrap open={popUpState}>
        <LayoutWithSideNav theme={theme}>
          <PageHeader
            className="main-page-header"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ fontSize: 36 }}
                  rowGap={5}
                  mt={1}
                  theme={theme}
                  children={
                    module === Module.LENDER_MODULE
                      ? "Lender Profile"
                      : "Lender Management"
                  }
                />
              </Grid>
              <Grid
                theme={theme}
                item
                xs={12}
                lg={5}
                sm={12}
                md={6}
                textAlign="right"
              >
                {module === Module.LENDER_MODULE ? (
                  <Button
                    theme={theme}
                    primary
                    text="Add New Lender"
                    onClick={handleshow}
                    sx={{ mb: 5 }}
                  />
                ) : (
                  <Button
                    theme={theme}
                    primary
                    disabled={
                      !provider?.provider_name || isLoading ? true : false
                    }
                    text="Import Lender"
                    endIcon={
                      isLoading && <img src={DisableLoader} alt="Loader" />
                    }
                    onClick={() => {
                      importDealerLender({
                        dealer_code: dealer_code,
                        provider: provider?.provider_name,
                      });
                    }}
                    sx={{ mb: 5 }}
                  />
                )}
              </Grid>
            </Grid>
          </PageHeader>

          <DataTableWrap theme={theme} className="table-pagination">
            <Box theme={theme} className="scroll">
              <Box className="scroll-hide spr-border" theme={theme} />
            </Box>
            <DataTable
              theme={theme}
              variant="basic"
              theadChildren={
                <>
                  <TableRow>
                    {/* <TableCell className="checkbox-cell fixed-cell">
                      <Checkbox label="" theme={theme} />
                    </TableCell> */}
                    <TableCell>Lender Name</TableCell>
                    <TableCell>External Code</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Finance Type(s)</TableCell>
                    <TableCell>Program</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell className="action-cell fixed-cell" />
                  </TableRow>
                  <TableRow className="filters-row">
                    {/* <TableCell className="checkbox-cell fixed-cell"></TableCell> */}
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"lenderName"}
                          onChange={(value) => {
                            setLenderNameFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={lenderName?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"externalCode"}
                          onChange={(value) => {
                            setExternalCodeFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={externalCode?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"source"}
                          onChange={(value) => {
                            setSource(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={source?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"financeType"}
                          onChange={(value) => {
                            setFinanceTypeFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={financeType?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"financeType"}
                          onChange={(value) => {
                            setProgramFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={program?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"status"}
                          onChange={(value) => {
                            setStatusFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={statusFilter?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell className="action-cell fixed-cell" />
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {lenderFilter?.result &&
                    lenderFilter?.result?.map((item, index) => (
                      <TableRow className="child-tr" key={index}>
                        {/* <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox label="" theme={theme} />
                  </TableCell> */}
                        <TableCell>
                          {item?.default_lender ? (
                            <Box theme={theme} display={"flex"}>
                              {item?.name}
                              <>&nbsp;</>
                              <Typography
                                theme={theme}
                                variant="body2"
                                component="span"
                                display={"block"}
                                className="fw-bold"
                              >
                                (Default)
                              </Typography>
                            </Box>
                          ) : (
                            item?.name
                          )}
                        </TableCell>
                        <TableCell>{item.external_code}</TableCell>
                        <TableCell>{item.lender_provider}</TableCell>
                        <TableCell>
                          {item &&
                            item?.finance_type
                              ?.map((type) => capitalizeFirstLetter(type))
                              ?.join(", ")}
                        </TableCell>
                        <TableCell>{item && item?.program}</TableCell>
                        <TableCell>
                          <Switch
                            id={"ListDataStatus" + item?.id}
                            theme={theme}
                            varient={"basic"}
                            switchEnabled={item.is_active}
                            onChange={(event) =>
                              handleEnableChange(item, event)
                            }
                            disabled={
                              item?.lender_provider == "Company" &&
                              module == Module.DEALER_MODULE
                            }
                            label={item?.is_active ? "Enabled" : "Disabled"}
                          />
                        </TableCell>
                        <TableCell className="action-cell fixed-cell">
                          <Menu
                            theme={theme}
                            options={[
                              {
                                optionText: (
                                  <>
                                    <Icon
                                      className="menu-icon"
                                      name="EditIcon"
                                    />{" "}
                                    Edit
                                  </>
                                ),
                                optionValue: "edit",
                              },
                            ]}
                            handleOptionClick={(_event, _key, value) => {
                              switch (value) {
                                case "edit":
                                  handleEditLenderProfile(item);
                                  break;
                              }
                            }}
                            render={(onMenuSelection) => (
                              <Button
                                defaultBtn
                                iconText={<Icon name="MoreIcon" />}
                                onClick={onMenuSelection}
                              ></Button>
                            )}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              }
            />
            <PaginationWrap>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={lenderFilter ? lenderFilter?.total_results : -1}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  IconComponent: ChevronDown,
                  MenuProps: { disablePortal: true },
                }}
              />
            </PaginationWrap>
          </DataTableWrap>

          <PersistentDrawer
            title={
              popUpOpenState === ActionOptions.ADD
                ? "Add New Lender"
                : "Edit Lender"
            }
            openPopUp={popUpState}
            setPopUpState={setPopUpState}
            theme={theme}
            enabledPin={true}
            customFooter={
              <Button
                theme={theme}
                primary
                type="submit"
                text="Save Lender"
                fullWidth
                disabled={
                  popUpOpenState == ActionOptions.EDIT &&
                  getValues("lender_provider") == "Company" &&
                  module == Module.DEALER_MODULE
                }
                onClick={handleSubmit(handleSaveLender)}
              />
            }
          >
            <AddNewLender
              form={form}
              popUpOpenState={popUpOpenState}
              module={module}
            />
          </PersistentDrawer>
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default LenderProfile;
