import { INewFeesChartsPopUp } from "./AddNewFeesChart.type";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/material";
import {
  PersistentDrawer,
  Autocomplete,
  Icon,
  MultiSelect,
  Box,
  Button,
  Input,
  Select,
  Grid,
} from "@ntpkunity/controls";
import { ConfirmationDialog, MultiSelectWrap } from "@sharedComponents";
import { IChartCriteria, ICharts, ICriteria, ISelectItemList } from "Interface";
import {
  UseMutation_AddCharts,
  UseMutation_UpdateCharts,
  useMutation_AddNewFeesCriteria,
  useMutation_AddNewFeesCharts,
  useMutation_UpdateFeesChart,
  UseMutation_CheckDuplicateRVChartName,
  UseMutation_CheckDuplicateFeesChartName,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { logout } from "@ntpkunity/controls-ums";
import {
  ActionOptions,
  DialogMessages,
  ToastMessages,
  ValidationMessages,
} from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { Co2Sharp, ContactPageSharp } from "@mui/icons-material";

const AddNewFeesChart: FC<INewFeesChartsPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  feeChartData,
  productTypes,
  financeTypes,
  customerTypes,
  assetCategories,
  assetType,
  AllFinancedAmounts,
  terms,
  assetConditions,
  feesCharts,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    resetField,
    getValues,
    watch,
    setError,
    trigger,
    clearErrors,
    formState: { errors, isValid, isDirty, isValidating },
  } = useForm<IChartCriteria>({
    mode: "all",
  });

  const [state, dispatch] = useSetupsStore();
  const { actions } = useStoreContext();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [assetUsage, setAssetUsage] = useState([]);

  var [assetTerm, setAssetTerm] = useState([]);

  const [customerType, setCustomerType] = useState([]);
  const [financeType, setFinanceType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [financedAmounts, setFinancedAmounts] = useState([]);
  const [assetTermId, setAssetTermId] = useState([]);
  const [assetCondition, setAssetCondition] = useState([]);
  const [assetCategory, setAssetCategory] = useState([]);
  const [assettype, setAssetType] = useState([]);
  const [chartId, setChartId] = useState(null);
  const [chartEnable, setChartEnable] = useState<boolean>(false);
  const [criteriaEnable, setCriteriaEnable] = useState<boolean>(false);
  const [isChartAdded, setIsChartAdded] = useState<boolean>(false);
  const [chartNameValidation, setChartNameValidation] =
    useState<boolean>(false);

  const { mutate: AddFeesChart } = useMutation_AddNewFeesCharts();
  const { mutate: AddChart } = UseMutation_AddCharts();
  const { mutate: UpdateChart } = UseMutation_UpdateCharts();
  const { mutate: AddNewFeesCriteria } = useMutation_AddNewFeesCriteria();
  const { mutate: UpdateFeesCriteria } = useMutation_UpdateFeesChart();
  const { mutate: CheckNameDuplicate } =
    UseMutation_CheckDuplicateFeesChartName();

  const [customerTypeSelection, setCustomerTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [financeTypeSelection, setFinanceTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [productTypeSelection, setProductTypeSelectionState] =
    useState<Array<ISelectItemList>>();
  const [financedAmountsSelection, setFinancedAmountsSelectionState] =
    useState<Array<ISelectItemList>>();
  const [termsSelection, setTermsSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetConditionSelection, setAssetConditionSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetCategorySelection, setAssetCategortSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetTypeSelection, setAssetTypeSelection] =
    useState<Array<ISelectItemList>>();

  const validateFeeName = async (feeName: string) => {
    if (feeChartData?.chart?.id) {
      const isDuplicate = feesCharts?.some(
        (chart) =>
          chart.fee_name.toLowerCase() === feeName.trim().toLowerCase() &&
          chart.chart_id === feeChartData?.chart?.id
      );
      return isDuplicate;
    } else {
      return false;
    }
  };

  const feeType = watch("criteria.fee_type");

  const feeFrequencyOptions =
    feeType === "One time"
      ? [
          { text: "On start date", value: "On start date" },
          {
            text: "With first regular payment",
            value: "With first regular payment",
          },
          {
            text: "With last regular payment",
            value: "With last regular payment",
          },
        ]
      : feeType === "Periodic"
      ? [
          { text: "With every payment", value: "With every payment" },
          { text: "Monthly", value: "Monthly" },
          { text: "Quarterly", value: "Quarterly" },
          { text: "Semi-Annual", value: "Semi-Annual" },
          { text: "Annual", value: "Annual" },
        ]
      : [];

  const resetFormValues = () => {
    setCustomerType([]);
    setFinanceType([]);
    setProductType([]);
    setFinancedAmounts([]);
    setAssetTermId([]);
    setAssetCondition([]);
    setAssetCategory([]);
    setAssetType([]);
    resetField("criteria.fee_name");
    resetField("criteria.fee_type");
    resetField("criteria.fee_frequency");
    resetField("criteria.due_at_start_date");
    resetField("criteria.fee_amount");
    resetField("criteria.override_allowed");
  };

  const validateFeesChart = (data: any) => {
    let hasError = false;

    const isRequired =
      data?.criteria?.customer_type?.length > 0 ||
      data?.criteria?.product_type?.length > 0 ||
      data?.criteria?.finance_type?.length > 0 ||
      data?.criteria?.financed_amount_range?.length > 0 ||
      data?.criteria?.terms?.length > 0 ||
      data?.criteria?.asset_condition?.length > 0 ||
      data?.criteria?.asset_category?.length > 0 ||
      data?.criteria?.asset_type?.length > 0;

    const criteria = data?.criteria || {};

    if (isRequired) {
      if (!criteria.fee_name) {
        setError("criteria.fee_name", {
          type: "required",
          message: "Fee Name is required",
        });
        hasError = true;
      }
      if (!criteria.fee_type) {
        setError("criteria.fee_type", {
          type: "required",
          message: "Fee Type is required",
        });
        setError("criteria.fee_frequency", {
          type: "required",
          message: "Fee Frequency is required",
        });
        hasError = true;
      }
      if (!criteria.fee_frequency) {
        setError("criteria.fee_frequency", {
          type: "required",
          message: "Fee Frequency is required",
        });
        hasError = true;
      }
      if (criteria.due_at_start_date == undefined) {
        setError("criteria.due_at_start_date", {
          type: "required",
          message: "Due At Start Date is required",
        });
        hasError = true;
      }
      if (!criteria.fee_amount) {
        setError("criteria.fee_amount", {
          type: "required",
          message: "Fee Amount is required",
        });
        hasError = true;
      }
      if (criteria.override_allowed == undefined) {
        setError("criteria.override_allowed", {
          type: "required",
          message: "Override Allowed is required",
        });
        hasError = true;
      }
    }
    return hasError;
  };

  const onSubmit = (data: IChartCriteria) => {
    data.chart.chart_type_id = 7;

    data.criteria.product_type = productType;
    data.criteria.finance_type = financeType;
    data.criteria.customer_type = customerType;
    data.criteria.financed_amount_range = financedAmounts;
    data.criteria.terms = assetTermId;
    data.criteria.asset_condition = assetCondition;
    data.criteria.asset_category = assetCategory;
    data.criteria.asset_type = assettype;
    data.criteria.fee_name = data.criteria.fee_name.trim();

    const hasError = validateFeesChart(data);
    if (hasError) {
      return;
    }
    if (actionType == ActionOptions.ADD_NEW_CHART) {
      if (
        chartId == null &&
        data?.criteria?.customer_type.length == 0 &&
        data?.criteria?.product_type.length == 0 &&
        data?.criteria?.finance_type.length == 0 &&
        data?.criteria?.financed_amount_range.length == 0 &&
        data?.criteria?.terms.length == 0 &&
        data?.criteria?.asset_condition.length == 0 &&
        data?.criteria?.asset_category.length == 0 &&
        data?.criteria?.asset_type.length == 0
      ) {
        AddChart(data.chart, {
          onSuccess: (dataa, variables, context) => {
            setChartId(dataa.data.id);
            setValue("chart.chart_name", dataa.data.chart_name);
            setValue("chart.is_active", dataa.data.is_active);
            setIsChartAdded(true);
            setChartEnable(true);
            resetFormValues();
          },
          onError: (error) => {
            reset(undefined);
            setValue("chart.chart_name", data.chart.chart_name);
            if (error?.response?.data?.detail?.code === 400) {
              setError("chart.chart_name", {
                message: "Chart Name should be unique",
              });
              return;
            } else {
              actions.setToast({
                toastMessage:
                  error.response.data.detail.message ??
                  ToastMessages.Internal_Server_Error,
                toastState: true,
                variant: "error",
              });
            }
          },
        });
      } else if (
        data?.criteria?.customer_type.length != 0 ||
        data?.criteria?.product_type.length != 0 ||
        data?.criteria?.finance_type.length != 0 ||
        data?.criteria?.financed_amount_range.length != 0 ||
        data?.criteria?.terms.length != 0 ||
        data?.criteria?.asset_condition.length != 0 ||
        data?.criteria?.asset_category.length != 0 ||
        data?.criteria?.asset_type.length != 0
      ) {
        if (chartId != null) {
          data.criteria.chart_id = chartId;
          addCriteria(data.criteria);
          setIsChartAdded(true);
          setChartEnable(true);
          resetFormValues();
        } else {
          addNewChartWithCriteria(data);
          setIsChartAdded(true);
          setChartEnable(true);
          resetFormValues();
        }
      }
    } else if (actionType == ActionOptions.RENAME_CHART) {
      data.chart.id = feeChartData.chart.id;
      updateChart(data.chart);
    } else if (actionType == ActionOptions.ADD_CRITERIA) {
      data.criteria.chart_id = feeChartData.chart.id;
      addCriteria(data.criteria);
      setChartEnable(true);
    } else if (actionType == ActionOptions.EDIT) {
      data.criteria.chart_id = feeChartData?.chart.id;
      data.criteria.id = feeChartData?.criteria.id;
      updateCriteria(data.criteria);
    }
  };

  useEffect(() => {
    if (actionType === ActionOptions.RENAME_CHART && feeChartData) {
      setCriteriaEnable(true);
      setChartEnable(false);
      setValue("chart.chart_name", feeChartData.chart?.chart_name);
      setValue("chart.is_active", feeChartData.chart?.is_active);
    } else if (actionType === ActionOptions.ADD_CRITERIA && feeChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setValue("chart.chart_name", feeChartData.chart?.chart_name);
      setValue("chart.is_active", feeChartData.chart?.is_active);
    } else if (actionType === ActionOptions.ADD_NEW_CHART && !isChartAdded) {
    }
    //edit criteria
    else if (actionType === ActionOptions.EDIT && feeChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setValue("chart.chart_name", feeChartData?.chart?.chart_name);
      setValue("chart.is_active", feeChartData?.chart?.is_active);
      setValue("criteria.id", feeChartData?.criteria?.id);

      if (feeChartData?.criteria?.terms) {
        setAssetTermId(feeChartData?.criteria?.terms);
      }
      if (feeChartData?.criteria?.asset_condition) {
        setAssetCondition(feeChartData?.criteria?.asset_condition);
      }
      if (feeChartData?.criteria?.asset_category) {
        setAssetCategory(feeChartData?.criteria?.asset_category);
      }
      if (feeChartData?.criteria?.financed_amount_range) {
        setFinancedAmounts(feeChartData?.criteria?.financed_amount_range);
      }
      if (feeChartData?.criteria?.asset_type) {
        setAssetType(feeChartData?.criteria?.asset_type);
      }
      if (feeChartData?.criteria?.product_type) {
        setProductType(feeChartData?.criteria?.product_type);
      }
      if (feeChartData?.criteria?.finance_type) {
        setFinanceType(feeChartData?.criteria?.finance_type);
      }
      if (feeChartData?.criteria?.customer_type) {
        setCustomerType(feeChartData?.criteria?.customer_type);
      }

      if (feeChartData?.criteria?.fee_name) {
        setValue("criteria.fee_name", feeChartData?.criteria?.fee_name);
      }

      if (feeChartData?.criteria?.fee_type) {
        setValue("criteria.fee_type", feeChartData?.criteria?.fee_type);
      }

      if (feeChartData?.criteria?.fee_frequency) {
        setValue(
          "criteria.fee_frequency",
          feeChartData?.criteria?.fee_frequency
        );
      }
      if (feeChartData?.criteria?.due_at_start_date != undefined) {
        setValue(
          "criteria.due_at_start_date",
          feeChartData?.criteria?.due_at_start_date
        );
      }
      if (feeChartData?.criteria?.fee_amount) {
        setValue("criteria.fee_amount", feeChartData?.criteria?.fee_amount);
      }
      if (feeChartData?.criteria?.override_allowed != undefined) {
        setValue(
          "criteria.override_allowed",
          feeChartData?.criteria?.override_allowed
        );
      }
    }
  }, [feeChartData, criteriaEnable]);

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };

  const checkFeeChartName = (data: string) => {
    CheckNameDuplicate(
      {
        data: `name=${data}`,
      },
      {
        onSuccess: (response) => {
          setChartNameValidation(response.data);
          trigger("chart.chart_name");
          if (response.data) {
            setError(
              "chart.chart_name",
              {
                message: ValidationMessages.NAME_UNIQUE,
              },
              { shouldFocus: true }
            );
          } else {
            clearErrors("chart.chart_name");
          }
        },
      }
    );
  };

  useEffect(() => {
    const customerTypeArray = customerTypes
      ?.filter((x) => x.is_active)
      ?.map((customer) => ({
        value: `${customer.description}`,
        text: `${customer.description}`,
      }));
    customerType?.forEach((id) => {
      const selectedCustomerType = customerTypes?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedCustomerType &&
        customerTypeArray.push({
          value: selectedCustomerType?.description,
          text: selectedCustomerType?.description,
        });
    });
    setCustomerTypeSelection(customerTypeArray);
  }, [customerTypes, customerType]);

  useEffect(() => {
    const financeTypeArray = financeTypes
      ?.filter((x) => x.is_active)
      ?.map((finance) => ({
        value: `${finance.name}`,
        text: `${finance.name}`,
      }));
    financeTypes?.forEach((id) => {
      const selectedFinanceType = financeType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinanceType &&
        financeTypeArray.push({
          value: selectedFinanceType?.name,
          text: selectedFinanceType?.name,
        });
    });
    setFinanceTypeSelection(financeTypeArray);
  }, [financeTypes, financeType]);

  useEffect(() => {
    const productTypeArray = productTypes
      ?.filter((x) => x.is_active)
      ?.map((product) => ({
        value: `${product.name}`,
        text: `${product.name}`,
      }));
    productType?.forEach((id) => {
      const selectedProductType = productTypes?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedProductType &&
        productTypeArray.push({
          value: selectedProductType?.name,
          text: selectedProductType?.name,
        });
    });
    setProductTypeSelectionState(productTypeArray);
  }, [productTypes, productType]);

  useEffect(() => {
    const termsArray = terms
      ?.filter((x) => x.is_active)
      ?.map((term) => ({
        value: `${term.terms_from}-${term.terms_to}`,
        text: `${term.terms_from} - ${term.terms_to}`,
      }));
    assetTerm?.forEach((id) => {
      const selectedTerms = terms?.find((x) => x.id == id && !x.is_active);
      selectedTerms &&
        termsArray.push({
          value: `${selectedTerms.terms_from}-${selectedTerms.terms_to}`,
          text: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
        });
    });
    setTermsSelectionState(termsArray);
  }, [terms, assetTerm]);

  useEffect(() => {
    const assetConditionArray = assetConditions
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetCondition?.forEach((desc) => {
      const selectedAssetConditions = assetConditions?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetConditions &&
        assetConditionArray.push({
          value: selectedAssetConditions?.description,
          text: selectedAssetConditions?.description,
        });
    });
    setAssetConditionSelectionState(assetConditionArray);
  }, [assetConditions, assetCondition]);

  useEffect(() => {
    const assetCategoryArray = assetCategories
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetCategory?.forEach((desc) => {
      const selectedAssetCategory = assetCategories?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetCategory &&
        assetCategoryArray.push({
          value: selectedAssetCategory?.description,
          text: selectedAssetCategory?.description,
        });
    });
    setAssetCategortSelectionState(assetCategoryArray);
  }, [assetCategories, assetCategory]);

  useEffect(() => {
    const assetTypeArray = assetType
      ?.filter((x) => x.is_active || x.is_active === null)
      ?.map((asset) => ({
        value: `${asset.name}`,
        text: `${asset.name}`,
      }));
    assettype.forEach((id) => {
      const selectedAssetType = assetType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedAssetType &&
        assetTypeArray.push({
          value: selectedAssetType?.name,
          text: selectedAssetType?.name,
        });
    });
    setAssetTypeSelection(assetTypeArray);
  }, [assetType, assettype]);

  useEffect(() => {
    const financedAmountArray = AllFinancedAmounts?.filter(
      (x) => x.is_active
    )?.map((financed) => ({
      value: `${financed.financed_amount_from}-${financed.financed_amount_to}`,
      text: `${financed.financed_amount_from.toLocaleString()} - ${financed.financed_amount_to.toLocaleString()}`,
    }));
    financedAmounts?.forEach((id) => {
      const selectedFinancedAmounts = AllFinancedAmounts?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedAmounts &&
        financedAmountArray.push({
          value: `${selectedFinancedAmounts.financed_amount_from}-${selectedFinancedAmounts.financed_amount_to}`,
          text: `${selectedFinancedAmounts.financed_amount_from.toLocaleString()} - ${selectedFinancedAmounts.financed_amount_to.toLocaleString()}`,
        });
    });
    setFinancedAmountsSelectionState(financedAmountArray);
  }, [AllFinancedAmounts, financedAmounts]);

  const onCustomerTypeSelection = (e) => {
    setCustomerType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onProductTypeSelection = (e) => {
    setProductType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onFinanceTypeSelection = (e) => {
    setFinanceType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onFinancedAmountSelection = (e) => {
    setFinancedAmounts(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onTermSelection = (e) => {
    setAssetTermId(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onConditionSelection = (e) => {
    setAssetCondition(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onCategorySelection = (e) => {
    setAssetCategory(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onTypeSelection = (e) => {
    setAssetType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const addNewChartWithCriteria = (data: IChartCriteria) => {
    AddFeesChart(data, {
      onSuccess: (data) => {
        setChartId(data.data.id);
      },
    });
  };

  const addCriteria = (data: ICriteria) => {
    AddNewFeesCriteria(data);
  };

  const updateCriteria = (data: ICriteria) => {
    UpdateFeesCriteria(data);
    onClose();
  };

  const updateChart = (data: ICharts) => {
    UpdateChart(data);
    reset();
    onClose();
  };

  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        onClose={onClose}
        enabledPin={true}
        children={
          <>
            <Controller
              name="chart.chart_name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: ValidationMessages.CHART_NAME_REQUIRED,
                },
                validate: {
                  checkUniqueName: () => {
                    if (chartNameValidation) {
                      return ValidationMessages.NAME_UNIQUE;
                    }
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerChartName"
                  fullWidth={true}
                  theme={theme}
                  label={"Chart Name"}
                  placeholder={"Type Here"}
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onBlur={(e) => checkFeeChartName(e.target.value)}
                  disabled={chartEnable}
                  error={errors?.chart?.chart_name?.message}
                  type="text"
                />
              )}
            />
            <Controller
              name="chart.is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerStatus"
                  theme={theme}
                  label={"Status"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                  disabled={chartEnable}
                ></Select>
              )}
            />
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                BUSINESS CRITERIA
              </Box>
            </Box>

            <MultiSelectWrap>
              <Controller
                name="criteria.customer_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    id="DrawerCustomerType"
                    label={"Customer Type"}
                    value={customerType || []}
                    items={customerTypeSelection}
                    onChange={onCustomerTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.product_type"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerProductType"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Product Type"}
                    value={productType || []}
                    items={productTypeSelection}
                    onChange={onProductTypeSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.finance_type"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerFinanceType"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Finance Type"}
                    value={financeType || []}
                    items={financeTypeSelection}
                    onChange={onFinanceTypeSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.financed_amount_range"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerFinancedAmountRange"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Financed Amount"}
                    value={financedAmounts || []}
                    items={financedAmountsSelection}
                    onChange={onFinancedAmountSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.terms"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    theme={theme}
                    id="drawerTerms"
                    label={"Terms"}
                    value={assetTermId || []}
                    items={termsSelection}
                    onChange={onTermSelection}
                    disabled={criteriaEnable}
                    sxProps={{ m: 0, width: "100%" }}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_condition"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetCondition"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Condition"}
                    value={assetCondition || []}
                    items={assetConditionSelection}
                    onChange={onConditionSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_category"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetCategory"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Category"}
                    value={assetCategory || []}
                    items={assetCategorySelection}
                    onChange={onCategorySelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Asset Type"}
                    value={assettype || []}
                    items={assetTypeSelection}
                    onChange={onTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                OUTPUT PARAMETERS
              </Box>
            </Box>
            <Controller
              name="criteria.fee_name"
              control={control}
              rules={{
                validate: async (value) => {
                  const isDuplicate = await validateFeeName(value);
                  if (isDuplicate) {
                    return ValidationMessages.FEE_NAME_UNIQUE;
                  }
                  return true;
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerFeeName"
                  fullWidth={true}
                  theme={theme}
                  label={"Fee Name"}
                  placeholder={"Type Here"}
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  error={errors?.criteria?.fee_name?.message}
                  type="text"
                  disabled={criteriaEnable}
                />
              )}
            />

            <Controller
              name="criteria.fee_type"
              control={control}
              render={({ field }) => (
                <Select
                  theme={theme}
                  label={"Fee Type"}
                  items={[
                    { text: "One time", value: "One time" },
                    { text: "Periodic", value: "Periodic" },
                  ]}
                  {...field}
                  value={field.value || ""}
                  sxProps={""}
                  onChange={field.onChange}
                  selectError={errors?.criteria?.fee_type?.message as never}
                  disabled={criteriaEnable}
                ></Select>
              )}
            />

            <Controller
              name="criteria.fee_frequency"
              control={control}
              render={({ field }) => (
                <Select
                  theme={theme}
                  label={"Fee Frequency"}
                  items={feeFrequencyOptions}
                  {...field}
                  value={field.value || ""}
                  sxProps={""}
                  onChange={field.onChange}
                  selectError={
                    errors?.criteria?.fee_frequency?.message as never
                  }
                  disabled={criteriaEnable}
                ></Select>
              )}
            />
            <Controller
              name="criteria.due_at_start_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Due At Start Date"}
                  items={[
                    { text: "Yes", value: true },
                    { text: "No", value: false },
                  ]}
                  value={
                    actionType == ActionOptions.EDIT
                      ? feeChartData?.criteria?.due_at_start_date
                      : value
                  }
                  sxProps={""}
                  onChange={onChange}
                  selectError={
                    errors?.criteria?.due_at_start_date?.message as never
                  }
                  disabled={criteriaEnable}
                ></Select>
              )}
            />
            <Controller
              name="criteria.fee_amount"
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  label={"Fee Amount"}
                  type="text"
                  startAdornment={
                    <>
                      <span className="MuiInputAdornment-root">$</span>
                    </>
                  }
                  masking
                  maskDecimalScale={2}
                  maskNumeric
                  {...field}
                  onChange={(e) => field.onChange(e)}
                  error={errors?.criteria?.fee_amount?.message as never}
                  disabled={criteriaEnable}
                />
              )}
            />

            <Controller
              name="criteria.override_allowed"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="criteria.override_allowed"
                  theme={theme}
                  label={"Override"}
                  items={[
                    { text: "Yes", value: true },
                    { text: "No", value: false },
                  ]}
                  value={
                    actionType == ActionOptions.EDIT
                      ? feeChartData?.criteria?.override_allowed
                      : value
                  }
                  onChange={onChange}
                  selectError={
                    errors?.criteria?.override_allowed?.message as never
                  }
                  disabled={criteriaEnable}
                ></Select>
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            id="saveCriteria"
            primary
            text={"Save"}
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewFeesChart;
