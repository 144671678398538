import { ICharts, ISelectItemList } from "Interface";
import { useTheme } from "@mui/material";
import { Autocomplete, Grid, Input, Select } from "@ntpkunity/controls";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { ChartTypes, QueryKeys } from "Enums";
import { useQueryClient } from "react-query";
import { UseQuery_getAllCharts } from "services";
export const FinanceCharts: FC<{
  form: any;
  popUpOpenState: string;
  disabled: boolean;
  setCompanyFieldState?: any;
}> = ({ form, disabled, setCompanyFieldState }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = form;
  const queryClient = useQueryClient();
  UseQuery_getAllCharts();
  const charts: Array<ICharts> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CHARTS
  );
  const theme = useTheme();

  const [assetinterestChartsSelection, setAssetInterestChartsSelection] =
    useState<Array<ISelectItemList>>([]);
  const [commissionSelection, setCommissionSelection] = useState<
    Array<ISelectItemList>
  >([]);
  const [rvChartsSelection, setRvChartsSelection] = useState<
    Array<ISelectItemList>
  >([]);
  const [rvFeesSelection, setFeesSelection] = useState<Array<ISelectItemList>>(
    []
  );
  const assetInterestChartId = watch("interest_rate_chart_id")?.id;
  const commissionId = watch("commission_chart_id")?.id;
  const rvChartId = watch("rv_balloon_rate_chart_id")?.id;
  const feesChartId = watch("fees_chart_id")?.id;
  const mapChartsData = (
    setCharts: Dispatch<SetStateAction<Array<ISelectItemList>>>,
    chartId: number,
    chartType: number
  ) => {
    setCharts(
      charts
        ?.filter(
          (x) =>
            x.chart_type_id == chartType && (x.is_active || x.id == chartId)
        )
        ?.map((chart) => ({
          value: chart.id,
          text: chart.chart_name,
        }))
    );
  };
  useEffect(() => {
    setAssetInterestChartsSelection(
      charts
        ?.filter(
          (x) =>
            x.chart_type_id == ChartTypes.InterestChart &&
            (x.is_active || x.id == assetInterestChartId)
        )
        ?.map((chart) => ({
          value: chart.id,
          text: chart.chart_name,
        }))
    );
  }, [charts, assetInterestChartId]);
  useEffect(() => {
    if (charts) {
      mapChartsData(setRvChartsSelection, rvChartId, ChartTypes.RvChart);
      mapChartsData(
        setCommissionSelection,
        commissionId,
        ChartTypes.CommissionChart
      );
    }
  }, [charts, rvChartId, commissionId]);
  useEffect(() => {
    if (charts) {
      mapChartsData(setFeesSelection, feesChartId, ChartTypes.FeesChart);
      mapChartsData(
        setCommissionSelection,
        commissionId,
        ChartTypes.CommissionChart
      );
    }
  }, [charts, feesChartId, commissionId]);
  return (
    <>
      <Grid theme={theme} container columnSpacing={2} rowSpacing={3} pb={3}>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="interest_rate_chart_id"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Autocomplete
                {...field}
                theme={theme}
                disablePortal={true}
                capitalizeLabel={true}
                disabled={disabled}
                options={
                  assetinterestChartsSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                items={
                  assetinterestChartsSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_e: any, value: any) => {
                  field.onChange(value);
                  setCompanyFieldState(true);
                }}
                label={"Interest Rate (Optional)"}
                value={field.value}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="commission_chart_id"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disabled={disabled}
                theme={theme}
                disablePortal={true}
                capitalizeLabel={true}
                options={
                  commissionSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                items={
                  commissionSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_e: any, value: any) => {
                  field.onChange(value);
                  setCompanyFieldState(true);
                }}
                label={"Commission (Optional)"}
                value={field.value}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="rv_balloon_rate_chart_id"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disabled={disabled}
                theme={theme}
                disablePortal={true}
                capitalizeLabel={true}
                options={
                  rvChartsSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                items={
                  rvChartsSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_e: any, value: any) => {
                  field.onChange(value);
                  setCompanyFieldState(true);
                }}
                label={"RV/Balloon (Optional)"}
                value={field.value}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name="fees_chart_id"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disabled={disabled}
                theme={theme}
                disablePortal={true}
                capitalizeLabel={true}
                options={
                  rvFeesSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                items={
                  rvFeesSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  }) ?? []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_e: any, value: any) => {
                  field.onChange(value);
                  setCompanyFieldState(true);
                }}
                label={"Fees (Optional)"}
                disableCloseOnSelect={false}
                value={field.value}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
