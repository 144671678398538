export const isFeeCombinationsUnique = (data, setError) => {
  let duplicateIndexes = [];
  let hasError = false;

  const feesMapping = data.feesMapping;

  for (let i = 0; i < feesMapping?.length; i++) {
    const feeRecord = feesMapping[i];

    if (!feeRecord.fee_name_id || feeRecord.fee_name_id === null) {
      setError(`feesMapping.${i}.fee_name_id`, {
        type: "manual",
        message: "Fee Name is required",
      });
      hasError = true;
    }

    if (!feeRecord.state || feeRecord.state.length === 0) {
      setError(`feesMapping.${i}.state`, {
        type: "manual",
        message: "State is required",
      });
      hasError = true;
    }

    if (!feeRecord.finance_type || feeRecord.finance_type.length === 0) {
      setError(`feesMapping.${i}.finance_type`, {
        type: "manual",
        message: "Finance Type is required",
      });
      hasError = true;
    }
  }

  for (let i = 0; i < feesMapping?.length; i++) {
    const feeRecord = feesMapping[i];

    for (let j = 0; j < feesMapping?.length; j++) {
      if (i !== j && !duplicateIndexes.includes(i)) {
        const existingItem = feesMapping[j];
        const isDuplicate =
          existingItem.fee_name_id === feeRecord.fee_name_id &&
          feeRecord.state.some((stateItem) =>
            existingItem.state.includes(stateItem)
          ) &&
          feeRecord.finance_type.some((financeTypeItem) =>
            existingItem.finance_type.includes(financeTypeItem)
          );
        if (isDuplicate) {
          duplicateIndexes.push(j);
        }
      }
    }
  }

  duplicateIndexes.forEach((duplicateIndex) => {
    setError(`feesMapping.${duplicateIndex}.state`, {
      type: "manual",
      message: "This combination already exists",
    });
    setError(`feesMapping.${duplicateIndex}.finance_type`, {
      type: "manual",
      message: "This combination already exists",
    });
    setError(`feesMapping.${duplicateIndex}.fee_name_id`, {
      type: "manual",
      message: "This combination already exists",
    });
    hasError = true;
  });

  return hasError;
};

export const isFPCombinationsUnique = (data, setError) => {
  let duplicateIndexes = [];
  let hasError = false;

  const fpMapping = data.fpMapping;

  for (let i = 0; i < fpMapping?.length; i++) {
    const fpRecord = fpMapping[i];

    if (!fpRecord.category || fpRecord.category === null) {
      setError(`fpMapping.${i}.category`, {
        type: "manual",
        message: "Category is required",
      });
      hasError = true;
    }

    if (!fpRecord.finance_type || fpRecord.finance_type.length === 0) {
      setError(`fpMapping.${i}.finance_type`, {
        type: "manual",
        message: "Finance Type is required",
      });
      hasError = true;
    }
  }

  for (let i = 0; i < fpMapping?.length; i++) {
    const fpRecord = fpMapping[i];

    for (let j = 0; j < fpMapping?.length; j++) {
      if (i !== j && !duplicateIndexes.includes(i)) {
        const existingItem = fpMapping[j];
        const isDuplicate =
          existingItem.category === fpRecord.category &&
          fpRecord.finance_type.some((financeTypeItem) =>
            existingItem.finance_type.includes(financeTypeItem)
          );
        if (isDuplicate) {
          duplicateIndexes.push(j);
        }
      }
    }
  }

  duplicateIndexes.forEach((duplicateIndex) => {
    setError(`fpMapping.${duplicateIndex}.finance_type`, {
      type: "manual",
      message: "This combination already exists",
    });
    setError(`fpMapping.${duplicateIndex}.category`, {
      type: "manual",
      message: "This combination already exists",
    });
    hasError = true;
  });

  return hasError;
};
