import { FC, useEffect, useState } from "react";
import {
  // TablePagination,
  useTheme,
} from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap, PaginationWrap } from "@sharedComponents";
import LendersTable from "./lenders-table";
import LendersDrawer from "./lenders-drawer";
import { useSetupsStore } from "Stores/SetupStore/Store";
// import ChevronDown from "../../../shared/assets/images/chevron-down";

import { IOptionCategory } from "Interface";
import { ActionOptions } from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";

const Lenders: FC = () => {
  const theme = useTheme();
  const [actionTitle, setActionTitle] = useState<string>("Import Lenders");
  const [actionData, setActionData] = useState<IOptionCategory>(undefined);
  const [state, dispatch] = useSetupsStore();
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [lendersState, setLendersState] = useState<boolean>(false);
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Import Lenders");
        setActionData(value);
        setLendersState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  useEffect(() => {
    if (lendersState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [lendersState]);
  const onEdit = (formData: IOptionCategory) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Add-On Category");
    setActionData(formData);
    setLendersState(true);
    dispatch(setOpenDrawer(true));
  };

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <LendersTable />
        <Button
          id="idImportLenders"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Import Lenders"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        <PaginationWrap>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={undefined}
            rowsPerPage={undefined}
            page={undefined}
            onPageChange={undefined}
            onRowsPerPageChange={undefined}
            SelectProps={{
              IconComponent: ChevronDown,
              MenuProps: { disablePortal: true },
            }}
          /> */}
        </PaginationWrap>
      </DataTableWrap>
      {lendersState && (
        <LendersDrawer
          openPopUp={lendersState}
          setPopUpState={setLendersState}
          title={actionTitle}
        />
      )}
    </>
  );
};

export default Lenders;
