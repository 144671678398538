import { FC, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material";
import { INewRebatePopUp } from "./AddCompanyRebate.type";
import {
  IDocumentTypes,
  IFinanceTypeModel,
  IImage,
  IRebate,
  IStates,
} from "Interface";
import {
  Select,
  Button,
  ConfirmationDialog,
  FileDragDropWrap,
} from "@sharedComponents";
import { Controller, useForm } from "react-hook-form";
import {
  UseQuery_GetAllFinanceTypes,
  UseMutation_AddRebate,
  UseMutation_UpdateRebate,
  UseQuery_getAllDocumentTypes,
  UseMutation_uploadS3Image,
  UseMutation_deleteS3Image,
} from "services";
import {
  UseQuery_getAllStates,
  useGetContractTerms,
} from "services/dealer-configurations.service";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store/SetupsStoreProvider";
import { useQueryClient } from "react-query";
import {
  Grid,
  PersistentDrawer,
  Input,
  Icon,
  MultiSelect,
  Tooltip,
  FileDragDrop,
  FilePreview,
  Box,
  CircleLoader,
  ImageViewer,
} from "@ntpkunity/controls";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
import { useStoreContext } from "Stores/SetupStore/Store";

const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "relative !important",
      transform: "initial !important",
    },
  },
}));

const NewCompanyRebate: FC<INewRebatePopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  tradingAgeData,
  enabledPin,
  setImages,
  images,
}) => {
  const [state] = useSetupsStore();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const { mutate: getContractTerms, data: contractTermsData } =
    useGetContractTerms();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isDirty },
    watch,
  } = useForm<IRebate>();
  const navigate = useNavigate();
  const { actions } = useStoreContext();
  const [navigationState, setNavigationState] = useState<any>();
  const { mutate: addRebate } = UseMutation_AddRebate();
  const { mutate: editRebate } = UseMutation_UpdateRebate();
  const { mutate: uploadImage } = UseMutation_uploadS3Image();
  const { mutate: deleteImage } = UseMutation_deleteS3Image();
  const [states, setStates] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  UseQuery_GetAllFinanceTypes();
  UseQuery_getAllStates();
  UseQuery_getAllDocumentTypes();
  const available_finance: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const usaStates: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );
  const documentTypes: Array<IDocumentTypes> = queryClient.getQueryData(
    QueryKeys.GET_ALL_DOCUMENT_TYPES
  );
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const handleError = (error) => {
    if (
      error?.response?.data?.detail?.message ===
      "Rebate Code and Name should be unique"
    ) {
      setError("rebate_name", { message: "Rebate name should be unique" });
      setError("rebate_code", {
        message: "Rebate code should be unique",
      });
      return;
    } else if (
      error?.response?.data?.detail?.message === "Rebate name should be unique"
    ) {
      setError("rebate_name", { message: "Rebate name should be unique" });
      return;
    } else if (
      error?.response?.data?.detail?.message ===
      "Rebate code should be unique"
    ) {
      setError("rebate_code", {
        message: "Rebate code should be unique",
      });
      return;
    } else {
      onClose();
      actions.setToast({
        toastMessage: error?.response?.data?.detail?.message,
        toastState: true,
        variant: "error",
      });
    }
  };
  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);
function onSubmit(data) {
  if (
    actionType === ActionOptions.ADD ) {
      data.rebate_images = images;
    addRebate({...data,}, {
      onSuccess: (response) => {
        reset();
        setImages([]);
        onClose();
      },
      onError: (error) => {
        setPopUpState(true);
        handleError(error);
      },
    });
  }
  if (
    actionType === ActionOptions.EDIT 
  ) {
    data.rebate_images = images;
    editRebate({...data,}, {
      onSuccess: (response) => {
        reset();
        setImages([]);
        onClose();
      },
      onError: (error) => {
        setPopUpState(true);
        handleError(error);
      },
    });
    onClose();
  }
}
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  //   const onCancel = () => {
  //     onClose();
  //   };
  useEffect(() => {
    getContractTerms();
  }, []);
  useEffect(() => {
    if (usaStates) {
      const sortedStates = [...usaStates].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setStates(
        sortedStates?.map((x) => {
          return {
            text: x.name,
            value: x.id,
          };
        })
      );
    }
  }, [usaStates]);
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  useEffect(() => {
    if (actionType === ActionOptions.EDIT) {
      setValue("id", tradingAgeData.id);
      setImages(tradingAgeData?.rebate_images);
    }
    setValue("rebate_name", tradingAgeData?.rebate_name);
    setValue("rebate_description", tradingAgeData?.rebate_description);
    setValue("rebate_code", tradingAgeData?.rebate_code);
    setValue("rebate_amount", tradingAgeData?.rebate_amount);
    setValue("required_documents", tradingAgeData?.required_documents);
    setValue("finance_type", tradingAgeData?.finance_type);
    setValue(
      "payment_term",
      tradingAgeData?.payment_term?.map((str) => +str)
    );
    setValue(
      "applicable_states",
      tradingAgeData?.applicable_states?.map((str) => +str)
    );
    setValue("is_active", tradingAgeData?.is_active);

    if (actionType === ActionOptions.ADD) {
      reset();
      setValue("company_id", companyProfile?.id);
    }
  }, [tradingAgeData, state]);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [sliderImages, setSilderImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const onimageDrop = (e: any) => {
    setImageLoader(true);
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };
  const onimageUpload = (e: any) => {
    setImageLoader(true);
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };
  const onRemoveFile = (fileKey: string, index: number) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      setImageLoader(true);
      deleteImage(obj, {
        onSuccess(response) {
          const imageArray = images;
          imageArray.splice(index, 1);
          setImages([...imageArray]);
          setImageLoader(false);
        },
      });
    }
  };
  const onClickIcon = (indexNumber) => {
    const image_url = images?.map((image) => {
      return image?.location;
    });
    setIndex(indexNumber);
    setSilderImages(image_url);
    setVisibility(true);
  };
  const handleClose = () => {
    setVisibility(false);
    setIndex(0);
  };
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="rebate_name"
              control={control}
              defaultValue={""}
              rules={{
                required: ValidationMessages.REBATE_NAME_REQUIRED,
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here.."}
                  label={"Rebate Name"}
                  type="text"
                  {...field}
                  error={errors?.rebate_name?.message}
                />
              )}
            />
            <Controller
              name="rebate_description"
              control={control}
              defaultValue={""}
              rules={{
                required: ValidationMessages.REBATE_DESCRIPTION_REQUIRED,
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here.."}
                  label={"Rebate Description (Optional)"}
                  type="text"
                  {...field}
                  error={errors?.rebate_description?.message}
                />
              )}
            />
            <Controller
              name="rebate_code"
              control={control}
              defaultValue={""}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.EXTERNAL_CODE_MAX_LENGTH,
                },
                required: ValidationMessages.REBATE_CODE_REQUIRED,
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here.."}
                  label={"Rebate Code"}
                  type="text"
                  {...field}
                  error={errors?.rebate_code?.message}
                />
              )}
            />
            <Controller
              name="rebate_amount"
              control={control}
              defaultValue={""}
              rules={{
                required: ValidationMessages.REBATE_AMOUNT_REQUIRED,
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here.."}
                  label={"Rebate Amount"}
                  type="text"
                  {...field}
                  value={
                    field.value ? parseFloat(field.value) : undefined
                  }
                  startAdornment={
                    <>
                      <span className="adornment-text">
                        {defaultCurrency}
                      </span>
                    </>
                  }
                  masking
                  // scale={2}
                  maskDecimalScale={2}
                  maskNumeric
                  // numeric
                  error={errors?.rebate_amount?.message}
                />
              )}
            />
            <Controller
              name="required_documents"
              control={control}
              defaultValue={[]}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  id="requiredDocuments"
                  name="required_documents"
                  theme={theme}
                  label={"Required Documents (Optional)"}
                  items={documentTypes?.map((item) => {
                    return {
                      text: item.description,
                      value: item.description,
                    };
                  })}
                  value={value ?? []}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="finance_type"
              control={control}
              rules={{
                required: ValidationMessages.Finance_type_required,
              }}
              defaultValue={[]}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  id="financeType"
                  name="finance_type"
                  theme={theme}
                  label={"Finance Type"}
                  items={available_finance?.map((item) => {
                    return { text: item.name, value: item.name };
                  })}
                  value={value ?? []}
                  onChange={onChange}
                  selectError={errors?.finance_type?.message as string}
                />
              )}
            />
            <Controller
              name="payment_term"
              control={control}
              rules={{
                required: ValidationMessages.PAYMENT_TERM_REQUIRED,
              }}
              defaultValue={[]}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  id="paymentTerm"
                  name="payment_term"
                  theme={theme}
                  label={"Payment Term"}
                  items={contractTermsData
                    ?.filter((x) => {
                      return x.is_active;
                    })
                    ?.map((term) => {
                      return {
                        text: term.term,
                        value: term.term,
                      };
                    })}
                  value={value ?? []}
                  onChange={onChange}
                  selectError={errors?.payment_term?.message as string}
                />
              )}
            />
            <Controller
              name={"applicable_states"}
              control={control}
              defaultValue={[]}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  id="applicableStates"
                  name="applicable_states"
                  theme={theme}
                  label={"Applicable States (Optional)"}
                  items={states}
                  value={value ?? []}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value ?? []}
                  onChange={onChange}
                />
              )}
            />
            <FileDragDropWrap className="mb-24">
              <Controller
                name="rebate_images"
                control={control}
                render={({ field }) => (
                  <Tooltip theme={theme} title={""}>
                    <FileDragDrop
                      {...field}
                      hoverLabel="Drag and drop product images, or browse"
                      width="300"
                      height="200px"
                      backgroundColor="white"
                      onDrop={onimageDrop}
                      onChange={onimageUpload}
                      theme={theme}
                    />
                  </Tooltip>
                )}
              />
              <FilePreview
                files={images?.length > 0 ? images : []}
                onRemoveFile={onRemoveFile}
                theme={theme}
                viewIcon={true}
                onClickIcon={onClickIcon}
                preview="image"
              />
              {imageLoader && (
                <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                  <CircleLoader theme={theme} size="xs" />
                </ImgLoader>
              )}
            </FileDragDropWrap>
          </>
        }
        customFooter={
          <Button
            primary
            text="Save Rebate"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={sliderImages}
        isLoading={false}
        visible={visibility}
        onClose={handleClose}
        overlayCheck={true}
        index={index}
        setIndex={setIndex}
      />
    </>
  );
};

export default NewCompanyRebate;
