import { IFeeName } from "Interface";
import { StoreActionType } from "Types";
import { ActionTypes } from "Enums";

export const setFeeNamesInitialData = (
  fee_name: Array<IFeeName>
): StoreActionType => {
  return {
    type: ActionTypes.SET_FEE_NAMES_INITIAL_DATA,
    payload: fee_name,
  };
};
