import { INewCommissionChartsPopUp } from "./AddNewCommissionCharts.type";
import { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/material";
import {
  PersistentDrawer,
  Autocomplete,
  Icon,
  MultiSelect,
  Box,
  Button,
  Input,
  Select,
  Grid,
} from "@ntpkunity/controls";
import {
  ConfirmationDialog,
  CurrencyInputWrap,
  MultiSelectWrap,
} from "@sharedComponents";
import {
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  IChartCriteria,
  ICharts,
  ICriteria,
  ISelectItemList,
} from "Interface";
import {
  useMutation_UpdateCommissionCharts,
  useMutation_AddNewCommissionCharts,
  useMutation_AddNewCommissionCriteria,
  UseQuery_GetAllAssetMakes,
  UseQuery_GetAllAssetModels,
  UseQuery_GetAllAssetTrims,
  UseMutation_AddCharts,
  UseMutation_UpdateCharts,
  UseMutation_CheckDuplicateChartName,
  UseMutation_CheckCriteriaAttached,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { logout } from "@ntpkunity/controls-ums";
import {
  ActionOptions,
  ChartTypes,
  DialogMessages,
  QueryKeys,
  ValidationMessages,
  ToastMessages,
} from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SetupContext } from "context";

const schema = Yup.object().shape({
  chart: Yup.object().shape({
    chart_name: Yup.string().required("Chart name is required"),
    is_active: Yup.boolean().required("Chart status is required"),
  }),
  criteria: Yup.lazy((values) =>
    Yup.object().shape(
      {
        default_commission: Yup.number()
          .when(
            [
              // "asset_classification",
              "asset_make_name",
              "asset_model_name",
              "asset_trim_name",
              "asset_condition",
              "terms",
              "credit_rating",
              "asset_usages",
              "customer_type",
              "finance_type",
              "asset_category",
              "asset_type",
              "product_type",
              "minimum_commission",
              "maximum_commission",
              "purchase_option",
            ],
            {
              is: (
                // assetClassification,
                assetMakeName,
                assetModelName,
                assetTrimName,
                assetCondition,
                term,
                creditRating,
                assetUsage,
                minimumCommission,
                maximumCommission,
                purchaseOption
              ) => {
                return (
                  // assetClassification ||
                  assetMakeName ||
                  assetModelName ||
                  assetTrimName ||
                  assetCondition ||
                  term ||
                  creditRating ||
                  assetUsage ||
                  minimumCommission ||
                  maximumCommission ||
                  purchaseOption
                );
              },
              then: Yup.number()
                .typeError("Default Commission is required")
                .required("Default Commission is required")
                .min(0, "Default Commission must be greater than or equal to 0")
                .test({
                  name: "default_commission",
                  message:
                    "Default Commission must be between Min. and Max. Commission",
                  test: function (value) {
                    const minCommission = this.resolve(
                      Yup.ref("minimum_commission")
                    );
                    const maxCommission = this.resolve(
                      Yup.ref("maximum_commission")
                    );
                    if (value >= minCommission && value <= maxCommission) {
                      return true;
                    }
                    return false;
                  },
                }),
            }
          )
          .typeError("Default Commission is required"),
        minimum_commission: Yup.number()
          .when(
            [
              // "asset_classification",
              "asset_make_name",
              "asset_model_name",
              "asset_trim_name",
              "asset_condition",
              "terms",
              "credit_rating",
              "asset_usages",
              "customer_type",
              "finance_type",
              "asset_category",
              "asset_type",
              "product_type",
              "maximum_commission",
              "default_commission",
              "purchase_option",
            ],
            {
              is: (
                // assetClassification,
                assetMakeName,
                assetModelName,
                assetTrimName,
                assetCondition,
                term,
                creditRating,
                assetUsage,
                maximumCommission,
                defaultCommission,
                purchaseOption
              ) => {
                return (
                  // assetClassification ||
                  assetMakeName ||
                  assetModelName ||
                  assetTrimName ||
                  assetCondition ||
                  term ||
                  creditRating ||
                  assetUsage ||
                  maximumCommission ||
                  defaultCommission ||
                  purchaseOption
                );
              },
              then: Yup.number()
                .typeError("Min. Commission is required")
                .required("Min. Commission is required")
                .min(0, "Min. Commission must be greater than or equal to 0")
                .test({
                  name: "minimum_commission",
                  message:
                    "Minimum Commission must be between 0 and Default Commission",
                  test: function (value) {
                    const defaultCommission = this.resolve(
                      Yup.ref("default_commission")
                    );
                    if (value >= 0 && value <= defaultCommission) {
                      return true;
                    }
                    return false;
                  },
                }),
            }
          )
          .typeError("Min. Commission is required"),
        maximum_commission: Yup.number()
          .when(
            [
              // "asset_classification",
              "asset_make_name",
              "asset_model_name",
              "asset_trim_name",
              "asset_condition",
              "terms",
              "credit_rating",
              "asset_usages",
              "customer_type",
              "finance_type",
              "asset_category",
              "asset_type",
              "product_type",
              "default_commission",
              "minimum_commission",
              "purchase_option",
            ],
            {
              is: (
                // assetClassification,
                assetMakeName,
                assetModelName,
                assetTrimName,
                assetCondition,
                term,
                creditRating,
                assetUsage,
                defaultCommission,
                minimumCommission,
                purchaseOption
              ) => {
                return (
                  // assetClassification ||
                  assetMakeName ||
                  assetModelName ||
                  assetTrimName ||
                  assetCondition ||
                  term ||
                  creditRating ||
                  assetUsage ||
                  defaultCommission ||
                  minimumCommission ||
                  purchaseOption
                );
              },
              then: Yup.number()
                .typeError("Max. Commission is required")
                .required("Max. Commission is required")
                .min(0, "Max. Commission must be greater than or equal to 0")
                .test({
                  name: "maximum_commission",
                  message:
                    "Max. Commission must be between Min. and Default Commission",
                  test: function (value) {
                    const defaultCommission = this.resolve(
                      Yup.ref("default_commission")
                    );
                    const minCommission = this.resolve(
                      Yup.ref("minimum_commission")
                    );
                    if (value >= minCommission && value >= defaultCommission) {
                      return true;
                    }
                    return false;
                  },
                }),
            }
          )
          .typeError("Max. Commission is required"),
        calculation_basis: Yup.string()
          .nullable()
          .test({
            name: "calculation_basis",
            exclusive: false,
            params: {},
            message: "Calculation Basis is required",
            test: function (value, context: any) {
              const output_parameter_type =
                context?.from[1]?.value?.chart?.output_parameter_type;
              if (
                output_parameter_type == "Percentage" &&
                !value &&
                // (this.parent.asset_classification ||
                (this.parent.asset_make_name ||
                  this.parent.asset_model_name ||
                  this.parent.asset_trim_name ||
                  this.parent.asset_condition ||
                  this.parent.term ||
                  this.parent.credit_rating ||
                  this.parent.asset_usages ||
                  this.parent.customer_type ||
                  this.parent.finance_type ||
                  this.parent.asset_category ||
                  this.parent.asset_type ||
                  this.parent.product_type ||
                  this.parent.default_commission ||
                  this.parent.minimum_commission ||
                  this.parent.maximum_commission ||
                  this.parent.purchase_option)
              )
                return false;
              return true;
            },
          }),
        gl_split: Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .nullable()
          .test({
            name: "gl_split",
            exclusive: false,
            params: {},
            message: ValidationMessages.SUM_SHOULD_BE_100,
            test: function (value, context: any) {
              const glSplit = value || 0;
              const customerSplit = this.parent.customer_split || 0;
              const manufacturerSplit = this.parent.manufacturer_split || 0;
              const salesRepresentativeSplit =
                this.parent.sales_representative_split || 0;
              const sum =
                glSplit +
                customerSplit +
                manufacturerSplit +
                salesRepresentativeSplit;
              if (
                (this.parent.asset_make_name ||
                  this.parent.asset_model_name ||
                  this.parent.asset_trim_name ||
                  this.parent.asset_condition ||
                  this.parent.term ||
                  this.parent.credit_rating ||
                  this.parent.asset_usages ||
                  this.parent.customer_type ||
                  this.parent.finance_type ||
                  this.parent.asset_category ||
                  this.parent.asset_type ||
                  this.parent.product_type ||
                  this.parent.default_commission ||
                  this.parent.minimum_commission ||
                  this.parent.maximum_commission ||
                  this.parent.purchase_option) &&
                sum !== 100
              ) {
                return false;
              }
              return true;
            },
          }),

        customer_split: Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .nullable()
          .test({
            name: "customer_split",
            exclusive: false,
            params: {},
            message: ValidationMessages.SUM_SHOULD_BE_100,
            test: function (value, context: any) {
              const glSplit = this.parent.gl_split || 0;
              const customerSplit = value || 0;
              const manufacturerSplit = this.parent.manufacturer_split || 0;
              const salesRepresentativeSplit =
                this.parent.sales_representative_split || 0;
              const sum =
                glSplit +
                customerSplit +
                manufacturerSplit +
                salesRepresentativeSplit;
              if (
                (this.parent.asset_make_name ||
                  this.parent.asset_model_name ||
                  this.parent.asset_trim_name ||
                  this.parent.asset_condition ||
                  this.parent.term ||
                  this.parent.credit_rating ||
                  this.parent.asset_usages ||
                  this.parent.customer_type ||
                  this.parent.finance_type ||
                  this.parent.asset_category ||
                  this.parent.asset_type ||
                  this.parent.product_type ||
                  this.parent.default_commission ||
                  this.parent.minimum_commission ||
                  this.parent.maximum_commission ||
                  this.parent.purchase_option) &&
                sum !== 100
              ) {
                return false;
              }
              return true;
            },
          }),

        manufacturer_split: Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .nullable()
          .test({
            name: "manufacturer_split",
            exclusive: false,
            params: {},
            message: ValidationMessages.SUM_SHOULD_BE_100,
            test: function (value, context: any) {
              const glSplit = this.parent.gl_split || 0;
              const customerSplit = this.parent.customer_split || 0;
              const manufacturerSplit = value || 0;
              const salesRepresentativeSplit =
                this.parent.sales_representative_split || 0;
              const sum =
                glSplit +
                customerSplit +
                manufacturerSplit +
                salesRepresentativeSplit;
              if (
                (this.parent.asset_make_name ||
                  this.parent.asset_model_name ||
                  this.parent.asset_trim_name ||
                  this.parent.asset_condition ||
                  this.parent.term ||
                  this.parent.credit_rating ||
                  this.parent.asset_usages ||
                  this.parent.customer_type ||
                  this.parent.finance_type ||
                  this.parent.asset_category ||
                  this.parent.asset_type ||
                  this.parent.product_type ||
                  this.parent.default_commission ||
                  this.parent.minimum_commission ||
                  this.parent.maximum_commission ||
                  this.parent.purchase_option) &&
                sum !== 100
              ) {
                return false;
              }
              return true;
            },
          }),

        sales_representative_split: Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .nullable()
          .test({
            name: "sales_representative_split",
            exclusive: false,
            params: {},
            message: ValidationMessages.SUM_SHOULD_BE_100,
            test: function (value, context: any) {
              const glSplit = this.parent.gl_split || 0;
              const customerSplit = this.parent.customer_split || 0;
              const manufacturerSplit = this.parent.manufacturer_split || 0;
              const salesRepresentativeSplit = value || 0;
              const sum =
                glSplit +
                customerSplit +
                manufacturerSplit +
                salesRepresentativeSplit;
              if (
                (this.parent.asset_make_name ||
                  this.parent.asset_model_name ||
                  this.parent.asset_trim_name ||
                  this.parent.asset_condition ||
                  this.parent.term ||
                  this.parent.credit_rating ||
                  this.parent.asset_usages ||
                  this.parent.customer_type ||
                  this.parent.finance_type ||
                  this.parent.asset_category ||
                  this.parent.asset_type ||
                  this.parent.product_type ||
                  this.parent.default_commission ||
                  this.parent.minimum_commission ||
                  this.parent.maximum_commission ||
                  this.parent.purchase_option) &&
                sum !== 100
              ) {
                return false;
              }
              return true;
            },
          }),
      },
      [
        ["default_commission", "minimum_commission"],
        ["default_commission", "maximum_commission"],
        ["maximum_commission", "minimum_commission"],
        ["maximum_commission", "default_commission"],
        ["minimum_commission", "default_commission"],
        ["minimum_commission", "maximum_commission"],
      ]
    )
  ),
});

const AddNewCommissionCharts: FC<INewCommissionChartsPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  commissionChartData,
  financeType,
  customerType,
  assetCategory,
  assetType,
  producttype,
}) => {
  const { mutate: CheckNameDuplicate } = UseMutation_CheckDuplicateChartName();
  const [state, dispatch] = useSetupsStore();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    resetField,
    trigger,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty, isValidating },
  } = useForm<IChartCriteria>({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const theme = useTheme();
  const {
    assetConditions,
    terms,
    creditRatings,
    currencies,
    AllFinancedAmounts,
    lenderProfile,
    assetUsages,
  } = useContext(SetupContext);
  const queryClient = useQueryClient();
  const lenderProfileData: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const { actions } = useStoreContext();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const commission_type = watch("chart.output_parameter_type");

  const onSubmit = (data: IChartCriteria) => {
    data.criteria.asset_make_name = assetMakeId;
    data.criteria.asset_model_name = assetModelId;
    data.criteria.asset_trim_name = assetTrimId;
    data.criteria.terms = assetTerm;
    data.criteria.credit_rating = assetCreditScore;
    data.criteria.asset_condition = assetCondition;
    data.criteria.finance_type = financetype;
    data.criteria.customer_type = customertype;
    data.criteria.asset_category = assetcategory;
    data.criteria.asset_type = assettype;
    data.criteria.product_type = productType;
    data.criteria.asset_usages = assetUsage;
    data.criteria.financed_amount = financedAmounts;
    data.chart.chart_type_id = ChartTypes.CommissionChart;
    if (actionType == ActionOptions.ADD_NEW_CHART) {
      if (
        !(data?.criteria.default_commission >= 0) ||
        data?.criteria.default_commission == null
      ) {
        if (
          chartId == null &&
          data?.criteria?.asset_make_name == null &&
          data?.criteria?.asset_model_name == null &&
          data?.criteria?.asset_trim_name == null &&
          data?.criteria?.credit_rating.length == 0 &&
          data?.criteria?.terms.length == 0 &&
          data?.criteria?.financed_amount.length == 0 &&
          data?.criteria?.asset_condition.length == 0 &&
          data?.criteria?.customer_type.length == 0 &&
          data?.criteria?.finance_type.length == 0 &&
          data?.criteria?.asset_category.length == 0 &&
          data?.criteria?.asset_type.length == 0 &&
          data?.criteria?.product_type.length == 0
        ) {
          AddChart(data.chart, {
            onSuccess: (dataa, variables, context) => {
              setChartId(dataa.data.id);
              setValue("chart.chart_name", dataa.data.chart_name);
              setIsChartAdded(true);
              setCommissionTypeEnable(true);
              setChartEnable(true);
              onClose();
            },
            onError: (error) => {
              reset(undefined);
              setValue("chart.chart_name", data.chart.chart_name);
              if (error?.response?.data?.detail?.code === 400) {
                setError("chart.chart_name", {
                  message: "Chart Name should be unique",
                });
                return;
              } else {
                actions.setToast({
                  toastMessage:
                    error.response.data.detail.message ??
                    ToastMessages.Internal_Server_Error,
                  toastState: true,
                  variant: "error",
                });
              }
            },
          });
        } else if (
          chartId == null &&
          data?.criteria?.asset_make_name != null &&
          data?.criteria?.asset_model_name != null &&
          data?.criteria?.asset_trim_name != null &&
          data?.criteria?.credit_rating.length != 0 &&
          data?.criteria?.terms.length != 0 &&
          data?.criteria?.asset_condition.length != 0 &&
          data?.criteria?.financed_amount.length != 0 &&
          data?.criteria?.customer_type.length != 0 &&
          data?.criteria?.finance_type.length != 0 &&
          data?.criteria?.asset_category.length != 0 &&
          data?.criteria?.asset_type.length != 0 &&
          data?.criteria?.product_type.length != 0
        ) {
          if (
            data?.criteria?.minimum_commission != null &&
            data.criteria?.maximum_commission != null
          ) {
            addNewChartWithCriteria(data);
            setChartEnable(true);
            setCommissionTypeEnable(true);
          }
        }
      } else if (data?.criteria?.default_commission >= 0) {
        if (chartId != null) {
          data.criteria.chart_id = chartId;
          addCriteria(data.criteria);
          setIsChartAdded(true);
          setChartEnable(true);
        } else {
          addNewChartWithCriteria(data);
          setIsChartAdded(true);
          setChartEnable(true);
        }
      }
    } else if (actionType == ActionOptions.RENAME_CHART) {
      data.chart.id = commissionChartData.chart.id;
      updateChart(data.chart);
    } else if (actionType == ActionOptions.ADD_CRITERIA) {
      if (
        data?.criteria?.default_commission >= 0 &&
        data?.criteria?.maximum_commission >=
          data?.criteria?.minimum_commission &&
        data?.criteria?.minimum_commission >= 0
      ) {
        data.criteria.chart_id = commissionChartData.chart.id;
        addCriteria(data.criteria);
        setCommissionTypeEnable(true);
      }
      setChartEnable(true);
    }
    //edit criteria
    else if (actionType == ActionOptions.EDIT) {
      data.criteria.chart_id = commissionChartData?.chart.id;
      data.criteria.id = commissionChartData?.criteria.id;
      updateCriteria(data.criteria);
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };

  const { mutate: AddCommissionChart } = useMutation_AddNewCommissionCharts();
  const { mutate: AddChart } = UseMutation_AddCharts();
  const { mutate: UpdateChart } = UseMutation_UpdateCharts();
  const { mutate: AddNewCommissionCriteria } =
    useMutation_AddNewCommissionCriteria();
  const { mutate: UpdateCommissionCriteria } =
    useMutation_UpdateCommissionCharts(undefined);
  const { mutate: CheckCriteriaExist } = UseMutation_CheckCriteriaAttached();
  UseQuery_GetAllAssetMakes();
  UseQuery_GetAllAssetModels();
  UseQuery_GetAllAssetTrims();

  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const [assetMakeId, setAssetMakeId] = useState(null);
  const [assetModelId, setAssetModelId] = useState(null);
  const [assetTrimId, setAssetTrimId] = useState(null);
  const [assetMake, setAssetMake] = useState(null);
  const [assetModel, setAssetModel] = useState(null);
  var [assetTerm, setAssetTerm] = useState([]);
  const [assetCondition, setAssetCondition] = useState([]);
  const [financetype, setFinanceType] = useState([]);
  const [customertype, setCustomerType] = useState([]);
  const [assetcategory, setAssetCategory] = useState([]);
  const [assettype, setAssetType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [assetUsage, setAssetUsage] = useState([]);
  const [assetCreditScore, setAssetCreditScore] = useState([]);
  const [chartId, setChartId] = useState(null);
  const [chartEnable, setChartEnable] = useState<boolean>(false);
  const [criteriaEnable, setCriteriaEnable] = useState<boolean>(false);
  const [isChartAdded, setIsChartAdded] = useState<boolean>(false);
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const [chartNameValidation, setChartNameValidation] =
    useState<boolean>(false);
  const [commissionTypeEnable, setCommissionTypeEnable] = useState(false);
  const [financedAmounts, setFinancedAmounts] = useState([]);

  useEffect(() => {
    if (actionType === ActionOptions.RENAME_CHART && commissionChartData) {
      CheckCriteriaExist(
        {
          data: `name=${commissionChartData.chart?.chart_name}&chart_type=${ChartTypes.CommissionChart}`,
        },
        {
          onSuccess: (response) => {
            setCommissionTypeEnable(response.data);
          },
        }
      );
      setCriteriaEnable(true);
      setChartEnable(false);
      setValue("chart.chart_name", commissionChartData.chart?.chart_name);
      setValue("chart.is_active", commissionChartData.chart?.is_active);
      setValue(
        "chart.output_parameter_type",
        commissionChartData.chart?.output_parameter_type
      );
    } else if (
      actionType === ActionOptions.ADD_CRITERIA &&
      commissionChartData
    ) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setCommissionTypeEnable(true);
      setValue("chart.chart_name", commissionChartData.chart?.chart_name);
      setValue("chart.is_active", commissionChartData.chart?.is_active);
      setValue(
        "chart.output_parameter_type",
        commissionChartData.chart?.output_parameter_type
      );
      setValue("criteria.asset_condition", []);
      setValue("criteria.terms", []);
      setValue("criteria.credit_rating", []);
      setValue("criteria.customer_type", []);
      setValue("criteria.finance_type", []);
      setValue("criteria.asset_category", []);
      setValue("criteria.asset_type", []);
      setValue("criteria.product_type", []);
    } else if (actionType === ActionOptions.ADD_NEW_CHART && !isChartAdded) {
      setChartEnable(false);
      setCriteriaEnable(false);
      // reset(undefined);
    }
    //edit criteria
    else if (actionType === ActionOptions.EDIT && commissionChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setCommissionTypeEnable(true);
      setValue("chart.chart_name", commissionChartData?.chart?.chart_name);
      setValue("chart.is_active", commissionChartData?.chart?.is_active);
      setValue("criteria.id", commissionChartData?.criteria?.id);
      setValue(
        "chart.output_parameter_type",
        commissionChartData.chart?.output_parameter_type
      );
      setAssetMakeId(commissionChartData?.criteria?.asset_make_name);
      setAssetModelId(commissionChartData?.criteria?.asset_model_name);
      setAssetTrimId(commissionChartData?.criteria?.asset_trim_name);
      if (commissionChartData?.criteria?.terms) {
        setAssetTerm(commissionChartData?.criteria?.terms);
      }
      if (commissionChartData?.criteria?.asset_condition) {
        setAssetCondition(commissionChartData?.criteria?.asset_condition);
      }
      if (commissionChartData?.criteria?.credit_rating) {
        setAssetCreditScore(commissionChartData?.criteria?.credit_rating);
      }
      if (commissionChartData?.criteria?.finance_type) {
        setFinanceType(commissionChartData?.criteria?.finance_type);
      }
      if (commissionChartData?.criteria?.customer_type) {
        setCustomerType(commissionChartData?.criteria?.customer_type);
      }
      if (commissionChartData?.criteria?.asset_category) {
        setAssetCategory(commissionChartData?.criteria?.asset_category);
      }
      if (commissionChartData?.criteria?.asset_type) {
        setAssetType(commissionChartData?.criteria?.asset_type);
      }
      if (commissionChartData?.criteria?.product_type) {
        setProductType(commissionChartData?.criteria?.product_type);
      }
      if (commissionChartData?.criteria?.purchase_option) {
        setValue(
          "criteria.purchase_option",
          commissionChartData?.criteria?.purchase_option
        );
      }
      if (commissionChartData?.criteria?.financed_amount) {
        setFinancedAmounts(commissionChartData?.criteria?.financed_amount);
      }
      // setValue(
      //   "criteria.asset_classification",
      //   commissionChartData?.criteria?.asset_classification
      // );
      if (commissionChartData?.criteria?.asset_usages) {
        setAssetUsage(commissionChartData?.criteria?.asset_usages);
      }
      setValue(
        "criteria.asset_condition",
        commissionChartData?.criteria?.asset_condition
      );
      setValue(
        "criteria.finance_type",
        commissionChartData?.criteria?.finance_type
      );
      setValue(
        "criteria.customer_type",
        commissionChartData?.criteria?.customer_type
      );
      setValue(
        "criteria.asset_category",
        commissionChartData?.criteria?.asset_category
      );
      setValue(
        "criteria.asset_type",
        commissionChartData?.criteria?.asset_type
      );
      setValue(
        "criteria.product_type",
        commissionChartData?.criteria?.product_type
      );
      setValue(
        "criteria.default_commission",
        commissionChartData?.criteria?.default_commission
      );
      setValue(
        "criteria.minimum_commission",
        commissionChartData?.criteria?.minimum_commission
      );
      setValue(
        "criteria.maximum_commission",
        commissionChartData?.criteria?.maximum_commission
      );
      setValue(
        "criteria.calculation_basis",
        commissionChartData?.criteria?.calculation_basis
      );
      setValue("criteria.gl_split", commissionChartData?.criteria?.gl_split);
      setValue(
        "criteria.customer_split",
        commissionChartData?.criteria?.customer_split
      );
      setValue(
        "criteria.manufacturer_split",
        commissionChartData?.criteria?.manufacturer_split
      );
      setValue(
        "criteria.sales_representative_split",
        commissionChartData?.criteria?.sales_representative_split
      );
    }
  }, [commissionChartData, criteriaEnable]);
  const [assetmakesSelection, setAssetmakesSelection] = useState<
    Array<ISelectItemList>
  >(
    assetMakes
      ?.filter((x) => x.is_active)
      .map((make) => ({
        value: make.id,
        text: make.name,
      }))
  );

  useEffect(() => {
    setAssetmakesSelection(
      assetMakes
        ?.filter((x) => x.is_active)
        .map((makes) => ({
          value: makes.name,
          text: makes.name,
        }))
    );
  }, [assetMakes, assetMakeId]);

  const [assetmodelsSelection, setAssetmodelsSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [assetTrimSelection, setAssettrimSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [creditRatingsSelection, setCreditRatingState] =
    useState<Array<ISelectItemList>>();

  const [financedAmountsSelection, setFinancedAmountsSelectionState] =
    useState<Array<ISelectItemList>>();

  const [financeTypeSelection, setFinancedTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [customerTypeSelection, setCustomerTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [assetCategorySelection, setAssetCategorySelection] =
    useState<Array<ISelectItemList>>();
  const [assetTypeSelection, setAssetTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [productTypeSelection, setProductTypeSelection] =
    useState<Array<ISelectItemList>>();

  useEffect(() => {
    const ratingsArray = creditRatings
      ?.filter((x) => x.is_active)
      ?.map((creditRating) => ({
        value: creditRating.description,
        text: creditRating.description,
      }));
    assetCreditScore?.forEach((desc) => {
      const selectedRating = creditRatings?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedRating &&
        ratingsArray.push({
          value: selectedRating?.description,
          text: selectedRating?.description,
        });
    });
    setCreditRatingState(ratingsArray);
  }, [creditRatings, assetCreditScore]);

  const [termsSelection, setTermsSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetConditionSelection, setAssetConditionSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetUsageSelection, setAssetUsageSelectionState] =
    useState<Array<ISelectItemList>>();
  useEffect(() => {
    const termsArray = terms
      ?.filter((x) => x.is_active)
      ?.map((term) => ({
        value: `${term.terms_from} - ${term.terms_to}`,
        text: `${term.terms_from} - ${term.terms_to}`,
      }));
    assetTerm?.forEach((id) => {
      const selectedTerms = terms?.find((x) => x.id == id && !x.is_active);
      selectedTerms &&
        termsArray.push({
          value: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
          text: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
        });
    });
    setTermsSelectionState(termsArray);
  }, [terms, assetTerm]);

  useEffect(() => {
    const financedAmountArray = AllFinancedAmounts?.filter(
      (x) => x.is_active
    )?.map((financed) => ({
      value: `${financed.financed_amount_from} - ${financed.financed_amount_to}`,
      text: `${financed.financed_amount_from.toLocaleString()} - ${financed.financed_amount_to.toLocaleString()}`,
    }));
    financedAmounts?.forEach((id) => {
      const selectedFinancedAmounts = AllFinancedAmounts?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedAmounts &&
        financedAmountArray.push({
          value: `${selectedFinancedAmounts.financed_amount_from} - ${selectedFinancedAmounts.financed_amount_to}`,
          text: `${selectedFinancedAmounts.financed_amount_from.toLocaleString()} - ${selectedFinancedAmounts.financed_amount_to.toLocaleString()}`,
        });
    });
    setFinancedAmountsSelectionState(financedAmountArray);
  }, [AllFinancedAmounts, financedAmounts]);

  useEffect(() => {
    const assetConditionArray = assetConditions
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetCondition.forEach((desc) => {
      const selectedAssetConditions = assetConditions?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetConditions &&
        assetConditionArray.push({
          value: selectedAssetConditions?.description,
          text: selectedAssetConditions?.description,
        });
    });
    setAssetConditionSelectionState(assetConditionArray);
  }, [assetConditions, assetCondition]);

  useEffect(() => {
    const assetUsageArray = assetUsages
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetUsage.forEach((desc) => {
      const selectedAssetUsages = assetUsages?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetUsages &&
        assetUsageArray.push({
          value: selectedAssetUsages?.description,
          text: selectedAssetUsages?.description,
        });
    });
    setAssetUsageSelectionState(assetUsageArray);
  }, [assetUsages, assetUsage]);

  useEffect(() => {
    setAssetMake(assetMakes?.filter((x) => x.name == assetMakeId)[0]?.id);
    setAssetmodelsSelection(
      assetModels
        ?.filter(
          (models) =>
            assetMake > 0 && models.make_id === assetMake && models.is_active
        )
        ?.map((model) => ({
          value: model.model_name,
          text: model.model_name,
        }))
    );
  }, [assetModels, assetMakes, assetMakeId, assetModelId, assetMake]);

  useEffect(() => {
    setAssetModel(
      assetModels?.filter((x) => x.model_name == assetModelId)[0]?.id
    );
    setAssettrimSelection(
      assetTrims
        ?.filter(
          (trims) =>
            assetModel > 0 && trims.model_id === assetModel && trims.is_active
        )
        ?.map((trim) => ({
          value: trim.trim_name,
          text: trim.trim_name,
        }))
    );
  }, [
    assetTrims,
    assetModels,
    assetMakes,
    assetMakeId,
    assetModelId,
    assetTrimId,
    assetModel,
  ]);

  useEffect(() => {
    const financedTypeArray = financeType
      ?.filter((x) => x.is_active)
      ?.map((finance) => ({
        value: `${finance.name}`,
        text: `${finance.name}`,
      }));
    financetype.forEach((id) => {
      const selectedFinancedType = financeType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedType &&
        financedTypeArray.push({
          value: selectedFinancedType?.name,
          text: selectedFinancedType?.name,
        });
    });
    setFinancedTypeSelection(financedTypeArray);
  }, [financeType, financetype]);

  useEffect(() => {
    const customerTypeArray = customerType
      ?.filter((x) => x.is_active)
      ?.map((customer) => ({
        value: `${customer.description}`,
        text: `${customer.description}`,
      }));
    customertype.forEach((id) => {
      const selectedCustomerType = customerType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedCustomerType &&
        customerTypeArray.push({
          value: selectedCustomerType?.description,
          text: selectedCustomerType?.description,
        });
    });
    setCustomerTypeSelection(customerTypeArray);
  }, [customerType, customertype]);

  useEffect(() => {
    const assetCategoryArray = assetCategory
      ?.filter((x) => x.is_active)
      ?.map((category) => ({
        value: `${category.description}`,
        text: `${category.description}`,
      }));
    assetcategory.forEach((id) => {
      const selectedAssetCategory = assetCategory?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedAssetCategory &&
        assetCategoryArray.push({
          value: selectedAssetCategory?.description,
          text: selectedAssetCategory?.description,
        });
    });
    setAssetCategorySelection(assetCategoryArray);
  }, [assetCategory, assetcategory]);

  useEffect(() => {
    const assetTypeArray = assetType
      ?.filter((x) => x.is_active || x.is_active === null)
      ?.map((asset) => ({
        value: `${asset.name}`,
        text: `${asset.name}`,
      }));
    assettype.forEach((id) => {
      const selectedAssetType = assetType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedAssetType &&
        assetTypeArray.push({
          value: selectedAssetType?.name,
          text: selectedAssetType?.name,
        });
    });
    setAssetTypeSelection(assetTypeArray);
  }, [assetType, assettype]);

  useEffect(() => {
    const productTypeArray = producttype
      ?.filter((x) => x.is_active || x.is_active === null)
      ?.map((asset) => ({
        value: `${asset.name}`,
        text: `${asset.name}`,
      }));
    productType.forEach((id) => {
      const selectedProductType = producttype?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedProductType &&
        productTypeArray.push({
          value: selectedProductType?.name,
          text: selectedProductType?.name,
        });
    });
    setProductTypeSelection(productTypeArray);
  }, [producttype, productType]);

  const onMakeSelection = (value) => {
    setAssetMakeId(value?.id);
    setAssetModelId(null);
    setAssetTrimId(null);
  };

  const onModelSelection = (value) => {
    setAssetModelId(value?.id);
    setAssetTrimId(null);
  };

  const onTrimSelection = (value) => {
    setAssetTrimId(value?.id);
  };

  const onTermsSelection = (e) => {
    setAssetTerm(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onCreditScoreSelection = (e) => {
    setAssetCreditScore(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onConditionSelection = (e) => {
    setAssetCondition(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onUsageSelection = (e) => {
    setAssetUsage(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onFinancedAmountSelection = (e) => {
    setFinancedAmounts(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onFinanceTypeSelection = (e) => {
    setFinanceType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onCustomerTypeSelection = (e) => {
    setCustomerType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onAssetCategorySelection = (e) => {
    setAssetCategory(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onAssetTypeSelection = (e) => {
    setAssetType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onProductTypeSelection = (e) => {
    setProductType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const addNewChartWithCriteria = (data: IChartCriteria) => {
    AddCommissionChart(data, {
      onSuccess: (data, variables, context) => {
        setChartId(data.data.id);
      },
    });
    onClose();
  };

  const addCriteria = (data: ICriteria) => {
    AddNewCommissionCriteria(data);
    onClose();
  };

  const updateCriteria = (data: ICriteria) => {
    UpdateCommissionCriteria(data);
    onClose();
  };

  const updateChart = (data: ICharts) => {
    UpdateChart(data);
    reset();
    onClose();
  };
  const setMarginValue = (val) => {
    let value = parseFloat(val);
    if (val > 100 && commission_type == "Percentage") {
      if (val == 1000) value = val.substr(0, 3);
      else value = val.substr(0, 2);
    }
    setValue("criteria.default_commission", value);
    const minMargin = watch("criteria.minimum_commission");
    const maxMargin = watch("criteria.maximum_commission");
    clearErrors("criteria.default_commission");
    if (value < minMargin) {
      setError("criteria.minimum_commission", {
        message: ValidationMessages.MIN_SMALLER_THAN_DEFAULT,
      });
    } else {
      clearErrors("criteria.minimum_commission");
    }
    if (value > maxMargin) {
      setError("criteria.maximum_commission", {
        message: ValidationMessages.Max_GREATER_THAN_DEFAULT,
      });
    } else {
      clearErrors(["criteria.maximum_commission"]);
    }
    // }
  };
  const setMinCommission = (val) => {
    let value = parseFloat(val);
    if (val > 100 && commission_type == "Percentage") {
      if (val == 1000) value = val.substr(0, 3);
      else value = val.substr(0, 2);
    }
    setValue("criteria.minimum_commission", value);
    const maxMargin = watch("criteria.maximum_commission");
    const margin = watch("criteria.default_commission") || 0;
    if (value > margin) {
      setError("criteria.minimum_commission", {
        message: ValidationMessages.MIN_MARGIN_SMALLER_THAN_DEFAULT,
      });
    } else if (value > maxMargin) {
      setError("criteria.maximum_commission", {
        message: ValidationMessages.MAX_MARGIN_GREATER,
      });
    } else {
      clearErrors(["criteria.minimum_commission"]);
    }
  };
  const setMaxCommission = (val) => {
    let value = parseFloat(val);
    if (val > 100 && commission_type == "Percentage") {
      if (val == 1000) value = val.substr(0, 3);
      else value = val.substr(0, 2);
    }
    setValue("criteria.maximum_commission", value);
    const minMargin = watch("criteria.minimum_commission");
    const margin = watch("criteria.default_commission");
    if (minMargin > value) {
      setError("criteria.maximum_commission", {
        message: ValidationMessages.MAX_MARGIN_GREATER,
      });
    } else if (margin > value) {
      setError("criteria.maximum_commission", {
        message: ValidationMessages.Max_MARGIN_GREATER_THAN_DEFAULT,
      });
    } else {
      clearErrors([
        "criteria.minimum_commission",
        "criteria.maximum_commission",
      ]);
    }
  };

  const checkChartName = (data: string) => {
    CheckNameDuplicate(
      {
        data: `name=${data}&chart_type_id=${ChartTypes.CommissionChart}`,
      },
      {
        onSuccess: (response) => {
          setChartNameValidation(response.data);
          trigger("chart.chart_name");
          if (response.data) {
            setError(
              "chart.chart_name",
              {
                message: ValidationMessages.NAME_UNIQUE,
              },
              { shouldFocus: true }
            );
          } else {
            clearErrors("chart.chart_name");
          }
        },
      }
    );
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === lenderProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [lenderProfile]);

  const calculateSum = (val) => {
    const glSplit: any = watch("criteria.gl_split") || 0;
    const customerSplit: any = watch("criteria.customer_split") || 0;
    const manufacturerSplit: any = watch("criteria.manufacturer_split") || 0;
    const salesRepresentativeSplit: any =
      watch("criteria.sales_representative_split") || 0;
    const sum =
      parseFloat(glSplit) +
      parseFloat(customerSplit) +
      parseFloat(manufacturerSplit) +
      parseFloat(salesRepresentativeSplit);
    if (sum > 100) {
      setError("criteria.gl_split", {
        message: ValidationMessages.SUM_SHOULD_NO_BE_GREATER_THAN_100,
      });
      setError("criteria.manufacturer_split", {
        message: ValidationMessages.SUM_SHOULD_NO_BE_GREATER_THAN_100,
      });
      setError("criteria.customer_split", {
        message: ValidationMessages.SUM_SHOULD_NO_BE_GREATER_THAN_100,
      });
      setError("criteria.sales_representative_split", {
        message: ValidationMessages.SUM_SHOULD_NO_BE_GREATER_THAN_100,
      });
    } else if (sum < 100) {
      setError("criteria.gl_split", {
        message: ValidationMessages.SUM_SHOULD_NO_BE_LESS_THAN_100,
      });
      setError("criteria.manufacturer_split", {
        message: ValidationMessages.SUM_SHOULD_NO_BE_LESS_THAN_100,
      });
      setError("criteria.customer_split", {
        message: ValidationMessages.SUM_SHOULD_NO_BE_LESS_THAN_100,
      });
      setError("criteria.sales_representative_split", {
        message: ValidationMessages.SUM_SHOULD_NO_BE_LESS_THAN_100,
      });
    } else {
      clearErrors([
        "criteria.gl_split",
        "criteria.manufacturer_split",
        "criteria.customer_split",
        "criteria.sales_representative_split",
      ]);
    }
  };

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        onClose={onClose}
        enabledPin={true}
        children={
          <>
            <Controller
              name="chart.chart_name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: ValidationMessages.CHART_NAME_REQUIRED,
                },
                validate: {
                  checkUniqueName: () => {
                    if (chartNameValidation) {
                      return ValidationMessages.NAME_UNIQUE;
                    }
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerChartName"
                  fullWidth={true}
                  theme={theme}
                  label={"Chart Name"}
                  placeholder={"Type Here"}
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  // onBlur={(e) => checkChartName(e.target.value)}
                  disabled={chartEnable}
                  error={errors?.chart?.chart_name?.message}
                  type="text"
                />
              )}
            />
            <Controller
              name="chart.output_parameter_type"
              control={control}
              defaultValue={"Amount"}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="outputParameterType"
                  theme={theme}
                  label={"Commission Type"}
                  items={[
                    { text: "Amount", value: "Amount" },
                    { text: "Percentage", value: "Percentage" },
                  ]}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    if (e.target.value == "Percentage") {
                      setValue("criteria.default_commission", 0);
                      setValue("criteria.minimum_commission", 0);
                      setValue("criteria.maximum_commission", 0);
                    }
                  }}
                  disabled={commissionTypeEnable}
                ></Select>
              )}
            />
            <Controller
              name="chart.is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerStatus"
                  theme={theme}
                  label={"Status"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                  disabled={chartEnable}
                ></Select>
              )}
            />
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                BUSINESS CRITERIA
              </Box>
            </Box>
            <Controller
              name="criteria.asset_make_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  {...field}
                  id="drawerMake"
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetmakesSelection
                      ? assetmakesSelection?.map((option: any) => {
                          return { label: option.text, id: option.value };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onMakeSelection(value);
                    field.onChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  label={assetMakeId ? "Asset Make" : "Asset Make"}
                  value={
                    assetMakeId
                      ? {
                          label: assetMakeId,
                          id: assetMakeId,
                        }
                      : null
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="criteria.asset_model_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  id="drawerModel"
                  {...field}
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetmodelsSelection
                      ? assetmodelsSelection?.map((option: any) => {
                          return { label: option.text, id: option.text };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onModelSelection(value);
                    field.onChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  label={assetModelId ? "Asset Model" : "Asset Model"}
                  value={
                    assetModelId
                      ? {
                          label: assetModelId,
                          id: assetModelId,
                        }
                      : null
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="criteria.asset_trim_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  id="drawerTrim"
                  {...field}
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetTrimSelection
                      ? assetTrimSelection?.map((option: any) => {
                          return { label: option.text, id: option.text };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onTrimSelection(value);
                    field.onChange(value);
                  }}
                  label={assetTrimId ? "Asset Trim" : "Asset Trim"}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={
                    assetTrimId
                      ? {
                          label: assetTrimId,
                          id: assetTrimId,
                        }
                      : null
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <MultiSelectWrap>
              <Controller
                name="criteria.purchase_option"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerPurchaseOption"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Purchase Option"}
                    value={value || []}
                    items={[
                      { text: "$1 out", value: "$1 out" },
                      { text: "FMV", value: "FMV" },
                    ]}
                    onChange={(e) => {
                      onChange(
                        typeof e.target.value === "string"
                          ? e.target.value.split(",")
                          : e.target.value
                      );
                    }}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_condition"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetCondition"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Condition"}
                    value={assetCondition || []}
                    items={assetConditionSelection}
                    onChange={onConditionSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.finance_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Finance Type"}
                    value={financetype || []}
                    items={financeTypeSelection}
                    onChange={onFinanceTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.customer_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Customer Type"}
                    value={customertype || []}
                    items={customerTypeSelection}
                    onChange={onCustomerTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_category"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Asset Category"}
                    value={assetcategory || []}
                    items={assetCategorySelection}
                    onChange={onAssetCategorySelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Asset Type"}
                    value={assettype || []}
                    items={assetTypeSelection}
                    onChange={onAssetTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.product_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Product Type"}
                    value={productType || []}
                    items={productTypeSelection}
                    onChange={onProductTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Grid
              theme={theme}
              container
              rowSpacing={3}
              columnSpacing={2}
              sx={{ mb: 3 }}
            >
              <Grid theme={theme} item xs={6}>
                <MultiSelectWrap>
                  <Controller
                    name="criteria.terms"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <MultiSelect
                        theme={theme}
                        id="drawerTerms"
                        label={"Terms"}
                        value={assetTerm || []}
                        items={termsSelection}
                        onChange={onTermsSelection}
                        disabled={criteriaEnable}
                        sxProps={{ m: 0, width: "100%" }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </Grid>
              <Grid theme={theme} item xs={6}>
                <MultiSelectWrap>
                  <Controller
                    name="criteria.credit_rating"
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        id="drawerCreditRating"
                        theme={theme}
                        label={"Credit Score"}
                        items={creditRatingsSelection}
                        value={assetCreditScore || []}
                        onChange={onCreditScoreSelection}
                        disabled={criteriaEnable}
                        sxProps={{ m: 0, width: "100%" }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </Grid>
            </Grid>
            <MultiSelectWrap>
              <Controller
                name="criteria.financed_amount"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerFinancedAmountRange"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Financed Amount Range"}
                    value={financedAmounts || []}
                    items={financedAmountsSelection}
                    onChange={onFinancedAmountSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_usages"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetUsage"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Usage"}
                    value={assetUsage || []}
                    items={assetUsageSelection}
                    onChange={onUsageSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                OUTPUT PARAMETERS
              </Box>
            </Box>
            {commission_type == "Percentage" && (
              <Controller
                name="criteria.calculation_basis"
                control={control}
                render={({ field }) => (
                  <Select
                    id="calculationBasis"
                    theme={theme}
                    label={"Calculation Basis"}
                    items={[
                      {
                        text: "Asset cost incl. tax",
                        value: "Asset cost incl. tax",
                      },
                      {
                        text: "Asset cost excl. tax",
                        value: "Asset cost excl. tax",
                      },
                      {
                        text: "Net Financed Amount",
                        value: "Net Financed Amount",
                      },
                      {
                        text: "Interest income",
                        value: "Interest income",
                      },
                    ]}
                    {...field}
                    disabled={criteriaEnable}
                    selectError={
                      errors?.criteria?.calculation_basis?.message as never
                    }
                  ></Select>
                )}
              />
            )}
            <CurrencyInputWrap>
              <Controller
                name="criteria.default_commission"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    fullWidth
                    theme={theme}
                    id="defaultCommission"
                    label={"Default Commission"}
                    type="text"
                    value={value}
                    onChange={(val) => {
                      setMarginValue(val);
                    }}
                    disabled={criteriaEnable}
                    masking
                    maskDecimalScale={commission_type == "Percentage" ? 4 : 2}
                    adornmentOnFocus
                    endAdornment={
                      commission_type == "Percentage" && (
                        <span className="MuiInputAdornment-root">{"%"}</span>
                      )
                    }
                    startAdornment={
                      commission_type == "Amount" && (
                        <span className="adornment-text">
                          {defaultCurrency}
                        </span>
                      )
                    }
                    maskNumeric
                    error={
                      errors?.criteria?.default_commission?.message as never
                    }
                  />
                )}
              />
            </CurrencyInputWrap>
            <Grid theme={theme} container rowSpacing={3} columnSpacing={2}>
              <Grid theme={theme} item xs={6}>
                <CurrencyInputWrap>
                  <Controller
                    name="criteria.minimum_commission"
                    control={control}
                    render={({ field }) => (
                      <Input
                        fullWidth
                        theme={theme}
                        id="minimumCommission"
                        type="text"
                        {...field}
                        // value={value}
                        onChange={(val) => {
                          setMinCommission(val);
                        }}
                        label={"Min. Commission"}
                        masking
                        adornmentOnFocus
                        endAdornment={
                          commission_type == "Percentage" && (
                            <span className="MuiInputAdornment-root">
                              {"%"}
                            </span>
                          )
                        }
                        startAdornment={
                          commission_type == "Amount" && (
                            <span className="adornment-text">
                              {defaultCurrency}
                            </span>
                          )
                        }
                        maskDecimalScale={
                          commission_type == "Percentage" ? 4 : 2
                        }
                        maskNumeric
                        disabled={criteriaEnable}
                        error={
                          errors?.criteria?.minimum_commission?.message as never
                        }
                      />
                    )}
                  />
                </CurrencyInputWrap>
              </Grid>
              <Grid theme={theme} item xs={6}>
                <CurrencyInputWrap>
                  <Controller
                    name="criteria.maximum_commission"
                    control={control}
                    render={({ field }) => (
                      <Input
                        fullWidth
                        theme={theme}
                        id="maximumCommission"
                        type="text"
                        {...field}
                        onChange={(val) => {
                          setMaxCommission(val);
                        }}
                        label={"Max. Commission"}
                        disabled={criteriaEnable}
                        masking
                        maskDecimalScale={
                          commission_type == "Percentage" ? 4 : 2
                        }
                        maskNumeric
                        adornmentOnFocus
                        endAdornment={
                          commission_type == "Percentage" && (
                            <span className="MuiInputAdornment-root">
                              {"%"}
                            </span>
                          )
                        }
                        startAdornment={
                          commission_type == "Amount" && (
                            <span className="adornment-text">
                              {defaultCurrency}
                            </span>
                          )
                        }
                        error={
                          errors?.criteria?.maximum_commission?.message as never
                        }
                      />
                    )}
                  />
                </CurrencyInputWrap>
              </Grid>
            </Grid>
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                Commission Split
              </Box>
            </Box>
            <Grid theme={theme} container rowSpacing={3} columnSpacing={2}>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="criteria.gl_split"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="glSplit"
                      type="text"
                      value={value}
                      onChange={(val) => {
                        onChange(
                          val > 100
                            ? val == 1000
                              ? val.substr(0, 3)
                              : val.substr(0, 2)
                            : val
                        );
                      }}
                      label={"GL"}
                      masking
                      adornmentOnFocus
                      endAdornment={
                        <span className="MuiInputAdornment-root">{"%"}</span>
                      }
                      maskDecimalScale={2}
                      maskNumeric
                      disabled={criteriaEnable}
                      error={errors?.criteria?.gl_split?.message as never}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="criteria.customer_split"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="customerSplit"
                      type="text"
                      value={value}
                      onChange={(val) => {
                        onChange(
                          val > 100
                            ? val == 1000
                              ? val.substr(0, 3)
                              : val.substr(0, 2)
                            : val
                        );
                      }}
                      label={"Customer"}
                      disabled={criteriaEnable}
                      masking
                      maskNumeric
                      adornmentOnFocus
                      endAdornment={
                        <span className="MuiInputAdornment-root">{"%"}</span>
                      }
                      maskDecimalScale={2}
                      error={errors?.criteria?.customer_split?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <br />
            <Grid theme={theme} container rowSpacing={3} columnSpacing={2}>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="criteria.manufacturer_split"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="manufacturerSplit"
                      type="text"
                      value={value}
                      onChange={(val) => {
                        onChange(
                          val > 100
                            ? val == 1000
                              ? val.substr(0, 3)
                              : val.substr(0, 2)
                            : val
                        );
                      }}
                      label={"Manufacturer"}
                      masking
                      adornmentOnFocus
                      endAdornment={
                        <span className="MuiInputAdornment-root">{"%"}</span>
                      }
                      maskDecimalScale={2}
                      maskNumeric
                      disabled={criteriaEnable}
                      error={errors?.criteria?.manufacturer_split?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="criteria.sales_representative_split"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="salesRepresentativeSplit"
                      type="text"
                      value={value}
                      onChange={(val) => {
                        onChange(
                          val > 100
                            ? val == 1000
                              ? val.substr(0, 3)
                              : val.substr(0, 2)
                            : val
                        );
                      }}
                      label={"Sales Representative"}
                      disabled={criteriaEnable}
                      masking
                      maskNumeric
                      adornmentOnFocus
                      endAdornment={
                        <span className="MuiInputAdornment-root">{"%"}</span>
                      }
                      maskDecimalScale={2}
                      error={
                        errors?.criteria?.sales_representative_split?.message
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <Button
            theme={theme}
            id="saveCriteria"
            primary
            text={"Save"}
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewCommissionCharts;
