import { FC } from "react";
import { useTheme, Link } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Icon,
  Menu,
  Input,
  DataTable,
  Switch,
} from "@ntpkunity/controls";

const LendersTable: FC = () => {
  const theme = useTheme();

  const Tr: FC = () => {
    return (
      <TableRow className="child-tr">
        <TableCell>Sample Lender Name</TableCell>
        <TableCell>ABC-1234</TableCell>
        <TableCell>Company</TableCell>
        <TableCell>Finance</TableCell>
        <TableCell>Internal</TableCell>
        <TableCell>
          <Switch theme={theme} varient={"basic"} />
        </TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="EditIcon" /> Edit
                  </>
                ),
                optionValue: "edit",
              },
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="DeleteIcon" /> Delete
                  </>
                ),
                optionValue: "delete",
              },
            ]}
            render={(cb) => (
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        sx={{ mb: 3 }}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Lender Name</TableCell>
              <TableCell>External Code</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Finance Type(s)</TableCell>
              <TableCell>Program</TableCell>
              <TableCell id="taxableHeader">Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerCodeSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerCodeFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerTypeSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerTypeFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"providerNameSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="providerNameSearch"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"numberSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="numberFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"taxableSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="taxableFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
            <Tr />
          </>
        }
      />
    </>
  );
};
export default LendersTable;
