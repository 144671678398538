import { FC, useEffect, useMemo, useState, useContext } from "react";
import { useTheme } from "@mui/material";
import { Box, DuoTab, Typography } from "@ntpkunity/controls";
import { LayoutWithSideNav } from "layout";
import { DrawerWrap, Main, PageHeader } from "@sharedComponents";
import IndividualProducts from "modules/FniProducts/IndividualProducts";
import ProductBundles from "modules/FniProducts/ProductBundles";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  useMutation_CheckS3Credentials,
  useGetProfilebyDealerCode,
} from "services";
import {
  UseQueryGetDealerIntergrations,
  UseQueryGetDealerProviders,
} from "services/dealer-integration.service";
import { useGetProvider } from "services/integration-api.service";
import { DealerContext } from "context";
import { IDealerProfile } from "Interface";

const FniProducts: FC = () => {
  const theme = useTheme();
  const [state] = useSetupsStore();
  const { openDrawerSt } = state;
  const [popUpState] = useState(false);
  const { dealer_code } = useContext(DealerContext);
  const { data: dealerIntegrations, isFetched: isIntegrationsFetched } =
    UseQueryGetDealerIntergrations(dealer_code);
  const { mutate: getProvider, data: provider_data } = useGetProvider();
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  const { mutate: getDealerProfile, data: profile_data } =
    useGetProfilebyDealerCode();
  const { data: dealerProvider } = UseQueryGetDealerProviders(dealer_code);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  useEffect(() => {}, [openDrawerSt]);
  const isIntegrationActive = (integration_type, provider_name) => {
    let is_active = false;

    const integration = dealerIntegrations?.find(
      (integration) => integration.integration_type === integration_type
    );

    integration?.providers?.map((provider) => {
      const dealer_provider = dealerProvider?.filter(
        (item) => item?.company_provider_id == provider?.id
      );
      if (
        provider?.provider_name == provider_name &&
        dealer_provider?.length == 1
      ) {
        is_active = provider?.is_active;
      }
    });
    return is_active;
  };
  useEffect(() => {
    if (isIntegrationsFetched && dealerProvider)
      getDealerProfile(
        { dealer_code: dealer_code },
        {
          onSuccess: (response: IDealerProfile) => {
            if (
              response &&
              response.pen_dealer_id &&
              isIntegrationActive("F&I Products", "PEN")
            ) {
              setIsDisabled(false);
              getProvider(dealer_code);
            } else {
              setIsDisabled(true);
            }
          },
        }
      );
  }, [dealer_code, isIntegrationsFetched, dealerProvider]);

  useEffect(() => {
    checkS3Credentials(JSON.parse(localStorage.getItem("settings"))?.tenant_id);
  }, []);
  return (
    <>
      <Main open={openDrawerSt ? openDrawerSt : false}>
        <DrawerWrap open={popUpState}>
          <LayoutWithSideNav theme={theme}>
            <PageHeader
              className="main-page-header border-bottom"
              theme={theme}
              container
              item
              spacing={2}
            >
              <Typography variant="h2" component="h2" theme={theme}>
                F&amp;I Products
              </Typography>
            </PageHeader>
            <DuoTab
              theme={theme}
              varient="underline"
              defaultTabIndex={0}
              items={[
                {
                  title: "Individual Products",
                  content: (
                    <IndividualProducts
                      isDisabled={isDisabled}
                      s3Response={s3Response}
                      providerData={provider_data}
                      profileData={profile_data}
                    />
                  ),
                },
                {
                  title: "Product Bundles",
                  content: (
                    <ProductBundles
                      isDisabled={isDisabled}
                      s3Response={s3Response}
                    />
                  ),
                },
              ]}
            />
          </LayoutWithSideNav>
        </DrawerWrap>
      </Main>
    </>
  );
};

export default FniProducts;
