import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddressLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.address-layout-wrap": {
    ".u-form-group": {
      ".u-form-control": {
        "&.MuiInputBase-adornedEnd": {
          ".styled-tooltip": {
            height: 24,
            cursor: "auto",
            ".MuiTooltip-popper": {
              '&[data-popper-placement*="bottom"]': {
                ".MuiTooltip-arrow": {
                  transform: "none !important",
                  marginLeft: 7,
                },
              },
              '&[data-popper-placement*="left"]': {
                marginTop: "-7px !important",
              },
            },
          },
          "~": {
            ".u-input-label": {
              width: "calc(100% - 60px)",
            },
          },
        },
      },
    },
    ".tooltip-content": {
      ".fw-bold": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
}));

export const VerifyAddressUi = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  ".MuiFormGroup-root": {
    ".MuiFormControlLabel-root": {
      marginRight: "24px !important",

      "&:last-of-type": {
        marginRight: 0,
      },
    },
  },
}));
