import { FC, useEffect, useState, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material";
import {
  Select,
  Input,
  MultiSelect,
  Autocomplete,
  Box,
} from "@ntpkunity/controls";
import { MultiSelectWrap } from "@sharedComponents";
import { decimalPlaceValidation, validation } from "shared/helper/methods";
import {
  useGetFees,
  useGetStates,
} from "services/dealer-configurations.service";
import { useQueryClient } from "react-query";
import { QueryKeys, ToastMessages, ValidationMessages } from "Enums";
import { DEALER_FEE_CONFIG_MSG } from "shared/config/constants";
import { IFinanceTypeModel } from "Interface";
import { UseQuery_GetAllDealTypes } from "services";

interface IFormInputs {
  id?: number;
  fee_name: string;
  deal_type: string;
  states: number[];
  default_amount: number;
  taxable: boolean;
  fee_handling_override: boolean;
  is_active: boolean;
  dealer_code: string;
  applicable_finance_type: string[];
  vendor?: string;
  event: string;
  calculation_type: string;
  multiplication_factor: number;
}

const DealerFeeDrawer: FC<{
  form: any;
  formData?: IFormInputs;
  isEditMode: boolean;
  dealer_code: string;
  setStateNames?: any;
  setIsError?: any;
}> = ({
  form,
  formData,
  isEditMode,
  dealer_code,
  setStateNames,
  setIsError,
}) => {
    const theme = useTheme();
    const { mutate: getStates, data: statesData } = useGetStates();
    const { mutate: getFees, data: feeData } = useGetFees();
    const queryClient = useQueryClient();

    const {
      control,
      formState: { errors },
      setValue,
      watch,
      clearErrors,
    } = form;

    const clearFieldErrors = () => {
      if (errors?.fee_name?.message !== ValidationMessages.FEE_NAME_REQUIRED) {
        clearErrors("fee_name");
      }

      if (errors?.states?.message !== ValidationMessages.STATE_REQUIRED) {
        clearErrors("states");
      }

      if (
        errors?.applicable_finance_type?.message !==
        ValidationMessages.APPLICABLE_FINANCE_TYPE_REQUIRED
      ) {
        clearErrors("applicable_finance_type");
      }

      const allFieldsHaveValues =
        form.getValues("fee_name") &&
        form.getValues("states") &&
        form.getValues("applicable_finance_type");
      if (!Object.keys(errors).length && allFieldsHaveValues) {
        setIsError(false);
      }
    };

    const calculationType = watch("calculation_type");
    const selectedDealType = watch("deal_type");
    const selectEvent = watch("event");

    const [feeName, setFeeName] = useState<string>();
    useEffect(() => {
      getStates();
      getFees();
    }, []);
    const isDisabled = useMemo(() => {
      return calculationType === "Fixed amount";
    }, [calculationType]);
    UseQuery_GetAllDealTypes();
    const allCurrencies: any = queryClient.getQueryData(
      QueryKeys.GET_ALL_CURRENCIES
    );
    const companyProfile: any = queryClient.getQueryData(
      QueryKeys.GET_COMPANY_PROFILE
    );
    const available_finance: Array<IFinanceTypeModel> = queryClient.getQueryData(
      QueryKeys.GET_ALL_FINANCE_TYPES
    );
    const dealTypes: { dealTypeData: Array<any> } = queryClient.getQueryData(
      QueryKeys.DEAL_TYPES
    );
    const [defaultCurrency, setDefaultCurrency] = useState<string>("");

    useEffect(() => {
      const filteredCurrency = allCurrencies?.filter(
        (currency) => currency.id === companyProfile?.default_currency
      );
      setDefaultCurrency(
        filteredCurrency && filteredCurrency.length > 0
          ? filteredCurrency[0].symbol
          : ""
      );
    }, [companyProfile]);

    return (
      <>
        <Box theme={theme} className="auto-complete-with-error">
          <Controller
            name="fee_name"
            control={control}
            defaultValue={undefined}
            rules={validation("Fee Name", true)}
            render={({ field }) => (
              <Autocomplete
                disablePortal
                freeSolo={true}
                theme={theme}
                items={feeData?.map((option: any) => {
                  return { label: option.name };
                })}
                inputValue={field?.value}
                options={feeData?.map((option: any) => {
                  return { label: option.name };
                })}
                getOptionLabel={(option: any) =>
                  option.label ? option.label : field.value
                }
                onInputChange={(_e: any, value) => {
                  setFeeName(value);
                  setValue("fee_name", value);
                  field.onChange(_e);
                  clearFieldErrors();
                  if (value !== null && value !== undefined) {
                    clearErrors("fee_name");
                  }
                }}
                {...field}
                onChange={(_e: any, value: any) => {
                  setFeeName(value?.label);
                  setValue("fee_name", value?.label);
                  clearFieldErrors();
                  if (value !== null && value !== undefined) {
                    clearErrors("fee_name");
                  }
                }}
                sx={{ mb: 2 }}
                error={errors?.fee_name?.message}
                label="Fee Name"
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
              />
            )}
          />
        </Box>
        <MultiSelectWrap>
          <Controller
            name="states"
            control={control}
            rules={{
              validate: (value) => {
                if (value === null || value === undefined || value.length === 0) {
                  return "State is required";
                }
                return true;
              },
            }}
            defaultValue={[]}
            render={({ field: { value, onChange } }) => (
              <MultiSelect
                theme={theme}
                label={"State Name"}
                items={statesData?.map((state: any) => {
                  return { value: state.id, text: state.name };
                })}
                value={value}
                onChange={(e: any) => {
                  setStateNames(
                    e.target.value.map(
                      (val: number) =>
                        statesData.find((state_id: any) => state_id.id === val)
                          ?.name
                    )
                  );
                  onChange(e);
                  clearFieldErrors();
                }}
                selectError={errors?.states?.message}
                sxProps={""}
              />
            )}
          />
        </MultiSelectWrap>
        <Controller
          name="deal_type"
          control={control}
          defaultValue={""}
          rules={{
            validate: (value) => {
              if (value === "") {
                return "Deal Type is required";
              }
              return true;
            },
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Deal Type"}
              items={dealTypes?.dealTypeData?.map((item) => {
                return {
                  text: item.deal_type_name,
                  value: item.deal_type_name
                };
              })}
              value={value ?? []}
              onChange={(e) => {
                onChange(e);
              }}
              selectError={errors?.deal_type?.message}
            />
          )}
        />
        <MultiSelectWrap>
          <Controller
            name="applicable_finance_type"
            control={control}
            defaultValue={[]}
            rules={{
              validate: (value) => {
                if (selectedDealType === "Financing" && (value === null || value === undefined || value.length === 0)) {
                  return "Applicable Finance Type(s) is required";
                }
                return true;
              },
            }}
            render={({ field }) => (
              <MultiSelect
                id="applicationFinanceType"
                name="applicable_finance_type"
                theme={theme}
                label={"Applicable Finance Types"}
                items={available_finance?.map((item) => {
                  return { text: item.name, value: item.name };
                })}
                {...field}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  clearFieldErrors();
                }}
                disabled={(selectedDealType !== "Financing")}
                selectError={errors?.applicable_finance_type?.message}
              />
            )}
          />
        </MultiSelectWrap>
        <Controller
          name="event"
          control={control}
          defaultValue={""}
          rules={{
            validate: (value) => {
              if (value === "") {
                return "Event is required";
              }
              return true;
            },
          }}
          render={({ field: { value, onChange } }) => {
            const items =
              selectedDealType !== "Cash"
                ? [
                  { text: "End of Term", value: "End of Term" },
                  { text: "Inception", value: "Inception" },
                ]
                : [{ text: "Inception", value: "Inception" }];

            return (
              <Select
                theme={theme}
                label={"Event"}
                items={items}
                value={value}
                sxProps={""}
                onChange={(e) => {
                  onChange(e);
                }}
                selectError={errors?.event?.message}
              />
            );
          }}
        />
        <Controller
          name="calculation_type"
          control={control}
          defaultValue={""}
          rules={{
            validate: (value) => {
              if (value === "") {
                return "Calculation Type is required";
              }
              return true;
            },
          }}
          render={({ field: { value, onChange } }) => {
            const items =
              selectEvent !== "Inception"
                ? [
                  { text: "Fixed amount", value: "Fixed amount" },
                  { text: "Monthly Payment based", value: "Monthly Payment based" },
                ]
                : [{ text: "Fixed amount", value: "Fixed amount" }];
            return (
              <Select
                theme={theme}
                label={"Calculation Type"}
                items={items}
                value={value}
                sxProps={""}
                onChange={(e) => {
                  if (e.target.value === "Fixed amount")
                    setValue("multiplication_factor", undefined);
                  onChange(e);
                }}
                selectError={errors?.calculation_type?.message}
              />)
          }}
        />
        <Controller
          name="default_amount"
          control={control}
          rules={validation("Default Amount", true)}
          render={({ field }) => (
            <Input
              theme={theme}
              label={"Default Amount"}
              fullWidth
              startAdornment={
                <>
                  <span className="adornment-text">{defaultCurrency}</span>
                </>
              }
              {...field}
              type="number"
              error={errors?.default_amount?.message}
            />
          )}
        />
        <Controller
          name="multiplication_factor"
          control={control}
          rules={{
            required: {
              value: !isDisabled,
              message: `Multiplication Factor is required`,
            },
          }}
          render={({ field }) => (
            <Input
              theme={theme}
              label={"Multiplication Factor"}
              fullWidth
              {...field}
              onChange={(val) => {
                const formattedValue = decimalPlaceValidation(val, 2);

                setValue(
                  "multiplication_factor",
                  formattedValue ? parseFloat(formattedValue) : undefined
                );

                if (errors?.multiplication_factor?.message && val)
                  clearErrors("multiplication_factor");
              }}
              type="text"
              error={!isDisabled && errors?.multiplication_factor?.message}
              disabled={isDisabled}
              helperText={!isDisabled && DEALER_FEE_CONFIG_MSG}
              masking
              maskDecimalScale={2}
              maskNumeric
            />
          )}
        />
        <Controller
          name="fee_handling"
          control={control}
          defaultValue={""}
          rules={{
            validate: (value) => {
              if (value === "") {
                return "Fee Handling is required";
              }
              return true;
            },
          }}
          render={({ field: { value, onChange } }) => {
            const items =
              selectedDealType !== "Cash" && selectEvent !== "End of Term"
                ? [
                  { text: "Financed", value: "Financed" },
                  { text: "Upfront", value: "Upfront" },
                ]
                : [{ text: "Upfront", value: "Upfront" },];
            return (<Select
              theme={theme}
              label={"Fee Handling"}
              items={items}
              value={value}
              sxProps={""}
              onChange={(e) => {
                onChange(e);
              }}
              selectError={errors?.fee_handling?.message}
            />)
          }}
        />
        <Controller
          name="fee_handling_override"
          control={control}
          defaultValue={false}
          rules={{
            validate: (value) => {
              if (value === "") {
                return "Fee Handling Override is required";
              }
              return true;
            },
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Fee Handling Override"}
              items={[
                { text: "Yes", value: true },
                { text: "No", value: false },
              ]}
              value={value}
              sxProps={""}
              onChange={(e) => {
                onChange(e);
              }}
              disabled={(selectedDealType !== "Financing" || selectEvent === "End of Term")}
              selectError={errors?.fee_handling_override?.message}
            ></Select>
          )}
        />
        <Controller
          name="taxable"
          control={control}
          defaultValue={""}
          rules={{
            validate: (value) => {
              if (value === "") {
                return "Taxable is required";
              }
              return true;
            },
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Taxable"}
              items={[
                { text: "Yes", value: true },
                { text: "No", value: false },
              ]}
              value={value}
              sxProps={""}
              onChange={(e) => {
                onChange(e);
              }}
              selectError={errors?.taxable?.message}
            ></Select>
          )}
        />
        <Controller
          name="vendor"
          control={control}
          rules={{
            pattern: {
              value: /^[A-Za-z\s]*$/,
              message: "Only characters are allowed",
            },
          }}
          render={({ field }) => (
            <Input
              theme={theme}
              label={"Vendor (Optional)"}
              fullWidth
              {...field}
              type="string"
              error={errors?.vendor?.message}
            />
          )}
        />
        <Controller
          name="is_active"
          control={control}
          defaultValue={true}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Status"}
              items={[
                { text: "Enabled", value: true },
                { text: "Disabled", value: false },
              ]}
              value={value}
              sxProps={""}
              onChange={onChange}
            ></Select>
          )}
        />
      </>
    );
  };

export default DealerFeeDrawer;
