import { styled } from "@mui/material";
import { StyleVariables } from "../../styles/theme";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const SmallAccordionWrap: any = styled("div")(({ theme }) => ({
  ".u-accordian-wrap": {
    borderRadius: 0,
    boxShadow: "0 0 0 transparent",
    marginBottom: 24,
    backgroundColor: "transparent !important",

    "&:before": {
      display: "none",
    },

    ".MuiButtonBase-root.MuiAccordionSummary-root": {
      padding: 0,
      minHeight: 30,

      "&:after": {
        backgroundColor: theme.palette.divider,
        height: 1,
        width: "100%",
        content: "''",
        display: "inline-block",
        position: "absolute",
      },
      ".MuiAccordionSummary-content": {
        margin: 0,
        paddingLeft: 5,
        color: theme.palette.grey[600],
        flexGrow: "initial",
        backgroundColor:
          theme.palette.mode === "dark"
            ? StyleVariables.darkPaperBg
            : theme.palette.common.white,
        zIndex: 10,

        ".title": {
          width: "auto",
          display: "inline-block",
          margin: "auto",
          padding: "0 2px 0 5px",
          backgroundColor: theme.palette.common.white,
          fontSize: theme.typography.subtitle2.fontSize,
          fontWeight: theme.typography.subtitle2.fontWeight,
          // textTransform: "uppercase",
          position: "relative",
          zIndex: 1,
        },

        ".btn-delete": {
          padding: "0 0 0 5px",
          position: "absolute",
          right: 0,
          top: 3,
          zIndex: 1,
          backgroundColor:
            theme.palette.mode === "dark"
              ? StyleVariables.darkPaperBg
              : theme.palette.common.white,
          svg: {
            path: {
              stroke: theme.palette.grey[400],
            },
          },
        },
      },

      ".MuiAccordionSummary-expandIconWrapper": {
        position: "relative",
        zIndex: 1,
        padding: "0 5px 0 2px",
        marginLeft: 0,
        backgroundColor:
          theme.palette.mode === "dark"
            ? StyleVariables.darkPaperBg
            : theme.palette.common.white,
      },
    },
    ".MuiCollapse-root": {
      ".MuiCollapse-wrapper": {
        ".MuiCollapse-wrapperInner": {
          ".MuiAccordion-region": {
            ".MuiAccordionDetails-root": {
              padding: "24px 0 0 0",
              border: "0 solid transparent",
            },
          },
        },
      },
    },
    "&:last-of-type .u-form-group:last-of-type": {
      marginBottom: 24,
    },
  },
}));

export const AccordionWrapSm: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".u-accordian-wrap": {
    borderRadius: 0,
    boxShadow: "0 0 0 transparent",
    marginBottom: 24,
    backgroundColor: "transparent !important",

    "&:before": {
      display: "none",
    },

    ".MuiButtonBase-root.MuiAccordionSummary-root": {
      padding: 0,
      minHeight: 30,
      position: "relative",
      "&:after": {
        backgroundColor: theme.palette.divider,
        height: 1,
        width: "100%",
        content: "''",
        display: "inline-block",
        position: "absolute",
      },
      ".MuiAccordionSummary-content": {
        margin: 0,
        paddingLeft: 5,
        color: theme.palette.grey[600],
        flexGrow: "initial",
        backgroundColor:
          theme.palette.mode === "dark"
            ? StyleVariables.darkPaperBg
            : theme.palette.common.white,
        zIndex: 10,
        ".action-btn": {
          position: "absolute",
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          ".btn": {
            width: "30px",
            height: "30px",
            backgroundColor:
              theme.palette.mode === "dark"
                ? StyleVariables.darkPaperBg
                : theme.palette.common.white,
            padding: "5px",
          },
        },
        ".main-title": {
          width: "auto",
          display: "inline-block",
          margin: "auto",
          padding: "0 2px 0 5px",
          backgroundColor:
            theme.palette.mode === "dark"
              ? StyleVariables.darkPaperBg
              : theme.palette.common.white,
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.customVariables.fontWeightSemiBold,
          textTransform: "uppercase",
          position: "relative",
          zIndex: 1,
        },
      },

      ".MuiAccordionSummary-expandIconWrapper": {
        position: "relative",
        zIndex: 1,
        padding: "0 5px 0 2px",
        marginLeft: 0,
        backgroundColor:
          theme.palette.mode === "dark"
            ? StyleVariables.darkPaperBg
            : theme.palette.common.white,
      },
    },
    ".MuiCollapse-root": {
      ".MuiCollapse-wrapper": {
        ".MuiCollapse-wrapperInner": {
          ".MuiAccordion-region": {
            ".MuiAccordionDetails-root": {
              padding: "24px 0 0 0",
              border: "0 solid transparent",
              maxHeight: "100%",
              overflow: "initial",
            },
          },
        },
      },
    },
  },
}));
