import { FC, useEffect, useState } from "react";
import {
  // TablePagination is commented due to some errors. Developers will fix it.
  //  TablePagination,
  useTheme,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  Button,
  Switch,
  Box,
  Input,
  Icon,
  Menu,
  DataTable,
  Select,
} from "@ntpkunity/controls";
import { IDealerCustomLink } from "Interface";

// Related with TablePagination
// import { PaginationWrap } from "@sharedComponents";
// import ChevronDown from "shared/assets/images/chevron-down";

const CustomLinksTable: FC<{
  customLinks: IDealerCustomLink[];
  handleEditLink: (v: any, d?: any) => void;
  handleDeleteLink: (v: any) => void;
}> = ({ customLinks, handleEditLink, handleDeleteLink }) => {
  const theme = useTheme();
  const [customLinkData, setCustomLinkData] = useState([]);
  const [customFilterLinkData, setCustomFilterLinkData] = useState(null);
  const [searchType, setSearchType] = useState<any>(null);
  const [searchName, setSearchName] = useState<any>(null);
  const [searchURL, setSearchURL] = useState<any>(null);
  const [searchStatus, setSearchStatus] = useState<any>(null);
  useEffect(() => {
    if (customLinkData && customLinkData.length > 0) {
      const linkData = [...customLinkData].filter((v: IDealerCustomLink) => {
        let retValue = true;
        if (searchType && searchType.trim().length > 0) {
          retValue =
            retValue &&
            v?.menu_position.toLowerCase().includes(searchType.toLowerCase());
        }
        if (searchName && searchName.trim().length > 0) {
          retValue =
            retValue &&
            v?.menu_name.toLowerCase().includes(searchName.toLowerCase());
        }
        if (searchURL && searchURL.trim().length > 0) {
          retValue =
            retValue &&
            v?.menu_url.toLowerCase().includes(searchURL.toLowerCase());
        }
        if (searchStatus !== null) {
          retValue = retValue && v?.is_active === searchStatus;
        }
        return retValue;
      });
      setCustomFilterLinkData(linkData);
    }
  }, [searchType, searchName, searchURL, searchStatus]);
  useEffect(() => {
    if (customLinks) {
      setCustomLinkData(customLinks);
    }
  }, [customLinks]);
  const linkData = customFilterLinkData ? customFilterLinkData : customLinkData;
  return (
    <>
      <DataTable
        theme={theme}
        variant={"basic"}
        theadChildren={
          <>
            <TableRow>
              <TableCell className="checkbox-cell fixed-left"></TableCell>
              <TableCell>Link Type</TableCell>
              <TableCell>Link Name</TableCell>
              <TableCell>Link URL</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell className="checkbox-cell"></TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search Type..."}
                    type="text"
                    id={"linkTypeSearchId"}
                    value={searchType}
                    defaultValue={searchType}
                    onChange={setSearchType}
                  />
                  <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search Name..."}
                    type="text"
                    id={"linkNameSearchId"}
                    value={searchName}
                    defaultValue={searchName}
                    onChange={setSearchName}
                  />
                  <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search URL..."}
                    type="text"
                    id={"linkUrlSearchId"}
                    value={searchURL}
                    defaultValue={searchURL}
                    onChange={setSearchURL}
                  />
                  <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  {/* <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    id={"statusSearchId"}
                  />
                
                  <Button defaultBtn iconText={<Icon name="IconFilter" />} /> */}
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {linkData.map((item: IDealerCustomLink, i: number) => {
              return (
                <TableRow className="child-tr" key={i}>
                  <TableCell className="checkbox-cell fixed-left"></TableCell>
                  <TableCell>{item.menu_position}</TableCell>
                  <TableCell>{item.menu_name}</TableCell>
                  <TableCell>{item?.menu_url?.toString()}</TableCell>
                  <TableCell>
                    <Switch
                      theme={theme}
                      varient={"basic"}
                      label={"Enabled"}
                      switchEnabled={item.is_active}
                      onChange={(event) => {
                        handleEditLink(
                          { ...item, is_active: event?.target?.checked },
                          true
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={[
                        {
                          optionText: (
                            <>
                              <Icon className="menu-icon" name="EditIcon" />
                              {"Edit"}
                            </>
                          ),
                          optionValue: "edit",
                        },
                        {
                          optionText: (
                            <>
                              <Icon className="menu-icon" name="DeleteIcon" />
                              {"Delete"}
                            </>
                          ),
                          optionValue: "delete",
                        },
                      ]}
                      handleOptionClick={(_event, _key, value) => {
                        if (value === "edit") {
                          handleEditLink(item);
                        }
                        if (value === "delete") {
                          handleDeleteLink(item.id);
                        }
                      }}
                      render={(onMenuSelection) => (
                        <Button
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        }
      />
      {/* TablePagination is commented due to some errors. Developers will fix it.
      <PaginationWrap>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={undefined}
        rowsPerPage={undefined}
        page={undefined}
        onPageChange={undefined}
        onRowsPerPageChange={undefined}
        SelectProps={{
          IconComponent: ChevronDown,
          MenuProps: { disablePortal: true }
        }}
      /></PaginationWrap> 
      */}
    </>
  );
};

export default CustomLinksTable;
