import React, { FC, useState, useEffect } from "react";
import { useTheme, Link, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import BundleRow from "./bundle-row";
import {
  Box,
  Button,
  Icon,
  Input,
  DataTable,
  ImageViewer,
} from "@ntpkunity/controls";
import { PaginationWrap } from "@sharedComponents";
import { UseQuery_getAllFinancialInsuranceProductBundle_filter } from "services/financial-insurance.service";
import { ActionOptions } from "Enums";
import ChevronDown from "../../../shared/assets/images/chevron-down";
const ProductBundlesTable: FC<{
  dealer_code?: any;
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
}> = ({ dealer_code, onEdit, onDelete }) => {
  const theme = useTheme();
  const [bundleFilter, setBundleFilter] = useState("");
  const [bundleDesriptionFilter, setBundleDesriptionFilter] = useState("");
  const [bundleMarkupFilter, setBundleMarkUpFilter] = useState("");
  const [bundleTaxableFilter, setBundleTaxableFilter] = useState("");
  const [bundleStatusFilter, setBundleStatusFilter] = useState("");
  const [financeTypeFilter, setFinanceTypeFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [images, setImages] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [index, setIndex] = useState(0);
  const [columnFilters, setColumnFilters] = useState(
    `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
  );
  const { data } =
    UseQuery_getAllFinancialInsuranceProductBundle_filter(columnFilters);
  const financialInsuranceProductBundlesFilter = data;
  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    }
  }, [dealer_code]);

  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `dealer_code=${dealer_code}&page_number=${pgNo}&page_size=${pgSize}`;
    if (bundleFilter.trimStart() != null && bundleFilter.trimStart() != "") {
      query_string = query_string.concat(`&bundle_name=${bundleFilter}`);
    }
    if (
      bundleDesriptionFilter.trimStart() != null &&
      bundleDesriptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&description=${bundleDesriptionFilter}`
      );
    }
    if (
      financeTypeFilter.trimStart() != null &&
      financeTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&finance_type=${financeTypeFilter}`);
    }
    if (
      bundleMarkupFilter.trimStart() != null &&
      bundleMarkupFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&markup=${bundleMarkupFilter}`);
    }
    if (
      bundleTaxableFilter.trimStart() != null &&
      bundleTaxableFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&taxable=${bundleTaxableFilter}`);
    }
    if (
      bundleStatusFilter.trimStart() != null &&
      bundleStatusFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&is_active=${bundleStatusFilter}`);
    }
    return query_string;
  };
  const handleImage = (imagesData) => {
    const image_url = imagesData?.map((image) => {
      return image?.location;
    });

    setImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => {
    setVisibility(false);
    setIndex(0);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        onDelete(value);
        return;
      default:
        return;
    }
  };

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        sx={{ mb: 3 }}
        theadChildren={
          <>
            <TableRow>
              <TableCell align="center" valign="middle" className="img-cell">
                <Icon name="ImageIcon" />
              </TableCell>
              <TableCell>Bundle/Product Name</TableCell>
              <TableCell
                sx={{
                  minWidth: "300px !important",
                  maxWidth: "300px !important",
                }}
              >
                Description
              </TableCell>
              <TableCell>Markup %</TableCell>
              <TableCell>Taxable</TableCell>
              <TableCell>Taxable Finance Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell className="img-cell" />
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setBundleFilter(value);
                    }}
                    value={bundleFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"bundleSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setBundleDesriptionFilter(value);
                    }}
                    value={bundleDesriptionFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"bundleDescriptionId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleDesriptionFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    onChange={(value) => {
                      setBundleMarkUpFilter(value);
                    }}
                    value={bundleMarkupFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"bundleMarkUpSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleMarkupFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setBundleTaxableFilter(value);
                    }}
                    value={bundleTaxableFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"numberSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleTaxableFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setFinanceTypeFilter(value);
                    }}
                    value={financeTypeFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"bundleSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="financeTypeFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setBundleStatusFilter(value);
                    }}
                    value={bundleStatusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"taxableSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleStatusFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {financialInsuranceProductBundlesFilter &&
              financialInsuranceProductBundlesFilter?.results?.map(
                (bundleRow, index) => {
                  return (
                    <React.Fragment key={bundleRow.id + "parent"}>
                      <BundleRow
                        bundleData={bundleRow}
                        dealer_code={dealer_code}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        handleImage={handleImage}
                        handleSelection={handleSelection}
                      />
                    </React.Fragment>
                  );
                }
              )}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={
            financialInsuranceProductBundlesFilter
              ? financialInsuranceProductBundlesFilter?.total_results
              : -1
          }
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={images}
        isLoading={false}
        singleTriggerNode={true}
        overlayCheck={true}
        visible={visibility}
        index={index}
        setIndex={setIndex}
        onClose={handleClose}
      />
    </>
  );
};
export default ProductBundlesTable;
