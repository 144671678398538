import { FC, useEffect, useState } from "react";
import { useTheme, TablePagination, styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IDocumentTypeProps } from "./document-type";
import {
  Icon,
  Menu,
  DataTable,
  Button,
  Switch,
  Box,
  Input,
  Typography,
  Tooltip,
} from "@ntpkunity/controls";
import { ConfirmationDialog, PaginationWrap } from "@sharedComponents";
import ChevronDown from "../../shared/assets/images/chevron-down";
import {
  UseMutation_DeleteDocumentType,
  UseMutation_UpdateDocumentType,
  UseQuery_getAllDocumentType_filter,
} from "services";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { IDocumentTypes, IDocumentTypesFilter } from "Interface";
import { StyleVariables } from "../../shared/styles/theme";
import { convertArray } from "shared/helper/methods";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const select = [{ text: "Select", value: "Select" }];

const DocumentTypeTable: FC<{ onEdit: (data: IDocumentTypes) => unknown }> = ({
  onEdit,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data } = UseQuery_getAllDocumentType_filter(columnFilters);

  const [codeFilter, setCodeFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [customerTypeFilter, setCustomerTypeFilter] = useState("");
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [financedAmountFilter, setFinancedAmountFilter] = useState("");
  const [stateFilter, setStateFilter] = useState("");

  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&document_code=${codeFilter}`);
    }
    if (
      descriptionFilter.trimStart() != null &&
      descriptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&description=${descriptionFilter}`);
    }
    if (
      customerTypeFilter.trimStart() != null &&
      customerTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&customer_type=${customerTypeFilter}`
      );
    }
    if (
      productTypeFilter.trimStart() != null &&
      productTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&finance_type =${productTypeFilter}`);
    }
    if (
      financedAmountFilter.trimStart() != null &&
      financedAmountFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&financed_amount=${financedAmountFilter}`
      );
    }
    if (stateFilter.trimStart() != null && stateFilter.trimStart() != "") {
      if (
        "mandatory"
          .toLowerCase()
          .startsWith(stateFilter.slice(0, Math.max("mandatory".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&state=${true}`);
      } else if (
        "optional"
          .toLowerCase()
          .startsWith(stateFilter.slice(0, Math.max("optional".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&state=${false}`);
      } else {
        query_string = query_string.concat(`&state=None`);
      }
    }

    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled"
          .toLowerCase()
          .startsWith(statusFilter.slice(0, Math.max("enabled".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled"
          .toLowerCase()
          .startsWith(statusFilter.slice(0, Math.max("disabled".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const documentTypesFilter: IDocumentTypesFilter = data;
  const [actionData, setActionData] = useState<IDocumentTypes>(undefined);

  const { mutate: deleteDocument } = UseMutation_DeleteDocumentType();
  const { mutate: UpdateDocumentType } =
    UseMutation_UpdateDocumentType(undefined);
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteDocument(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  const handleEnableChange = (data: IDocumentTypes, event) => {
    UpdateDocumentType({ ...data, is_active: event.target.checked });
  };

  const handleStateChange = (data: IDocumentTypes, event) => {
    UpdateDocumentType({ ...data, state: event.target.checked });
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              {/* <TableCell className="checkbox-cell fixed-cell">
                <Checkbox label="" theme={theme} />
                </TableCell> */}
              <TableCell id="listHeaderCode">Code</TableCell>
              <TableCell id="listHeaderDescription">Description</TableCell>
              <TableCell id="listHeaderCustomerType">Customer Type</TableCell>
              <TableCell id="listHeaderProductType">Finance Type</TableCell>
              <TableCell id="listHeaderFinancedAmount">
                Financed Amount
              </TableCell>
              <TableCell id="listHeaderState">State</TableCell>
              <TableCell id="listHeaderStatus">Status</TableCell>

              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              {/* <TableCell className="checkbox-cell fixed-cell"></TableCell> */}
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingCode"}
                    onChange={(value) => {
                      setCodeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={codeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingDescription"}
                    onChange={(value) => {
                      setDescriptionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={descriptionFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingDescription"}
                    onChange={(value) => {
                      setCustomerTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={customerTypeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingDescription"}
                    onChange={(value) => {
                      setProductTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={productTypeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingDescription"}
                    onChange={(value) => {
                      setFinancedAmountFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={financedAmountFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingState"}
                    onChange={(value) => {
                      setStateFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={stateFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingStatus"}
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          documentTypesFilter?.result &&
          documentTypesFilter?.result
            .filter((item) => item.description !== "All")
            .map((item, index) => (
              <TableRow key={index} className="child-tr">
                {/* <TableCell className="checkbox-cell fixed-cell">
    <Checkbox label="" theme={theme} />
  </TableCell> */}
                <TableCell id={"listDataCode" + item.document_code}>
                  {item?.document_code}
                </TableCell>
                <TableCell id={"listDataDescription" + item.document_code}>
                  {item?.description}
                </TableCell>
                <TableCell>
                  <Box theme={theme} display="flex" alignItems="center">
                    <Tooltip
                      theme={theme}
                      title={
                        <>
                          {item?.customer_type
                            ? item?.customer_type.join(", ")
                            : ""}
                        </>
                      }
                    >
                      <Box theme={theme} display="flex" alignItems="center">
                        <Typography
                          theme={theme}
                          variant="body2"
                          sx={{ ml: 0.7 }}
                          component="span"
                        >
                          {
                            <>
                              {item?.customer_type
                                ? item?.customer_type.join(", ")
                                : ""}
                            </>
                          }
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} display="flex" alignItems="center">
                    <Tooltip
                      theme={theme}
                      title={
                        <>
                          {item?.finance_type
                            ? item?.finance_type.join(", ")
                            : ""}
                        </>
                      }
                    >
                      <Box theme={theme} display="flex" alignItems="center">
                        <Typography
                          theme={theme}
                          variant="body2"
                          sx={{ ml: 0.7 }}
                          component="span"
                        >
                          {
                            <>
                              {item?.finance_type
                                ? item?.finance_type.join(", ")
                                : ""}
                            </>
                          }
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} display="flex" alignItems="center">
                    <Tooltip
                      theme={theme}
                      title={
                        <>
                          {item?.financed_amount
                            ? convertArray(item?.financed_amount).join(", ")
                            : ""}
                        </>
                      }
                    >
                      <Box theme={theme} display="flex" alignItems="center">
                        {/* <Icon className="icon" name="InfoIcon" /> */}
                        <Typography
                          theme={theme}
                          variant="body2"
                          sx={{ ml: 0.7 }}
                          component="span"
                        >
                          {
                            <>
                              {item?.financed_amount
                                ? convertArray(item?.financed_amount).join(", ")
                                : ""}
                            </>
                          }
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell>
                  <Switch
                    id={"ListDataState" + item.document_code}
                    theme={theme}
                    varient={"basic"}
                    switchEnabled={item.state}
                    onChange={(event) => handleStateChange(item, event)}
                    label={item?.state ? "Mandatory" : "Optional"}
                  />
                </TableCell>

                <TableCell>
                  <Switch
                    id={"ListDataStatus" + item.document_code}
                    theme={theme}
                    varient={"basic"}
                    switchEnabled={item.is_active}
                    onChange={(event) => handleEnableChange(item, event)}
                    label={item?.is_active ? "Enabled" : "Disabled"}
                  />
                </TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={tableOptions.map((option) => {
                      return {
                        optionText: option.optionText,
                        optionkey: option.optionkey,
                        optionValue: item,
                      };
                    })}
                    handleOptionClick={handleSelection}
                    render={(onMenuSelection) => (
                      <Button
                        id={"listDataEllipses" + item.document_code}
                        defaultBtn
                        iconText={<Icon name="MoreIcon" />}
                        onClick={onMenuSelection}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={documentTypesFilter ? documentTypesFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default DocumentTypeTable;
