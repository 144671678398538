import { FC, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material";
import { Button, Menu, Icon, Switch } from "@ntpkunity/controls";
import { ConfirmationDialog } from "@sharedComponents";
import { UseMutation_DeleteCharts, UseMutation_UpdateCharts } from "services";
import {
  IChartCriteria,
  ICharts,
  ICriteria,
  IFinanceType,
  ICustomerType,
  IAssetCategory,
  IAssetType,
  IProductType,
} from "Interface";
import { ActionOptions, ChartTypes, DialogMessages } from "Enums";
import CriteriaTable from "./criteria-table";

interface ChartProps {
  chart: ICharts;
  onOptionsClick?: (key, value) => unknown;
  onEditCriteria?: (data) => unknown;
  criteria: ICriteria[];
  chartType: number;
  AllFinanceType?: IFinanceType[];
  AllCustomerType?: ICustomerType[];
  AllAssetCategory?: IAssetCategory[];
  AllAssetType?: IAssetType[];
  AllProductType?: IProductType[];
}

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.RENAME_CHART}
      </>
    ),
    optionkey: ActionOptions.RENAME_CHART,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="AddIcon" />
        {ActionOptions.ADD_CRITERIA}
      </>
    ),
    optionkey: ActionOptions.ADD_CRITERIA,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const ChartTable: FC<ChartProps> = ({
  chart,
  onOptionsClick,
  onEditCriteria,
  criteria,
  chartType,
  AllFinanceType,
  AllCustomerType,
  AllAssetCategory,
  AllAssetType,
  AllProductType,
}) => {
  const [open, setOpen] = useState(false);

  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const { mutate: deleteChart } = UseMutation_DeleteCharts();
  const { mutate: UpdateMake } = UseMutation_UpdateCharts();

  const [chartId, setChartId] = useState<IChartCriteria>(undefined);
  const handleSelection = (event, key, value) => {
    onOptionsClick(key, value);
    if (key === ActionOptions.DELETE) {
      setChartId(value.id);
      setConfirmationPopupState(true);
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteChart(chartId, {
      onSuccess: () => {
        setChartId(undefined);
      },
    });
  };

  const handleEnableChange = (data: ICharts, event) => {
    let updatedData = {
      chart_name: data.chart_name,
      chart_type_id: data.chart_type_id,
      id: data.id,
      is_active: event?.target?.checked,
    };
    UpdateMake(updatedData);
  };

  const theme = useTheme();

  const colSpan =
    ChartTypes.DownPaymentChart == chartType
      ? 11
      : ChartTypes.SubsidyChart == chartType
      ? 13
      : ChartTypes.FeesChart == chartType
      ? 12
      : ChartTypes.CommissionChart == chartType
      ? 18
      : 13;

  return (
    <>
      <TableRow className="child-tr">
        <TableCell className="indent-cell" colSpan={colSpan - 2}>
          {criteria?.filter((item) => item.chart_id == chart.id).length >= 0 ? (
            open ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpen(!open)}
              />
            )
          ) : (
            <></>
          )}
          {chart?.chart_name}
        </TableCell>
        {/* {chartType == ChartTypes.CommissionChart && (
          <>
            <TableCell className="indent-cell" colSpan={2} />
          </>
        )} */}
        {/* {chartType == ChartTypes.SubsidyChart && (
          <>
            <TableCell className="indent-cell" colSpan={2} />
          </>
        )} */}

        <TableCell>
          <Switch
            theme={theme}
            varient={"basic"}
            label={chart.is_active ? "Enabled" : "Disabled"}
            switchEnabled={chart.is_active}
            onChange={(event) => handleEnableChange(chart, event)}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell space-fixed">
          <Menu
            theme={theme}
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: chart,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>

      {open ? (
        <>
          <TableRow>
            <TableCell
              colSpan={colSpan}
              className="second-level-cell chart-header"
            >
              {"Chart Criteria ("}
              {criteria &&
                criteria.filter((item) => item.chart_id == chart.id).length}
              {")"}
            </TableCell>
          </TableRow>

          {criteria &&
            criteria
              .filter((item) => item.chart_id == chart.id)
              .map((item, index) => (
                <CriteriaTable
                  key={index}
                  chart={chart}
                  criteria={item}
                  onEditCriteria={(data) => onEditCriteria(data)}
                  chartType={chartType}
                  financeType={AllFinanceType}
                  customerType={AllCustomerType}
                  assetCategory={AllAssetCategory}
                  assetType={AllAssetType}
                  productType={AllProductType}
                />
              ))}
        </>
      ) : null}
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default ChartTable;
