export const ACCESS_TOKEN_KEY = "access_token";
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z]).{6,35}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ZIP_CODE_REGEX = /^\d{5,5}?(-\d{4})?$/;
export const WEBSITE_REGEX =
  /(http:\/\/|https:\/\/|www\.|[a-zA-Z])+([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,6}/;
export const NEXT_SCREEN_URL = "/market-place/app-user-profile";
export const FAVICON = "images/favicon.ico";
export const DEFAULT_FAVICON = "images/dealex.svg";
export const THEME_BUILDER_CSS = "theme-builder.css";
export const TEMPLATE_EXCEL_FILE_ENDPOINT = "Asset_Catalogue_Template.xlsx";
export const INVENTORY_EXCEL_FILE_ENDPOINT = "Inventory_Template.xlsx";
export const CREATE_TEMPLATE_ENDPOINT = "route-to-page?page-path=create";
export const EDIT_TEMPLATE_ENDPOINT = "route-to-page";
export const DEALER_FEE_CONFIG_MSG =
  'System will compare the value in "Default amount" field with the (monthly payment of deal multiplied by this factor), and then use the amount whichever is lesser';
export const DEFAULT_REFERRAL_EMAIL_DESCRIPTION = `I've selected this vehicle for your review. Check it out and let me know what you think!`;
export const INTEGRATION_VALIDATION_FIELDS = [
  "api_url",
  "token_url",
  "webhook_url",
  "manual_api_base_url",
  "identity_api_base_url",
  "api_base_url",
  "soap_action_url",
];
