import { FC, useState, useEffect, useRef } from "react";
import {
  Input,
  Icon,
  Select,
  Button,
  PersistentDrawer,
  Accordion,
  MultiSelect,
  // Autocomplete,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import {
  ConfirmationDialog,
  SmallAccordionWrap,
  AutocompleteWrap,
  Autocomplete,
  MultiSelectAll,
  MultiSelectWrap,
} from "@sharedComponents";
import { UseQuery_GetAllEvents } from "services/asset-condition.service";
import { INewStationeryPopUp } from "./addNewTemplate.type.js";
import { IDealer, ISelectItemList, IStates, IStationery } from "Interface";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { logout } from "@ntpkunity/controls-ums";
import {
  UseMutation_AddStationery,
  UseMutation_UpdateStationery,
  useMutation_getTenantDocuments,
} from "services";

import {
  ActionOptions,
  ValidationMessages,
  QueryKeys,
  DialogMessages,
  StType,
  StationeryRole,
  StationeryType,
  StationeryAction,
  StationeryEvent,
} from "Enums";
import { useNavigate } from "react-router-dom";
import { useStoreContext } from "Stores/SetupStore/Store";

const AddNewStationery: FC<INewStationeryPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  stationeryData,
  enabledPin,
  esigantureintegration,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors, isDirty },
    watch,
  } = useForm<IStationery>();
  const navigate = useNavigate();

  const theme = useTheme();

  const defaultValues = {
    id: null,
    name: "",
    code: "",
    applicable_finance_types: [],
    states: [],
    type: "",
    co_applicant: false,
    dealers: [],
    recipient: [],
    event: [],
    is_signature_required: false,
    required_signatories: [],
    signature_type: "",
    applicant_signing_order: null,
    applicant_action: "",
    co_applicant_signing_order: null,
    co_applicant_action: "",
    dealer_signing_order: null,
    dealer_action: "",
    templates: [],
  };

  const { data: eventsData } = UseQuery_GetAllEvents();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [applicabeFinanceTypeItems, setApplicabeFinanceTypeItems] = useState([
    { text: "Lease", value: "Lease" },
    { text: "Finance", value: "Finance" },
  ]);

  const [states, setStates] = useState([]);
  const [dealerDropdownItems, setDealerDropdownItems] = useState<Array<any>>(
    []
  );
  const [signingOrderItems, setSigningOrderItems] = useState<any>([]);
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const items = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];

  const usaStates: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );

  const { mutate: tenantTemplate, data: tenant_templates } =
    useMutation_getTenantDocuments();

  useEffect(() => {
    tenantTemplate();
  }, []);

  const allDealers: Array<IDealer> = queryClient.getQueryData(
    QueryKeys.GET_ALL_DEALERS
  );

  const activeDealers: Array<IDealer> = queryClient.getQueryData(
    QueryKeys.GET_ACTIVE_DEALERS
  );
  const { mutate: AddStationery } = UseMutation_AddStationery();
  const { mutate: UpdateStationery } = UseMutation_UpdateStationery();

  const updateDealerDropdownItems = () => {
    if (stationeryData) {
      const allDealersSelectedIds: any = stationeryData?.dealers?.map(
        (dealer) => dealer
      );
      const selectedDealers = allDealersSelectedIds
        ?.map((id) => {
          let dealer = allDealers?.find((dealer) => dealer.id === id);
          return dealer;
        })
        .filter((dealer) => dealer != undefined);

      if (activeDealers) {
        const combinedDealers = [...selectedDealers, ...activeDealers];

        const uniqueDealersSet = new Set(
          combinedDealers?.map((dealer) => dealer?.id)
        );
        const dropdownDealers = Array.from(uniqueDealersSet)?.map((id) =>
          combinedDealers?.find((dealer) => dealer?.id === id)
        );
        setDealerDropdownItems(
          dropdownDealers?.map((dealer) => ({
            selected: allDealersSelectedIds?.includes(dealer?.id),
            text: dealer?.dealer_name,
            value: dealer?.id,
          }))
        );
      }
    }
  };

  const getTextBoxInputValue = (input) => {
    return input.map((itm) => itm.label).join(";");
  };

  const handleSelectionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    if (actionType === ActionOptions.ADD) {
      setDealerDropdownItems(
        activeDealers?.map((dealer) => ({
          text: dealer.dealer_name,
          value: dealer.id,
        }))
      );
      reset(defaultValues);
    }

    if (actionType === ActionOptions.EDIT) {
      stationeryData = setFieldsData(stationeryData);
      setSigningOrderItems(
        formArray(stationeryData?.required_signatories?.length)
      );
      const hasLabelKey = stationeryData.templates.every((item) =>
        item.hasOwnProperty("label")
      );
      if (!hasLabelKey) {
        stationeryData.templates = stationeryData?.templates
          .map((item) => {
            return { id: item?.id, label: item?.template_name };
          })
          .filter(Boolean);
      }
      reset({
        ...stationeryData,
        is_signature_required:
          stationeryData?.is_signature_required?.toString(),
      });
      updateDealerDropdownItems();
    }
  }, [actionType, stationeryData, activeDealers]);
  const setFieldsData = (obj) => {
    obj?.signatories?.forEach((element) => {
      if (element.role == StationeryRole.APPLICANT) {
        obj.applicant_signing_order = element.signing_order;
        obj.applicant_action = element.action;
        obj.applicant_id = element.id;
      } else if (element.role == StationeryRole.CO_APPLICANT) {
        obj.co_applicant_signing_order = element.signing_order;
        obj.co_applicant_action = element.action;
        obj.co_applicant_id = element.id;
      } else {
        obj.dealer_signing_order = element.signing_order;
        obj.dealer_action = element.action;
        obj.dealer_signatory_id = element.id;
      }
    });
    obj.templates = obj?.templates ? obj?.templates?.map((x) => x) : [];
    return obj;
  };
  const updateSignatureType = (co_applicant: boolean) => {
    if (co_applicant == true) {
      setSignatureTypeItems(
        signatureTypeItems?.filter((x) => x.value !== StationeryType.ON_SCREEN)
      );
    } else {
      if (
        signatureTypeItems.find((x) => x.value !== StationeryType.ON_SCREEN)
      ) {
        setSignatureTypeItems([
          ...signatureTypeItems,
          {
            text: StationeryType.ON_SCREEN,
            value: StationeryType.ON_SCREEN,
          },
        ]);
      }
    }
    setValue("recipient", []);
    setValue("required_signatories", []);
  };
  function onSubmit(data: any) {
    let signatories: any = [];
    if (data?.applicant_signing_order)
      signatories.push({
        id: data?.applicant_id ?? 0,
        role: StationeryRole.APPLICANT,
        signing_order: data?.applicant_signing_order,
        action: data?.applicant_action,
      });
    if (data?.co_applicant_signing_order)
      signatories.push({
        id: data?.co_applicant_id ?? 0,
        role: StationeryRole.CO_APPLICANT,
        signing_order: data?.co_applicant_signing_order,
        action: data?.co_applicant_action,
      });
    if (data?.dealer_signing_order)
      signatories.push({
        id: data?.dealer_signatory_id ?? 0,
        role: StationeryRole.DEALER_ADMIN,
        signing_order: data?.dealer_signing_order,
        action: data?.dealer_action,
      });
    data.signatories = signatories;
    data.templates = JSON.stringify(mapTamplates(data?.templates));
    if (actionType === ActionOptions.ADD) {
      AddStationery(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    } else if (actionType === ActionOptions.EDIT) {
      UpdateStationery(data, {
        onSuccess: () => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }
  const mapTamplates = (templateIds) => {
    const result = templateIds
      .map((item1) => {
        const matchingItem = tenant_templates?.data?.find(
          (item2) => item2.id === item1.id
        );
        if (matchingItem) {
          return {
            id: matchingItem.id,
            template_name: matchingItem.template_name,
            template_url: matchingItem.template_url,
          };
        }
        return [];
      })
      .filter((item) => item !== null);
    return result;
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  const [signatureTypeItems, setSignatureTypeItems] = useState<
    Array<ISelectItemList>
  >(
    Object.values(StationeryType)
      .filter((value) => {
        if (!esigantureintegration && value === StationeryType.ELECTRONIC) {
          return false;
        }
        return true;
      })
      .map((value) => {
        return { text: value, value: value };
      })
  );

  useEffect(() => {
    if (usaStates) {
      const sortedStates = [...usaStates].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setStates(
        sortedStates?.map((x) => {
          return {
            text: x.name,
            value: x.id,
          };
        })
      );
    }
  }, [usaStates]);

  function formArray(number) {
    return Array.from(Array(number), (_, index) => ({
      text: index + 1,
      value: index + 1,
    }));
  }
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="code"
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.DOCUMENT_CODE_MAX_LENGTH,
                },
                required: ValidationMessages.CODE_REQUIRED,
                validate: {
                  noSpaces: (value) =>
                    value.trim().length > 0 || ValidationMessages.CODE_REQUIRED,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerCode"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type Code..."}
                  label={"Code"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.code?.message}
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              rules={{
                required: ValidationMessages.STATIONERY_NAME,
                validate: {
                  noSpaces: (value) =>
                    value.trim().length > 0 ||
                    ValidationMessages.STATIONERY_NAME,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerCode"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type Code..."}
                  label={"Name"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.name?.message}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerIsActive"
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <MultiSelectWrap>
              <Controller
                name="applicable_finance_types"
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <MultiSelectAll
                    id="applicationFinanceType"
                    name="applicable_finance_types"
                    label="Applicable Finance Types"
                    theme={theme}
                    isSelectAll={
                      actionType == ActionOptions.EDIT ? false : true
                    }
                    getValues={getValues}
                    setValue={setValue}
                    items={applicabeFinanceTypeItems}
                    value={
                      value
                        ? stationeryData?.applicable_finance_types
                        : (value as any[])
                    }
                    onChange={onChange}
                    disablePortal
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="dealers"
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <MultiSelectAll
                    id="dealers"
                    name="dealers"
                    theme={theme}
                    label={"Dealer"}
                    isSelectAll={
                      actionType == ActionOptions.EDIT ? false : true
                    }
                    setValue={setValue}
                    getValues={getValues}
                    items={dealerDropdownItems}
                    value={value as any[]}
                    onChange={onChange}
                    disablePortal
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name={"states"}
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <MultiSelectAll
                    id="states"
                    name="states"
                    theme={theme}
                    label={"State"}
                    isSelectAll={
                      actionType == ActionOptions.EDIT ? false : true
                    }
                    getValues={getValues}
                    setValue={setValue}
                    items={states}
                    value={value as any[]}
                    onChange={onChange}
                    disablePortal
                  />
                )}
              />
            </MultiSelectWrap>
            <Controller
              name="co_applicant"
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="CoapplicantIsActive"
                  theme={theme}
                  label={"Co-Applicant"}
                  placeholder={"Select"}
                  items={items}
                  value={value ?? false}
                  onChange={(e) => {
                    onChange(e);
                    // On screen not to be available as an option if Co-applicant dealerached = Yes

                    updateSignatureType(e.target.value as boolean);
                    // reset({ recipient: [] });
                  }}
                />
              )}
            />
            <MultiSelectWrap>
              <Controller
                name="event"
                control={control}
                defaultValue={[]}
                rules={{
                  required: ValidationMessages.STATIONERY_EVENT,
                }}
                render={({ field }) => (
                  <MultiSelect
                    id="events"
                    name="event"
                    theme={theme}
                    label={"Event"}
                    {...field}
                    value={field.value}
                    items={eventsData
                      ?.filter((event) => event.event_name !== "Lead Creation")
                      ?.map((event) => {
                        return {
                          text: event.event_name,
                          value: event.event_name,
                        };
                      })}
                    selectError={errors?.event?.message}
                  />
                )}
              />
            </MultiSelectWrap>
            <Controller
              name="type"
              control={control}
              defaultValue={""}
              rules={{
                required: ValidationMessages.STATIONERY_TYPE,
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerIsActive"
                  theme={theme}
                  label={"Type"}
                  placeholder={"Select"}
                  items={Object.values(StType).map((value) => {
                    return { text: value, value: value };
                  })}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setValue("required_signatories", []);
                    setValue("signature_type", "");
                    setValue("is_signature_required", null);
                    setValue("applicant_signing_order", null);
                    setValue("applicant_action", "");
                    setValue("co_applicant_signing_order", null);
                    setValue("co_applicant_action", "");
                    setValue("dealer_signing_order", null);
                    setValue("dealer_action", "");
                  }}
                  selectError={errors?.type?.message}
                />
              )}
            />
            <MultiSelectWrap>
              <Controller
                name="recipient"
                control={control}
                defaultValue={[]}
                rules={{
                  required: ValidationMessages.STATIONERY_RECIPIENT,
                }}
                render={({ field }) => (
                  <MultiSelect
                    id="Recipient"
                    name="recipient"
                    theme={theme}
                    label={"Recipient"}
                    items={Object.values(StationeryRole)
                      .map((value) => {
                        if (
                          value == "Co-applicant" &&
                          watch("co_applicant") === false
                        ) {
                          return null;
                        }
                        return { text: String(value), value: String(value) };
                      })
                      .filter((item) => item !== null)}
                    onChange={(e: any) => {
                      field.onChange(e);
                      if (!e.target.value?.includes(StationeryRole.APPLICANT)) {
                        if (
                          getValues("required_signatories").includes(
                            StationeryRole.APPLICANT
                          )
                        ) {
                          setValue(
                            "required_signatories",
                            getValues("required_signatories").filter(
                              (x) => x !== StationeryRole.APPLICANT
                            )
                          );
                        }
                        setValue("applicant_signing_order", null);
                        setValue("applicant_action", "");
                      }
                      if (
                        !e.target.value?.includes(StationeryRole.CO_APPLICANT)
                      ) {
                        if (
                          getValues("required_signatories")?.includes(
                            StationeryRole.CO_APPLICANT
                          )
                        ) {
                          setValue(
                            "required_signatories",
                            getValues("required_signatories").filter(
                              (x) => x !== StationeryRole.CO_APPLICANT
                            )
                          );
                        }
                        setValue("co_applicant_signing_order", null);
                        setValue("co_applicant_action", "");
                      }
                      if (
                        !e.target.value?.includes(StationeryRole.DEALER_ADMIN)
                      ) {
                        if (
                          getValues("required_signatories").includes(
                            StationeryRole.DEALER_ADMIN
                          )
                        ) {
                          setValue(
                            "required_signatories",
                            getValues("required_signatories").filter(
                              (x) => x !== StationeryRole.DEALER_ADMIN
                            )
                          );
                        }
                        setValue("dealer_signing_order", null);
                        setValue("dealer_action", "");
                      }
                    }}
                    value={field.value}
                    selectError={errors?.recipient?.message}
                  />
                )}
              />
            </MultiSelectWrap>
            {watch("type") == StType.DOCUMENT && (
              <Controller
                name="is_signature_required"
                control={control}
                rules={{
                  required: {
                    value: watch("type") == StType.DOCUMENT,
                    message: ValidationMessages.SIGNATURE_REQUIRED,
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    id="Signatures Required"
                    theme={theme}
                    label={"Signatures Required"}
                    placeholder={"Select"}
                    items={[
                      { text: "Yes", value: "true" },
                      { text: "No", value: "false" },
                    ]}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      setValue("required_signatories", []);
                      setValue("signature_type", "");
                      setValue("applicant_signing_order", null);
                      setValue("applicant_action", "");
                      setValue("co_applicant_signing_order", null);
                      setValue("co_applicant_action", "");
                      setValue("dealer_signing_order", null);
                      setValue("dealer_action", "");
                    }}
                    selectError={errors?.is_signature_required?.message}
                  />
                )}
              />
            )}
            {watch("is_signature_required") == "true" && (
              <>
                <MultiSelectWrap>
                  <Controller
                    name="required_signatories"
                    control={control}
                    defaultValue={[]}
                    rules={{
                      required: {
                        value: watch("is_signature_required") == "true",
                        message: ValidationMessages.REQUIRED_SIGNATORIES,
                      },
                    }}
                    render={({ field }) => (
                      <MultiSelect
                        id="Required Signatories"
                        name="Required Signatories"
                        theme={theme}
                        label={"Required Signatories"}
                        items={watch("recipient")?.map((value) => {
                          return { text: value, value: value };
                        })}
                        onChange={(e: any) => {
                          field.onChange(e);
                          if (
                            !e.target.value?.includes(StationeryRole.APPLICANT)
                          ) {
                            setValue("applicant_signing_order", null);
                            setValue("applicant_action", "");
                          }
                          if (
                            !e.target.value?.includes(
                              StationeryRole.CO_APPLICANT
                            )
                          ) {
                            setValue("co_applicant_signing_order", null);
                            setValue("co_applicant_action", "");
                          }
                          if (
                            !e.target.value?.includes(
                              StationeryRole.DEALER_ADMIN
                            )
                          ) {
                            setValue("dealer_signing_order", null);
                            setValue("dealer_action", "");
                          }
                          setSigningOrderItems(
                            formArray(e.target.value?.length)
                          );
                        }}
                        value={field.value}
                        selectError={errors?.required_signatories?.message}
                      />
                    )}
                  />
                </MultiSelectWrap>
                <Controller
                  name="signature_type"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: {
                      value: watch("is_signature_required") == "true",
                      message: ValidationMessages.SIGNATURE_TYPE,
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      id="Signature Type"
                      theme={theme}
                      label={"Signature Type"}
                      placeholder={"Select"}
                      items={signatureTypeItems}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        setValue("applicant_signing_order", null);
                        setValue("applicant_action", "");
                        setValue("co_applicant_signing_order", null);
                        setValue("co_applicant_action", "");
                        setValue("dealer_signing_order", null);
                        setValue("dealer_action", "");
                      }}
                      selectError={errors?.signature_type?.message}
                    />
                  )}
                />
              </>
            )}
            {watch("signature_type") == StationeryType.ELECTRONIC && (
              <SmallAccordionWrap>
                {watch("required_signatories")?.find(
                  (x) => x == StationeryRole.APPLICANT
                ) && (
                  <Accordion
                    theme={theme}
                    items={[
                      {
                        title: "APPLICANT",
                        isExpanded: true,
                        key: "string",
                        content: (
                          <>
                            <Controller
                              name="applicant_signing_order"
                              control={control}
                              //   defaultValue={""}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.APPLICANT
                                  ),
                                  message: ValidationMessages.SIGNING_ORDER,
                                },
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="signing Order"
                                  theme={theme}
                                  label={"Signing Order"}
                                  placeholder={"Select"}
                                  items={signingOrderItems}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.applicant_signing_order?.message
                                  }
                                />
                              )}
                            />
                            <Controller
                              name="applicant_action"
                              control={control}
                              defaultValue={""}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.APPLICANT
                                  ),
                                  message: ValidationMessages.SIGNING_ACTION,
                                },
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="Action"
                                  theme={theme}
                                  label={"Action"}
                                  placeholder={"Select"}
                                  items={Object.values(StationeryAction).map(
                                    (value) => {
                                      return { text: value, value: value };
                                    }
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.applicant_action?.message
                                  }
                                />
                              )}
                            />
                          </>
                        ),
                        actionBtn: (
                          <>
                            <Button
                              theme={theme}
                              defaultBtn
                              className="btn-delete"
                              iconText={<Icon name="DeleteIcon" />}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                {watch("required_signatories")?.find(
                  (x) => x == StationeryRole.CO_APPLICANT
                ) && (
                  <Accordion
                    theme={theme}
                    items={[
                      {
                        title: "CO-APPLICANT",
                        isExpanded: true,
                        key: "string",
                        content: (
                          <>
                            <Controller
                              name="co_applicant_signing_order"
                              control={control}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.CO_APPLICANT
                                  ),
                                  message: ValidationMessages.SIGNING_ORDER,
                                },
                              }}
                              //   defaultValue={""}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="signing Order"
                                  theme={theme}
                                  label={"Signing Order"}
                                  placeholder={"Select"}
                                  items={signingOrderItems}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.co_applicant_signing_order?.message
                                  }
                                />
                              )}
                            />
                            <Controller
                              name="co_applicant_action"
                              control={control}
                              defaultValue={""}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.CO_APPLICANT
                                  ),
                                  message: ValidationMessages.SIGNING_ACTION,
                                },
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="Action"
                                  theme={theme}
                                  label={"Action"}
                                  placeholder={"Select"}
                                  items={Object.values(StationeryAction).map(
                                    (value) => {
                                      return { text: value, value: value };
                                    }
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.co_applicant_action?.message
                                  }
                                />
                              )}
                            />
                          </>
                        ),
                        actionBtn: (
                          <>
                            <Button
                              theme={theme}
                              defaultBtn
                              className="btn-delete"
                              iconText={<Icon name="DeleteIcon" />}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                {watch("required_signatories")?.find(
                  (x) => x == StationeryRole.DEALER_ADMIN
                ) && (
                  <Accordion
                    theme={theme}
                    items={[
                      {
                        title: "DEALER ADMIN",
                        isExpanded: true,
                        key: "string",
                        content: (
                          <>
                            <Controller
                              name="dealer_signing_order"
                              control={control}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.DEALER_ADMIN
                                  ),
                                  message: ValidationMessages.SIGNING_ORDER,
                                },
                              }}
                              //   defaultValue={""}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="signing Order"
                                  theme={theme}
                                  label={"Signing Order"}
                                  placeholder={"Select"}
                                  items={signingOrderItems}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.dealer_signing_order?.message
                                  }
                                />
                              )}
                            />
                            <Controller
                              name="dealer_action"
                              control={control}
                              defaultValue={""}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.DEALER_ADMIN
                                  ),
                                  message: ValidationMessages.SIGNING_ACTION,
                                },
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="Action"
                                  theme={theme}
                                  label={"Action"}
                                  placeholder={"Select"}
                                  items={Object.values(StationeryAction).map(
                                    (value) => {
                                      return { text: value, value: value };
                                    }
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  selectError={errors?.dealer_action?.message}
                                />
                              )}
                            />
                          </>
                        ),
                        actionBtn: (
                          <>
                            <Button
                              theme={theme}
                              defaultBtn
                              className="btn-delete"
                              iconText={<Icon name="DeleteIcon" />}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
              </SmallAccordionWrap>
            )}
            <Controller
              name="templates"
              control={control}
              defaultValue={[]}
              rules={{
                required: {
                  value: true,
                  message: ValidationMessages.TEMPLATE_REQUIRED,
                },
              }}
              render={({ field }) => (
                <AutocompleteWrap className="auto-complete-wrap">
                  <Autocomplete
                    disablePortal={true}
                    id={"templates"}
                    theme={theme}
                    label={"Template"}
                    placeholder="Type here.."
                    // advanced
                    disableCloseOnSelect
                    multiple={true as never}
                    limitTags={1}
                    capitalizeLabel={false}
                    options={tenant_templates?.data?.map((item) => {
                      return {
                        label: item.template_name,
                        id: item.id,
                      };
                    })}
                    items={tenant_templates?.data?.map((item) => {
                      return {
                        label: item.template_name,
                        id: item.id,
                      };
                    })}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    {...field}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    searchMatchFrom="start"
                    value={field.value}
                    error={errors?.templates?.message as any}
                  />
                </AutocompleteWrap>
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            type="submit"
            text="Save Template"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default AddNewStationery;
