import { FC, useState, useEffect, useMemo, useContext } from "react";
import { useTheme } from "@mui/material";
import { Button, Box } from "@ntpkunity/controls";
import { DrawerWrap, DataTableWrap } from "@sharedComponents";
import AddFnIMarketingMaterial from "./marketing-material-drawer";
import FniMarketingMaterialTable from "./marketing-material-table";
import { IFinancialInsurance, IImage } from "Interface";
import { ActionOptions } from "Enums";
import { useForm } from "react-hook-form";
import { DealerContext } from "context";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

const FniMarketingMaterial: FC = () => {
  const theme = useTheme();
  const { dealer_code } = useContext(DealerContext);
  const [popUpState, setPopUpState] = useState(false);
  const [enabledPin, setEnabledPin] = useState(false);
  const [images, setImages] = useState<Array<IImage>>([]);
  const [state, dispatch] = useSetupsStore();

  const [actionTitle, setActionTitle] = useState<string>("Add New Material");

  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IFinancialInsurance>();
  const [ProductState, setProductState] = useState<boolean>(false);

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Material");
        setActionData(value);
        setProductState(true);
        dispatch(setOpenDrawer(true));
        setImages([]);
        form.reset({} as IFinancialInsurance);
      default:
        return;
    }
  };

  const defaultValues: IFinancialInsurance = useMemo(() => {
    const updatedFormDefaultValues = actionData;
    setActionData(updatedFormDefaultValues);
    return actionData;
  }, [actionData]) as unknown as IFinancialInsurance;

  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      form.reset({ ...defaultValues });
    } else {
      form.reset();
    }
  }, [defaultValues]);

  useEffect(() => {
    if (ProductState == false) {
      form.reset();
      dispatch(setOpenDrawer(false));
    }
  }, [ProductState]);

  const form: {
    handleSubmit: any;
    reset: any;
  } = useForm<IFinancialInsurance>();

  return (
    <DrawerWrap open={popUpState}>
      <form onSubmit={(e) => e.preventDefault()}>
        {ProductState && (
          <AddFnIMarketingMaterial
            popUpState={ProductState}
            setPopUpState={setProductState}
            title={actionTitle}
            actionType={actionType}
            financialInsuranceData={actionData}
            form={form}
            enabledPin={enabledPin}
            setImages={setImages}
            images={images}
          ></AddFnIMarketingMaterial>
        )}
      </form>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <FniMarketingMaterialTable
          setPopUpState={setProductState}
          setActionData={setActionData}
          setActionType={setActionType}
          setActionTitle={setActionTitle}
          actionData={actionData}
        ></FniMarketingMaterialTable>
        <Button
          id="addNewOptionCategory"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Material"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
      </DataTableWrap>
    </DrawerWrap>
  );
};

export default FniMarketingMaterial;
