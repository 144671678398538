import React, { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { IconButton, TablePagination, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { setOpenDrawer } from "../../../Stores/SetupStore/Actions";
import { useSetupsStore } from "../../../Stores/SetupStore/Store";
import {
  Icon,
  Menu,
  Button,
  DataTable,
  Tooltip,
  Box,
  Input,
  Switch,
} from "@ntpkunity/controls";
import {
  ConfirmationDialog,
  DataTableWrap,
  PaginationWrap,
} from "@sharedComponents";
import { useGetStripeAccountById } from "services/integration-api.service";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import {
  useUpdateLenderIntegration,
  UseQuery_getAllLenderIntegration_filter,
  useUpdateLenderIntegrationParentStatus,
  UseGetHubexCredentialsByProvider,
  UseUpdateHubexCredentials,
  UseDeleteHubexCredentials,
} from "services";
import {
  UseQueryGetDealerIntergrations,
  UseQuery_getAllDealerIntergrations,
  useDeleteIntegrationProvider,
} from "services/dealer-integration.service";
import { useGetDealerProfileByDealerId } from "services/dealer-configurations.service";
import DealerTableRow from "./dealer-table-row";
import ChevronDown from "shared/assets/images/chevron-down";
import { AddDealerIntegration } from "components";
import { IntegrationType, Providers } from "Enums/IntegrationTypesAndProviders";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ProviderMapping } from "modules";

const DealerIntergrations: FC<{
  dealer_code?: string;
  isProfileDirty?: any;
  form: any;
  onPopupStateChange: (popUpState: any) => unknown;
}> = ({ dealer_code, isProfileDirty, onPopupStateChange, form }) => {
  const { watch } = form;
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [addLenderProvider, setAddLenderProvider] = useState(false);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}&dealer_code=${dealer_code}`
  );
  const { data: integrationData } =
    UseQuery_getAllDealerIntergrations(columnFilters);
  const { data: dealerIntegrations } =
    UseQueryGetDealerIntergrations(dealer_code);
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.EDIT
  );
  const { mutateAsync: deleteHubexCredentials } = UseDeleteHubexCredentials();

  const {
    mutate: getHubexCredentialsByProvider,
    data: hubexConfigurationsByProvider,
  } = UseGetHubexCredentialsByProvider();
  const { actions } = useStoreContext();
  const [penDealerId, setPenDealerId] = useState();
  const [shiftDigitalId, setShiftDigitalId] = useState();
  const [payoffDealerID, setPayoffDealerID] = useState();
  const [stripeDealerID, setStripeDealerID] = useState();
  const [homenetDealerID, setHomenetDealerID] = useState();
  const [actionTitle, setActionTitle] = useState<string>("Edit Provider");
  const [dealerPopupState, setDealerPopupState] = useState<boolean>(false);
  const [actionData, setActionData] = useState<any>();
  const [integrationFilter, setIntegrationFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [integratedbyfilter, setIntegratedByFilter] = useState("");
  const [marketScanCredentials, setMarketScanCredentials] = useState(false);
  const [isRouteOneCredentials, setIsRouteOneCredentials] = useState(false);
  const [providerMappingState, setProviderMappingState] = useState(false);
  const [providerId, setProviderId] = useState(undefined);
  const [integrationType, setIntegrationType] = useState(undefined);

  const [, dispatch] = useSetupsStore();
  const [detailedSubmited, setDetailSubmitted] = useState<boolean>(false);
  const { mutate: deleteProvider } = useDeleteIntegrationProvider();
  const pen_dealer_id = watch("profile")?.pen_dealer_id;
  const shift_digital_dealer_id = watch("profile")?.shift_digital_dealer_id;
  const payoff_dealer_id = watch("profile")?.payoff_dealer_id;
  const home_net_dealer_id = watch("profile")?.home_net_dealer_id;
  const stripe_dealer_id = watch("profile")?.stripe_dealer_id;
  useEffect(() => {
    setPenDealerId(watch("profile")?.pen_dealer_id);
    setShiftDigitalId(watch("profile")?.shift_digital_dealer_id);
    setPayoffDealerID(watch("profile")?.payoff_dealer_id);
    setStripeDealerID(watch("profile")?.stripe_dealer_id);
    setHomenetDealerID(watch("profile")?.home_net_dealer_id);
  }, [
    pen_dealer_id,
    shift_digital_dealer_id,
    payoff_dealer_id,
    stripe_dealer_id,
    home_net_dealer_id,
  ]);

  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `page_number=${pageNumber}&page_size=${pageSize}&dealer_code=${dealer_code}`
      );
    }
  }, [dealer_code]);
  useEffect(() => {
    getHubexCredentialsByProvider({
      provider: Providers.MARKETSCAN,
      dealer_code: dealer_code,
    });
  }, [dealer_code]);

  useEffect(() => {
    if (integrationData) {
      checkLenderManagementIntegration();
    }
  }, [integrationData]);

  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}&dealer_code=${dealer_code}`;
    if (
      integrationFilter.trimStart() != null &&
      integrationFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&integration=${integrationFilter}`);
    }
    if (
      integratedbyfilter.trimStart() != null &&
      integratedbyfilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&integrated_by=${integratedbyfilter}`
      );
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${statusFilter}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${statusFilter}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  useEffect(() => {
    if (dealerPopupState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [dealerPopupState]);
  const onEdit = (formData: any, actionType: ActionOptions) => {
    setActionType(actionType);
    if (actionType === ActionOptions.ADD_PROVIDER) {
      setActionTitle("Add New Provider");
    } else {
      setActionTitle("Edit Provider Type");
    }
    setActionData(formData);
    setDealerPopupState(true);
    dispatch(setOpenDrawer(true));
  };
  const onDelete = (formData) => {
    setActionData(formData);
    setConfirmationPopupState(true);
  };
  const onProviderMapping = (id, integration_type) => {
    setProviderMappingState(true);
    setProviderId(id);
    setIntegrationType(integration_type);
  };
  const onDeleteConfirm = (e: any) => {
    deleteProvider(actionData?.id, {
      onSuccess: (data, variables, context) => {
        deleteHubexCredentials({
          dealer_code: dealer_code,
          provider_name: actionData?.provider_name,
        });
        setActionData(undefined);
      },
    });
  };
  onPopupStateChange(dealerPopupState);

  const theme = useTheme();
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Integration");
        setActionData(value);
        setDealerPopupState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const checkLenderManagementIntegration = () => {
    const hasLenderProvider = integrationData?.result?.some((integration) => {
      if (
        integration?.integration_type ===
          IntegrationType.DEALER_LENDER_ASSOCIATION &&
        integration?.providers.length > 0
      ) {
        return true;
      }
      return false;
    });
    setAddLenderProvider(hasLenderProvider);
  };

  return (
    <>
      <DataTableWrap theme={theme} className="table-tabs-pagination-button ">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell>Integrations</TableCell>
                <TableCell>Integrated By</TableCell>
                <TableCell className="indent-cell">Status</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={""}
                      onChange={(value) => {
                        setIntegrationFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={integrationFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={""}
                      onChange={(value) => {
                        setIntegratedByFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={integratedbyfilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setStatusFilter(value);
                      }}
                      value={statusFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {integrationData?.result?.map((integrationsRow, index) => {
                return (
                  <React.Fragment key={integrationsRow.id + "parent"}>
                    <DealerTableRow
                      dealer_code={dealer_code}
                      marketScanConfig={hubexConfigurationsByProvider}
                      integrationsRow={integrationsRow}
                      onEdit={(data, actionType) => onEdit(data, actionType)}
                      onDelete={(data) => onDelete(data)}
                      onProviderMapping={onProviderMapping}
                      penDealerId={penDealerId}
                      detailedSubmited={detailedSubmited}
                      shiftDigitalId={shiftDigitalId}
                      marketScanCredentials={marketScanCredentials}
                      setMarketScanCredentials={setMarketScanCredentials}
                      payoffDealerID={payoffDealerID}
                      stripeDealerID={stripeDealerID}
                      homenetDealerID={homenetDealerID}
                    />
                  </React.Fragment>
                );
              })}
            </>
          }
        />
        <PaginationWrap>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={integrationData ? integrationData?.total_results : -1}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: ChevronDown,
              MenuProps: { disablePortal: true },
            }}
          />
        </PaginationWrap>
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Integration"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
      </DataTableWrap>
      {dealerPopupState && (
        <AddDealerIntegration
          openPopUp={dealerPopupState}
          dealer_code={dealer_code}
          penDealerId={penDealerId}
          setPopUpState={setDealerPopupState}
          title={actionTitle}
          isProfileDirty={isProfileDirty}
          actionType={actionType}
          selectedProvider={actionData}
          dealerIntegraions={dealerIntegrations}
          dealerForm={form}
          addLenderProvider={addLenderProvider}
          shiftDigitalId={shiftDigitalId}
          marketScanConfig={hubexConfigurationsByProvider}
          setMarketScanCredentials={setMarketScanCredentials}
          stripeDealerID={stripeDealerID}
          setIsRouteOneCredentials={setIsRouteOneCredentials}
        />
      )}
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
      {providerMappingState && (
        <ProviderMapping
          providerId={providerId}
          setProviderMappingState={setProviderMappingState}
          integrationType={integrationType}
        />
      )}
    </>
  );
};

export default DealerIntergrations;
