import { FC, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TablePagination, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  Menu,
  DataTable,
  Button,
  Box,
  Grid,
  Typography,
  PersistentDrawer,
  Input,
} from "@ntpkunity/controls";
import {
  DataTableWrap,
  DrawerWrap,
  PageHeader,
  PaginationWrap,
  Switch,
} from "@sharedComponents";
import ChevronDown from "shared/assets/images/chevron-down";
import {
  UseQuery_getAllRebate_filter,
  UseMutation_UpdateRebate,
  UseMutation_AddRebate,
  UseMutation_DeleteRebate,
} from "services";
import { IRebate, IRebateFilter, IImage, IStates } from "Interface";
import { AddNewRebate } from "./AddNewRebate";
import { useStoreContext } from "Stores/SetupStore/Store";
import { LayoutWithSideNav } from "layout";
import { DealerContext } from "context";
import { useQueryClient } from "react-query";
import { ActionOptions, QueryKeys, ToastMessages } from "Enums";
import {
  UseQuery_getAllStates,
  useGetContractTerms,
} from "services/dealer-configurations.service";
interface IFormInputs {
  id?: number;
  rebate_type?: string;
  rebate_name: string;
  rebate_description: string;
  rebate_code?: string;
  rebate_amount: string;
  rebate_images: string;
  required_documents: string[];
  finance_type: string[];
  payment_term: string[];
  applicable_states: string[];
  company_id: number;
  dealer_code: string;
  is_active: boolean;
}

const RebateTable: FC<any> = ({ onEdit }) => {
  const theme = useTheme();
  const form = useForm<IFormInputs>({ mode: "all" });
  const { dealerData, dealer_code } = useContext(DealerContext);
  const { actions } = useStoreContext();
  const { getValues, reset, setValue, handleSubmit, setError, watch } = form;
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [popUpState, setPopUpState] = useState(false);
  const [popUpOpenState, setPopUpOpenState] = useState("");
  const [rebateName, setRebateName] = useState("");
  const [rebateCode, setRebateCode] = useState("");
  const [rebateAmount, setRebateAmount] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [dealer_id, setDealerId] = useState("");
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const [images, setImages] = useState<Array<IImage>>([]);
  useEffect(() => {
    if (dealerData) {
      setDealerId(
        dealerData?.find((obj) => obj?.dealer_code === dealer_code)?.id
      );
    }
  }, [dealerData]);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    }
  }, [dealer_code]);
  UseQuery_getAllStates();
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}&dealer_code=${dealer_code}`
  );
  const { data } = UseQuery_getAllRebate_filter(columnFilters);
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const usaStates: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );
  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}
        &dealer_code=${dealer_code}`;
        if (rebateName.trimStart() != null && rebateName.trimStart() != "") {
          query_string = query_string.concat(`&rebate_name=${rebateName}`);
        }
        if (rebateCode.trimStart() != null && rebateCode.trimStart() != "") {
          query_string = query_string.concat(`&rebate_code=${rebateCode}`);
        }
        if (rebateAmount.trimStart() != null && rebateAmount.trimStart() != "") {
          query_string = query_string.concat(`&rebate_amount=${rebateAmount}`);
        }
        if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
          if (
            "enabled"
              .toLowerCase()
              .startsWith(statusFilter.slice(0, Math.max("enabled".length - 1, 1)))
          ) {
            query_string = query_string.concat(`&is_active=${true}`);
          } else if (
            "disabled"
              .toLowerCase()
              .startsWith(statusFilter.slice(0, Math.max("disabled".length - 1, 1)))
          ) {
            query_string = query_string.concat(`&is_active=${false}`);
          } else {
            query_string = query_string.concat(`&is_active=None`);
          }
        }
        return query_string;
      };
      const handleChangePage = (_event: unknown, newPage: number) => {
        setPageNumber(newPage);
        setColumnFilters(setQueryString(newPage, pageSize));
      };
      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setPageSize(+event.target.value);
        setPageNumber(0);
        setColumnFilters(setQueryString(0, +event.target.value));
      };
    
      const handleFilter = () => {
        setPageNumber(0);
        setColumnFilters(setQueryString(0, pageSize));
      };
      const rebateFilter: IRebateFilter = data;
      const { mutate: updateRebateData } = UseMutation_UpdateRebate()
      const { mutate: addRebateData } = UseMutation_AddRebate()
      const { mutate: deleteRebate } = UseMutation_DeleteRebate();
      const handleEnableChange = (data: IRebate, event) => {
        updateRebateData({
          id: data?.id,
          is_active: event.target.checked,
        });
      };
      const onClose = () => {
        let children = document.querySelectorAll(".drawer-header .pinned");
        if (children.length == 0) {
          setPopUpState(false);
        }
        reset({} as IFormInputs);
      };
      const handleError = (error) => {
        if (
          error?.response?.data?.detail?.message ===
          "Rebate Code and Name should be unique"
        ) {
          setError("rebate_name", { message: "Rebate name should be unique" });
          setError("rebate_code", {
            message: "Rebate code should be unique",
          });
          return;
        } else if (
          error?.response?.data?.detail?.message === "Rebate name should be unique"
        ) {
          setError("rebate_name", { message: "Rebate name should be unique" });
          return;
        } else if (
          error?.response?.data?.detail?.message ===
          "Rebate code should be unique"
        ) {
          setError("rebate_code", {
            message: "Rebate code should be unique",
          });
          return;
        } else {
          onClose();
          actions.setToast({
            toastMessage: error?.response?.data?.detail?.message,
            toastState: true,
            variant: "error",
          });
        }
      };
    const handleshow = () => {
        reset({} as IFormInputs);
        form.reset({} as any);
        setPopUpOpenState(ActionOptions.ADD);
        setPopUpState(true);
      };
      const handleSaveRebate = (form: any) => {
        const data = {
                rebate_type: form.rebate_type,
                rebate_name: form.rebate_name,
                rebate_description: form.rebate_description,
                rebate_code: form.rebate_code,
                rebate_amount: form.rebate_amount,
                required_documents:form.required_documents,
                finance_type: form.finance_type, 
                payment_term: form.payment_term,
                applicable_states: form.applicable_states,
                is_active: form.is_active,
                dealer_code: dealer_code,
                rebate_images:images,
            }
        if (popUpOpenState === ActionOptions.ADD) {
            addRebateData(data, {
            onSuccess() {
              actions.setToast({
                toastMessage: "Record Added Successfully",
                toastState: true,
              });
              setImages([]);
              onClose();
            },
            onError: (error) => {
            setPopUpState(true);
              handleError(error);
            },
          });
        } else {
          updateRebateData(
            {
                rebate_type: form.rebate_type,
                rebate_name: form.rebate_name,
                rebate_description: form.rebate_description,
                rebate_code: form.rebate_code,
                rebate_amount: form.rebate_amount,
                required_documents:form.required_documents,
                finance_type: form.finance_type, 
                payment_term: form.payment_term,
                applicable_states: form.applicable_states,
                is_active: form.is_active,
                id:getValues("id"),
                dealer_code: dealer_code,
                rebate_images:images,
            },
            {
              onSuccess() {
                reset({} as IFormInputs);
                setPopUpState(false);
                actions.setToast({
                  toastMessage: "Record Updated Successfully",
                  toastState: true,
                });
              setImages([]);
                
              },
              onError: (error) => {
                setPopUpState(true);  
                handleError(error);
              },
            }
          );
        }
      };
      const handleEditRebate = (item: any) => {
        setValue("id", item?.id);
        setImages(item?.rebate_images);
        setValue("payment_term", item?.payment_term?.map(str => +str));
        setValue("applicable_states", item?.applicable_states?.map(str => +str));
        reset({
          ...item,
        });
        setPopUpState(true);
        setPopUpOpenState(ActionOptions.EDIT);
      };
      const handleDeleteRebate = (item: any) => {
        deleteRebate(item?.id, {
          onSuccess: () => {
            actions.setToast({
              toastMessage: ToastMessages.Record_Deleted_Success,
              toastState: true,
            });
          },
        });
      };
    return (
      <>
      <DrawerWrap open={popUpState}>
        <LayoutWithSideNav theme={theme}>
          <PageHeader
            className="main-page-header"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ fontSize: 36 }}
                  rowGap={5}
                  mt={1}
                  theme={theme}
                  children={"Dealer Rebates"}
                />
              </Grid>
              <Grid
                theme={theme}
                item
                xs={12}
                lg={5}
                sm={12}
                md={6}
                textAlign="right"
              >
                <Button
                  theme={theme}
                  primary
                  text="Add New Rebate"
                  onClick={handleshow}
                  sx={{ mb: 5 }}
                />
              </Grid>
            </Grid>
          </PageHeader>

          <DataTableWrap theme={theme} className="table-pagination">
            <Box theme={theme} className="scroll">
              <Box className="scroll-hide spr-border" theme={theme} />
            </Box>
            <DataTable
              theme={theme}
              variant={"basic"}
              theadChildren={
                <>
                  <TableRow key={0}>
                    <TableCell>Rebate Name</TableCell>
                    <TableCell>Rebate Code</TableCell>
                    <TableCell>Rebate Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell className="action-cell"></TableCell>
                  </TableRow>
                  <TableRow key={1} className="filters-row">
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"rebateName"}
                          onChange={(value) => {
                            setRebateName(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={rebateName?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"rebateCode"}
                          onChange={(value) => {
                            setRebateCode(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={rebateCode?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"rebateAmount"}
                          onChange={(value) => {
                            setRebateAmount(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={rebateAmount?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={""}
                          onChange={(value) => {
                            setStatusFilter(value);
                          }}
                          value={statusFilter?.trimStart()}
                          onBlur={() => {
                            handleFilter();
                          }}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell className="action-cell"></TableCell>
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {rebateFilter?.result &&
                    rebateFilter?.result?.map((item, index) => (
                      <TableRow className="child-tr" key={index}>
                        {/* <TableCell className="checkbox-cell fixed-cell">
                <Checkbox label="" theme={theme} />
              </TableCell> */}
                        <TableCell>{item?.rebate_name}</TableCell>
                        <TableCell>{item?.rebate_code}</TableCell>
                        <TableCell>
                          {defaultCurrency}{" "}
                          {item?.rebate_amount?.toLocaleString()}
                        </TableCell>
                        <TableCell>
                          <Switch
                            id={"ListDataStatus" + item?.id}
                            theme={theme}
                            varient={"basic"}
                            switchEnabled={item?.is_active}
                            onChange={(event) =>
                              handleEnableChange(item, event)
                            }
                            label={item?.is_active ? "Enabled" : "Disabled"}
                          />
                        </TableCell>
                        <TableCell className="action-cell fixed-cell">
                          <Menu
                            theme={theme}
                            options={[
                              {
                                optionText: (
                                  <>
                                    <Icon
                                      className="menu-icon"
                                      name="EditIcon"
                                    />{" "}
                                    Edit
                                  </>
                                ),
                                optionValue: "edit",
                              },
                              {
                                optionText: (
                                  <>
                                    <Icon
                                      className="menu-icon"
                                      name="DeleteIcon"
                                    />{" "}
                                    Delete
                                  </>
                                ),
                                optionValue: "delete",
                              },
                            ]}
                            handleOptionClick={(_event, _key, value) => {
                              switch (value) {
                                case "edit":
                                  handleEditRebate(item);
                                  break;
                                case "delete":
                                  handleDeleteRebate(item)
                                 break
                              }
                            }}
                            render={(onMenuSelection) => (
                              <Button
                                defaultBtn
                                iconText={<Icon name="MoreIcon" />}
                                onClick={onMenuSelection}
                              ></Button>
                            )}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              }
            />
            <PaginationWrap>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={rebateFilter ? rebateFilter?.total_results : -1}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  IconComponent: ChevronDown,
                  MenuProps: { disablePortal: true },
                }}
              />
            </PaginationWrap>
          </DataTableWrap>
          <PersistentDrawer
            title={
              popUpOpenState === ActionOptions.ADD
                ? "Add New Rebate"
                : "Edit Rebate"
            }
            openPopUp={popUpState}
            setPopUpState={setPopUpState}
            theme={theme}
            enabledPin={true}
            customFooter={
              <Button
                theme={theme}
                primary
                type="submit"
                text="Save Rebate"
                fullWidth
                onClick={handleSubmit(handleSaveRebate)}
              />
            }
          >
            <AddNewRebate
              form={form}
              setImages={setImages}
              images={images}
              popUpOpenState={popUpOpenState}
              //   module={module}
            />
          </PersistentDrawer>
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default RebateTable;
