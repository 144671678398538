import React, { FC } from "react";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, DataTable, Input } from "@ntpkunity/controls";
import { Controller } from "react-hook-form";
import { BorderedTableWrap, MultiSelectAll } from "@sharedComponents";
import { IFPCategoryMappingProps } from "Interface";
import { FinancialInsuranceCategory } from "Enums";

const FinancialInsuranceTable: FC<IFPCategoryMappingProps> = ({
  form,
  setUpdatedFPCategoryRows,
  financeTypes,
  fpCategoryMappings,
  categoriesArray,
}) => {
  const theme = useTheme();
  const { setValue, getValues, control } = form;

  const financeTypeItems =
    financeTypes?.map((finance_type) => ({
      text: finance_type.name,
      value: finance_type.id,
    })) || [];

  const categories = categoriesArray.map(({ code, name }) => ({
    text: name,
    value: name,
  }));

  const handleRowChange = (index) => {
    setUpdatedFPCategoryRows((prev) => new Set(prev).add(index));
  };

  return (
    <BorderedTableWrap theme={theme} className="custom-table-wrap">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell className="sr-num"></TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Finance Type</TableCell>
              <TableCell>External Code</TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={categories?.map((item, index) => {
          return (
            <React.Fragment key={index + 1}>
              <TableRow className="child-tr">
                <TableCell className="sr-num">{index + 1}</TableCell>
                <TableCell>{item.value}</TableCell>
                <Controller
                  name={`fpMapping.${index}.category`}
                  control={control}
                  defaultValue={item.text}
                  render={({ field }) => <input type="hidden" {...field} />}
                />
                <TableCell className="input-cell">
                  <Controller
                    name={`fpMapping.${index}.finance_type`}
                    control={control}
                    defaultValue={
                      fpCategoryMappings?.data?.find(
                        (record) => record.category === item.text
                      )?.finance_type
                    }
                    render={({ field: { value, onChange } }) => (
                      <MultiSelectAll
                        id={`fpMapping.${index}.finance_type`}
                        name={`fpMapping.${index}.finance_type`}
                        theme={theme}
                        isSelectAll={false}
                        getValues={getValues}
                        setValue={setValue}
                        items={financeTypeItems}
                        value={
                          value
                            ? (value as any[])
                            : fpCategoryMappings?.data?.find(
                                (record) => record.category === item.text
                              )?.finance_type
                        }
                        onChange={(e) => {
                          onChange(e);
                          handleRowChange(index);
                        }}
                        disablePortal={false}
                      />
                    )}
                  />
                </TableCell>
                <TableCell className="input-cell">
                  <Controller
                    name={`fpMapping.${index}.external_code`}
                    control={control}
                    defaultValue={
                      fpCategoryMappings?.data?.find(
                        (record) => record.category === item.text
                      )?.external_code
                    }
                    render={({ field: { value, onChange } }) => (
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Type here..."}
                        type="string"
                        id={`fpMapping.${index}.external_code`}
                        onChange={(e) => {
                          onChange(e);
                          handleRowChange(index);
                        }}
                        value={
                          value
                            ? value?.trimStart()
                            : fpCategoryMappings?.data?.find(
                                (record) => record.category === item.text
                              )?.external_code
                        }
                      ></Input>
                    )}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      />
    </BorderedTableWrap>
  );
};

export default FinancialInsuranceTable;
